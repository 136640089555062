export type DepartmentListResponse = {
    departments: Department[];
    users: Record<string, string>;
    memberships: Record<string, string[]>;
}

export const ASSIGN_MANUAL = 0;
export const ASSIGN_DEFAULT = 1;
export const ASSIGN_AI = 2;

export type Department = {
    department_id: string;
    department: string; // department name
    email: string;
    colour: string; // for AI department assign column and comments
    default_assignment_type: number; // if ai assigned, default, or manual
    abbreviation?: string;
}

export type DepartmentRequest = {
    department_id: string;
    department: string; // department name
    email: string;
    colour: string; // for AI department assign column and comments
    default_assignment_type: number; // if ai assigned, default, or manual
    abbreviation?: string;
    members: string[];
}