import 'App.css';
import '../Sidebar.css';
import { useCallback, useEffect, useRef, useState } from "react";
import { ArrowLeft } from "lucide-react";
import { ButtonTabs } from "components/ButtonTabs/ButtonTabs.tsx";
import DepartmentTab from "components/Sidebar/Settings/DepartmentsTab.tsx";
import UserTab from "components/Sidebar/Settings/UsersTab.tsx";
import { useBlocker, useNavigate } from "react-router-dom";
import { usePopup } from 'hooks/Popup';

function SettingsMenu() {
    const dialog = useRef<HTMLDialogElement>(null);
    const navigate = useNavigate();
    const [tab, setTab] = useState("Users");
    const [tabDirty, setTabDirty] = useState(false);
    const [blockedTab, setBlockedTab] = useState("");
    const goBackPopup = usePopup("Go Back")

    const shouldBlock = useCallback(() => {
        return tabDirty
    }, [tabDirty]);
    const blocker = useBlocker(shouldBlock);

    useEffect(() => {
        if (tabDirty) {
            window.onbeforeunload = () => {
                return ""
            };
        } else {
            window.onbeforeunload = null;
        }

        // Unmount the window.onbeforeunload event
        return () => { window.onbeforeunload = null };
    }, [tabDirty]);

    useEffect(() => {
        if (blocker.state === "blocked") {
            setBlockedTab("");
            dialog.current?.showModal();
        }
    }, [blocker]);

    function backPage() {
        if (tabDirty) {
            setBlockedTab("");
            dialog.current?.showModal();
            return;
        }
        navigate(-1);
    }

    function changeTab(newTab: string) {
        if (newTab === tab) return;
        if (tabDirty) {
            setBlockedTab(newTab);
            dialog.current?.showModal();
            return;
        }
        setTabDirty(false);
        setTab(newTab);
    }

    function dirtyChangeTab() {
        setTabDirty(false);
        if (blockedTab !== "") {
            setTab(blockedTab);
            dialog.current?.close();
            return
        }
        else navigate(-1);
        if (blocker.state === "blocked") blocker.proceed();
    }

    function stayOnPage() {
        dialog.current?.close();
    }

    return (<>
        <div className="fixed top-0 left-16 right-0 bottom-0 z-50 p-7">
            <div className="flex gap-x-4 items-center pb-8">
                <button className="ghost-button clear-button text-black" onClick={backPage} {...goBackPopup.props} ><ArrowLeft /></button>
                {goBackPopup.component}
                <span className="text-xl font-semibold">Settings</span>
            </div>
            <div className="flex content-center gap-x-2 mb-4">
                <ButtonTabs tab={tab} tabs={["Users", "Departments"]} onTabSelected={changeTab} />
                {tabDirty && <span className="bg-yellow-100 content-center p-1 rounded-lg border border-yellow-500">Unsaved changes</span>}
            </div>
            {tab === "Departments" && <DepartmentTab setDirty={setTabDirty} />}
            {tab === "Users" && <UserTab setDirty={setTabDirty} />}
        </div >
        <dialog id="settings-delete" ref={dialog} className="bg-transparent">
            <div className="bg-white text-zinc-950 p-4 rounded-lg w-96 text-left">
                <h3 className="select-none text-zinc-950 font-bold pb-4">Unsaved changes</h3>
                <span>You have unsaved changes. Are you sure you want to leave the page?</span>
                <div className="flex gap-x-4 justify-end pt-4 items-center">
                    <button onClick={dirtyChangeTab} className="bg-zinc-100 text-zinc-950 hover:bg-zinc-200">Leave page</button>
                    <button className="bg-black" onClick={stayOnPage}>Stay on page</button>
                </div>
            </div>
        </dialog>
    </>);
}



export default SettingsMenu;
