import { useEffect, useRef, useState } from "react";
import 'App.css'
import { Breadcrumb } from "types/requests";
import { Folder } from "lucide-react";

interface MenuProps {
    open: boolean;
    setOpen: (o: boolean) => void;
    onNav: (id: string) => void;
    position?: number[];
    items: Breadcrumb[];
}
// TODO: values besides pure strings
function BreadcrumbMenu(props: MenuProps) {
    const dialog = useRef<HTMLDialogElement>(null);
    const [showOptions, setShowOptions] = useState(false);
    const [position, setPosition] = useState([0, 0]);

    useEffect(() => {
        setShowOptions(props.open);
        if (props.position)
            setPosition(props.position);
    }, [props.open, props.position])

    function closeMenu() {
        props.setOpen(false);
        setShowOptions(false);

        dialog.current?.close()
    }

    function folderClicked(id: string) {
        props.onNav(id);
        closeMenu();
    }

    //Handle clicks outside menus
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: Event) => {
        if (dialog.current?.open) {
            if ((e.target as HTMLElement).id === "rename" || showOptions) {
                closeMenu();
            }
        }
        else if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
            closeMenu();
        }
    };

    const menuStyle = {
        "top": position[1],
        "left": position[0],
        "border": "1px white solid",
        "backgroundColor": "white",
        "filter": "drop-shadow(1px 1px 1px #ddd)",
        "borderRadius": "6px",
    };

    if (!showOptions) return (<></>);
    return (
        <>
            <div ref={menuRef} className={"absolute dialog z-10 "} style={menuStyle}>
                <div className="flex flex-col">
                    {props.items.map(i =>
                        <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={() => folderClicked(i.id)}>
                            <div className="px-2"><Folder /></div>{i.name}
                        </button>
                    )}
                </div>
            </div>
        </>
    )
}

export default BreadcrumbMenu;
