import '../../table-extract.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "@glideapps/glide-data-grid/dist/index.css";
import React, { useEffect, useState, useRef } from "react";
import { ChevronDown, Trash2 } from 'lucide-react';
import { Column, TableResponse, Taggable, UserListResponse, Value } from "types/requests";
import { Filter, getIconByType, Option } from "../types/types";
import FilterTypePicker from "./FilterTypePicker";
import { BEComment } from "types/comments.ts";
import PickerMenuContent from "components/Sidebar/Settings/menus/PickerMenu/PickerMenuContent.tsx";
import { DepartmentListResponse } from "types/departments.ts";
import { APIResponse, request } from "services/api.ts";
import { usePopup } from 'hooks/Popup';

interface AddFilterMenuProps {
    isOpen: boolean;
    setOpen: (o: boolean) => void;
    position: number[];
    currentFilter?: Filter;
    setCurrentFilter: (f: Filter | undefined) => void;
    onAddFilter: (f: Filter) => void;
    onDeleteFilter: (colName: string) => void;
    onFilterChanged: (f: Filter) => void;
    filters: Filter[];
    table: TableResponse;
    diffTable: TableResponse;
}

export type FilterData = {
    operation: string;
    filterContent: string
}

export type FilterSchema = {
    id: number;
    label: string;
    showOptions: boolean;
    isMatch: (cell: string, isSepArr: boolean, filter: string) => boolean;
}


// Filter Schema IDs
const stringContainsFSId = 0;
const stringDoesNotContainFSId = 1;
const stringIsFSId = 2;
const stringIsNotFSId = 3;
const stringStartsWithFSId = 4;
const stringEndsWithFSId = 6;
const stringIsEmptyFSId = 7;
const stringIsNotEmptyFSId = 8;

const dropdownContainsFSId = 9;
const dropdownDoesNotContainFSId = 10;
const dropdownIsEmptyFSId = 11;
const dropdownIsNotEmptyFSId = 12;

const chipsContainsAllFSId = 13;
const chipsDoesNotContainFSId = 14;
const chipsIsEmptyFSId = 15;
const chipsIsNotEmptyFSId = 16;
const chipsContainsAnyFSId = 17;

const commentMentionsAllFSId = 18;
const commentHasCommentsFSId = 19;
const commentNoCommentsFSId = 20;
const commentContainsFSId = 21;
const commentMentionsAnyFSId = 22;
export const noPreviewOperations = [commentMentionsAnyFSId, commentMentionsAllFSId];

export const splitChar = "|";

export function getFilterSchema(type: string, operation: string): FilterSchema | undefined {
    switch (type) {
        case "change":
        case "categorisation":
        case "classification":
        case "dropdown":
            return dropdownFilterSchemas.filter(f => f.label === operation)[0];
        case "departments":
        case "chips":
            return chipsFilterSchemas.filter(f => f.label === operation)[0];
        case "comment":
            return commentFilterSchemas.filter(f => f.label === operation)[0];
        case "string":
        case "text":
        case "translation":
        case "deviation":
        default:
            return stringFilterSchemas.filter(f => f.label === operation)[0];
    }
}

export function valueToMatchParams(value: Value, comments?: Map<string, BEComment[]>): [success: boolean, filterReadyCellData: string, isSeparated: boolean] {
    let filterReadyCellData = "";
    let isSeparated = false;
    switch (value.type) {
        case "string":
        case "translation":
        case "text":
        case "deviation":
            filterReadyCellData = value.value;
            break;
        case "comment":
            // eslint-disable-next-line no-case-declarations
            const comment_cell = JSON.parse(value.value);
            if (!("id" in comment_cell)) break;
            // eslint-disable-next-line no-case-declarations
            const comment_cell_id: string = comment_cell["id"];
            filterReadyCellData = JSON.stringify(comments?.get(comment_cell_id) ?? [])
            break;
        case "departments":
        case "chips":
            // eslint-disable-next-line no-case-declarations
            const chips = value.value.split("|")[0];
            if (chips.length === 0) break;
            filterReadyCellData = chips.split(",").join(splitChar);
            isSeparated = true;
            break;
        case "change":
        case "categorisation":
        case "classification":
        case "dropdown":
            filterReadyCellData = value.value.split("|")[0].split("^")[0];
            break
        default: return [false, "", false];
    }

    return [true, filterReadyCellData, isSeparated];
}

function clean(i: string) {
    return i.trim().toLowerCase();
}

function multiFunc(cell: string, i: boolean, filter: string, baseFunc: (c: string, f: string) => boolean): boolean {
    if (!i) return baseFunc(cell, filter);
    const items = cell.split(splitChar);
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (baseFunc(item, filter)) return true;
    }
    return false;
}

const stringFilterSchemas: FilterSchema[] = [
    {
        id: stringContainsFSId,
        label: "contains",
        showOptions: true,
        isMatch: (c, i, f) => {
            return multiFunc(c, i, f, (cd, fd) => { return clean(cd).includes(clean(fd)) });
        }
    },
    {
        id: stringDoesNotContainFSId,
        label: "does not contain",
        showOptions: true,
        isMatch: (c, i, f) => {
            return multiFunc(c, i, f, (cd, fd) => { return !clean(cd).includes(clean(fd)) });
        }
    },
    {
        id: stringIsFSId,
        label: "is",
        showOptions: true,
        isMatch: (c, i, f) => {
            return multiFunc(c, i, f, (cd, fd) => { return clean(cd) === clean(fd) });
        }
    },
    {
        id: stringIsNotFSId,
        label: "is not",
        showOptions: true,
        isMatch: (c, i, f) => {
            return multiFunc(c, i, f, (cd, fd) => { return clean(cd) !== clean(fd) });
        }
    },
    {
        id: stringStartsWithFSId,
        label: "starts with",
        showOptions: true,
        isMatch: (c, i, f) => {
            return multiFunc(c, i, f, (cd, fd) => { return clean(cd).startsWith(clean(fd)) });
        }
    },
    {
        id: stringEndsWithFSId,
        label: "ends with",
        showOptions: true,
        isMatch: (c, i, f) => {
            return multiFunc(c, i, f, (cd, fd) => { return clean(cd).endsWith(clean(fd)) });
        }
    },
    {
        id: stringIsEmptyFSId,
        label: "is empty",
        showOptions: false,
        isMatch: (c, i) => {
            return multiFunc(c, i, "", (cd) => { return clean(cd).length === 0 });
        }
    },
    {
        id: stringIsNotEmptyFSId,
        label: "is not empty",
        showOptions: false,
        isMatch: (c, i) => {
            return multiFunc(c, i, "", (cd) => { return clean(cd).length !== 0 });
        }
    }
];
const dropdownFilterSchemas: FilterSchema[] = [
    {
        id: dropdownContainsFSId,
        label: "contains",
        showOptions: true,
        isMatch: (c, _i, f) => {
            if (clean(f).length === 0) return true;
            const selectedOptions = clean(c).split(splitChar)
            let found = false;
            f.split(splitChar).forEach(filterChip => {
                if (found) return;
                if (selectedOptions.includes(clean(filterChip))) found = true;
            })
            return found;
        }
    },
    {
        id: dropdownDoesNotContainFSId,
        label: "does not contain",
        showOptions: true,
        isMatch: (c, _i, f) => {
            const selectedOptions = clean(c).split(splitChar)
            let found = false;
            f.split(splitChar).forEach(filterChip => {
                if (found) return;
                if (selectedOptions.includes(clean(filterChip))) found = true;
            })
            return !found;
        }
    },
    {
        id: dropdownIsEmptyFSId,
        label: "is empty",
        showOptions: false,
        isMatch: (c) => {
            return clean(c).length === 0;
        }
    },
    {
        id: dropdownIsNotEmptyFSId,
        label: "is not empty",
        showOptions: false,
        isMatch: (c) => {
            return clean(c).length !== 0;
        }
    }
];
const chipsFilterSchemas: FilterSchema[] = [
    {
        id: chipsContainsAllFSId,
        label: "contains all",
        showOptions: true,
        isMatch: (c, _i, f) => {
            if (clean(f).length === 0) return true;
            const selectedChips = clean(c).split(splitChar)
            return f.split(splitChar).every(filterChip => selectedChips.includes(clean(filterChip)));
        }
    },
    {
        id: chipsContainsAnyFSId,
        label: "contains any",
        showOptions: true,
        isMatch: (c, _i, f) => {
            if (clean(f).length === 0) return true;
            const selectedChips = clean(c).split(splitChar)
            return f.split(splitChar).some(filterChip => selectedChips.includes(clean(filterChip)));
        }
    },
    {
        id: chipsDoesNotContainFSId,
        label: "does not contain",
        showOptions: true,
        isMatch: (c, _i, f) => {
            const selectedChips = clean(c).split(splitChar)
            let found = false;
            f.split(splitChar).forEach(filterChip => {
                if (found) return;
                if (selectedChips.includes(clean(filterChip))) found = true;
            })
            return !found;
        }
    },
    {
        id: chipsIsEmptyFSId,
        label: "is empty",
        showOptions: false,
        isMatch: (c) => {
            return clean(c).length === 0;
        }
    },
    {
        id: chipsIsNotEmptyFSId,
        label: "is not empty",
        showOptions: false,
        isMatch: (c) => {
            return clean(c).length !== 0;
        }
    }
];
const commentFilterSchemas: FilterSchema[] = [
    {
        id: commentMentionsAnyFSId,
        label: "mentions any",
        showOptions: true,
        isMatch: (c, _i, f) => {
            if (clean(f).length === 0) return true; //TODO wipe filter on switch
            if (clean(c).length === 0) return false;

            const tags = clean(f).split(splitChar);
            const comments: BEComment[] = JSON.parse(c);
            for (const comment of comments) {
                if (comment.tags.length > 0) {
                    const commentTags = comment.tags.map(ct => ct.id + "^" + ct.type.toLowerCase());
                    if (tags.some(t => commentTags.includes(t))) return true;
                }
            }
            return false;
        }
    },
    {
        id: commentMentionsAllFSId,
        label: "mentions all",
        showOptions: true,
        isMatch: (c, _i, f) => {
            if (clean(f).length === 0) return true;
            if (clean(c).length === 0) return false;

            const tags = clean(f).split(splitChar);
            const comments: BEComment[] = JSON.parse(c);
            for (const comment of comments) {
                if (comment.tags.length > 0) {
                    const commentTags = comment.tags.map(ct => ct.id + "^" + ct.type.toLowerCase());
                    if (tags.every(t => commentTags.includes(t))) return true;
                }
            }
            return false;
        }
    },
    {
        id: commentHasCommentsFSId,
        label: "has comments",
        showOptions: false,
        isMatch: (c) => {
            if (clean(c).length === 0) return false;
            const comments: BEComment[] = JSON.parse(c);
            return comments.length !== 0;
        }
    },
    {
        id: commentNoCommentsFSId,
        label: "no comments",
        showOptions: false,
        isMatch: (c) => {
            if (clean(c).length === 0) return false;
            const comments: BEComment[] = JSON.parse(c);
            return comments.length === 0;
        }
    },
    {
        id: commentContainsFSId,
        label: "contains",
        showOptions: true,
        isMatch: (c, _i, f) => {
            if (clean(f).length === 0) return true;
            if (clean(c).length === 0) return false;

            const comments: BEComment[] = JSON.parse(c);
            for (const comment of comments) {
                if (comment.comment.toLowerCase().includes(clean(f))) return true;
            }
            return false;
        }
    },
]

function AddFilterMenu(props: AddFilterMenuProps) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [filterActive, setFilterActive] = useState<boolean>(false);
    const [colList, setColList] = useState<Column[]>([]);
    const [filterCol, setFilterCol] = useState<string>("");
    const [filterType, setFilterType] = useState<string>("");
    const [filterData, setFilterData] = useState<string>("");
    const [colListSearch, setColListSearch] = useState<string>("");
    const [chipSearch, setChipSearch] = useState<string>("");
    const [typePickerOpen, setTypePickerOpen] = useState(false);

    //Values for comment filtering
    const [loadingTaggables, setLoadingTaggables] = useState<boolean>(false);
    const [_errorTaggables, setErrorTaggables] = useState<boolean>(false);
    const [taggables, setTaggables] = useState<Taggable[]>([]);
    const [selectedTags, setSelectedTags] = useState<Taggable[]>([]);

    const deleteFilterPopup = usePopup("Delete Filter", "left");

    useEffect(() => {
        if (filterType === "comment") {
            request<UserListResponse>("GET", `/users?hide_system=true`).subscribe({
                next: (r: APIResponse<UserListResponse>) => {
                    if (!r.ok || r.statusCode !== 200 || !r.data) {
                        setErrorTaggables(true);
                        setLoadingTaggables(false);
                        console.error("Failed to load users for filter");
                        return;
                    }
                    const users = r.data;

                    request<DepartmentListResponse>("GET", "/config/departments").subscribe({
                        next: (r: APIResponse<DepartmentListResponse>) => {
                            if (!r.ok || r.statusCode !== 200 || !r.data) {
                                setErrorTaggables(true);
                                setLoadingTaggables(false);
                                console.error("Failed to load departments for filter");
                                return;
                            }

                            setTaggables([
                                ...users.users.map(u => ({ name: u.name, type: "user", id: u.user_id, colour: "#D1D1D1", abbreviation: u.abbreviation })),
                                ...r.data.departments.map(d => ({ name: d.department, type: "department", id: d.department_id, colour: d.colour, abbreviation: d.abbreviation }))
                            ]);
                            setErrorTaggables(false);
                            setLoadingTaggables(false);
                        },
                        error: (e) => {
                            console.error(e);
                            setErrorTaggables(true);
                            setLoadingTaggables(false);
                        },
                    });
                },
                error: (e) => {
                    console.error(e);
                    setErrorTaggables(true);
                    setLoadingTaggables(false);
                },
            });
        } else {
            setLoadingTaggables(false);
        }
    }, [filterType]);

    function selectTag(tag: Taggable) {
        if (!selectedTags.map(t => t.id).includes(tag.id)) {
            setSelectedTags(prevSel => {
                const newTags = [...prevSel, tag];
                updateTagFilter(newTags)
                return newTags;
            })
        }
    }

    function deselectTag(tag: Taggable) {
        setSelectedTags(prevSel => {
            const newTags = [...prevSel].filter(s => s.id != tag.id);
            updateTagFilter(newTags)
            return newTags;
        })
    }

    function updateTagFilter(tags: Taggable[]) {
        if (tags.length === 0) setFilterContent("");
        setFilterContent(tags.map(t => t.id + "^" + t.type).join(splitChar));
    }

    //Menu systems
    function createFilterCol(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, menu: string, type: string) {
        e.preventDefault();
        e.stopPropagation();

        setFilterCol(menu);
        setFilterType(type);
        const f: Filter = { column_name: menu, column_type: type, filter: initialiseFilterData(type), active: true }
        setFilterData(f.filter);

        props.onAddFilter(f);
        props.setCurrentFilter(f);
    }

    function initialiseFilterData(type: string): string {
        return JSON.stringify({ operation: getTypeOperations(type)[0], filterContent: "" })
    }

    useEffect(() => { //Pass through prop updates to open menu
        setMenuOpen(props.isOpen);
        if (!props.isOpen) closeMenu();
        if (props.currentFilter) {
            setFilterActive(props.currentFilter.active);
            setFilterCol(props.currentFilter.column_name);
            setFilterType(props.currentFilter.column_type);
            if (props.currentFilter.filter === "") {
                props.currentFilter.filter = initialiseFilterData(props.currentFilter.column_type);
            }
            setFilterData(props.currentFilter.filter);
            //Reload chip selections
            if (props.currentFilter.column_type === "comment" && props.currentFilter.filter !== "") {
                const parsed = JSON.parse(props.currentFilter.filter);
                if (parsed.filterContent.trim() !== "" && ["mentions any", "mentions all"].includes(parsed.operation.toLowerCase())) {
                    const entries = parsed.filterContent.split(splitChar).map((t:string)=>t.split("^")[0]);
                    const tags: Taggable[] = [];
                    entries.forEach((e: string) => {
                        const tIdx = taggables.map(t=>t.id).indexOf(e);
                        if (tIdx === -1) return;
                        tags.push(taggables[tIdx]);
                    });
                    setSelectedTags(tags);
                }
            }
        }
        setColList([...props.diffTable.columns, ...props.table.columns]);
    }, [props.isOpen, props.currentFilter]);

    function closeMenu() {
        props.setOpen(false);
        setMenuOpen(false);
        setTypePickerOpen(false);

        setFilterCol("");
        setFilterType("");
        setFilterData("");
        setSelectedTags([]);
        setChipSearch("");
        props.setCurrentFilter(undefined);
    }

    function deleteFilter(c: string) {
        closeMenu();
        props.onDeleteFilter(c);
    }

    function getTargetTable(index: number): { trueCol: number, mTable: TableResponse } {
        if (index < props.diffTable.columns.length) return { trueCol: index, mTable: props.diffTable };
        if (index < props.diffTable.columns.length + props.table.columns.length) return { trueCol: index - props.diffTable.columns.length, mTable: props.table };
        return { trueCol: -1, mTable: props.table };
    }

    function isDropdownType(type: string) {
        return ["dropdown", "change", "classification", "categorisation"].includes(type);
    }

    function isChipsType(type: string) {
        return ["chips", "departments"].includes(type)
    }

    function isTaggableType(type: string, filterData: string) {
        if (!filterData) return false;
        const op = JSON.parse(filterData).operation
        return type === "comment" && ["mentions any", "mentions all"].includes(op);
    }

    function isTextInputType(type: string, filterData: string) {
        return (filterType === "comment" && !isTaggableType(filterType, filterData)) ||
            ["string", "text", "deviation", "image", "translation"].includes(type);
    }

    function getDropdownOptions(): Option[] {
        let target = props.table;
        let colPos = target.columns.map(c => c.name).indexOf(filterCol);
        if (colPos === -1) {
            colPos = props.diffTable.columns.map(c => c.name).indexOf(filterCol);
            target = props.diffTable;
        }
        if (colPos < 0 || props.table.table.length === 0) return [];
        const rawVal = target.table[0].values[colPos][0].value;
        const splitVals = rawVal.split("|")
        if (splitVals.length != 2) return [];
        return splitVals[1].split(",").map(v => ({
            "tag": v.split("^")[0],
            "color": v.split("^")[1]
        }))
    }

    const menuStyle = {
        "right": props.position[0] ?? "",
        "top": props.position[1] ?? "",
        "border": "1px white solid",
        "backgroundColor": "white",
        "filter": "drop-shadow(1px 1px 1px #ddd)",
        "borderRadius": "6px",
    };

    //Filter funcs
    function schemaArrToLabels(arr: FilterSchema[]): string[] {
        return arr.map(s => s.label);
    }

    function toSentenceLabel(label: string) {
        return label.slice(0, 1).toUpperCase() + label.slice(1);
    }

    function getTypeOperations(type: string): string[] {
        switch (type) {
            case "change":
            case "classification":
            case "dropdown":
                return schemaArrToLabels(dropdownFilterSchemas);
            case "departments":
            case "chips":
                return schemaArrToLabels(chipsFilterSchemas);
            case "comment":
                return schemaArrToLabels(commentFilterSchemas);
            case "string":
            case "text":
            case "deviation":
            default:
                return schemaArrToLabels(stringFilterSchemas);
        }
    }

    function extractFilter(): FilterData {
        if (filterData === "") {
            const iFD = initialiseFilterData(filterType);
            setFilterData(iFD);
            return JSON.parse(iFD);
        }
        return JSON.parse(filterData);
    }

    function extractFilterSchema(): FilterSchema {
        const op = extractFilter().operation;
        return getFilterSchema(filterType, op) ?? stringFilterSchemas[0]; //null doesn't happen but ynk
    }

    function getPostFilterChangeValue(type: string, data: FilterData, newOp: string, currentValue: string) {
        switch (type) {
            case "comment":
                if (["mentions any", "mentions all"].includes(newOp) && //if switching to tag mode from non tag mode, blank
                    !["mentions any", "mentions all"].includes(data.operation)) return "";
                else if (["mentions any", "mentions all"].includes(data.operation) && //if switching from tag mode to non tag mode, blank
                    !["mentions any", "mentions all"].includes(newOp)) {
                        setSelectedTags([]);
                        return "";
                }
            default: return currentValue;
        }
    }

    function setFilterMode(op: string) {
        const filter = extractFilter();
        const postFilterChangeValue = getPostFilterChangeValue(filterType, filter, op, filter.filterContent)
        filter.operation = op;
        filter.filterContent = postFilterChangeValue;
        setFilterData(JSON.stringify(filter));

        props.onFilterChanged({ filter: JSON.stringify(filter), column_type: filterType, column_name: filterCol, active: filterActive })
        if (props.currentFilter) props.currentFilter.filter = JSON.stringify(filter);
    }

    function setFilterContent(c: string) {
        const filter = extractFilter();
        filter.filterContent = c;
        setFilterData(JSON.stringify(filter));
        props.onFilterChanged({ filter: JSON.stringify(filter), column_type: filterType, column_name: filterCol, active: filterActive })
        if (props.currentFilter) props.currentFilter.filter = JSON.stringify(filter);
    }

    function setFilterIsActive(a: boolean) {
        setFilterActive(a);
        props.onFilterChanged({ filter: filterData, column_type: filterType, column_name: filterCol, active: a })
        if (props.currentFilter) props.currentFilter.active = a;
    }

    function isCheckedMulti(value: string): boolean {
        if (isDropdownType(filterType) || isChipsType(filterType)) {
            return extractFilter().filterContent.split(splitChar).includes(value);
        }

        return false;
    }

    function updateMultiVal(value: string) {
        let current = extractFilter().filterContent.split(splitChar);
        if (current.length === 1 && current[0] === "") current = [];
        if (current.includes(value)) {
            current = current.filter(c => c !== value);
        } else {
            current.push(value)
        }
        setFilterContent(current.join(splitChar));
    }

    function isExcludedColType(c: Column) {
        const excluded = ["suggestion"]
        return excluded.includes(getTargetTable(colList.indexOf(c)).mTable.table[0].values[getTargetTable(colList.indexOf(c)).trueCol][0].type);
    }

    //Handle clicks outside menus
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: Event) => {
        if (menuRef.current && !menuRef.current.contains(e.target as Node)) closeMenu();
    };

    if (!menuOpen) return <></>
    else return (
        <div ref={menuRef} className="absolute px-6 py-4 rounded-xl z-50" style={menuStyle}>
            <div className="flex flex-col">
                {filterType === "" && <>
                    <input
                        type="text"
                        placeholder="Filter by..."
                        className="w-full input bg-white border rounded-lg text-[1.2em] px-2 mb-2"
                        value={colListSearch}
                        onChange={(e) => setColListSearch(e.currentTarget.value)}
                    />
                    {colList.filter(c => !props.filters.map(pf => pf.column_name).includes(c.name) && c.name !== "Compare" && !isExcludedColType(c) && c.name.toLowerCase().trim().includes(colListSearch.toLowerCase().trim())).map(c =>
                        <button
                            onClick={(e) => {

                                const colData = getTargetTable(colList.indexOf(c))
                                createFilterCol(e, c.name, colData.mTable.table[0].values[colData.trueCol][0].type)
                            }}
                            className="flex clear-button pr-4 w-full py-1 hover:bg-neutral-100 text-zinc-900"
                        >
                            <div className="pr-2">{getIconByType(getTargetTable(colList.indexOf(c)).mTable.table[0].values[getTargetTable(colList.indexOf(c)).trueCol][0].type, "#4B4945")}</div>{c.name}
                        </button>
                    )}
                </>}
                {filterType !== "" &&
                    <div className="text-sm flex mb-1">
                        <span className="py-2 select-none">{filterCol}</span>
                        <button className="clear-button ghost-button input-invisible text-zinc-950 py-0 px-1 flex border-0" onClick={() => setTypePickerOpen(!typePickerOpen)}>
                            <span className="font-bold">{toSentenceLabel(extractFilter().operation)}</span>
                            <ChevronDown width={18} />
                            <FilterTypePicker open={typePickerOpen} items={getTypeOperations(filterType)} setOpen={(o) => setTypePickerOpen(o)} setItem={setFilterMode} openItemLabel={getTypeOperations(filterType)[0]} />
                        </button>
                        <div className="flex gap-x-2 p-2 ml-auto">
                            <input type="checkbox" id={filterCol + (filterCol.replace("_", "") === "" ? "_" : "")} className="checkbox" checked={filterActive} onChange={() => setFilterIsActive(!filterActive)} />
                            <label htmlFor={filterCol + (filterCol.replace("_", "") === "" ? "_" : "")} className="switch"></label>
                        </div>
                        <button className="clear-button ghost-button input-invisible text-red-400" {...deleteFilterPopup.props} onClick={() => deleteFilter(filterCol)}><Trash2 size={20} /></button>
                        {deleteFilterPopup.component}
                    </div>
                }
                {isTextInputType(filterType, filterData) && extractFilterSchema().showOptions && <>
                    <div className="flex gap-x-4 items-center">
                        <input className="bg-white input border w-full rounded-lg h-9 p-2 font-semibold" value={extractFilter().filterContent} onChange={(e) => setFilterContent(e.target.value)}></input>
                    </div>
                </>}
                {isTaggableType(filterType, filterData) && <div className="h-72">
                    <PickerMenuContent contentLoading={loadingTaggables} selected={selectedTags} taggables={taggables} handleSelection={selectTag} handleDeselection={deselectTag} style={"profile"} selectedTitle={"Selected"} useColour={true} />
                </div>}
                {(isDropdownType(filterType) || isChipsType(filterType)) && extractFilterSchema().showOptions && <>
                    <div className="flex flex-col gap-x-4 items-center">
                        <input
                            className="bg-white input border rounded-lg h-9 p-2 mb-2 font-semibold w-full"
                            value={chipSearch}
                            onChange={(e) => setChipSearch(e.target.value)}
                            placeholder="Search for options..."
                        />
                        {getDropdownOptions().filter(o => o.tag.toLowerCase().trim().includes(chipSearch.toLowerCase().trim())).map((o, i) =>
                            <label htmlFor={i + ""} className="flex w-full gap-x-2 hover:bg-neutral-100 p-1 cursor-pointer">
                                <input className="cursor-pointer" id={i + ""} onChange={() => updateMultiVal(o.tag)} checked={isCheckedMulti(o.tag)} name="fakeForm" type="checkbox" />
                                <div className="rounded-xl px-2 select-none" style={{ backgroundColor: o.color }}>{o.tag}</div>
                            </label>
                        )}
                        {getDropdownOptions().filter(o => o.tag.toLowerCase().trim().includes(chipSearch.toLowerCase().trim())).length === 0 &&
                            <span className="pt-2 select-none">No results</span>
                        }
                    </div>
                </>}
            </div>
        </div>
    );
}

export default AddFilterMenu;
