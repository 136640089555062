import { usePopup } from "hooks/Popup";
import { MoreVertical, SquareCheckBig, X } from "lucide-react";
import React from "react";

interface ActionCellProps {
    id: number;
    showEdit: boolean;
    onEdit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onSave: () => void;
    onCancel: () => void;
}

function ActionCell(props: ActionCellProps) {
    const savePopup = usePopup("Save", "left");
    const cancelPopup = usePopup("Cancel", "left");
    const moreOptionsPopup = usePopup("More Options", "left");

    return (
        <div className="place-self-center">
            {!props.showEdit && <>
                <button className="text-black clear-button ghost-button input-invisible p-2" {...moreOptionsPopup.props} onClick={(e) => props.onEdit(e)}><MoreVertical /></button>
                {moreOptionsPopup.component}
            </>
            }
            {props.showEdit && <>
                <button className="text-black clear-button ghost-button input-invisible p-2 mr-2" {...savePopup.props} onClick={props.onSave}>
                    <SquareCheckBig className="w-5" />
                </button>
                <button className="text-black clear-button ghost-button input-invisible p-2" {...cancelPopup.props} onClick={props.onCancel}>
                    <X className="w-5" />
                </button>
                {savePopup.component}
                {cancelPopup.component}
            </>}
        </div>
    );
}

export default ActionCell;
