import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";



export function SidebarLayout() {
    return (
        <div className="grid grid-cols-auto-fr">
            <Sidebar />
            <div className="w-full h-full max-h-[calc(100dvh)]">
                <Outlet />
            </div>
        </div>);

}
