interface ErrorStageProps {
    error?: string;
    hideSubtitle?: boolean;
}

function ErrorStage(props: ErrorStageProps) {
    return (
        <div className="flex flex-col gap-y-8 items-center h-full place-content-center">
        <div className="flex flex-col relative w-fit select-none items-center px-44 py-16 gap-y-16 border-dashed border-2 border-gray-200 rounded-lg">
            <div className="flex flex-col gap-y-3 items-center">
                <div className="flex w-fit items-center gap-2">
                    <span className="text-primary-500 text-3xl">{props.error ?? "An error occurred"}</span>
                </div>
                {!props.hideSubtitle && <span className="text-neutral-450 text-xl">Please try again later</span>}
            </div>

        </div></div>
    )
}

export default ErrorStage;