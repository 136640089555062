import { useEffect, useState } from "react";
import 'App.css'
import { Taggable } from "types/requests.ts";
import { Minus, Plus } from "lucide-react";
import { getInitials } from "components/TableExtract/Table/cells/comments/comment-cell.tsx";
import { usePopup } from "hooks/Popup";

interface PickerMenuProps {
    selected: Taggable[];
    taggables: Taggable[];
    handleSelection: (tag: Taggable) => void;
    handleDeselection: (tag: Taggable) => void;
    style: "chip" | "profile";
    unselectedTitle?: string;
    selectedTitle?: string;
    contentLoading?: boolean;
    useColour?: boolean;
    selectedPopup?: string;
    unselectedPopup?: string;
}

function Picker(props: PickerMenuProps) {
    const [search, setSearch] = useState("");
    const [selectedSearchResults, setSelectedSearchResults] = useState<Taggable[]>([]);
    const [searchResults, setSearchResults] = useState<Taggable[]>([]);

    useEffect(() => {
        const selectedSearchResults = props.selected.filter(u => u.name.toLowerCase().includes(search) || u.abbreviation?.toLowerCase().includes(search));
        setSelectedSearchResults(selectedSearchResults);
        const searchResults = props.taggables.filter(u =>
            (u.name.toLowerCase().includes(search) || u.abbreviation?.toLowerCase().includes(search)) && !selectedSearchResults.map(sr => sr.id).includes(u.id));
        setSearchResults(searchResults);
    }, [props.selected, props.taggables, search]);

    return (
        <div className="flex flex-col  px-2 h-full">
            <input
                className="w-full bg-white border rounded-lg p-2 mb-2 float-left focus-visible:outline-0"
                placeholder="Search"
                autoFocus
                value={search}
                onChange={(e) => setSearch(e.target.value.toLowerCase())}
            />
            <div className="overflow-y-auto h-full">
                <div className="flex flex-col w-full max-h-32">
                    {props.selected.length > 0 && <>
                        <div className="text-gray-400 w-full text-left">
                            {props.selectedTitle ?? (props.selected.length + " selection" + (props.selected.length > 1 ? "s" : ""))}
                        </div>
                        {(search ? selectedSearchResults : props.selected).map(u =>
                            <PickerRow item={u} handleSelection={props.handleDeselection} showDeselect={true} style={props.style} useColour={props.useColour} popupText={props.selectedPopup ?? "Unlink Option"} />
                        )}
                    </>}
                    <div className="text-gray-400 w-full text-left">{props.unselectedTitle ?? "Options"}</div>
                    {(searchResults).map(u =>
                        <PickerRow item={u} handleSelection={props.handleSelection} showDeselect={false} style={props.style} useColour={props.useColour} popupText={props.unselectedPopup ?? "Link Option"} />
                    )}
                    {(!searchResults || searchResults.length === 0) &&
                        <div className="text-black py-2">No results</div>
                    }
                </div>
            </div>
        </div>
    )
}

interface PickerRowProps {
    item: Taggable;
    handleSelection: (tag: Taggable) => void;
    showDeselect: boolean;
    style: "chip" | "profile";
    useColour?: boolean;
    popupText: string;
}
function PickerRow(props: PickerRowProps) {
    const popup = usePopup(props.popupText)
    return (
        <button
            key={props.item.name}
            className="flex gap-x-2 py-2 px-2 clear-button hover:bg-neutral-100 input-invisible text-black items-center justify-between"
            onClick={(event) => {
                event.stopPropagation()
                props.handleSelection(props.item)
            }
            }
            {...popup.props}
        >
            {popup.component}
            {props.style === "chip" &&
                <span className="border border-zinc-200 bg-white px-2 py-1 rounded-lg select-none text-sm">
                    {props.item.name}
                </span>
            }
            {props.style === "profile" &&
                <span className="flex gap-x-4 items-center">
                    <span className="flex p-2 rounded-full w-8 h-8 items-center place-content-center text-white select-none" style={{ backgroundColor: props.useColour ? props.item.colour : "#18181B" }}>
                        {getInitials(props.item.name)}
                    </span>
                    {props.item.name}
                </span>
            }
            <div className="text-black bg-white hover:bg-zinc-100 border rounded-lg p-1">
                {!props.showDeselect && <Plus />}
                {props.showDeselect && <Minus />}
            </div>
        </button>
    );
}

export default Picker;
