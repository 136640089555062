import 'App.css';
import './Sidebar.css';
import logo from '/icon.svg';
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import SideMenu from "./SideMenu.tsx";
import {useNavigate} from "react-router-dom";

function Sidebar() {
    const navigate = useNavigate();
    const { isLoading, isAuthenticated, user, logout } = useAuth0();
    const [showUserDialog, setShowUserDialog] = useState(false);

    function getInitials(): string {
        if (isLoading || !isAuthenticated) return "";
        let givenName = user?.given_name;
        let familyName = user?.family_name;
        if (!givenName || !familyName) {
            if (!user?.name || user.name.split(" ").length < 2) {
                return user?.email?.charAt(0).toUpperCase() + "";
            }
            [givenName, familyName] = user.name.split(" ")

        }

        return (familyName.charAt(0) + givenName.charAt(0)).toUpperCase();
    }

    return (
        <div className={`sidebar select-none w-14 h-[calc(100vh)] bg-neutral-50 py-6 border-r border-r-neutral-200 text-left flex flex-col justify-between`}>
            <img src={logo} className="w-8 mx-auto cursor-pointer" onClick={() => navigate("")} />

            <div className="py-4 gap-8 flex flex-col">
                <div className="mx-8 border-t border-t-neutral-200" />

                {!isLoading && isAuthenticated &&
                    <div className="flex mx-auto gap-2 cursor-pointer" onClick={() => setShowUserDialog(!showUserDialog)}>
                        <div className="flex p-2 rounded-full w-8 h-8 bg-primary-500 items-center place-content-center text-white select-none">
                            {getInitials()}
                        </div>
                    </div>
                }
            </div>

            <SideMenu open={showUserDialog} setOpen={setShowUserDialog} getInitials={getInitials} name={user?.name ?? ""} email={user?.email ?? ""} logout={() => logout({ logoutParams: { returnTo: window.location.origin } })} />
        </div>
    )
}

export default Sidebar;
