import { AlignLeft, ChevronDownCircle, List, MessagesSquare, Sparkles, MessageSquareX, CircleX, Languages, Group, Heading } from 'lucide-react';
import { Value } from "../../../../types/requests.ts";

export type Filter = {
    active: boolean;
    column_name: string;
    column_type: string;
    filter: string;
}

export type Option = {
    tag: string;
    color: string;
}

export type AISuggestionReference = {
    spec_id: string
    row_id: string
    row_number: string
    requirement: string
    suggestion: string
    id: string
    url: string
}

//TODO changes icon
export function getIconByType(type: string, colour: string = "#000") {
    switch (type) {
        case "deviation":
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={colour} stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-message-square-x"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" /><path d="m14.5 7.5-5 5" /><path d="m9.5 7.5 5 5" /></svg>
        case "suggestion":
        case "ai":
            return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-accent"><path d="M5 3V7M19 17V21M3 5H7M17 19H21M12 3L10.088 8.813C9.99015 9.11051 9.82379 9.38088 9.60234 9.60234C9.38088 9.82379 9.11051 9.99015 8.813 10.088L3 12L8.813 13.912C9.11051 14.0099 9.38088 14.1762 9.60234 14.3977C9.82379 14.6191 9.99015 14.8895 10.088 15.187L12 21L13.912 15.187C14.0099 14.8895 14.1762 14.6191 14.3977 14.3977C14.6191 14.1762 14.8895 14.0099 15.187 13.912L21 12L15.187 10.088C14.8895 9.99015 14.6191 9.82379 14.3977 9.60234C14.1762 9.38088 14.0099 9.11051 13.912 8.813L12 3Z" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" /></svg>
        case "chips":
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={colour} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-list"><line x1="8" x2="21" y1="6" y2="6"></line><line x1="8" x2="21" y1="12" y2="12"></line><line x1="8" x2="21" y1="18" y2="18"></line><line x1="3" x2="3.01" y1="6" y2="6"></line><line x1="3" x2="3.01" y1="12" y2="12"></line><line x1="3" x2="3.01" y1="18" y2="18"></line></svg>
        case "change":
        case "dropdown":
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={colour} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-down-circle "><circle cx="12" cy="12" r="10"></circle><path d="m16 10-4 4-4-4"></path></svg>
        case "comment":
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={colour} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-messages-square "><path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z"></path><path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1"></path></svg>
        case "classification":
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={colour} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-heading "><path d="M6 12h12"></path><path d="M6 20V4"></path><path d="M18 20V4"></path></svg>
        case "categorisation":
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={colour} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-circle-x"><circle cx="12" cy="12" r="10"/><path d="m15 9-6 6"/><path d="m9 9 6 6"/></svg>
        case "translation":
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-languages stroke-accent"><path d="m5 8 6 6"/><path d="m4 14 6-6 2-3"/><path d="M2 5h12"/><path d="M7 2h1"/><path d="m22 22-5-10-5 10"/><path d="M14 18h6"/></svg>
        case "departments":
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="1.25" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-group stroke-accent"><path d="M3 7V5c0-1.1.9-2 2-2h2"/><path d="M17 3h2c1.1 0 2 .9 2 2v2"/><path d="M21 17v2c0 1.1-.9 2-2 2h-2"/><path d="M7 21H5c-1.1 0-2-.9-2-2v-2"/><rect width="7" height="5" x="7" y="7" rx="1"/><rect width="7" height="5" x="10" y="12" rx="1"/></svg>            
        case "string":
        case "image":
        case "text":
        default:
            return <svg width="22" height="22" viewBox="-1 -1 14 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.3333 1.06665H1M13 5.06665H1M9.06667 9H1" stroke={colour} strokeLinecap="round" strokeLinejoin="round" /></svg>
                
        }
}

//TODO changes icon
export function getLucideIconByType(type: string, colour: string = "#000", size: number = 20, strokeWidth: number = 2) {
    switch (type) {
        case "suggestion":
        case "ai":
            return <Sparkles className="text-accent" size={size} strokeWidth={strokeWidth} />
        case "departments":
            return <Group className="text-accent" size={size} strokeWidth={strokeWidth}/>
        case "chips":
            return <List color={colour} size={size} strokeWidth={strokeWidth}/>
        case "change":
        case "dropdown":
            return <ChevronDownCircle color={colour} size={size} strokeWidth={strokeWidth}/>
        case "comment":
            return <MessagesSquare color={colour} size={size} strokeWidth={strokeWidth}/>
        case "deviation":
            return <MessageSquareX color={colour} size={size} strokeWidth={strokeWidth}/>
        case "classification":
            return <Heading color={colour} size={size} strokeWidth={strokeWidth}/>
        case "categorisation":
            return <CircleX color={colour} size={size} strokeWidth={strokeWidth}/>
        case "translation":
            return <Languages className="text-accent" size={size} strokeWidth={strokeWidth}/>
        case "string":
        case "image":
        case "text":
        default:
            return <AlignLeft color={colour} size={size} strokeWidth={strokeWidth}/>
    }
}

export type HistoryChangeEvent = {
    event_id: string;
    type: string;
    original_value: Value;
    new_value: Value;
    changed: number;
}
