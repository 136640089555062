import React, { useEffect, useRef } from "react";
import 'App.css'

interface CrudMenuProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    handleConfirm: () => void;
}

function ChangesWarning(props: CrudMenuProps) {
    const dialog = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        if(props.open) {
            dialog.current?.showModal();
        } else {
            dialog.current?.close();
        }
    }, [props.open]);

    function returnToMain(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        cleanup();
    }

    function cleanup() {
        dialog.current?.close();
        props.setOpen(false);
    }

    //Handle clicks outside menus
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: Event) => {
        if (dialog.current?.open) {
            if ((e.target as HTMLElement).id === "unsaved-warning") {
                e.preventDefault();
                e.stopPropagation();
                cleanup();
            }
        }
        else if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
            e.preventDefault();
            e.stopPropagation();
            cleanup();
        }
    };

    return (
        <dialog id="unsaved-warning" ref={dialog} className="bg-transparent">
            <div className="bg-white text-zinc-950 p-4 rounded-lg">
                <h3 className="select-none text-zinc-950 font-bold pb-4">Unsaved Changes</h3>
                <span>You have unsaved changes, do you wish to continue?</span>
                <div className="flex gap-x-4 justify-end pt-4 items-center">
                    <button onClick={returnToMain} className="bg-zinc-100 text-zinc-950 hover:bg-zinc-200">Cancel</button>
                    <button className="bg-red-600" onClick={props.handleConfirm}>Confirm</button>
                </div>
            </div>
        </dialog>
    )
}

export default ChangesWarning;
