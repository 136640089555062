import React, { useEffect, useRef, useState } from "react";
import 'App.css'
import { FilePlus, FolderPlus } from "lucide-react";
import { APIResponse, bodyRequest } from "services/api";
import { CreateDirectoryNodeRequest } from "types/requests";

interface MenuProps {
    open: boolean;
    setOpen: (o: boolean) => void;
    id: string;
    position?: number[];
    openSpecUploadModal?: () => void;
}
// TODO: values besides pure strings
function NewNodeMenu(props: MenuProps) {
    const dialog = useRef<HTMLDialogElement>(null);
    const [showOptions, setShowOptions] = useState(false);
    const [currentName, setCurrentName] = useState("");
    const [position, setPosition] = useState([0, 0]);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [saveError, setSaveError] = useState("");

    useEffect(() => {
        setShowOptions(props.open);
        if (props.position)
            setPosition(props.position);
    }, [props.open, props.id, props.position])

    function closeMenu() {
        props.setOpen(false);
        setShowOptions(false);

        dialog.current?.close();
    }

    function openFolderCreateDialog(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        dialog.current?.showModal();
    }

    function createFolder(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        setSaveDisabled(true);
        const folderName = currentName === "" ? "Untitled folder" : currentName;
        const payload = {
            name: folderName,
            parent_id: props.id ? props.id : "",
        }
        bodyRequest<CreateDirectoryNodeRequest, string>("POST", `/folders`, payload).subscribe({
            next: (r: APIResponse<string>) => {
                if (r.statusCode !== 200) {
                    setSaveDisabled(false);
                    setSaveError("Invalid folder name")
                    return;
                } //TODO add error handling
                closeMenu();
                window.location.reload();
                setSaveDisabled(false);
            },
            error: (e) => {
                console.error(e);
                setSaveDisabled(false);
                //TODO proper error
            },
        });
    }

    function ignore(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
    }
    function returnToMain(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        dialog.current?.close()
        closeMenu();
    }

    function updateNameValue(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.value.length > 128) {
            setSaveError("Folder name too long");
            setSaveDisabled(true);
        } else {
            setSaveError("")
            setSaveDisabled(false);
        }
        setCurrentName(e.target.value);
    }

    //Handle clicks outside menus
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: MouseEvent) => {
        if (dialog.current?.open) {
            if ((e.target as HTMLElement).id === "rename" || showOptions) {
                closeMenu();
            }
        }
        else if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
            closeMenu();
        }
    };

    const menuStyle = {
        "top": position[1],
        "right": window.innerWidth - position[0],
        "border": "1px white solid",
        "backgroundColor": "white",
        "filter": "drop-shadow(1px 1px 1px #ddd)",
        "borderRadius": "6px",
    };

    if (!showOptions) return (<></>);
    return (
        <>
            {/*TODO need to pass folder for upload*/}
            <dialog id="rename" ref={dialog} className="bg-transparent">
                <div className="bg-white text-zinc-950 p-4 rounded-lg">
                    <h3 className="select-none text-zinc-950 font-bold pb-4">New folder</h3>
                    <div className="flex gap-x-4 items-center min-w-[20rem]">
                        <input className="bg-white w-full input border rounded-lg h-9 p-2 font-semibold" placeholder="Untitled folder" onClick={ignore} value={currentName} onChange={updateNameValue}></input>
                    </div>
                    <div className="flex gap-x-4 items-center justify-end pt-4">
                        <span className="pr-2 text-red-500 font-semibold">{saveError}</span>
                        <button onClick={returnToMain} className="bg-zinc-100 text-zinc-950 hover:bg-zinc-200">Cancel</button>
                        <button onClick={createFolder} disabled={saveDisabled}>Create</button>
                    </div>
                </div>
            </dialog>
            <div ref={menuRef} className={"absolute dialog z-10 "} style={menuStyle}>
                <div className="flex flex-col">
                    <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={openFolderCreateDialog}>
                        <div className="px-2"><FolderPlus absoluteStrokeWidth={true} /></div>New Folder
                    </button>
                    {props.id &&
                        <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={() => { closeMenu(); if (props.openSpecUploadModal) props.openSpecUploadModal() }}>
                            <div className="px-2"><FilePlus absoluteStrokeWidth={true} /></div>New Specification
                        </button>
                    }
                </div>
            </div>
        </>
    )
}

export default NewNodeMenu;
