import { ChangeEvent, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import styles from './formstyles.module.css'
import classNames from "classnames";

type CheckboxProps = {
    disabled?: boolean;
    checked?: boolean;
    indeterminate?: boolean;
    onChange?: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
    className?: string;
}

function Checkbox(props: CheckboxProps) {
    const [checked, setChecked] = useState(props.checked ?? false);
    const [id] = useState(nanoid())
    function setCheckedHandler(event: ChangeEvent<HTMLInputElement>) {
        setChecked(event.currentTarget.checked)
        if (!props.onChange) return;
        props.onChange(event.currentTarget.checked, event)
    }

    useEffect(() => {
        setChecked(props.checked ?? false);
    }, [props.checked]);
    return (
        <>
            <input
                type="checkbox"
                id={id}
                className={classNames(styles.checkbox, props.className)}
                disabled={props.disabled}
                checked={checked && !props.indeterminate}
                onChange={setCheckedHandler}
                ref={el => el && (el.indeterminate = props.indeterminate ?? false)}
            />
            {/*<label htmlFor={id} className="after:bg-green-300" />*/}
        </>
    )
}

export default Checkbox;
