import React, {SetStateAction, useEffect, useState} from "react";
import {ConfigUser, UserConfigListResponse, UserRequest} from "types/users.ts";
import {APIResponse, bodyRequest, request} from "services/api.ts";
import {Plus} from "lucide-react";
import Pagination from "components/Pagination/Pagination.tsx";
import CrudMenu from "components/CrudMenu/CrudMenu.tsx";
import PickerMenu from "components/Sidebar/Settings/menus/PickerMenu/PickerMenu.tsx";
import {Taggable} from "types/requests.ts";
import TextInputCell from "components/Sidebar/Settings/cells/TextInputCell.tsx";
import ActionCell from "components/Sidebar/Settings/cells/ActionCell.tsx";
import ChipPickCell from "components/Sidebar/Settings/cells/ChipPickCell.tsx";

interface UserTabProps {
    setDirty: (dirty: boolean) => void;
}

export type AbbreviationOwner = {
    abbreviation: string;
    user: string;
}

function UserTab(props: UserTabProps) {
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [errorGetUsers, setErrorGetUsers] = useState(true);
    const [errorSetUsers, setErrorSetUsers] = useState(false);
    const [users, setUsers] = useState<ConfigUser[]>([]);
    const [abbreviations, setAbbreviations] = useState<AbbreviationOwner[]>([]);
    const [createdUsers, setCreatedUsers] = useState<ConfigUser[]>([]);
    const [loadingCount, setLoadingCount] = useState<number>(0);
    const [reloadingUsers, setReloadingUsers] = useState<Map<string, boolean>>(new Map());
    const [memberships, setMemberships] = useState<Map<string,string[]>>(new Map());
    const [departmentNames, setDepartmentNames] = useState<Map<string,string>>(new Map());

    const [currentPage] = useState<number>(1); //TODO pagination set


    useEffect(() => { //Pass through prop updates to open menu
        setLoadingUsers(true);
        getUsers();
    }, []);
    useEffect(() => {
        const handleUnload = (e: BeforeUnloadEvent) => {
            // Recommended
            e.preventDefault();
            e.returnValue = true;
            // Perform actions before the component unloads
        };
        window.addEventListener('unload', handleUnload);
        return () => {
            window.removeEventListener('unload', handleUnload);
        };
    }, []);
    function getUsers() {
        setLoadingUsers(true);
        setErrorGetUsers(false);
        request<UserConfigListResponse>("GET", "/config/users").subscribe({
            next: (r: APIResponse<UserConfigListResponse>) => {
                if (!r.ok || r.statusCode !== 200 || !r.data) {
                    setLoadingUsers(false);
                    setErrorGetUsers(true);
                    console.error("Failed to get users for config")
                    return;
                }
                //Filters out newly created users to prevent duplication as we are persisting them ourselves
                setUsers(r.data.users.filter(u => !createdUsers.map(c=>c.userId).includes(u.userId)).sort((a,b)=>a.surname.localeCompare(b.surname)));
                setAbbreviations(r.data.users.map(u=> ({
                    abbreviation: u.abbreviation.toUpperCase(),
                    user: u.userId
                })).filter(a=>a.abbreviation.trim()!==""))
                const membMap = new Map<string, string[]>();
                for (let i = 0; i < r.data.users.length; i++){
                    membMap.set(r.data.users[i].userId, r.data.users[i].departments);
                }
                setMemberships(membMap);
                setDepartmentNames(new Map(Object.entries(r.data.department_names)));

                setLoadingUsers(false);
            },
            error: (e) => {
                console.error(e);
                setLoadingUsers(false);
                setErrorGetUsers(true);
            },
        });
    }

    function saveUser(user: UserRequest, userId?: string, skipUpdate?: boolean) {
        setLoadingUsers(true);
        setErrorGetUsers(false);
        setErrorSetUsers(false);
        setReloadingUsers(prevState => {
            const newState = new Map(prevState);
            newState.set(userId??"", true);
            return newState;
        })
        bodyRequest<UserRequest, string>("PUT", `/users/${userId}`, user).subscribe({
            next: (r: APIResponse<string>) => {
                if (!r.ok || r.statusCode !== 200) {
                    console.error("Failed to save user")
                    setLoadingUsers(false);
                    setErrorSetUsers(true);
                    return;
                }

                //Update existing data without reload
                if(!skipUpdate) getUsers();
                setReloadingUsers(prevState => {
                    const newState = new Map(prevState);
                    newState.delete(userId??"");
                    return newState;
                });
                setLoadingUsers(false);
                setErrorSetUsers(false);
            },
            error: (e) => {
                console.error(e);
                setLoadingUsers(false);
                setErrorSetUsers(true);
            },
        });
    }

    function findUserUpdateMode(user: UserRequest, userId?: string) {
        if (userId && userId !== "") {
            saveUser(user, userId, false)
            return;
        }
        createUser(user);
    }

    function createUser(user: UserRequest) {
        setErrorGetUsers(false);
        setErrorSetUsers(false);
        setLoadingCount(loadingCount+1);
        bodyRequest<UserRequest, string>("POST", `/users`, user).subscribe({
            next: (r: APIResponse<string>) => {
                if (!r.ok || r.statusCode !== 200) {
                    console.error("Failed to create user")
                    setErrorSetUsers(true);
                    setLoadingCount(loadingCount-1);
                    return;
                }

                setCreatedUsers(prevState => {
                    return [...prevState, {
                        name: user.firstname,
                        surname: user.surname,
                        abbreviation: user.abbreviation,
                        userId: r.data??"",
                        email: user.email,
                        departments: user.departments
                    }]
                })

                setLoadingCount(loadingCount-1);
                setErrorSetUsers(false);
            },
            error: (e) => {
                console.error(e);
                setLoadingCount(loadingCount-1);
                setErrorSetUsers(true);
            },
        });
    }

    function userDeleted() {
        setLoadingUsers(true);
        setErrorSetUsers(false);
        setErrorGetUsers(false);
        getUsers();
        setLoadingUsers(false);
    }

    function virtualUserDeleted(id?: string) {
        if (!id) return;
        setCreatedUsers(prevState => {
            return [...prevState].filter(c => c.userId !== id)
        })
    }

    if (errorGetUsers) return (
        <div className="flex flex-col">
            <div className="text-red-500 font-semibold">
                There was an error retrieving a list of users. Please try again later.
            </div>
        </div>
    );
    if (loadingUsers) return (
        <div className="flex flex-col">
            <span>Loading users...</span>
        </div>
    );

    return (
        <div className="flex flex-col">
            {errorSetUsers && <span className="text-red-500 text-xl">Failed to save user</span>}
            <div className="overflow-y-auto max-h-[60vh] w-full">
                <div className="border rounded-xl">
                    <div className="grid grid-cols-6 text-gray-400 text-left py-3 px-8 border-b" style={{gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 100px"}}>
                        <span>First name*</span>
                        <span>Surname*</span>
                        <span>Abbreviation</span>
                        <span>E-Mail*</span>
                        <span>Departments</span>
                        <span className="text-center">Actions</span>
                    </div>

                    <UserRows users={createdUsers} reloadingUsers={reloadingUsers} abbreviations={abbreviations} memberships={memberships} departmentNames={departmentNames} onSaveUser={findUserUpdateMode} onDeleteUser={virtualUserDeleted} loadingCount={loadingCount} setDirty={props.setDirty} ghostMode={true}/>
                    <UserRows users={users} reloadingUsers={reloadingUsers} abbreviations={abbreviations} memberships={memberships} departmentNames={departmentNames} onSaveUser={saveUser} onDeleteUser={userDeleted} setDirty={props.setDirty} ghostMode={false}/>
                </div>
                <div className="mt-4 float-right">
                    <Pagination totalItemCount={users.length} pageSize={10000} currentPage={currentPage}/>
                </div>
            </div>
        </div>
    );
}

interface UserRowsProps {
    users: ConfigUser[];
    reloadingUsers: Map<string, boolean>;
    abbreviations: AbbreviationOwner[];
    memberships: Map<string,string[]>;
    departmentNames: Map<string,string>;
    onSaveUser: (user: UserRequest, userId?: string) => void;
    onDeleteUser: (userId?: string) => void;
    loadingCount?: number;
    setDirty: (dirty: boolean) => void;
    ghostMode: boolean;
}

function UserRows(props: UserRowsProps) {
    const [rowActionsOpen, setRowActionsOpen] = useState<Map<number, boolean>>(new Map());
    const [nameErrors, setNameErrors] = useState<Map<number, string>>(new Map());
    const [surnameErrors, setSurnameErrors] = useState<Map<number, string>>(new Map());
    const [emailErrors, setEmailErrors] = useState<Map<number, string>>(new Map());
    const [abbreviationErrors, setAbbreviationErrors] = useState<Map<number, string>>(new Map());

    const [originalAbbreviations, setOriginalAbbreviations] = useState<Map<number, string>>(new Map());
    const [userEditors, setUserEditors] = useState<Map<number, ConfigUser>>(new Map());
    const [pendingUsers, setPendingUsers] = useState<ConfigUser[]>([]);

    const [taggableDeps, setTaggableDeps] = useState<Taggable[]>([]);
    const [pickerSelected, setPickerSelected] = useState<Taggable[]>([]);
    const [pickerId, setPickerId] = useState(-1);
    const [showPicker, setShowPicker] = useState(false);
    const [pickerPosition, setPickerPosition] = useState([0, 0]);

    const [showCrudMenu, setShowCrudMenu] = useState(false);
    const [crudId, setCrudId] = useState("");
    const [delId, setDelId] = useState("");
    const [crudPosition, setCrudPosition] = useState([0, 0]);

    useEffect(() => {
        props.setDirty(userEditors.size>0 || (pendingUsers.length > 0))
    }, [userEditors, pendingUsers]);

    useEffect(() => {
        setTaggableDeps(Array.from(props.departmentNames.entries()).map(([key, value]) => ({
            "name": value,
            "type": "department",
            "id": key,
            "colour": "#ddd"
        })));
    }, [props.departmentNames]);

    //User fields
    function getBlankUser(): ConfigUser {
        return {abbreviation: "", departments: [], email: "", name: "", surname: "", userId: ""};
    }

    function setUserName(i: number, newVal: string) {
        setUserEditors(prevUE => {
            const newUE = new Map(prevUE);
            const user = newUE.get(i)??getBlankUser();
            user.name = newVal;
            newUE.set(i, user)
            return newUE;
        });
        validateUserName(i, newVal);
    }
    function validateUserName(i: number, newVal: string) {
        const valid = newVal.trim() !== "";
        setNameErrors(prevNameErrs => {
            const newNameErrs = new Map(prevNameErrs);
            if(!valid) newNameErrs.set(i, "First name cannot be blank");
            else newNameErrs.delete(i);
            return newNameErrs;
        });
        return valid;
    }

    function setUserSurname(i: number, newVal: string) {
        setUserEditors(prevUE => {
            const newUE = new Map(prevUE);
            const user = newUE.get(i)??getBlankUser();
            user.surname = newVal;
            newUE.set(i, user)
            return newUE;
        });
        validateUserSurname(i, newVal);
    }
    function validateUserSurname(i: number, newVal: string) {
        const valid = newVal.trim() !== "";
        setSurnameErrors(prevSurnameErrs => {
            const newSurnameErrs = new Map(prevSurnameErrs);
            if(!valid) newSurnameErrs.set(i, "Surname cannot be blank");
            else newSurnameErrs.delete(i);
            return newSurnameErrs;
        });
        return valid;
    }

    function setUserAbbreviation(i: number, newVal: string) {
        setUserEditors(prevUE => {
            const newUE = new Map(prevUE);
            const user = newUE.get(i)??getBlankUser();
            user.abbreviation = newVal;
            newUE.set(i, user)
            return newUE;
        });
        validateUserAbbreviation(i, newVal);
    }
    function validateUserAbbreviation(i: number, newVal: string) {
        newVal = newVal.trim().toUpperCase();
        const present = newVal.length > 0;
        const existingAbbr = props.abbreviations.find(a=>a.abbreviation === newVal);
        const exists = newVal !== "" && existingAbbr !== undefined && existingAbbr.user !== (userEditors.get(i)?.userId??"NONE");
        const valid = newVal === "" || newVal === (originalAbbreviations.get(i)??"") || !exists;
        setAbbreviationErrors(prevAbbrErrs => {
            const newAbbrErrs = new Map(prevAbbrErrs);
            if(!valid) {
                newAbbrErrs.set(i, "Abbreviations must be unique");
            } else if(!present) {
                newAbbrErrs.set(i, "User abbreviations are required");
            } else {
                newAbbrErrs.delete(i);
            }
            return newAbbrErrs;
        });
        return present && valid;
    }

    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; //Very basic, only way to really validate is to email them
    function validateEmail(email: string) {
        return re.test(email);
    }

    function setUserEmail(i: number, newVal: string) {
        setUserEditors(prevUE => {
            const newUE = new Map(prevUE);
            const user = newUE.get(i)??getBlankUser();
            user.email = newVal;
            newUE.set(i, user)
            return newUE;
        });
        validateUserEmail(i, newVal);
    }
    function validateUserEmail(i: number, newVal: string) {
        const valid = validateEmail(newVal);
        setEmailErrors(prevEmailsErrs => {
            const newEmailErrs = new Map(prevEmailsErrs);
            if(!valid) newEmailErrs.set(i, "Email invalid");
            else newEmailErrs.delete(i);
            return newEmailErrs;
        });
        return valid;
    }

    //API related
    function saveUser(i: number, user: ConfigUser) {
        const ueUser = userEditors.get(i);
        if(!ueUser) return;
        user = ueUser;

        const validName = validateUserName(i, user.name);
        const validSurname = validateUserSurname(i, user.surname);
        const validEmail = validateUserEmail(i, user.email);
        const validateAbbr = validateUserAbbreviation(i, user.abbreviation);
        if (!validName || !validSurname || !validEmail || !validateAbbr) return;

        const req: UserRequest = {
            surname: user.surname,
            firstname:user.name,
            abbreviation:user.abbreviation,
            email:user.email,
            set_departments: true,
            departments: user.departments
        }
        props.onSaveUser(req, user.userId);
        setRowActions(i, false);
    }

    //UI
    function clearError(i: number, setter: React.Dispatch<SetStateAction<Map<number, string>>>) {
        setter(prevErr => {
            const newErr = new Map(prevErr);
            newErr.delete(i);
            return newErr;
        })
    }

    function newUser() {
        setPendingUsers([getBlankUser()]);
        // setPendingUsers(prevPUsers => {
        //     return [getBlankUser()];//TODO restore, ...prevPUsers];
        // });
    }

    function getUserSource() {
        return props.ghostMode ? [...pendingUsers, ...props.users] : props.users;
    }

    function displayEdit(i: number) {
        return props.ghostMode && i < pendingUsers.length ? true : rowActionsOpen.get(i);
    }

    function openPicker(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, i: number) {
        setShowPicker(true);
        setPickerId(i);
        const pickerHeight = 384;
        const offset = Math.max(0, pickerHeight-(window.innerHeight - (e.currentTarget.offsetTop+e.currentTarget.clientHeight)));
        setPickerPosition([e.currentTarget.offsetLeft+e.currentTarget.clientWidth-416, e.currentTarget.offsetTop+e.currentTarget.clientHeight-offset])
        setPickerSelected((userEditors.get(i)?.departments??[]).map(d => ({
            "name": props.departmentNames.get(d)??"Unknown",
            "type": "department",
            "id": d,
            "colour": "#ddd"
        })))
    }

    function selectDepartment(tag: Taggable) {
        if (!pickerSelected.map(t=>t.id).includes(tag.id)) {
            setPickerSelected(prevSel => {
                return [...prevSel, tag];
            })
        }
        const current = userEditors.get(pickerId);
        if (current && !current.departments.includes(tag.id)) {
            if(current.departments.includes(tag.id)) return;
            setUserEditors(prevUE => {
                const newUE = new Map(prevUE);
                const user = newUE.get(pickerId) ?? getBlankUser();
                user.departments = [...user.departments, tag.id];
                newUE.set(pickerId, user)
                return newUE;
            });
        }
    }

    function deselectDepartment(tag: Taggable) {
        setPickerSelected(prevSel => {
            return [...prevSel].filter(s => s.id != tag.id);
        })
        setUserEditors(prevUE => {
            const newUE = new Map(prevUE);
            const user = newUE.get(pickerId)??getBlankUser();
            user.departments = user.departments.filter(u => u !== tag.id);
            newUE.set(pickerId, user)
            return newUE;
        });
    }

    function setRowActions(i: number, open: boolean) {
        if (props.ghostMode && !open && i < pendingUsers.length) {
            setPendingUsers(prevPUsers => {
                const newPUsers = [...prevPUsers];
                newPUsers.splice(i, 1);
                return newPUsers;
            });
        }

        setUserEditors(prevUE => {
            const newUE = new Map(prevUE);
            if (open) {
                newUE.set(i, {...getUserSource()[i]});
                setOriginalAbbreviations(prevState => {
                    const newState = new Map(prevState);
                    newState.set(i, newUE.get(i)?.abbreviation ?? "");
                    return newState;
                })
            } else {
                newUE.delete(i);
                setOriginalAbbreviations(prevState => {
                    const newState = new Map(prevState);
                    newState.delete(i);
                    return newState;
                })
            }
            return newUE;
        });
        clearError(i, setNameErrors);
        clearError(i, setSurnameErrors);
        clearError(i, setEmailErrors);
        clearError(i, setAbbreviationErrors);
        setRowActionsOpen(prevState => {
            const newState = new Map(prevState);
            newState.clear(); //TODO this is temp to avoid edit reload issue
            newState.set(i, open);
            return newState;
        });
    }

    function openCrudMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, i: number, id: string) {
        setCrudId(i.toString());
        setDelId(id);
        setCrudPosition([e.pageX, e.pageY])
        setShowCrudMenu(true)
    }

    return (<>
    {props.ghostMode &&
        <div className="flex gap-x-2 select-none cursor-pointer text-left py-3 px-8 border-b hover:bg-zinc-50" onClick={newUser}>
            <Plus/>New User
        </div>
    }
    {(props.loadingCount??0)>0 && <span className="flex items-center justify-center border-b py-5">Refreshing...</span>}
    {getUserSource().map((u, i) => <>
        {props.reloadingUsers.get(u.userId) &&
            <div className="grid grid-cols-1 py-3 px-8 border-b hover:bg-zinc-50">
                Refreshing...
            </div>}
        {!props.reloadingUsers.get(u.userId) &&
        <div className="grid grid-cols-6 text-left py-3 px-8 border-b hover:bg-zinc-50" style={{gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 100px"}}>
            <TextInputCell id={i} showEdit={displayEdit(i)??false} displayValue={u.name} editValue={userEditors.get(i)?.name??""} onChange={setUserName} error={nameErrors.get(i)} />
            <TextInputCell id={i} showEdit={displayEdit(i)??false} displayValue={u.surname} editValue={userEditors.get(i)?.surname??""} onChange={setUserSurname} error={surnameErrors.get(i)} />
            <div className="content-center">
                <TextInputCell id={i} showEdit={displayEdit(i)??false} displayValue={u.abbreviation} editValue={userEditors.get(i)?.abbreviation??""} onChange={setUserAbbreviation} error={abbreviationErrors.get(i)} maxLength={3} />
                {abbreviationErrors.get(i) && (userEditors.get(i)?.abbreviation.length??0) > 0 && <span className="text-red-500 text-sm">{abbreviationErrors.get(i)}</span>}
            </div>
            <TextInputCell id={i} showEdit={displayEdit(i)??false} displayValue={u.email} editValue={userEditors.get(i)?.email??""} onChange={setUserEmail} error={emailErrors.get(i)} />
            <ChipPickCell id={i} showEdit={displayEdit(i)??false} itemSource={getUserSource()[i].departments??[]} editItemSource={userEditors.get(i)?.departments??[]} itemMapper={(i)=>props.departmentNames.get(i)} onOpenPicker={openPicker} defaultItemName={"Unknown"} pickerText={"Link Departments"} />
            <ActionCell id={i} showEdit={displayEdit(i)??false} onEdit={(e) => openCrudMenu(e, i, u.userId)} onSave={() => saveUser(i, u)} onCancel={() => setRowActions(i, false)} />
        </div>
        }
        </>
    )}
    <CrudMenu open={showCrudMenu} id={crudId} setOpen={setShowCrudMenu} handleDelete={()=>props.onDeleteUser(delId)} handleEdit={() => setRowActions(parseInt(crudId), true)} deletePath={`/users/${delId}`} itemType="user" position={crudPosition} />
    <PickerMenu open={showPicker} setOpen={setShowPicker} taggables={taggableDeps} selected={pickerSelected} handleSelection={selectDepartment} handleDeselection={deselectDepartment} type="department" style="chip" position={pickerPosition} />
    </>);
}

export default UserTab;