import {Plus} from "lucide-react";
import React from "react";

interface ProfilePickCellProps {
    id: number;
    showEdit: boolean;
    itemSource: string[];
    editItemSource: string[];
    onOpenPicker: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, i: number) => void;
    itemMapper?: (input: string) => string | undefined;
    defaultItemName: string;
    limit: number;
    pickerText: string;
}

function ProfilePickCell(props: ProfilePickCellProps) {
    function mapItemName(input: string): string | undefined {
        if(!props.itemMapper) return input;
        return props.itemMapper(input);
    }

    function getSource() {
        return props.showEdit ? props.editItemSource : props.itemSource;
    }

    return (
        <div className="content-center items-center flex gap-x-2">
            <div className="flex items-center">
                {getSource().slice(0, props.limit).map(m =>
                    <span className="flex p-2 rounded-full w-8 h-8 bg-zinc-300 items-center place-content-center text-white select-none">
                        {mapItemName(m) ?? props.defaultItemName}
                    </span>
                )}
                {getSource().length > props.limit &&
                    <span className="bg-black rounded-full w-6 h-6 text-white select-none">+{getSource().length-props.limit}</span>
                }
            </div>
            {props.showEdit &&
                <button className="flex gap-x-2 secondary-button border-0 text-black input-invisible" onClick={(e) => props.onOpenPicker(e, props.id)}>
                    <Plus width={18}/> {props.pickerText}
                </button>
            }
        </div>
    );
}

export default ProfilePickCell;