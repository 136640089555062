import React, { useCallback, useEffect, useRef, useState } from "react";
import { ArchiveRestore, X } from "lucide-react";
import { APIResponse, request } from "services/api";
import { DetailMessage, Specification } from "types/requests";
import { useNavigate } from 'react-router-dom';
import {usePopup} from "hooks/Popup.tsx";

interface ModalProps {
    open: boolean;
    setOpen: (o: boolean) => void;
    specId?: string;
    viewingSpec?: boolean;
}

function HistoryModal(props: ModalProps) {
    const navigate = useNavigate();
    const dialog = useRef<HTMLDialogElement>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [specs, setSpecs] = useState<Specification[]>([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [contextSpec, setContextSpec] = useState<Specification>();
    const [rollbackDisabled, setRollbackDisabled] = useState(false);
    const [rollbackError, setRollbackError] = useState("");
    const closePopup = usePopup("Cancel", "left");

    useEffect(() => {
        if (props.open) {
            openModal();
            request<Specification[]>("GET", `/specifications/${props.specId}/versions`).subscribe({
                next: (r: APIResponse<Specification[]>) => {
                    if (!r.ok || r.statusCode !== 200 || !r.data) return; //TODO logs
                    // @ts-expect-error comes out as number
                    r.data = r.data.sort((a, b) => b.created - a.created);
                    for (let i = 0; i < r.data.length; i++) {
                        const createdDate = new Date(0);
                        //@ts-expect-error Make into real dates for table
                        createdDate.setUTCSeconds(r.data[i].created);
                        r.data[i].created = createdDate;
                    }
                    setSpecs(r.data);
                    setLoading(false);
                },
                error: (e) => {
                    console.error(e);
                    setLoading(false);
                    //TODO proper error
                },
            });
        } else closeMenu();
    }, [props.open]);

    function openSubMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, spec: Specification) {
        e.preventDefault();
        e.stopPropagation();
        setContextSpec(spec);
        setConfirmOpen(true);
    }

    function closeSubMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        setContextSpec(undefined);
        setConfirmOpen(false);
    }

    function performRollback(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        if (!contextSpec) return;
        setRollbackDisabled(true);
        request<DetailMessage>("POST", `/specifications/${contextSpec?.project_id}/rollback`).subscribe({
            next: (r: APIResponse<DetailMessage>) => {
                if (!r.ok || r.statusCode !== 200) {
                    setRollbackError(r.data?.detail ?? "Failed to rollback");
                    setRollbackDisabled(false);
                    return;
                }
                closeMenu();
                if (props.viewingSpec) {
                    handleViewSpec(contextSpec?.project_id);
                } else {
                    window.location.reload();
                }
            },
            error: (e) => {
                console.error(e);
                setRollbackError("Failed to delete folder");
                setRollbackDisabled(false);
            },
        });
    }

    function openModal() {
        dialog.current?.showModal();
    }

    function closeMenu() {
        dialog.current?.close()
        props.setOpen(false);
    }

    const handleViewSpec = useCallback((id: string) => {
        navigate(`/specifications/view/${id}`);
        window.location.reload();
    }, [navigate]);

    //Handle clicks outside menus
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: Event) => {
        if (dialog.current) {
            if ((e.target as HTMLElement).id === "historymodal") {
                closeMenu();
            }
        }
    };

    return (
        <>
            <dialog id="historymodal" ref={dialog} className="bg-transparent">
                {confirmOpen &&
                    <div className="bg-white text-zinc-950 p-4 rounded-lg">
                        <h3 className="select-none text-zinc-950 font-bold pb-4">Restore version</h3>
                        <span className="font-semibold">{specs[0].name} -&gt; {contextSpec?.name}</span><br /><br />
                        <span>Are you sure you want to rollback to {contextSpec?.name ?? ""}?<br />This will delete all versions created after this version.</span><br /><br />
                        <div className="flex gap-x-4 justify-end pt-4 items-center">
                            <span className="pr-2 text-red-500 font-semibold">{rollbackError}</span>
                            <button onClick={closeSubMenu} className="bg-zinc-100 text-zinc-950 hover:bg-zinc-200">Cancel</button>
                            <button className="bg-red-600" onClick={performRollback} disabled={rollbackDisabled}>Rollback</button>
                        </div>
                    </div>
                }
                {!confirmOpen && <div className="bg-white text-zinc-950 p-12 rounded-lg z-10">
                    <div className="w-full items-center flex pb-8 text-xl font-semibold">
                        <span>Version History</span>
                        <button className="clear-button ghost-button input-invisible ml-auto w-8 h-8 right-0 mr-2 mt-2 text-zinc-950" {...closePopup.props} onClick={closeMenu}> <X /></button>
                        {closePopup.component}
                    </div>
                    {loading && <>Loading...</>}
                    {!loading &&
                        <table className="w-full">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Owner</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {specs.map((o, i) =>
                                    <VersionRow showArchiveButton={i !== 0} spec={o} handleViewSpec={handleViewSpec} archiveClicked={openSubMenu}/>
                                )}
                            </tbody>
                        </table>
                    }
                </div>}
            </dialog>
        </>
    );
}

interface VersionRowProps {
    showArchiveButton: boolean;
    spec: Specification;
    handleViewSpec: (specId: string) => void;
    archiveClicked: (event: React.MouseEvent<HTMLButtonElement>, spec: Specification) => void;
}

function VersionRow(props: VersionRowProps) {
    const rollbackPopup = usePopup("Rollback", "left");

    return (
        <tr className="hover:bg-zinc-100 select-none cursor-pointer" onClick={() => props.handleViewSpec(props.spec.project_id)}>
            <td>{props.spec.name}</td>
            <td>{props.spec.owner}</td>
            <td>{props.spec.created.toLocaleDateString()}</td>
            <td className="flex justify-center">
                {props.showArchiveButton &&
                    <button onClick={(e) => props.archiveClicked(e, props.spec)} {...rollbackPopup.props} className="clear-button ghost-button text-zinc-950"><ArchiveRestore /></button>
                }
            </td>
            {rollbackPopup.component}
        </tr>
    );
}

export default HistoryModal;
