import 'App.css';
import './NotifyDepartmentsSidebar.css';
import 'react-clock/dist/Clock.css';

import React, { useEffect, useRef, useState } from "react";
import { Bell, ChevronRight, CircleAlert, Info, Loader2, MoreVertical } from "lucide-react";
import { APIResponse, bodyRequest, request } from "services/api";
import { Department, DepartmentListResponse } from "types/departments";
import { ConfigUser, UserConfigListResponse } from "types/users.ts";
import ChangesWarning from "components/CrudMenu/ChangesWarning.tsx";
import TextareaAutosize from 'react-textarea-autosize';
import CheckTree, { GroupState } from "components/NotifyDepartmentsSidebar/CheckTree.tsx";
import {
    WorkAssignment,
    CreateWorkAssignmentRequest,
    EditWorkAssignmentRequest,
    UpdateWorkAssignmentRequest
} from "components/NotifyDepartmentsSidebar/types.ts";
import DateTimePicker from "react-datetime-picker";
import ReviewTaskActionsMenu from "components/NotifyDepartmentsSidebar/ReviewTaskActionsMenu.tsx";
import DepartmentMisalignmentWarning from "components/NotifyDepartmentsSidebar/DepartmentMisalignmentWarning.tsx";
import { usePopup } from 'hooks/Popup';

interface StatusAttribute {
    label: string;
    bgColorStrong: string;
    bgColorLight: string;
    bgColorHover: string;
    borderColor: string;
    textColor: string;
}

interface NotifyDepartmentsSidebarProps {
    specId?: string;
    department_config: string[];
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    assignmentStatus: AssignmentStatus;
    setAssignmentStatus: (status: AssignmentStatus) => void;
}

export type AssignmentStatus = "Open" | "In progress" | "Overdue" | "Done" | "Loading";

const convertUnixTimestampToDateTime = (timestamp: number): string => {
    // return DD.MM.YYYY HH:MM AM/PM
    const date = new Date(timestamp * 1000);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${day}.${month}.${year} ${formattedHours}:${formattedMinutes} ${ampm}`;
}

const statusAttributes: Record<AssignmentStatus, StatusAttribute> = {
    "Open": {
        label: "Notify Departments",
        bgColorLight: "bg-indigo-50",
        bgColorStrong: "bg-indigo-600",
        borderColor: "border-grey-200",
        textColor: "text-indigo-600",
        bgColorHover: "hover:bg-indigo-800"
    },
    "In progress": {
        label: "Update Status",
        bgColorLight: "bg-yellow-50",
        bgColorStrong: "bg-yellow-700",
        borderColor: "border-grey-200",
        textColor: "text-yellow-800",
        bgColorHover: "hover:bg-yellow-800"
    },
    "Overdue": {
        label: "Update Status",
        bgColorLight: "bg-red-50",
        bgColorStrong: "bg-red-500",
        borderColor: "border-grey-200",
        textColor: "text-red-600",
        bgColorHover: "hover:bg-red-800"
    },
    "Done": {
        label: "Update Status",
        bgColorLight: "bg-green-50",
        bgColorStrong: "bg-green-700",
        borderColor: "border-grey-200",
        textColor: "text-green-600",
        bgColorHover: "hover:bg-green-800"
    },
    "Loading": {
        label: "Loading...",
        bgColorLight: "bg-gray-50",
        bgColorStrong: "bg-gray-500",
        borderColor: "border-gray-200",
        textColor: "text-gray-600",
        bgColorHover: "hover:bg-gray-800"
    }
};

export const getStatusAttributes = (status: AssignmentStatus) => statusAttributes[status] || {
    label: "Loading",
    bgColorLight: "bg-gray-500",
    textColor: "text-white"
};

function NotifyDepartmentsSidebar(props: NotifyDepartmentsSidebarProps) {
    const [changesWarnOpen, setChangesWarnOpen] = useState<boolean>(false);
    const [sendingRequest, setSendingRequest] = useState<boolean>(false);

    const [showActionMenu, setShowActionMenu] = useState(false);
    const [newNodePosition, setNewNodePosition] = useState([0, 0]);

    const [times, setTimes] = useState<string[]>([])
    const customTimeframeValue = "Custom";
    const [timeframeInDays, setTimeframeInDays] = useState('5 business days');
    const [timeframeEndTime, setTimeframeEndTime] = useState('08:00 AM');
    const [deadlineTimestamp, setDeadlineTimestamp] = useState(0); // unix Timestamp
    const [users, setUsers] = useState<Map<string, ConfigUser>>(new Map());
    const [allDepartments, setAllDepartments] = useState<Map<string, Department>>(new Map());
    const [memberships, setMemberships] = useState<Map<string, string[]>>(new Map());
    const [selectableReviewers, setSelectableReviewers] = useState<Map<string, string[]>>(new Map());
    const [selectedReviewers, setSelectedReviewers] = useState<Map<string, string[]>>(new Map<string, string[]>);
    const [expandedDepartment, setExpandedDepartment] = useState<string>("");
    const [formDirty, setFormDirty] = useState<boolean>(false);

    const [showEditMode, setShowEditMode] = useState(false);
    const [allReviewers, setAllReviewers] = useState<Map<string, string[]>>(new Map());
    const [editorDeadlineTimestamp, setEditorDeadlineTimestamp] = useState(new Date().getTime() / 1000); // unix Timestamp
    const [editorMessage, setEditorMessage] = useState(""); // unix Timestamp
    const [editorReviewers, setEditorReviewers] = useState<Map<string, string[]>>(new Map<string, string[]>);
    const [missingDepsWarnOpen, setMissingDepsWarnOpen] = useState<boolean>(false);
    const [editorMissingDepartments, setEditorMissingDepartments] = useState<string[]>([]);

    const [workAssignment, setWorkAssignment] = useState<WorkAssignment | null>(null);

    const closePopup = usePopup("Close")
    const moreOptionsPopup = usePopup("More Options", "left")

    useEffect(() => {
        //Setup times
        const times: string[] = [];
        for (let hour = 6; hour <= 24; hour++) {
            const timeString = `${hour < 10 ? '0' + hour : hour}:00 ${hour < 12 ? 'AM' : 'PM'}`;
            times.push(timeString);
        }
        setTimes(times);

        // Fetch all departments & users
        request<UserConfigListResponse>("GET", `/config/users`).subscribe({
            next: (ru: APIResponse<UserConfigListResponse>) => {
                if (!ru.ok || ru.statusCode !== 200 || !ru.data) return; //TODO logs
                request<DepartmentListResponse>("GET", `/config/departments`).subscribe({
                    next: (r: APIResponse<DepartmentListResponse>) => {
                        if (!r.ok || r.statusCode !== 200 || !r.data) return; //TODO logs
                        const allDeps = new Map();
                        r.data.departments.forEach(d => allDeps.set(d.department_id, d));
                        allDeps.set("00000000-0000-0000-0000-000000000000", {
                            department_id: "",
                            department: "Other Users",
                            email: "",
                            colour: "#000000",
                            assign_default: false,
                        })
                        setAllDepartments(allDeps)
                        const memberships = new Map(Object.entries(r.data.memberships));

                        const users: Map<string, ConfigUser> = new Map();
                        (ru.data?.users ?? []).forEach(u => users.set(u.userId, u))
                        setUsers(users);

                        const membershipUsers = Array.from(memberships).flatMap(([, values]) =>
                            values.map(value => value)
                        );
                        const nonMemberships = Array.from(users.keys()).filter(u => !membershipUsers.includes(u));
                        if (nonMemberships.length > 0) memberships.set("00000000-0000-0000-0000-000000000000", nonMemberships);
                        setMemberships(memberships);
                    },
                    error: (e) => {
                        console.error(e);
                        setAllDepartments(new Map());
                    },
                });
            },
            error: (e) => {
                console.error(e);
            },
        });
    }, [props.isOpen]);

    // Fetch work assignment
    useEffect(() => {
        request<WorkAssignment>("GET", `/specifications/${props.specId}/work-assignment`).subscribe({
            next: (r: APIResponse<WorkAssignment>) => {
                setWorkAssignment(r.data);
            },
            error: (e) => {
                console.error(e);
            },
        });
        const intervalId = setInterval(() => {
            request<WorkAssignment>("GET", `/specifications/${props.specId}/work-assignment`).subscribe({
                next: (r: APIResponse<WorkAssignment>) => {
                    setWorkAssignment(r.data);
                },
                error: (e) => {
                    console.error(e);
                },
            });
        }, 5000);
        return () => {
            clearInterval(intervalId)
        }
    }, [props.specId, props.isOpen]);

    useEffect(() => {
        // update sidebar content
        const dep_config_map = new Map();
        props.department_config.forEach(d => {
            if (memberships.has(d)) dep_config_map.set(d, memberships.get(d))
            else dep_config_map.set(d, []);
        });
        if (!workAssignment) {
            updateAssignmentStatus();
            setSelectableReviewers(dep_config_map);
            return;
        }

        setAllReviewers(dep_config_map);
        setDeadlineTimestamp(workAssignment.deadline_timestamp);

        const selectableRev = new Map<string, string[]>();
        workAssignment.department_reviews.forEach(dr => {
            if (dr.user_id) selectableRev.set(dr.department_id, [...selectableRev.get(dr.department_id) ?? [], dr.user_id]);
            //TODO all users ifuser id nil
        })
        setSelectableReviewers(selectableRev);

        const selectedRev = new Map<string, string[]>();
        workAssignment.department_reviews.filter(review => review.reviewed).forEach(dr => {
            if (dr.user_id) selectedRev.set(dr.department_id, [...selectedRev.get(dr.department_id) ?? [], dr.user_id]);
        })
        setSelectedReviewers(selectedRev)

        updateAssignmentStatus();
    }, [workAssignment, allDepartments, props.isOpen]);

    function saveButtonDisabled() {
        if (sendingRequest) return true;
        if (showEditMode) {
            return workAssignment?.message === editorMessage && deadlineTimestamp === editorDeadlineTimestamp && !formDirty;
        }
        if (props.assignmentStatus === "Open") {
            return selectedReviewers.size === 0;
        }
        return !formDirty;
    }

    function departmentConfigChanged(): boolean {
        if (!workAssignment) return false;

        const existing: string[] = Array.from(selectableReviewers.keys());
        return props.department_config.sort().toString() !== existing.sort().toString()
    }

    function formatDepartmentList(departments: string[]): string {
        departments = departments.map(d => allDepartments.get(d)?.department ?? "");
        if (departments.length === 0) return "";
        if (departments.length === 1) return departments[0];
        return `${departments.slice(0, -1).join(", ")} & ${departments[departments.length - 1]}`;
    }

    function getDepartmentWarningMessage(): string {
        if (!departmentConfigChanged() || !workAssignment) return "";

        const existing: string[] = Array.from(selectableReviewers.keys());

        let warning = "";
        // Find departments added to department_config that weren't in workAssignment.initial_department_config
        const addedDepartments = props.department_config.filter(
            (dept) => !existing.includes(dept)
        );

        if (addedDepartments.length > 0) {
            warning += `Some departments have not been notified. Edit the review task to notify users from ${formatDepartmentList(addedDepartments)}. `;
        }

        // Find departments removed from department_config that were in workAssignment.initial_department_config
        const removedDepartments = existing.filter(
            (dept) => !props.department_config.includes(dept)
        );

        if (removedDepartments.length > 0) {
            warning += `Some departments in the review task are missing from the table. Edit the review task and remove reviewers from ${formatDepartmentList(removedDepartments)}. `;
        }

        return warning
    }

    function updateAssignmentStatus() {
        if (workAssignment === null) {
            props.setAssignmentStatus("Open");
            return;
        }

        //Determine status
        const now = Date.now() / 1000; // JavaScript gives time in milliseconds
        const allReviewed = workAssignment.department_reviews.every(review => review.reviewed);

        let status: AssignmentStatus;
        if (allReviewed) status = "Done"
        else if (now < workAssignment.deadline_timestamp) status = "In progress";
        else status = "Overdue";

        props.setAssignmentStatus(status);
    }

    function toggleActionMenu(e: React.MouseEvent<HTMLButtonElement>) {
        e.stopPropagation();
        setNewNodePosition([e.currentTarget.offsetLeft, e.currentTarget.offsetTop + e.currentTarget.clientHeight]);
        setShowActionMenu(!showActionMenu);
    }

    function editClicked(e: React.MouseEvent<HTMLButtonElement>) {
        e.stopPropagation();
        setExpandedDepartment("");
        setEditorDeadlineTimestamp(deadlineTimestamp);
        setEditorMessage(workAssignment?.message ?? "")
        setEditorReviewers(selectableReviewers)
        setShowEditMode(true);
        setShowActionMenu(false);
        setEditorMissingDepartments([]);
    }

    function departmentSelected(departmentId: string): GroupState {
        const selected = ((showEditMode ? editorReviewers : selectedReviewers).get(departmentId) ?? []).length;
        const max = ((showEditMode ? allReviewers : selectableReviewers).get(departmentId) ?? []).length;

        if (max === 0 || selected === 0) return GroupState.NONE_SELECTED;
        if (selected === max) return GroupState.ALL_SELECTED;
        else return GroupState.SOME_SELECTED;
    }

    function itemSelected(department_id: string, member_id: string): boolean {
        if (showEditMode) return (editorReviewers.get(department_id) ?? []).includes(member_id);
        return (selectedReviewers.get(department_id) ?? []).includes(member_id);
    }

    function toggleDepartment(departmentId: string) {
        setExpandedDepartment(expandedDepartment === departmentId ? "" : departmentId);
    }

    //Update missing departments list for warnings
    useEffect(() => {
        const missing: string[] = [];
        props.department_config.forEach(d => {
            if ((editorReviewers.get(d)?.length ?? 0) === 0) missing.push(allDepartments.get(d)?.department ?? "");
        })
        setEditorMissingDepartments(missing);
    }, [editorReviewers]);

    function handleDepartmentChange(departmentId: string) {
        setFormDirty(true);
        const setter = showEditMode ? setEditorReviewers : setSelectedReviewers;
        setter(prevState => {
            const newState = new Map(prevState);

            const currentRevList = newState.get(departmentId) ?? [];
            const depMembers = (showEditMode ? allReviewers : selectableReviewers).get(departmentId) ?? [];
            newState.set(departmentId, currentRevList.length === depMembers.length ? [] : depMembers);

            return newState;
        });
    }

    function handleReviewerChange(departmentId: string, userId: string, approved: boolean) {
        if (showEditMode) setFormDirty(true);
        const setter = showEditMode ? setEditorReviewers : setSelectedReviewers;
        if (props.assignmentStatus === "Open" || showEditMode)
            setter(prevState => {
                const newState = new Map(prevState);

                const currentRevList = newState.get(departmentId) ?? [];

                if (currentRevList.includes(userId)) {
                    newState.set(departmentId, [...currentRevList.filter(r => r !== userId)])
                } else {
                    newState.set(departmentId, [...currentRevList, userId])
                }

                return newState;
            })

        if (props.assignmentStatus !== "Open" && !showEditMode) {
            request<WorkAssignment>("PUT", `/specifications/${props.specId}/work-assignment/${departmentId}/${userId}/${approved ? "reviewed" : "unreviewed"}`).subscribe({
                next: (r: APIResponse<WorkAssignment>) => {
                    if (r.statusCode !== 200) return; //TODO add error handling
                    setWorkAssignment(r.data)
                },
                error: (e) => {
                    console.error('Error sending edit work assignment request:', e);
                }
            })
        }
    }

    function getUserLabels(userId: string) {
        const user = users.get(userId);
        if (!user) return [""];
        return [user.name, user.surname, user.email]
    }

    async function saveAndCloseSidebar() {
        setMissingDepsWarnOpen(false);
        await handleSubmit();
        closeSidebar(false);
    }

    function closeSidebar(checkForm = true) {
        if (checkForm && (formDirty || !saveButtonDisabled())) {
            setChangesWarnOpen(true);
            return;
        }
        if (showEditMode) {
            setShowEditMode(false);
            setExpandedDepartment("");
        }
        setChangesWarnOpen(false);
        setSelectedReviewers(new Map());
        setFormDirty(false);
        props.setIsOpen(false);
    }

    async function handleSubmit() {
        setSendingRequest(true);
        if (showEditMode) await updateReviewTask();
        else await createReviewTask();
        setSendingRequest(false);
    }

    async function updateReviewTask() {
        const submittedReviewers = new Map(editorReviewers);
        Array.from(submittedReviewers.entries()).forEach(([key, v]) => {
            if (v.length === 0) submittedReviewers.delete(key);
        })
        const payload: EditWorkAssignmentRequest = {
            deadline: editorDeadlineTimestamp,
            message: editorMessage,
            department_config: props.department_config,
            user_ids: Object.fromEntries(submittedReviewers)
        }

        bodyRequest<EditWorkAssignmentRequest, string>('PUT', `/specifications/${props.specId}/work-assignment`, payload).subscribe({
            next: (r: APIResponse<string>) => {
                if (r.statusCode !== 200) return; //TODO add error handling
                setSelectedReviewers(new Map()); // Cleanup
                setExpandedDepartment("");
                closeSidebar(false); // close the sidebar upon success
            },
            error: (e) => {
                console.error('Error sending edit work assignment request:', e);
            }
        });
    }

    function addUpBusinessDays(days: number, time: string) {
        const today = new Date();
        today.setHours(Number(time.split(":")[0]));
        today.setMinutes(0);
        today.setSeconds(0);

        let date = today.getTime();
        let daysAdded = 0;
        while (daysAdded < days) {
            date += 1000 * 60 * 60 * 24
            if (new Date(date).getDay() > 0 && new Date(date).getDay() < 6) daysAdded += 1;
        }
        return date
    }

    async function createReviewTask() {
        const submittedReviewers = new Map(selectedReviewers);
        Array.from(submittedReviewers.entries()).forEach(([key, v]) => {
            if (v.length === 0) submittedReviewers.delete(key);
        })

        //Compute UTC timezone
        let deadline = editorDeadlineTimestamp;
        if (timeframeInDays !== customTimeframeValue) {
            const businessDays = Number(timeframeInDays.split(" ")[0]);
            deadline = addUpBusinessDays(businessDays, timeframeEndTime) / 1000;
        }

        // Construct the work assignment payload
        const assignmentPayload: CreateWorkAssignmentRequest = {
            deadline: Math.floor(deadline),
            user_ids: Object.fromEntries(submittedReviewers),
            message: editorMessage,
            initialDepartmentConfig: props.department_config
        };

        // Send the payload to the backend
        bodyRequest<CreateWorkAssignmentRequest, string>('POST', `/specifications/${props.specId}/work-assignment`, assignmentPayload).subscribe({
            next: (r: APIResponse<string>) => {
                if (r.statusCode !== 200) return; //TODO add error handling
                setSelectedReviewers(new Map()); // Cleanup
                setExpandedDepartment("");
                closeSidebar(false); // close the sidebar upon success
            },
            error: (e) => {
                console.error('Error sending create work assignment request:', e);
            }
        });
    }

    async function handleUpdateStatus() {
        if (showEditMode) {
            if (editorMissingDepartments.length > 0) {
                setMissingDepsWarnOpen(true);
                return
            }
            await handleSubmit();
            return;
        }
        // Construct the work assignment payload
        const assignmentPayload: UpdateWorkAssignmentRequest = {
            department_reviews: Array.from(selectableReviewers).flatMap(([key, values]) =>
                values.map(value => [key, value])
            ).map(([dept, user]) => {
                return {
                    department_id: dept,
                    user_id: user,
                    reviewed: selectedReviewers.has(dept) && (selectedReviewers.get(dept) ?? []).includes(user)
                }
            })
        };
        // Send the payload to the backend
        bodyRequest<UpdateWorkAssignmentRequest, string>('PUT', `/specifications/${props.specId}/work-assignment/reviewers`, assignmentPayload).subscribe({
            next: (r: APIResponse<string>) => {
                if (r.statusCode !== 200) return; //TODO add error handling
                closeSidebar(false); // close the sidebar upon success
            },
            error: (e) => {
                console.error('Error creating work assignment:', e);
            }
        });
    }

    //Handle clicks outside sidebar
    const sidebarRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, [handleClickOutside]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function handleClickOutside(e: Event) {
        if (!props.isOpen) return;
        const target = e.target as HTMLElement;
        if (sidebarRef.current && sidebarRef.current !== target && !sidebarRef.current.contains(target)) closeSidebar();
    }

    return (
        <div ref={sidebarRef} className={`notifySidebar p-10 ${props.isOpen ? 'open' : ''}`}>
            <div className="flex flex-col h-full">
                <div className="">
                    <div className="flex border-b pb-6 border-zinc-200">
                        <ChevronRight className="mt-1 mr-5 cursor-pointer" {...closePopup.props} onClick={() => closeSidebar()} />
                        {closePopup.component}
                        {props.assignmentStatus === "Open" &&
                            <span className="font-semibold text-2xl">New Review Task</span>
                        }
                        {["In progress", "Overdue", "Done"].includes(props.assignmentStatus) && <>
                            <div className="flex flex-row items-center">
                                <span className="font-semibold text-2xl">{showEditMode && "Edit "}Review Task</span>
                                <span
                                    className={`border rounded-lg px-2 ml-4  ${getStatusAttributes(props.assignmentStatus).borderColor} ${getStatusAttributes(props.assignmentStatus).bgColorLight} ${getStatusAttributes(props.assignmentStatus).textColor}`}>{props.assignmentStatus}</span>
                            </div>
                            {!showEditMode && <>
                                <button className="clear-button input-invisible ghost-button text-black ml-auto"
                                    onClick={toggleActionMenu} {...moreOptionsPopup.props}>
                                    <MoreVertical />
                                </button>
                                {moreOptionsPopup.component}</>
                            }
                        </>}
                    </div>
                    {/* Timeframe selection */}
                    <div className="mt-4">
                        <label htmlFor="timeframeInDays-selection" className="block text-neutral-400">Deadline</label>
                        <div className="flex">
                            {["In progress", "Overdue", "Done"].includes(props.assignmentStatus) && !showEditMode &&
                                <div
                                    id="timeframeInDays-selection"
                                    className="mt-1 mr-2 block w-1/2 pl-3 py-2 sm:text-sm rounded-md custom-disabled-select"
                                >
                                    {convertUnixTimestampToDateTime(deadlineTimestamp)}{/* Display the set timeframeInDays as text */}
                                </div>
                            }

                            {props.assignmentStatus === "Open" &&
                                <div className="flex">
                                    <select
                                        id="timeframeInDays-selection"
                                        className="mt-1 ml-1 mr-2 block pl-3 py-2 bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md input"
                                        value={timeframeInDays}
                                        onChange={(e) => setTimeframeInDays(e.target.value)}
                                    >
                                        {[...['1', '2', '3', '4', '5', '7', '10', '15'].map((day) => (
                                            <option key={day} value={`${day} business days`}>
                                                In {day} business days
                                            </option>
                                        )), <option key={customTimeframeValue} value={customTimeframeValue}>Custom</option>]}
                                    </select>

                                    {timeframeInDays !== customTimeframeValue &&
                                        <select
                                            id="timeframe-end-time-selection"
                                            className="mt-1 block px-3 py-2 bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md input"
                                            value={timeframeEndTime}
                                            onChange={(e) => setTimeframeEndTime(e.target.value)}
                                        >
                                            {times.map((time) => (
                                                <option key={time} value={time}>
                                                    {time}
                                                </option>
                                            ))}
                                        </select>
                                    }
                                </div>
                            }

                            {(showEditMode || (props.assignmentStatus === "Open" && timeframeInDays === customTimeframeValue)) &&
                                <DateTimePicker
                                    value={new Date(editorDeadlineTimestamp * 1000)}
                                    onChange={(e) => setEditorDeadlineTimestamp((e?.getTime() ?? deadlineTimestamp * 1000) / 1000)}
                                    disableClock={true}
                                    clearIcon={null}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="flex-grow overflow-y-scroll">
                    {/* Department checkboxes */}
                    <div className="flex flex-col mt-2">
                        <legend className="flex justify-between items-center text-neutral-400 w-full">Departments
                            {props.assignmentStatus === "Open" &&
                                <div className="rounded-full cursor-default tooltip">
                                    <Info className="w-6 h-6 text-grey-900 " />
                                    <span
                                        className="tooltiptext">You can edit departments and emails in the settings.</span>
                                </div>
                            }
                        </legend>
                        {props.assignmentStatus !== "Open" && departmentConfigChanged() &&
                            <div
                                className="grid grid-cols-2 items-center p-1 my-2 border border-yellow-700 rounded-lg bg-yellow-50 text-yellow-700"
                                style={{ gridTemplateColumns: "50px 1fr" }}
                            >
                                <CircleAlert className="place-self-center w-8" />
                                {getDepartmentWarningMessage()}
                            </div>
                        }
                        <CheckTree
                            className={"mt-2 space-y-4"}
                            treeData={showEditMode ? allReviewers : selectableReviewers}
                            expandedGroup={expandedDepartment}
                            getGroupState={departmentSelected}
                            getGroupLabel={(d) => allDepartments.get(d)?.department ?? ""}
                            onGroupToggleExpand={toggleDepartment}
                            onToggleGroup={props.assignmentStatus === "Open" || showEditMode ? (d) => handleDepartmentChange(d) : undefined}
                            getItemState={itemSelected}
                            getItemIconText={(_, m) => users.get(m)?.abbreviation ?? "?"}
                            getItemLabels={(_, m) => getUserLabels(m)}
                            onToggleItem={handleReviewerChange}
                            emptyMessage="No departments found. Please define them in Settings first."
                            noItemsInGroupMessage="This department has no users."
                            showFlagIcon={["In progress", "Overdue", "Done"].includes(props.assignmentStatus) && !showEditMode}
                            buttonMode={["In progress", "Overdue", "Done"].includes(props.assignmentStatus) && !showEditMode}
                            buttonTextWhenChecked='Unapprove'
                            buttonTextWhenUnchecked='Approve'
                        />
                    </div>

                    {/* Message input */}
                    {(props.assignmentStatus === "Open" || workAssignment?.message || showEditMode) &&
                        <div className="mt-2">
                            <label htmlFor="message-input" className="block py-2 text-neutral-400">Message</label>
                            <TextareaAutosize
                                id="message-input"
                                className="mx-1 bg-white w-11/12 input border rounded-lg p-2 disabled:bg-gray-100 disabled:text-gray-500 disabled:resize-none"
                                placeholder={props.assignmentStatus === "Open" || showEditMode ? "Enter your message..." : "No message."}
                                rows={6}
                                disabled={props.assignmentStatus !== "Open" && !showEditMode}
                                value={showEditMode || props.assignmentStatus === "Open" ? editorMessage : workAssignment?.message ?? ""}
                                onChange={(e) => setEditorMessage(e.target.value)}
                            />
                        </div>
                    }
                </div>

                {/* Submit button */}
                <span className="border-t border-zinc-200 w-full mx-auto" />

                <div className="mt-3 gap-x-2 flex justify-end">
                    {sendingRequest &&
                        <div className="items-center flex">
                            <div className="spin"><Loader2 size={26} color={"#737383"} /></div>
                        </div>
                    }
                    <button className="secondary-secondary-button text-black" onClick={() => closeSidebar()}>
                        {["In progress", "Overdue", "Done"].includes(props.assignmentStatus) ? "Close" : "Cancel"}
                    </button>
                    {props.assignmentStatus === "Open" &&
                        <button
                            type="button"
                            className="flex gap-x-2 bg-black"
                            onClick={handleSubmit}
                            disabled={saveButtonDisabled()}
                        >
                            <Bell />
                            Notify
                        </button>
                    }
                    {["In progress", "Overdue", "Done"].includes(props.assignmentStatus) && showEditMode &&
                        <button
                            type="button"
                            className="flex gap-x-2 bg-black"
                            onClick={handleUpdateStatus}
                            disabled={saveButtonDisabled()}
                        >
                            Save
                        </button>
                    }
                </div>
            </div>
            <DepartmentMisalignmentWarning open={missingDepsWarnOpen} setOpen={(x) => setMissingDepsWarnOpen(x)}
                handleConfirm={saveAndCloseSidebar}
                missingDepartments={editorMissingDepartments} />
            <ChangesWarning open={changesWarnOpen} setOpen={(x) => setChangesWarnOpen(x)}
                handleConfirm={() => closeSidebar(false)} />
            <ReviewTaskActionsMenu open={showActionMenu} setOpen={(x) => setShowActionMenu(x)}
                position={newNodePosition} specId={props.specId ?? ""} editClicked={editClicked}
                editDisabled={props.department_config.length === 0}
                onDelete={() => closeSidebar(false)} />
        </div>
    );
}

export default NotifyDepartmentsSidebar;
