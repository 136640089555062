import {
    type CustomCell,
    type CustomRenderer,
    GridCellKind,
    type ProvideEditorCallback
} from "@glideapps/glide-data-grid";
import { ArrowUp, AtSign, Check, MoreVertical } from "lucide-react";
import { Comment } from "types/comments";
import React, { useEffect, useRef, useState } from "react";
import CommentMenu from "./CommentMenu";
import PosterMenu from "./PosterMenu";
import { APIResponse, request } from "services/api";
import { Department, DepartmentListResponse } from "types/departments";
import { Taggable, User, UserListResponse } from "types/requests";
import TagMenu from "./TagMenu";
import { useComments } from "./CommentContext";
import {
    COMMENT_CREATE,
    COMMENT_DELETE,
    COMMENT_EDIT,
    COMMENT_RESOLVE,
    CommentCreate
} from "types/collab";
import TextareaAutosize from 'react-textarea-autosize';
import { usePopup } from "hooks/Popup";

interface CommentCellProps {
    readonly kind: "comment-cell";
    readonly: boolean;
    comments: string;
}
export type CommentCell = CustomCell<CommentCellProps>;

export const customerAbbreviation = "C";
export function getInitials(poster_name: string, displayCustomer: boolean = false): string {
    if (displayCustomer) return customerAbbreviation;
    const nameParts = poster_name.split(" ");
    if (nameParts.length >= 2) {
        const givenName = nameParts[0];
        const familyName = nameParts[nameParts.length - 1];
        return (givenName.charAt(0) + familyName.charAt(0)).toUpperCase();
    } else if (nameParts.length === 1) {
        return nameParts[0].charAt(0).toUpperCase();
    }
    return "~";
}


const Editor: ReturnType<ProvideEditorCallback<CommentCell>> = p => {
    const { value: cell, target } = p;
    const { comments, setComments, publish } = useComments();
    const [arr, setArr] = useState<Comment[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [departments, setDepartments] = useState<Department[]>([]);
    const [requester, setRequester] = useState<User>({ "user_id": "", "name": "", "abbreviation": "" });
    const [commentText, setCommentText] = useState<string>("");
    const [showResolved, setShowResolved] = useState<boolean>(false);
    const [editCommentIndex, setEditCommentIndex] = useState<string>("");
    const [editCommentValue, setEditCommentValue] = useState<string>("");

    const [showCommentMenu, setShowCommentMenu] = useState<boolean>(false);
    const [commentMenuId, setCommentMenuId] = useState<string>("");
    const [contextPosition, setContextPosition] = useState([0, 0]);

    const [showUserMenu, setShowUserMenu] = useState<boolean>(false);
    const [commentUserMenuId, setCommentUserMenuId] = useState<string>("");
    const [userPosition, setUserPosition] = useState([0, 0]);
    const [userMenuAbbreviation, setUserMenuAbbreviation] = useState("");
    const [userMenuColour, setUserMenuColour] = useState("");
    const [showCustomer, setShowCustomer] = useState(false);

    const [taggedUsers, setTaggedUsers] = useState<Taggable[]>([]);
    const [lastInputId, setLastInputId] = useState<string>("");
    const [showTagMenu, setShowTagMenu] = useState<boolean>(false);
    const [tagPosition, setTagPosition] = useState([0, 0]);
    const [cellId, setCellId] = useState<string>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [sendingComment, setSendingComment] = useState(false);

    const resolveCommentPopup = usePopup('Resolve Comment', "left");
    const moreOptionsPopup = usePopup('More Options', "left");
    const resolvedMoreOptionsPopup = usePopup('More Options', "left");
    const sendCommentPopup = usePopup('Send Comment', "left");

    useEffect(() => {
        setLoading(true);

        //Clear partially typed comment if we're on a different spec than before
        if (localStorage.getItem("lastpage") !== location.origin + location.pathname) {
            Object.keys(localStorage).forEach(function(key) {
                if (key.startsWith("partialcomment")) localStorage.removeItem(key);
                if (key.startsWith("partialtags")) localStorage.removeItem(key);
            });
            localStorage.setItem("lastpage", location.origin + location.pathname);
        }
        //Load partially typed comment
        setCommentText(localStorage.getItem("partialcomment" + target.y) ?? "")
        setTaggedUsers(JSON.parse(localStorage.getItem("partialtags" + target.y) ?? "[]"))

        //Update comments from context, gather users/departments
        const retrCellId = JSON.parse(cell.data.comments)["id"];
        setCellId(retrCellId);
        setArr((comments.get(retrCellId) ?? []).map(bc => ({
            comment_id: bc.comment_id,
            comment: bc.comment,
            poster_name: bc.commenter, //TODO map to list retrieved later
            display_customer: bc.customer_display,
            post_date: bc.post_date * 1000,
            resolved: bc.resolved,
            resolved_date: bc.resolved_date * 1000,
            resolved_user: bc.resolved_user, //TODO map to list retrieved later
            tags: bc.tags
        })));

        request<UserListResponse>("GET", `/users?hide_system=true`).subscribe({
            next: (r: APIResponse<UserListResponse>) => {
                if (!r.ok || r.statusCode !== 200 || !r.data) {
                    setError(true);
                    setLoading(false);
                    return; //TODO logs
                }
                const users = r.data;

                request<DepartmentListResponse>("GET", "/config/departments").subscribe({
                    next: (r: APIResponse<DepartmentListResponse>) => {
                        if (!r.ok || r.statusCode !== 200 || !r.data) {
                            setError(true);
                            setLoading(false);
                            return; //TODO logs
                        }

                        setDepartments(r.data.departments);
                        setUsers(users.users);
                        setRequester(users.requesting_user);
                        setError(false);
                        setLoading(false);
                    },
                    error: (e) => {
                        console.error(e);
                        setError(true);
                        setLoading(false);
                    },
                });
            },
            error: (e) => {
                console.error(e);
                setError(true);
                setLoading(false);
            },
        });
    }, [cell]);

    //Track updates to comments
    useEffect(() => {
        if (!cellId || !comments || !comments.get(cellId)) return;
        setArr((comments.get(cellId) ?? []).map(bc => ({
            comment_id: bc.comment_id,
            comment: bc.comment,
            poster_name: bc.commenter, //TODO map to list retrieved later
            display_customer: bc.customer_display,
            post_date: bc.post_date * 1000,
            resolved: bc.resolved,
            resolved_date: bc.resolved_date * 1000,
            resolved_user: bc.resolved_user, //TODO map to list retrieved later
            tags: bc.tags
        })));
    }, [comments, cellId]);

    function commentTextChanged(e: string) {
        setCommentText(e);
        localStorage.setItem("partialcomment" + target.y, e);
    }

    const commentDiv = useRef<HTMLDivElement>(null);
    function toggleContextMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) {
        const scroll = commentDiv.current ? commentDiv.current.scrollTop : 0;
        const overflow = (commentDiv.current ? commentDiv.current.clientHeight : 0) - (e.currentTarget.offsetTop + e.currentTarget.clientHeight) - 1
        const computeOverflow = overflow < 0 ? -overflow : 0;

        setShowCommentMenu(id === commentMenuId ? !showCommentMenu : true);
        setContextPosition([computeOverflow, e.currentTarget.offsetTop + e.currentTarget.clientHeight - scroll - computeOverflow]);
        setCommentMenuId(id);
    }

    function toggleUserMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, comment_id: string) {
        if (comment_id === "") {
            if (!showCustomer) {
                setUserMenuAbbreviation("");
                setUserMenuColour("");
            } else {
                setUserMenuAbbreviation(getUserAbbreviation(requester.user_id, false));
                setUserMenuColour("#D1D1D1");
            }
            setShowUserMenu(comment_id === commentUserMenuId ? !showUserMenu : true);
            setCommentUserMenuId(comment_id);
            const menuHeight = e.currentTarget.parentElement?.parentElement?.clientHeight ?? 0;
            if (menuHeight < 75) setUserPosition([45, e.currentTarget.offsetTop + e.currentTarget.clientHeight - 36]);
            else setUserPosition([1, e.currentTarget.offsetTop + e.currentTarget.clientHeight - 80]);
            return;
        }
        const c = arr.find(fc => fc.comment_id === comment_id);
        if (comment_id !== editCommentIndex) return;
        const scroll = commentDiv.current ? commentDiv.current.scrollTop : 0;
        const overflow = (commentDiv.current ? commentDiv.current.clientHeight : 0) - (e.currentTarget.offsetTop + e.currentTarget.clientHeight) - 1
        const computeOverflow = overflow < 0 ? -overflow : 0;

        if (!c) return;
        if (!c.display_customer) {
            setUserMenuAbbreviation("");
            setUserMenuColour("");
        } else {
            setUserMenuAbbreviation(getUserAbbreviation(requester.user_id, false));
            setUserMenuColour("#D1D1D1");
        }

        setShowUserMenu(comment_id === commentUserMenuId ? !showUserMenu : true);
        setUserPosition([computeOverflow, e.currentTarget.offsetTop + e.currentTarget.clientHeight - scroll - computeOverflow + 10]);
        setCommentUserMenuId(comment_id);
    }

    function changeCommentUser() {
        if (!cellId) return;
        if (commentUserMenuId === "") {//TODO Possible special case
            setShowCustomer(!showCustomer)
            setShowUserMenu(false);
            return;
        }

        const current_comment = arr.find(fc => fc.comment_id === commentUserMenuId);
        if (!current_comment) return;
        publish(COMMENT_EDIT, {
            comment_id: current_comment.comment_id,
            comment: current_comment.comment,
            is_customer: !current_comment.display_customer,
            tags: current_comment.tags,
        });

        //Simulate update
        setComments((prevComments => {
            const newComments = new Map(prevComments);
            const cell_comments = newComments.get(cellId) ?? [];
            const index = cell_comments.findIndex(c => c.comment_id === current_comment.comment_id);
            cell_comments[index] = {
                ...cell_comments[index],
                comment: current_comment.comment,
                customer_display: !current_comment.display_customer,
                tags: current_comment.tags,
            }
            newComments.set(cellId, cell_comments);
            return newComments;
        }));
        setShowUserMenu(false);
    }

    async function writeComment() {
        if (commentText.trim() === "" || !cellId) return;
        setSendingComment(true);

        const message: CommentCreate = {
            comment_id: crypto.randomUUID(),
            cell_id: cellId,
            is_customer: showCustomer,
            comment: commentText,
            tags: taggedUsers
        };
        publish(COMMENT_CREATE, message);

        //Simulate update
        const specIdSec = location.pathname.split("/");
        setComments((prevComments => {
            const newComments = new Map(prevComments);
            const cell_comments = newComments.get(cellId) ?? [];
            cell_comments.push({
                comment_id: message.comment_id,
                comment_cell_id: cellId,
                origin_spec: specIdSec[specIdSec.length - 1],
                comment: message.comment,
                commenter: requester.user_id,
                customer_display: message.is_customer,
                commenter_fallback: "Unknown",
                post_date: new Date().getTime() / 1000,
                resolved: false,
                resolved_date: 0,
                resolved_user: "",
                tags: message.tags
            });

            newComments.set(message.cell_id, cell_comments);
            return newComments;
        }));

        setShowCustomer(false); //Reset comment menu
        localStorage.removeItem("partialcomment" + target.y);
        setCommentText("");
        setSendingComment(false);
        setTaggedUsers([]);
        localStorage.removeItem("partialtags" + target.y);
    }

    function resolveComment(comment_id: string = "") {
        if (!cellId) return;
        if (commentMenuId !== "" && comment_id === "") comment_id = commentMenuId; //Try to get a valid index from commentMenuId
        if (comment_id === "") return; // Still blank? Return
        const c = arr.find(fc => fc.comment_id === comment_id);
        if (!c) return;

        publish(COMMENT_RESOLVE, {
            comment_id: c.comment_id,
            is_resolved: !c.resolved
        });

        //Simulate update
        setComments((prevComments => {
            const newComments = new Map(prevComments);
            const cell_comments = newComments.get(cellId) ?? [];
            const index = cell_comments.findIndex(fc => fc.comment_id === c.comment_id);
            const isResolved = !c.resolved;
            cell_comments[index].resolved = isResolved;
            cell_comments[index].resolved_date = isResolved ? new Date().getTime() / 1000 : 0;
            cell_comments[index].resolved_user = isResolved ? requester.user_id : "";
            newComments.set(cellId, cell_comments);
            return newComments;
        }));
    }

    function saveEditedComment() {
        if (!cellId) return;
        const c = arr.find(fc => fc.comment_id === editCommentIndex);
        if (!c) return;
        publish(COMMENT_EDIT, {
            comment_id: c.comment_id,
            comment: editCommentValue,
            is_customer: c.display_customer,
            tags: c.tags,
        });

        //Simulate update
        setComments((prevComments => {
            const newComments = new Map(prevComments);
            const cell_comments = newComments.get(cellId) ?? [];
            const index = cell_comments.findIndex(fc => fc.comment_id === c.comment_id);
            cell_comments[index].comment = editCommentValue;
            cell_comments[index].customer_display = c.display_customer;
            cell_comments[index].tags = c.tags;

            newComments.set(cellId, cell_comments);
            return newComments;
        }));

        editComment();
        clearTags();
    }

    function deleteComment() {
        if (!cellId) return;
        const c = arr.find(fc => fc.comment_id === commentMenuId);
        if (!c) return;
        publish(COMMENT_DELETE, {
            comment_id: c.comment_id
        });

        //Simulate update
        setComments((prevComments => {
            const newComments = new Map(prevComments);
            let cell_comments = newComments.get(cellId) ?? [];
            cell_comments = cell_comments.filter(fc => fc.comment_id !== c.comment_id)
            newComments.set(cellId, cell_comments);
            return newComments;
        }));
    }

    function cancelEditComment() {
        editComment();
        clearTags();
    }

    function editComment() {
        const c = arr.find(fc => fc.comment_id === commentMenuId)
        if (!c) return;
        setTaggedUsers(c.tags);
        if (editCommentIndex === commentMenuId) {
            setEditCommentIndex("");
            setEditCommentValue("");
            return
        }
        setEditCommentIndex(commentMenuId);
        setEditCommentValue(c.comment);
    }

    function resolvedComments() {
        return arr.filter((c) => c.resolved);
    }

    function getNameFromId(userId: string): string {
        const index = users.map(u => u.user_id).indexOf(userId);
        if (index < 0) {
            return "X";
        }
        return users[index].name;
    }

    function getAbbreviationFromUser(user: User, displayCustomer: boolean) {
        if (displayCustomer) return customerAbbreviation;
        if (user.abbreviation && user.abbreviation !== "") {
            return user.abbreviation;
        }
        return getInitials(user.name, displayCustomer)
    }

    function getUserAbbreviation(userId: string, displayCustomer: boolean) {
        if (displayCustomer) return customerAbbreviation;
        const index = users.map(u => u.user_id).indexOf(userId);
        if (index < 0) {
            return "X";
        }
        const user = users[index];
        return getAbbreviationFromUser(user, false)
    }

    function selectEndOfTextArea() {
        if (lastInputId !== "") {
            const el = document.getElementById(lastInputId);
            if (el instanceof HTMLTextAreaElement) {
                const area = el as HTMLTextAreaElement;
                area.focus()
                area.selectionEnd = area.value.length + 1
            }
        }
    }

    function clearTags() {
        setTaggedUsers([]);
        localStorage.removeItem("partialtags" + target.y);
    }

    function appendTag(t: Taggable) {
        setTaggedUsers(prevTag => {
            const newTag = [...prevTag];
            newTag.push(t);
            localStorage.setItem("partialtags" + target.y, JSON.stringify(newTag));
            return newTag
        })
        setShowTagMenu(false);
        selectEndOfTextArea();
    }

    useEffect(() => {
        if (arr.length === 0) return;
        if (editCommentIndex === "") return;
        setArr(prevArr => {
            const newArr = [...prevArr];
            const c = prevArr.find(fc => fc.comment_id === editCommentIndex);
            if (!c) return newArr;
            //TODO commentUserMenuId was previously used, possibly special case?
            newArr.splice(newArr.indexOf(c), 1, {
                ...c,
                tags: taggedUsers
            })
            return newArr;
        })
    }
        , [taggedUsers])

    function openTagMenu(id: string) {
        const el = document.getElementById(id);
        if (!el) return;
        setTagPosition([0, el.offsetTop + el.clientHeight + 5]);
        setLastInputId(id);
        setShowTagMenu(true);
    }

    function handleKeyDown(ev: React.KeyboardEvent<HTMLDivElement>) {
        if (ev.key === "Escape") {
            if (showTagMenu) {
                ev.stopPropagation();
                ev.preventDefault();
                setShowTagMenu(false);
                selectEndOfTextArea();
            }
        }
        if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(ev.key)) {
            ev.stopPropagation();
        }
        if (ev.key === "Enter" && !ev.shiftKey && !ev.altKey && !ev.ctrlKey) {
            ev.stopPropagation();
            ev.preventDefault();
            const source = document.activeElement?.id ?? "none";
            if (source === "comment-editor-input") {
                saveEditedComment();
            } else if (source === "comment-create-input") {
                writeComment().catch(e => console.log(e));
            }
        }
        if (ev.key === "@" && document.activeElement instanceof HTMLTextAreaElement) {
            const el = document.activeElement as HTMLSpanElement;
            if (el.id === "comment-editor-input") return;
            openTagMenu(el.id);
            ev.stopPropagation();
            ev.preventDefault();
        }
    }

    if (loading) return <div className="w-72 flex flex-col flex-grow gap-y-2 items-center justify-center py-4">
        <span>Loading...</span>
        <div className="loader w-64">
            <div className="loaderBar w-64"></div>
        </div>
    </div>
    if (error) return <div className="w-72 flex flex-col flex-grow gap-y-2 items-center justify-center py-4">
        <span>Failed to load comments</span>
    </div>

    return (
        <div onKeyDown={handleKeyDown} className="w-72 pb-4" style={{ display: "flex flex-col", alignItems: "center", padding: "", color: "#000" }}>
            <div ref={commentDiv} className="max-h-96 overflow-y-scroll pb-4">
                {resolveCommentPopup.component}
                {moreOptionsPopup.component}
                {resolvedMoreOptionsPopup.component}
                {arr.filter((c) => !c.resolved || cell.data.readonly).sort((a, b) => b.post_date - a.post_date).map((c) =>
                    <div key={c.comment + c.poster_name + c.post_date} className="pt-8 px-4">
                        <div className="flex items-center gap-x-2">
                            <button
                                onClick={(e) => toggleUserMenu(e, c.comment_id)}
                                style={{ backgroundColor: c.display_customer ? "#552AD2" : "#D1D1D1" }}
                                className="flex p-2 rounded-full w-8 h-8 input-invisible items-center place-content-center text-white select-none"
                                disabled={c.comment_id !== editCommentIndex}
                            >
                                {getUserAbbreviation(c.poster_name, c.display_customer)}
                            </button>
                            <div className="flex flex-col mr-auto">
                                <span>{users.find(u => u.user_id == c.poster_name)?.name ?? ""}</span>
                                <span className="text-xs text-zinc-400">{new Date(c.post_date).toLocaleDateString()}</span>
                            </div>
                            {!cell.data.readonly && <button className="ghost-button clear-button" {...resolveCommentPopup.props} onClick={() => {
                                resolveComment(c.comment_id);
                            }}><Check size={20} className="text-accent" /></button>}
                            {!cell.data.readonly && requester.user_id === c.poster_name &&
                                <button className="ghost-button clear-button" {...moreOptionsPopup.props} onClick={(e) => toggleContextMenu(e, c.comment_id)}><MoreVertical size={20} color="#888" /></button>
                            }
                        </div>
                        <div className="py-2">
                            {editCommentIndex === c.comment_id ?
                                <div className="flex flex-col">
                                    <TextareaAutosize
                                        id="comment-editor-input"
                                        className="w-full bg-white border rounded-lg p-2"
                                        rows={2}
                                        maxRows={8}
                                        placeholder="Add a comment..."
                                        value={editCommentValue}
                                        onChange={(e) => setEditCommentValue(e.currentTarget.value)}
                                    />
                                    <div className="flex flex-col">
                                        {c.tags.map((t) => <span className="flex items-center gap-px"> <AtSign size={"1em"} />{t.name} </span>)}
                                    </div>
                                    <div className="w-full flex justify-between">
                                        <button
                                            className="clear-button text-left text-gray-500 hover:underline input-invisible flex gap-1"
                                            onClick={() => openTagMenu("comment-editor-input")}
                                        >
                                            <AtSign size={"1em"} /> - {taggedUsers.length === 0 ? "None" : taggedUsers.length} {taggedUsers.length === 0 ? "" : " tagged"}
                                        </button>
                                        <button
                                            className="ml-10 clear-button text-left text-gray-500 hover:underline input-invisible"
                                            onClick={clearTags}
                                        >
                                            Clear
                                        </button>
                                    </div>
                                    <div className="flex mt-2 gap-x-2">
                                        <button className="secondary-secondary-button text-zinc-950 ml-auto" onClick={cancelEditComment}>Cancel</button>
                                        <button onClick={saveEditedComment}>Save</button>
                                    </div>
                                </div>
                                : <span className="whitespace-pre-wrap">{c.comment}</span>}

                        </div>
                        {c.resolved && c.resolved_date && <div>Resolved by {getNameFromId(c.resolved_user)} on {new Date(c.resolved_date).toLocaleDateString()}</div>}
                        <div className="flex flex-col">
                            {editCommentIndex !== c.comment_id ? (
                                c.tags.map((t) => <span> @{t.name} </span>
                                )) : null
                            }
                        </div>
                    </div>
                )}
                {!cell.data.readonly && resolvedComments().length > 0 &&
                    <div className="w-full justify-center flex pt-6">
                        <button className="secondary-button text-zinc-950 px-8" onClick={() => setShowResolved(!showResolved)}>
                            {showResolved ? "Hide" : "Show"} {resolvedComments().length} resolved comment{resolvedComments().length > 1 ? "s" : ""}
                        </button>
                    </div>
                }
                {showResolved && resolvedComments().map((c) =>
                    <div key={c.comment + c.poster_name + c.post_date} className="pt-8 px-4">
                        <div className="flex items-center gap-x-2">
                            <div
                                style={{ backgroundColor: c.display_customer ? "#552AD2" : "#D1D1D1" }}
                                className="flex p-2 rounded-full w-8 h-8 bg-primary-200 items-center place-content-center text-white select-none"
                            >
                                {getUserAbbreviation(c.poster_name, c.display_customer)}
                            </div>
                            <div className="flex flex-col mr-auto">
                                <span className="text-xs text-zinc-400">{new Date(c.post_date).toLocaleDateString()}</span>
                            </div>
                            <button className="ghost-button clear-button" {...resolvedMoreOptionsPopup.props} onClick={(e) => toggleContextMenu(e, c.comment_id)}><MoreVertical size={20} color="#888" /></button>
                        </div>
                        <div className="py-2">
                            <span className="whitespace-pre-wrap">{c.comment}</span>
                        </div>
                        {c.resolved_date && <div>Resolved by {getNameFromId(c.resolved_user)} on {new Date(c.resolved_date).toLocaleDateString()}</div>}
                    </div>
                )}
                <CommentMenu
                    open={showCommentMenu}
                    setOpen={setShowCommentMenu}
                    isResolved={arr.find(fc => fc.comment_id === commentMenuId)?.resolved ?? false}
                    isMine={requester.user_id === arr.find(fc => fc.comment_id === commentMenuId)?.poster_name}
                    handleResolved={resolveComment}
                    handleDelete={deleteComment}
                    handleEdit={editComment}
                    position={contextPosition} />
            </div>
            {cell.data.readonly && arr.length === 0 && <div className="w-full text-center">No comments.</div>}
            {!cell.data.readonly && <div className="grid px-4 gap-x-2 items-center" style={{ gridTemplateColumns: "32px auto 38px" }}>
                {/*TODO this toggleUserMenu call was -2, is this a special case?*/}
                <button
                    onClick={(e) => toggleUserMenu(e, "")}
                    style={{ backgroundColor: showCustomer ? "#552AD2" : "#D1D1D1", width: "32px" }}
                    className="flex p-2 rounded-full w-10 h-8 input-invisible items-center place-content-center text-white select-none"
                >
                    {showCustomer ? "C" : users.find(x => x.user_id === requester.user_id)?.abbreviation ?? "You"}
                </button>
                <TextareaAutosize
                    id="comment-create-input"
                    className="w-full bg-white border rounded-lg text-[1.2em] p-2"
                    rows={1}
                    maxRows={8}
                    placeholder="Add a comment..."
                    value={commentText}
                    autoFocus
                    onChange={(e) => commentTextChanged(e.currentTarget.value)}
                />
                <button className="secondary-button text-zinc-950 px-2 p-2 w-[38px]" onClick={writeComment} {...sendCommentPopup.props} disabled={sendingComment}><ArrowUp width={20} height={20} /></button>
                {sendCommentPopup.component}
            </div>}
            {!cell.data.readonly && editCommentIndex === "" &&
                <div className="px-4">
                    <div className="flex flex-col">
                        {taggedUsers.map((t) => <span className="flex items-center gap-px"> <AtSign size={"1em"} />{t.abbreviation ? t.abbreviation : t.name} </span>)}
                    </div>
                    <button
                        className="ml-10 clear-button text-left text-gray-500 hover:underline input-invisible flex gap-1"
                        onClick={() => openTagMenu("comment-create-input")}
                    >
                        <AtSign size={"1em"} /> - {taggedUsers.length === 0 ? "None" : taggedUsers.length} {taggedUsers.length === 0 ? "" : " tagged"}
                    </button>
                    <button
                        className="ml-10 clear-button text-left text-gray-500 hover:underline input-invisible"
                        onClick={clearTags}
                    >
                        Clear
                    </button>
                </div>}
            {editCommentIndex !== "" && <div className="mb-4" />}
            <PosterMenu open={showUserMenu} setOpen={setShowUserMenu} handleChange={changeCommentUser} abbreviation={userMenuAbbreviation} iconColor={userMenuColour} position={userPosition} />
            <TagMenu
                open={showTagMenu}
                setOpen={setShowTagMenu}
                taggables={[
                    ...users.filter(
                        u => !taggedUsers.map(t => t.id).includes(u.user_id)
                    ).map(u => ({ "name": u.name, "type": "user", "id": u.user_id, "colour": "#D1D1D1", abbreviation: getAbbreviationFromUser(u, false) })),
                    ...departments.filter(
                        d => !taggedUsers.map(t => t.id).includes(d.department_id)
                    ).map(d => ({ "name": d.department, "type": "department", "id": d.department_id, "colour": d.colour, "abbreviation": d.abbreviation?.slice(0, 3) }))
                ]}
                handleSelection={appendTag}
                position={tagPosition}
            />
        </div>
    );
};

const Renderer: () => CustomRenderer<CommentCell> = () => {
    const { comments } = useComments();

    const renderer: CustomRenderer<CommentCell> = {
        kind: GridCellKind.Custom,
        isMatch: (cell: CustomCell): cell is CommentCell => (cell.data as CommentCellProps).kind === "comment-cell",
        needsHover: false,
        draw: (args, cell) => {
            const { ctx, theme, rect } = args;

            const commentCellId = JSON.parse(cell.data.comments)["id"];
            const resolvedCount = comments.get(commentCellId)?.filter(c => c.resolved).length ?? 0;
            const count: string | number = (comments.get(commentCellId)?.length ?? 0) - resolvedCount;

            const commentString = count > 0 ? `${count} comments` : "";

            const drawX = rect.x + theme.cellHorizontalPadding;

            ctx.font = "8px"
            ctx.fillStyle = "#222";
            ctx.fillText(count === 0 ? "" : `${commentString}`, drawX, rect.y + rect.height / 2 + 2);
            return true;
        },
        provideEditor: () => ({
            editor: Editor,
            disablePadding: true,
            deletedValue: v => ({
                ...v,
                copyData: "",
                data: {
                    ...v.data,
                    value: "",
                },
            }),
        }),
        onPaste: (_val, d) => { return d },
    }

    return renderer
};

export default Renderer;
