import { ChevronDown } from "lucide-react";
import { colours } from "components/TableExtract/Table/menus/colour-menus/common.ts";
import ColourMenu from "components/TableExtract/Table/menus/colour-menus/ColourMenu.tsx";
import {useEffect, useMemo, useState} from "react";
import {nanoid} from "nanoid";


interface ColourCellProps {
    id: number;
    canEdit: boolean;
    showEdit: boolean;
    displayValue: string;
    editValue?: string;
    setColour: (i: number, colour: string) => void;
}

function ColourCell(props: ColourCellProps) {
    const [menuOpen, setMenuOpen] = useState(false);
    const id = useMemo(() => nanoid(), [])

    useEffect(() => {
        if (!props.showEdit) setMenuOpen(false);
    }, [props.showEdit]);
    return (
        <div className="content-center relative">
            <button style={{
                backgroundColor: props.editValue ? props.editValue : props.displayValue,
                // @ts-expect-error tsc is slow
                anchorName: `--${id}`,
            }}
                className={"p-0 text-zinc-950 rounded-full w-[24px] h-[24px] flex place-content-center items-center " + (props.canEdit ? "" : "input-invisible cursor-default")}
                onClick={(e) => {
                    e.stopPropagation();
                    //Determine whether to close or open
                    setMenuOpen(!menuOpen && props.canEdit);
                }}
            >
                {props.canEdit && <ChevronDown height={20} width={20} />}
            </button>
            {menuOpen &&
                <div className="relative z-30">
                    <ColourMenu position={"right"} id={id} className="absolute inset-auto m-0 border bg-white rounded-2xl p-4" colours={colours} onPick={(colour) => {
                        props.setColour(props.id, colour);
                        setMenuOpen(false);
                    }} />
                </div>
            }
        </div>
    );
}

export default ColourCell;
