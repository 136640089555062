import 'App.css';
import { useEffect, useState } from "react";
import {
    CheckCircle, ChevronDown, ChevronUp,
    FileType, Loader2,
    Trash2, X
} from "lucide-react";
import { APIResponse, request } from "services/api";
import { DetailMessage, Job } from "types/requests";
import {usePopup} from "hooks/Popup.tsx";

function Tray() {
    const [open, setOpen] = useState<boolean>(false);
    //Ensure pop-open on upload isn't intrusive by limiting when it happens
    const [latch, setLatch] = useState<boolean>(false);
    const [clearingAll, setClearingAll] = useState<boolean>(false);
    const [jobs, setJobs] = useState<Job[]>([]);
    const [timeoutId, setTimeoutId] = useState<number>();

    const closePopup = usePopup("Close", "left", "top");
    const minimisePopup = usePopup("Minimise", "left", "top");
    const openSpecPopup = usePopup("Open Specification", "left", "top");
    const clearErrorPopup = usePopup("Clear Error", "left", "top");
    const loadingPopup = usePopup("Extracting Specification", "left", "top");

    function handleViewSpec(id: string, group: string) {
        clearItem(group)
        window.location.pathname = `/specifications/view/${id}`;
    }

    function clearItem(groupId: string) {
        request<DetailMessage>("POST", `/groups/${groupId}/status/clear`).subscribe({
            next: (r: APIResponse<DetailMessage>) => {
                if (!r.ok || r.statusCode !== 200) return;
                setJobs(prevJobs => {
                    const newJobs = [...prevJobs];
                    newJobs.splice(prevJobs.map(j => j.group_id).indexOf(groupId), 1);
                    return newJobs;
                });
            },
            error: (e) => {
                console.error(e);
            },
        });
    }

    function clearAllReady() {
        if (clearingAll) return
        setClearingAll(true);
        request<DetailMessage>("POST", `/jobs/status/READY/clear`).subscribe({
            next: (r: APIResponse<DetailMessage>) => {
                if (!r.ok || r.statusCode !== 200) return;
                setJobs([]);
                setLatch(false);
                setClearingAll(false);
            },
            error: (e) => {
                console.error(e);
                setClearingAll(false);
            },
        });
    }

    useEffect(() => {
        poll();
    }, []);

    function poll() {
        request<Job[]>("GET", `/jobs`).subscribe({
            next: (r: APIResponse<Job[]>) => {
                if (!r.ok || r.statusCode !== 200 || !r.data) {
                    const id = setTimeout(() => poll(), 15000) as unknown as number;
                    if (timeoutId) {
                        clearTimeout(timeoutId);
                        setTimeoutId(id);
                    }
                    return;
                } //TODO: logs

                setJobs(r.data);
                if (r.data.length > 0 && !latch) {
                    setOpen(true);
                    setLatch(true);
                }
                const id = setTimeout(() => poll(), 5000) as unknown as number;
                if (timeoutId !== undefined) {
                    clearTimeout(timeoutId);
                    setTimeoutId(id);
                }
            },
            error: (e) => {
                console.error(e);
                const id = setTimeout(() => poll(), 15000) as unknown as number;
                if (timeoutId !== undefined) {
                    clearTimeout(timeoutId);
                    setTimeoutId(id);
                }
            },
        });
    }

    function getJobTrayLabel() {
        const workingJobs = jobs.map(j => j.status).filter(j => j === "WORKING").length;
        if (workingJobs > 0) return `${workingJobs} Running Job${workingJobs > 1 ? "s" : ""}`;

        const errorJobs = jobs.map(j => j.status).filter(j => j === "ERROR").length;
        if (errorJobs > 0) return `${errorJobs} Job${errorJobs > 1 ? "s" : ""} Failed`;

        if (jobs.length > 0) return `${jobs.length} Job${jobs.length > 1 ? "s" : ""} Completed`;
        return "No jobs pending";
    }

    if (jobs.length === 0) return <></>
    if (!open) return (
        <div className="fixed z-10 bottom-0 right-14 bg-white hover:bg-zinc-100 w-64 h-10 border-x border-t rounded-t-lg px-2">
            <div className="flex justify-between items-center h-full">
                <span></span>
                <span>{getJobTrayLabel()}</span>
                <ChevronUp className="cursor-pointer" onClick={() => setOpen(!open)} />
                {jobs.map(j => j.status).filter(j => j === "READY").length === jobs.length &&
                    <X className="cursor-pointer" onClick={clearAllReady} />
                }
            </div>
        </div>
    )

    return (
        <div className="fixed z-10 bottom-0 right-14 bg-white w-64 border-x border-t rounded-t-lg" style={{ zIndex: 100 }}>
            <div className="flex justify-between items-center h-full bg-zinc-100 p-2">
                <span></span>
                <span>{getJobTrayLabel()}</span>
                <button className="clear-button text-black cursor-pointer" {...minimisePopup.props} onClick={() => setOpen(!open)}>
                    <ChevronDown/>
                </button>
                {jobs.map(j => j.status).filter(j => j === "READY").length === jobs.length &&
                    <X className={clearingAll ? "cursor-wait" : "cursor-pointer"} {...closePopup.props} onClick={clearAllReady} />
                }
                {closePopup.component}
                {minimisePopup.component}
            </div>
            <div className="overflow-y-auto max-h-96">
                {jobs.map(j =>
                    <div className="flex flex-col gap-y-2 p-2 px-3 hover:bg-zinc-100">
                        <div className="flex select-none justify-between">
                            <div className="flex gap-x-2 w-full text-left overflow-hidden">
                                <FileType />
                                <div className="overflow-hidden w-[80%] whitespace-nowrap">{j.job_label}</div>
                            </div>
                            {j.status === "READY" &&
                                <button className="clear-button ghost-button hover:bg-zinc-300 text-black p-0 rounded-full" {...openSpecPopup.props} onClick={() => handleViewSpec(j.latest_spec_id, j.group_id)}>
                                    <CheckCircle color={"#2D8E72"} />
                                </button>
                            }
                            {j.status === "ERROR" &&
                                <button className="clear-button ghost-button hover:bg-zinc-300 text-black p-0 rounded-full" {...clearErrorPopup.props} onClick={() => clearItem(j.group_id)}>
                                    <Trash2 />
                                </button>
                            }
                            {j.status === "WORKING" &&
                                <div className="spin" {...loadingPopup.props}><Loader2 size={26} color={"#737383"} /></div>
                            }
                            {clearErrorPopup.component}
                            {openSpecPopup.component}
                            {loadingPopup.component}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Tray;
