import '../../table-extract.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "@glideapps/glide-data-grid/dist/index.css";
import React, { useEffect, useState, useRef } from "react";
import { Rectangle } from "@glideapps/glide-data-grid";
import { ArrowLeft, ArrowRight, Languages, Snowflake, Trash2 } from 'lucide-react';
import Pencil from "assets/Pencil.svg";
import { Column, ColumnProperties, TableResponse } from "types/requests";
import ColourTextOptions from "./colour-menus/ColourTextOptions.tsx";
import TranslateMenuContent, { createTranslateCol } from "./TranslateMenuContent";
import { getLucideIconByType } from "components/TableExtract/Table/types/types";
import { nanoid } from 'nanoid';
import { OptionsRewriteOperation } from 'types/collab';
import { OptionItemData } from "components/TableExtract/Table/menus/colour-menus/common.ts";
import ColourDepartments from "components/TableExtract/Table/menus/colour-menus/ColourDepartments.tsx";
import { APIResponse, request } from "services/api.ts";
import { Department, DepartmentListResponse } from "types/departments.ts";

interface ColMenuProps {
    setAddColVisible: (e: React.MouseEvent<HTMLButtonElement>, i: number) => void;
    onDeleteCol: (index: number) => void;
    onRenameCol: (index: number, newName: string) => void;
    onAddCol: (index: number, name: string, type: string, baseData: string) => void;
    onAddGenCol: (index: number, name: string, type: string, request: string) => void;
    firstColFrozen: boolean;
    onFreeze: () => void;
    getCol: (index: number) => Column;
    onRewriteOptions: (index: number, changes: OptionsRewriteOperation[], name_mapping?: Map<string, string>) => void;//TODO revert name_mapping when id cell is ready
    isOpen: boolean;
    setOpen: (o: boolean) => void;
    menu: {
        col: number;
        bounds: Rectangle;
    } | undefined;
    table: TableResponse;
    department_config: string[];
    department_name_mapping: Map<string, string>;
}

const dropdownishTypes = ["chips", "departments", "dropdown", "classification"]

function generateDepartmentChanges(departmentMap: Map<string, Department>, departmentConfig: string[], current: OptionItemData[]): OptionsRewriteOperation[] {
    const changes: OptionsRewriteOperation[] = [];

    for (const op of current) {
        if (departmentConfig.find(x => x === op.id)) {
            changes.push({
                type: "EDIT",
                previousName: op.id,
                previousColour: op.color,
                newName: op.id,
                newColour: op.color,
            });
        } else {
            changes.push({
                type: "CREATE",
                newName: op.id,
                newColour: op.color,
                previousName: "",
                previousColour: "",
            });
        }
    }

    for (const op of departmentConfig) {
        if (current.some(x => x.id === op)) continue;
        const dep = departmentMap.get(op)
        changes.push({
            type: "DELETE",
            previousName: dep!.department,
            previousColour: dep!.colour,
        });
    }

    return changes;
}

function generateOptionsChanges(initial: OptionItemData[], current: OptionItemData[]): OptionsRewriteOperation[] {
    const changes = [];

    for (const op of current) {
        const int = initial.find(x => x.id === op.id)
        const update: OptionsRewriteOperation = {
            type: int ? "EDIT" : "CREATE",
            previousName: int?.label!,
            previousColour: int?.color!,
            newName: op.label,
            newColour: op.color,
        };
        changes.push(update);
    }

    for (const op of initial) {
        if (current.some(x => x.id === op.id)) continue;
        const update: OptionsRewriteOperation = {
            type: "DELETE",
            previousName: op.label,
            previousColour: op.color,
        };
        changes.push(update);
    }

    return changes;
}

function ColMenu(props: ColMenuProps) {
    const [colMenuOpen, setColMenuOpen] = useState(false);
    const [colMenuMode, setColMenuMode] = useState<"" | "editcol" | "translatecol">("");
    const [colMenuInitialName, setColMenuInitialName] = useState("");
    const [colMenuCurrentName, setColMenuCurrentName] = useState("");
    const [colMenuType, setColMenuType] = useState("");
    const [initialColMenuOptions, setInitialColMenuOptions] = useState<OptionItemData[]>([]);
    const [colMenuOptions, setColMenuOptions] = useState<OptionItemData[]>([]);
    const [currentColProperties, setCurrentColProperties] = useState<ColumnProperties>()

    //Translate
    const [language, setLanguage] = useState<string>("German");
    const [targetColOverride, setTargetColOverride] = useState<string | undefined>(undefined);

    const iconColour = "#737383";
    const iconSize = 24;
    const iconStrokeWidth = 2;

    //Departments
    const [globalDepartmentData, setGlobalDepartmentData] = useState<Map<string, Department>>(new Map());
    const [allDepartments, setAllDepartments] = useState<OptionItemData[]>([]);
    const [departments, setDepartments] = useState<OptionItemData[]>([]);

    useEffect(() => {
        request<DepartmentListResponse>("GET", "/config/departments").subscribe({
            next: (r: APIResponse<DepartmentListResponse>) => {
                if (!r.ok || r.statusCode !== 200 || !r.data) return; //TODO logs
                const depMap = new Map<string, Department>();
                r.data.departments.forEach(d => depMap.set(d.department_id, d))
                setGlobalDepartmentData(depMap);

                const defDeps = r.data.departments.map(d => ({ id: d.department_id, color: d.colour, label: d.department }));
                setAllDepartments(defDeps);
            },
            error: (e) => {
                console.error(e);
                //TODO proper error
            },
        });
    }, []);

    function saveDisabled() {
        if (colMenuType !== "departments") return false;
        return departments.length === 0 || departments.map(i => i.id).includes("000");
    }

    function getColName(col: number) {
        if (props.table.table.length === 0 || props.table.table[0].values.length <= col || props.table.table[0].values[col].length === 0) {
            return undefined;
        }
        return props.table.columns[col].name;
    }

    function getColType(col: number): string {
        if (props.table.table.length === 0 || props.table.table[0].values.length <= col || props.table.table[0].values[col].length === 0) {
            return "unknown";
        }
        let type = props.table.table[0].values[col][0].type;
        if (type === "image") type = "string";
        else if (type === "translate") type = "string";
        return type;
    }

    function colTranslatable(col: number) {
        const type = getColType(col);
        switch (type) {
            case "string":
            case "text":
            case "translation":
            case "deviation":
                return true
            default:
                return false;
        }
    }

    function getColOptions(col: number): OptionItemData[] {
        if (props.table.table.length === 0 || props.table.table[0].values.length <= col || props.table.table[0].values[col].length === 0 || !props.table.table[0].values[col][0].value.includes("|")) {
            return [];
        }
        const sides = props.table.table[0].values[col][0].value.split("|");
        if (sides.length < 2) return [];
        const rawOpts = sides[1].split(",");

        if (rawOpts.length === 1 && rawOpts[0] === "") {
            return [];
        }
        return rawOpts.map(r => {
            const optSides = r.split("^");
            const label = optSides[0];
            const colour = optSides.length > 1 ? optSides[1] : "#aaaaaa";

            return {
                id: nanoid(),
                label: label,
                color: colour,
            }
        })
    }
    function getDepColOptions(col: number): OptionItemData[] {
        if (props.table.table.length === 0 || props.table.table[0].values.length <= col || props.table.table[0].values[col].length === 0 || !props.table.table[0].values[col][0].value.includes("|")) {
            return [];
        }
        const sides = props.table.table[0].values[col][0].value.split("|");
        if (sides.length < 2) return [];
        const rawOpts = sides[1].split(",");

        if (rawOpts.length === 1 && rawOpts[0] === "") {
            return [];
        }
        return rawOpts.map(r => {
            const optSides = r.split("^");
            const label = optSides[0];
            const colour = optSides.length > 1 ? optSides[1] : "#aaaaaa";
            return {
                id: props.department_name_mapping.get(label) ?? nanoid(),
                label: label,
                color: colour,
            }
        })
    }

    function openCreateTranslateMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        if (!menu) return;
        const type = getColType(menu.col);
        if (type === "unknown") return;
        setColMenuType(type);
        setTargetColOverride(getColName(menu.col));
        setColMenuCurrentName("TL " + (props.getCol(menu.col).name ?? ""));
        setColMenuMode("translatecol");
    }

    function freezeColumn() {
        props.onFreeze()
        closeMenus();
    }

    function openEditColMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();

        if (!menu) return;
        const type = getColType(menu.col);
        if (type === "unknown") return;
        setColMenuType(type);
        if (dropdownishTypes.includes(type)) {
            const ops = getColOptions(menu.col);
            setInitialColMenuOptions(ops);
            setColMenuOptions(ops);

            // Prep col menu if type is departments
            if (type === "departments") {
                const depOps = getDepColOptions(menu.col)
                const depMap = new Map();
                depOps.forEach(o => depMap.set(o.id, o.color));
                setDepartments(
                    props.department_config.map(d => ({
                        id: d,
                        color: (depMap.has(d) ? depMap.get(d) : globalDepartmentData.get(d)?.colour) ?? "#000000",
                        label: globalDepartmentData.get(d)?.department ?? "?"
                    }))
                );
            }
        }



        setColMenuInitialName(props.getCol(menu.col).name ?? "");
        setColMenuCurrentName(props.getCol(menu.col).name ?? "");
        setColMenuMode("editcol");
    }
    function backOutEditColMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        setColMenuMode("");
    }

    const [menu, setMenu] = useState<{
        col: number;
        bounds: Rectangle;
    }>();

    useEffect(() => { //Pass through prop updates to open menu
        setMenu(props.menu);
        if (props.menu) setCurrentColProperties(props.getCol(props.menu.col).properties)
        setColMenuOpen(props.isOpen);
        if (!props.isOpen) closeMenus();
    }, [props.menu, props.isOpen]);

    function closeMenus() {
        setColMenuOpen(false);
        setMenu(undefined);
        setColMenuMode("");
        setColMenuCurrentName("");
        setCurrentColProperties(undefined)
        props.setOpen(false);
    }

    function saveChanges() {
        if (!menu) return
        let colName = colMenuCurrentName;
        if (colName !== colMenuInitialName) {
            while (props.table.columns.map(c => c.name.toLowerCase()).includes(colName.toLowerCase())) colName += "_";
        }

        if (colMenuMode === "translatecol") {
            createTranslateCol(props.table, colName, targetColOverride ?? props.table.columns[0].name, language, props.onAddGenCol);
        } else {
            if (colName !== colMenuInitialName) props.onRenameCol(menu.col, colName);
            if (colMenuType === "departments") {
                const changes = generateDepartmentChanges(globalDepartmentData, props.department_config, departments);
                const name_map = new Map<string, string>();
                Array.from(globalDepartmentData.values()).forEach(d => name_map.set(d.department_id, !d.abbreviation || d.abbreviation.trim() === "" ? d.department : d.abbreviation))
                props.onRewriteOptions(menu.col, changes, name_map); //TODO revert name_mapping when id cell is ready
            } else if (dropdownishTypes.includes(colMenuType)) {
                const changes = generateOptionsChanges(initialColMenuOptions, colMenuOptions)
                props.onRewriteOptions(menu.col, changes);
            }
        }
        closeMenus();
    }

    const menuStyle = {
        "left": menu?.bounds.x ?? 0,
        "top": (menu?.bounds.y ?? 0) + (menu?.bounds.height ?? 0) + 1,
        "border": "1px white solid",
        "backgroundColor": "white",
        "filter": "drop-shadow(1px 1px 1px #ddd)",
        "borderRadius": "6px",
    };
    //Handle clicks outside menus
    const colMenuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: Event) => {
        if (colMenuRef.current && !colMenuRef.current.contains(e.target as Node)) closeMenus();
    };

    function showConfirmation(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        dialog.current?.showModal();
    }

    const dialog = useRef<HTMLDialogElement>(null);

    function returnToMain(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        dialog.current?.close()
    }


    if (!colMenuOpen) return <></>
    else return (
        <>
            <dialog id="deletewarn" ref={dialog} className="bg-transparent">
                <div className="bg-white text-zinc-950 p-4 rounded-lg">
                    <h3 className="select-none text-zinc-950 font-bold pb-4">Delete column</h3>
                    <span>Are you sure you want to delete column '{(menu ? props.getCol(menu.col).name : "")}'?<br /> All data within the column will be lost.</span>
                    <div className="flex gap-x-4 justify-end pt-4">
                        <button onClick={returnToMain} className="bg-zinc-100 text-zinc-950 hover:bg-zinc-200">Cancel</button>
                        <button className="bg-red-600" onClick={() => props.onDeleteCol(menu?.col ?? -1)}>Delete</button>
                    </div>
                </div>
            </dialog>
            <div ref={colMenuRef} className="absolute p-4 rounded-xl" style={menuStyle}>
                {colMenuMode === "" && <>
                    {!(currentColProperties?.uneditable ?? false) &&
                        <button
                            onClick={openEditColMenu}
                            className="flex clear-button pr-4 w-full py-2 hover:bg-neutral-100 text-zinc-950 font-semibold"
                        >
                            <div className="px-4"><img src={Pencil} /></div>Edit column
                        </button>
                    }
                    {menu && colTranslatable(menu.col) &&
                        <button
                            onClick={openCreateTranslateMenu}
                            className="flex clear-button pr-4 w-full py-2 hover:bg-neutral-100 text-zinc-950 font-semibold"
                        >
                            <div className="px-4"><Languages className="text-accent" /></div>Translate column
                        </button>
                    }
                    {menu && menu.col === 0 && !props.firstColFrozen &&
                        <button
                            onClick={freezeColumn}
                            className="flex clear-button pr-4 w-full py-2 border-b-slate-300 hover:bg-neutral-100 disabled:bg-white disabled:text-zinc-400 text-zinc-950 font-semibold"
                        >
                            <div className="px-4"><Snowflake /></div>
                            Freeze column
                        </button>
                    }
                    {menu && menu.col === 0 && props.firstColFrozen &&
                        <button
                            onClick={freezeColumn}
                            className="flex clear-button pr-4 w-full py-2 border-b-slate-300 hover:bg-neutral-100 disabled:bg-white disabled:text-zinc-400 text-zinc-950 font-semibold"
                        >
                            <div className="px-4"><Snowflake /></div>
                            Unfreeze column
                        </button>
                    }
                    {(!props.firstColFrozen || menu && menu.col > 0) &&
                        <button
                            onClick={(e) => props.setAddColVisible(e, menu?.col ?? -1)}
                            className="flex clear-button pr-4 w-full py-2 border-t-slate-300 hover:bg-neutral-100 text-zinc-950 font-semibold"
                        >
                            <div className="px-4"><ArrowLeft /></div>Insert left
                        </button>
                    }
                    <button
                        onClick={(e) => props.setAddColVisible(e, (menu?.col ?? -2) + 1)}
                        className="flex clear-button pr-4 w-full py-2 hover:bg-neutral-100 text-zinc-950 font-semibold"
                    >
                        <div className="px-4"><ArrowRight /></div>Insert right
                    </button>
                    {props.table.columns.length > 1 && !(currentColProperties?.permanent ?? false) &&
                        < button
                            onClick={showConfirmation}
                            className="flex clear-button pr-4 w-full py-2 border-t-slate-300 hover:bg-neutral-100 text-zinc-950 font-semibold"
                        >
                            <div className="px-4"><Trash2 className="" /></div>Delete column
                        </button>
                    }
                </>}
                {colMenuMode === "editcol" && <>
                    <h3 className="select-none text-zinc-950 font-bold pb-4">Edit column</h3>
                    <div className="flex gap-x-4 items-center pb-4">
                        {getLucideIconByType(colMenuType, iconColour, iconSize, iconStrokeWidth)}
                        <input className={"bg-white input border rounded-lg h-9 p-2 font-semibold" + (colMenuType === "departments" ? " w-96" : "")} autoFocus value={colMenuCurrentName} onChange={(e) => setColMenuCurrentName(e.target.value)} />
                    </div>
                    {colMenuType !== "departments" && dropdownishTypes.includes(colMenuType) && !currentColProperties?.options_hidden && <>
                        <span className="pt-4 border-b pb-2 text-zinc-400">Options</span>
                        <ColourTextOptions items={colMenuOptions} setItems={setColMenuOptions} openItemLabel={"editor"} />
                    </>}
                    {colMenuType === "departments" && <>
                        <div className="border-b pb-2 -mb-1 text-zinc-400 select-none">
                            Departments
                            <button
                                className="clear-button input-invisible hover:bg-zinc-100 px-2 text-black float-right"
                                onClick={() => setDepartments([])}
                                disabled={departments.length === 0}
                            >
                                Clear All
                            </button>
                        </div>
                        <ColourDepartments options={allDepartments} items={departments} disabledOptions={departments} setItems={setDepartments} openItemLabel={"allassignment"} />
                    </>}
                </>}
                {colMenuMode === "translatecol" &&
                    <TranslateMenuContent
                        table={props.table}
                        onNameChanged={setColMenuCurrentName}
                        onLanguageChanged={setLanguage}
                        onTargetColChanged={setTargetColOverride}
                        columnDefaultName={colMenuCurrentName}
                        disableColSelector={true}
                        selectedColumn={targetColOverride}
                    />
                }
                {colMenuMode !== "" &&
                    <div className="flex gap-x-4 items-center pt-4">
                        <button onClick={backOutEditColMenu} className="bg-zinc-100 hover:bg-zinc-200 text-zinc-950 ml-auto">Cancel</button>
                        <button onClick={saveChanges} disabled={saveDisabled()}>Save</button>
                    </div>
                }
            </div >
        </>
    );
}

export default ColMenu;
