import { ArrowLeft, EllipsisVertical, ExternalLink, PowerOff, Power } from "lucide-react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ButtonTabs } from "components/ButtonTabs/ButtonTabs"
import Pagination from "components/Pagination/Pagination";
import { ActiveTag, InactiveTag } from "components/Tags/Tag"
import { APIResponse } from "services/api";
import { useEffect, useState } from "react";
import { Deviation, Pagination as PaginationType } from "types/requests";
import { request } from "services/api";
import { usePopup } from "hooks/Popup";


const pageSize = 10

function knowledge() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [deviations, setDeviations] = useState<PaginationType<Deviation>>();
    const [disabledTagButtons, setDisabledTagButtons] = useState<string[]>([]);
    const [displayedMenu, setDisplayedMenu] = useState<number>();
    const [currentPage, setCurrentPage] = useState<number>(searchParams.has("page") ? parseInt(searchParams.get("page") ?? "1") ?? 1 : 1);

    const goBackPopup = usePopup("Go back");
    const moreOptionsPopup = usePopup("More Options")

    useEffect(() => {
        request<PaginationType<Deviation>>("GET", `/deviations?page=${currentPage}&size=${pageSize}`).subscribe({
            next: (r: APIResponse<PaginationType<Deviation>>) => {
                if (!r.ok || r.statusCode !== 200 || !r.data) {
                    return; //TODO logs

                }

                setDeviations(r.data)
            },
            error: (e) => {
                console.error(e);
            },
        });
    }, [currentPage])

    function back() {
        navigate("/")
    }



    function setDeviation(id: string, value: boolean) {
        if (disabledTagButtons.includes(id)) return;
        setDisabledTagButtons([...disabledTagButtons, id])
        request("PUT", `/deviations/${id}/${value ? "enable" : "disable"}`).subscribe({
            next: (r: APIResponse<unknown>) => {
                if (!r.ok || r.statusCode !== 200) {
                    return; //TODO logs

                }

                let data = {
                    ...deviations!
                }

                data.data.forEach(x => {
                    if (x.id === id) x.enabled = value
                })

                setDeviations(data)
            },
            error: (e) => {
                console.error(e);
            },
            complete: () => {
                setDisabledTagButtons(disabledTagButtons.filter(x => x !== id))
            }
        })
    }

    function openSource(de: Deviation) {
        const url = `/specifications/view/${de.spec_id}?row_id=${de.row_id}`
        window.open(window.location.origin + url, "_blank")
    }

    function updatePage(page: number) {
        setCurrentPage(page)
        setSearchParams(new URLSearchParams({ "page": "" + page }))
    }


    // if (deviations) {
    //     deviations.data = [...deviations.data, ...deviations.data, ...deviations.data, ...deviations.data, ...deviations.data, ...deviations.data, ...deviations.data,]
    //     deviations.count = deviations.data.length
    // }


    return (
        <div className="fixed top-0 left-16 right-0 bottom-0 z-50 p-7">
            <div className="flex gap-x-4 items-center pb-8">
                <button className="ghost-button clear-button text-black" {...goBackPopup.props} onClick={back}><ArrowLeft /></button>
                {goBackPopup.component}
                <span className="text-xl font-semibold">Knowledge</span>
            </div>
            <div>
                <ButtonTabs tab={"Deviations"} tabs={["Deviations"]} />
                <div className="grid" style={{ gridTemplateColumns: "4fr 4fr 2fr 1fr 1fr" }}>
                    <th>
                        Requirement
                    </th>
                    <th>
                        Deviations
                    </th>
                    <th>
                        Date
                    </th>
                    <th>
                        Status
                    </th>
                    <th>
                        Actions
                    </th>
                    <div className="grid overflow-y-auto overflow-x-hidden self-start" style={{ gridTemplateColumns: "subgrid", gridColumn: "1 / -1", maxHeight: "calc(100dvh - 15rem)" }}>
                        {moreOptionsPopup.component}
                        {deviations && deviations.data.map((de: Deviation, i: number) => {
                            return (<>
                                <td>{de.requirement}</td>
                                <td>{de.suggestion}</td>
                                <td>{new Date(Number(de.created_at)).toLocaleDateString()}</td>
                                <td>{de.enabled ? <ActiveTag className="cursor-pointer" onClick={() => setDeviation(de.id, false)} /> : <InactiveTag className="cursor-pointer" onClick={() => setDeviation(de.id, true)} />}</td>
                                <td className="relative w-4">
                                    <button onClick={() => setDisplayedMenu(displayedMenu === i ? undefined : i)} {...moreOptionsPopup.props} className="clear-button text-zinc-950"><EllipsisVertical /></button>
                                    <div className={`absolute menu ${displayedMenu !== i ? "hidden" : ""}`}>
                                        {de.enabled && <button onClick={() => { setDeviation(de.id, false); setDisplayedMenu(undefined) }} className="menu-button text-zinc-950"><PowerOff width={19} />Deactivate</button>}
                                        {!de.enabled && <button onClick={() => { setDeviation(de.id, true); setDisplayedMenu(undefined) }} className="menu-button text-zinc-950"><Power width={19} />Activate</button>}
                                        <button onClick={() => openSource(de)} className="menu-button text-zinc-950"><ExternalLink width={19} />View Source</button>
                                    </div>
                                </td>
                            </>)
                        })
                        }
                    </div>
                </div>
                <div className="flex justify-end">
                    <Pagination totalItemCount={deviations?.count ?? 0} pageSize={pageSize} currentPage={currentPage} onPageChange={(page: number) => updatePage(page)} />
                </div>
            </div >
        </div >);
}

export default knowledge;
