import { useEffect, useRef, useState } from "react";
import 'App.css'
import { CheckCircle2, Trash2 } from "lucide-react";
import Pencil from "assets/Pencil.svg"

interface MenuProps {
    open: boolean;
    setOpen: (o: boolean) => void;
    handleEdit: () => void;
    handleResolved: () => void;
    handleDelete: () => void;
    isResolved: boolean;
    isMine: boolean;
    position?: number[];
}
// TODO: values besides pure strings
function CommentMenu(props: MenuProps) {
    const dialog = useRef<HTMLDialogElement>(null);
    const [showOptions, setShowOptions] = useState(false);
    const [position, setPosition] = useState([0, 0]);
    const [menuState, setMenuState] = useState("");

    useEffect(() => {
        setShowOptions(props.open);
        if (props.position)
            setPosition(props.position);
        setMenuState("");
    }, [props.open, props.position])

    function closeMenu() {
        props.setOpen(false);
        setShowOptions(false);
        setMenuState("");
        dialog.current?.close()
    }

    function delClicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        setMenuState("delconfirm");
    }

    function clearMenuState(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        setMenuState("");
    }

    function confirmDelete() {
        dialog.current?.showModal();
        props.handleDelete();
        closeMenu();
    }

    function resolveClicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        dialog.current?.showModal();
        props.handleResolved();
        closeMenu();
    }

    function editClicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        props.handleEdit();
        closeMenu();
    }
    const menuStyle = {
        "top": position[1],
        "right": "16px",
        "border": "1px white solid",
        "backgroundColor": "white",
        "filter": "drop-shadow(1px 1px 1px #ddd)",
        "borderRadius": "6px",
    };
    const bottomMenuStyle = {
        "bottom": "20px",
        "right": "16px",
        "border": "1px white solid",
        "backgroundColor": "white",
        "filter": "drop-shadow(1px 1px 1px #ddd)",
        "borderRadius": "6px",
    };

    //Handle clicks outside menus
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: Event) => {
        if (dialog.current) { if ((e.target as HTMLElement).id === "rename") closeMenu() }
        else if (menuRef.current && !menuRef.current.contains(e.target as Node)) closeMenu();
    };

    if (!showOptions) return (<></>);

    return (
        <>
            <div ref={menuRef} className={"absolute dialog z-50"} style={position[0] === 0 ? menuStyle : bottomMenuStyle}>
                {menuState === "" &&
                    <div className="flex flex-col">
                        {!props.isResolved && props.isMine &&
                            <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={editClicked}>
                                <div className="px-2"><img src={Pencil} /></div>Edit
                            </button>
                        }
                        {props.isResolved &&
                            <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={resolveClicked}>
                                <div className="px-2"><CheckCircle2 absoluteStrokeWidth={true} strokeWidth={2} /></div>Unresolve
                            </button>
                        }
                        {props.isMine &&
                            <button className="flex clear-button text-red-500 items-center p-2 hover:bg-zinc-100" onClick={delClicked}>
                                <div className="px-2"><Trash2 absoluteStrokeWidth={true} strokeWidth={2} /></div>Delete
                            </button>
                        }
                    </div>
                }
                {menuState === "delconfirm" &&
                    <div className="flex flex-col">
                        <span>Confirm comment deletion?</span>
                        <div className="flex gap-x-2">
                            <button className="secondary-secondary-button text-zinc-950 ml-auto" onClick={clearMenuState}>Cancel</button>
                            <button className="bg-red-600" onClick={confirmDelete}>Delete</button>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default CommentMenu;
