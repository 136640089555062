import '../../table-extract.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "@glideapps/glide-data-grid/dist/index.css";
import React, { useEffect, useState, useRef } from "react";
import { Rectangle } from "@glideapps/glide-data-grid";
import {
    Languages,
    ChevronDownCircle,
    Group,
    List, MessageCircle, MessagesSquare,
    Text
} from 'lucide-react';
import { AIDepRequest, Column, TableResponse } from "types/requests";
import {ASSIGN_AI, ASSIGN_DEFAULT, ASSIGN_MANUAL, DepartmentListResponse} from "types/departments";
import { APIResponse, request } from "services/api";
import ColourTextOptions from "./colour-menus/ColourTextOptions.tsx";
import TranslateMenuContent, { createTranslateCol } from "./TranslateMenuContent";
import { GEN_TYPE_DEPARTMENTS } from "types/collab";
import {OptionItemData} from "components/TableExtract/Table/menus/colour-menus/common.ts";
import ColourDepartments from "components/TableExtract/Table/menus/colour-menus/ColourDepartments.tsx";

interface AddColMenuProps {
    onAddCol: (index: number, name: string, type: string, baseData: string) => void;
    onAddGenCol: (index: number, name: string, type: string, request: string) => void;
    getCol: (index: number) => Column;
    isOpen: boolean;
    setOpen: (o: boolean) => void;
    menu: {
        col: number;
        bounds: Rectangle;
    } | undefined;
    table: TableResponse;
}
function AddColMenu(props: AddColMenuProps) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuMode, setMenuMode] = useState<string>("");
    const [menuCurrentName, setMenuCurrentName] = useState("New Column");

    //AI assignment
    const [allDeps, setAllDeps] = useState<OptionItemData[]>([]);
    const [defaultItems, setDefaultItems] = useState<OptionItemData[]>([]);
    const [aiItems, setAIItems] = useState<OptionItemData[]>([]);
    const [manualItems, setManualItems] = useState<OptionItemData[]>([]);

    //Multi select
    const [multiSelectItems, setMultiSelectItems] = useState<OptionItemData[]>([]);

    //Single select
    const [singleSelectItems, setSingleSelectItems] = useState<OptionItemData[]>([]);

    //Translate
    const [translateCol, setTranslateCol] = useState<string>(props.table.columns[0].name);
    const [language, setLanguage] = useState<string>("German");


    //Menu systems
    function changeMode(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, menu: string) {
        e.preventDefault();
        e.stopPropagation();
        setMenuMode(menu);
    }
    function backOutEditColMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        setMenuMode("");
    }

    const [menu, setMenu] = useState<{
        col: number;
        bounds: Rectangle;
    }>();

    useEffect(() => { //Pass through prop updates to open menu
        setMenu(props.menu);
        setMenuOpen(props.isOpen);
        if (!props.isOpen) closeMenus();
    }, [props.menu, props.isOpen]);

    useEffect(() => {
        if (menuMode === "aidepassign") {
            request<DepartmentListResponse>("GET", "/config/departments").subscribe({
                next: (r: APIResponse<DepartmentListResponse>) => {
                    if (!r.ok || r.statusCode !== 200 || !r.data) return; //TODO logs
                    const defDeps = r.data.departments.filter(d => d.default_assignment_type === ASSIGN_DEFAULT).map(d => ({ id: d.department_id, color: d.colour, label: d.department }));
                    const aiDeps = r.data.departments.filter(d => d.default_assignment_type === ASSIGN_AI).map(d => ({ id: d.department_id, color: d.colour, label: d.department }));
                    const manDeps = r.data.departments.filter(d => d.default_assignment_type === ASSIGN_MANUAL).map(d => ({ id: d.department_id, color: d.colour, label: d.department }));

                    setDefaultItems(defDeps);
                    setAIItems(aiDeps);
                    setManualItems(manDeps)
                    setAllDeps([...defDeps, ...aiDeps, ...manDeps]);
                },
                error: (e) => {
                    console.error(e);
                    //TODO proper error
                },
            });
        }
    }, [menuMode]);


    const [menuTop, setMenuTop] = useState<number>(0);
    const [menuRight, setMenuRight] = useState<number>(0);
    const [menuHeight, setMenuHeight] = useState<string>("400px");
    useEffect(() => {
        setMenuRight(window.innerWidth - (menu?.bounds.x ?? 0));
        setMenuTop((menu?.bounds.y ?? 0) + (menu?.bounds.height ?? 0) + 1);
    }, [menu]);


    function closeMenus() {
        setMenuOpen(false);
        props.setOpen(false);
        setMenu(undefined);
        setMenuMode("");
        setMenuCurrentName("");
        setMultiSelectItems([]);
        setSingleSelectItems([]);
    }

    function handleSave() {
        let colName = menuCurrentName;
        while (props.table.columns.map(c => c.name.toLowerCase()).includes(colName.toLowerCase())) colName += "_";
        const index = menu?.col ?? -1;
        setMenuCurrentName(colName);
        if (menuMode === "aidepassign") {
            const req_col = props.table.columns.map(c => c.type).indexOf("string");
            let target_col = props.table.columns.map(c => c.type).indexOf("classification");
            if(target_col === -1) target_col = req_col;
            if(req_col < 0) return;
            const allowed = defaultItems.map(e => e.label + "^" + e.color);
            allowed.push(...aiItems.map(e => e.label + "^" + e.color));
            const defaultDeps = defaultItems.map(e => e.id);
            const assign_ai_deps = aiItems.map(e => e.id);
            const manual_deps = manualItems.map(e => e.id);

            const payload: AIDepRequest = {
                col_id: props.table.columns[req_col].col_id,
                default_departments: defaultDeps,
                ai_departments: assign_ai_deps,
                manual_departments: manual_deps,
            };

            props.onAddGenCol(target_col+1, colName, GEN_TYPE_DEPARTMENTS, JSON.stringify(payload))

            return;
        }
        if (menuMode === "aitranslate") {
            createTranslateCol(props.table, colName, translateCol, language, props.onAddGenCol);
            return;
        }
        if (menuMode === "multisel") {
            const allowed = multiSelectItems.map(e => e.label + "^" + e.color);
            props.onAddCol(index, colName, "chips", `|${allowed.join(",")}`)
            return;
        }
        if (menuMode === "singlesel") {
            const allowed = [...singleSelectItems.map(e => e.label + "^" + e.color)];
            props.onAddCol(index, colName, "dropdown", `|${allowed.join(",")}`) //Default to our custom blank option
            return;
        }
        if (menuMode === "comment") {
            props.onAddCol(index, colName, "comment", "")
            return;
        }
        props.onAddCol(index, colName, "text", "");
    }

    function saveDisabled() {
        if (menuMode === "aidepassign") {
            const allItems = [...aiItems, ...defaultItems, ...manualItems];
            return allItems.length === 0 || allItems.map(i=>i.id).includes("000");
        }
        return false;
    }

    const actionMenuRef = useRef<HTMLDivElement>(null);
    const colWrapperRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setMenuHeight(`calc(100dvh - ${colWrapperRef.current?.getBoundingClientRect().y ?? 0}px)`)
    }, [menuOpen, colWrapperRef]);

    const baseStyle = {
        "right": 0,
        "border": "1px white solid",
        "backgroundColor": "white",
        "filter": "drop-shadow(1px 1px 1px #ddd)",
        "borderRadius": "6px",
        "maxHeight": `calc(calc(100dvh - ${colWrapperRef.current?.getBoundingClientRect().y ?? 0}px) - ${actionMenuRef.current?.getBoundingClientRect().height ?? 0}px)`,
    }
    //Handle clicks outside menus
    const colMenuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: Event) => {
        if (colMenuRef.current && !colMenuRef.current.contains(e.target as Node)) closeMenus();
    };

    if (!menuOpen) return <></>
    else return (
        <div className="absolute"
             ref={colWrapperRef}
             style={{top: menuTop, right: menuRight, maxHeight: menuHeight}}
        >
            <div ref={colMenuRef} className="p-6 rounded-xl overflow-y-auto" style={baseStyle}>
                <div className="flex flex-col">
                    <span className="font-bold text-zinc-900">New Column</span>
                    {menuMode === "" && <>
                        <span className="pt-4 text-zinc-400">Type</span>
                        <button
                            onClick={(e) => changeMode(e, "text")}
                            className="flex clear-button pr-4 w-full pt-2 hover:bg-neutral-100 text-zinc-900"
                        >
                            <div className="pr-2"><Text strokeWidth={1} absoluteStrokeWidth={true} /></div>Text
                        </button>
                        <button
                            onClick={(e) => changeMode(e, "singlesel")}
                            className="flex clear-button pr-4 w-full pt-2 hover:bg-neutral-100 text-zinc-900"
                        >
                            <div className="pr-2"><ChevronDownCircle strokeWidth={1} absoluteStrokeWidth={true} /></div>Single Select
                        </button>
                        <button
                            onClick={(e) => changeMode(e, "multisel")}
                            className="flex clear-button pr-4 w-full pt-2 hover:bg-neutral-100 text-zinc-900"
                        >
                            <div className="pr-2"><List strokeWidth={1} absoluteStrokeWidth={true} /></div>Multi Select
                        </button>
                        <button
                            onClick={(e) => changeMode(e, "comment")}
                            className="flex clear-button pr-4 w-full pt-2 hover:bg-neutral-100 text-zinc-900"
                        >
                            <div className="pr-2"><MessagesSquare strokeWidth={1} absoluteStrokeWidth={true} /></div>Comments
                        </button>
                        {!props.table.columns.map(c => c.type).includes("departments") &&
                            <button
                                onClick={(e)=>changeMode(e, "aidepassign")}
                                className="flex clear-button pr-4 w-full pt-2 hover:bg-neutral-100 text-zinc-900"
                            >
                                <div className="pr-2"><Group className="text-accent" strokeWidth={1} absoluteStrokeWidth={true} /></div>AI Department Assignment
                            </button>
                        }
                        <button
                            onClick={(e) => changeMode(e, "aitranslate")}
                            className="flex clear-button pr-4 w-full pt-2 hover:bg-neutral-100 text-zinc-900"
                        >
                            <div className="pr-2"><Languages className="text-accent" strokeWidth={1} absoluteStrokeWidth={true} /></div>AI Translation
                        </button>
                    </>}
                    {menuMode === "text" && <>
                        <div className="flex gap-x-4 items-center py-4">
                            <Text strokeWidth={1} absoluteStrokeWidth={true} />
                            <input className="bg-white input border rounded-lg h-9 p-2 font-semibold"
                                autoFocus
                                value={menuCurrentName}
                                onChange={(e) => setMenuCurrentName(e.target.value)} />
                        </div>
                    </>}
                    {menuMode === "comment" && <>
                        <div className="flex gap-x-4 items-center py-4">
                            <MessageCircle strokeWidth={1} absoluteStrokeWidth={true} />
                            <input className="bg-white input border rounded-lg h-9 p-2 font-semibold"
                                autoFocus
                                value={menuCurrentName}
                                onChange={(e) => setMenuCurrentName(e.target.value)} />
                        </div>
                    </>}
                    {menuMode === "aidepassign" && <>
                        <div className="flex gap-x-4 items-center py-4">
                            <Group className="text-accent" strokeWidth={1} absoluteStrokeWidth={true} />
                            <input className="bg-white input border rounded-lg h-9 p-2 w-96 font-semibold"
                                autoFocus
                                value={menuCurrentName}
                                onChange={(e) => setMenuCurrentName(e.target.value)} />
                        </div>
                        <div className="max-h-[40rem] overflow-y-auto">
                            <div className="border-b pb-2 -mb-1 text-zinc-400">
                                Assign Per Default
                                <button
                                    className="clear-button input-invisible hover:bg-zinc-100 px-2 text-black float-right"
                                    onClick={() => setDefaultItems([])}
                                    disabled={defaultItems.length === 0}
                                >
                                    Clear All
                                </button>
                            </div>
                            <ColourDepartments options={allDeps} items={defaultItems} disabledOptions={[...defaultItems, ...aiItems, ...manualItems]} setItems={setDefaultItems} openItemLabel={"aidefault"} />

                            <div className="border-b pb-2 -mb-1 text-zinc-400">
                                Assign With AI
                                <button
                                    className="clear-button input-invisible hover:bg-zinc-100 px-2 text-black float-right"
                                    onClick={() => setAIItems([])}
                                    disabled={aiItems.length === 0}
                                >
                                    Clear All
                                </button>
                            </div>
                            <ColourDepartments options={allDeps} items={aiItems} disabledOptions={[...defaultItems, ...aiItems, ...manualItems]} setItems={setAIItems} openItemLabel={"aiassignai"} />

                            <div className="border-b pb-2 -mb-1 text-zinc-400">
                                Assign Manually
                                <button
                                    className="clear-button input-invisible hover:bg-zinc-100 px-2 text-black float-right"
                                    onClick={() => setManualItems([])}
                                    disabled={manualItems.length === 0}
                                >
                                    Clear All
                                </button>
                            </div>
                            <ColourDepartments options={allDeps} items={manualItems} disabledOptions={[...defaultItems, ...aiItems, ...manualItems]} setItems={setManualItems} openItemLabel={"aiassignmanual"} />
                        </div>
                    </>}
                    {menuMode === "aitranslate" &&
                        <TranslateMenuContent
                            table={props.table}
                            onNameChanged={setMenuCurrentName}
                            onLanguageChanged={setLanguage}
                            onTargetColChanged={setTranslateCol}
                        />
                    }
                    {menuMode === "multisel" && <>
                        <div className="flex gap-x-4 items-center py-4">
                            <List strokeWidth={1} absoluteStrokeWidth={true} />
                            <input className="bg-white input border rounded-lg h-9 p-2 font-semibold"
                                autoFocus
                                value={menuCurrentName}
                                onChange={(e) => setMenuCurrentName(e.target.value)} />
                        </div>

                        <span className="pt-4 border-b pb-2 text-zinc-400">Options</span>
                        <ColourTextOptions items={multiSelectItems} setItems={setMultiSelectItems} openItemLabel="multisel" />
                    </>}
                    {menuMode === "singlesel" && <>
                        <div className="flex gap-x-4 items-center py-4">
                            <ChevronDownCircle strokeWidth={1} absoluteStrokeWidth={true} />
                            <input className="bg-white input border rounded-lg h-9 p-2 font-semibold"
                                autoFocus
                                value={menuCurrentName}
                                onChange={(e) => setMenuCurrentName(e.target.value)} />
                        </div>

                        <span className="pt-4 border-b pb-2 text-zinc-400">Options</span>
                        <ColourTextOptions items={singleSelectItems} setItems={setSingleSelectItems} openItemLabel="singlesel" />
                    </>}
                </div>
            </div>

            {menuMode !== "" &&
                <div ref={actionMenuRef} className="sticky w-full bg-white py-2 border-t">
                    <div className="flex gap-x-4 items-center place-content-end pr-4">
                        <button onClick={backOutEditColMenu} className="bg-zinc-100 secondary-secondary-button text-zinc-950">Cancel</button>
                        <button onClick={handleSave} disabled={saveDisabled()}>Save</button>
                    </div>
                </div>
            }
        </div>
    );
}

export default AddColMenu;
