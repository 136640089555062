import { type CustomCell, type ProvideEditorCallback, type CustomRenderer, GridCellKind } from "@glideapps/glide-data-grid";
import { useState } from "react";
import { Option } from "components/TableExtract/Table/types/types";
import Checkbox from "components/FormElements/Checkbox.tsx";

interface PChipsCellProps {
    readonly kind: "pchips-cell";
    allowedValues: Option[];
    values: Option[];
    shouldSort?: boolean;
    cell_id: string; //Workaround for column moves underneath us, all custom cells must use this
}
export type PchipsCell = CustomCell<PChipsCellProps>;

const Editor: ReturnType<ProvideEditorCallback<PchipsCell>> = p => {
    const { value: cell, onChange } = p;
    const [searchText, setSearchText] = useState<string>("");
    const [values, setValues] = useState<Option[]>(cell.data.values);

    function isSelected(c: Option) {
        return values.map(v=>v.tag+v.color).includes(c.tag+c.color);
    }

    async function changeValueExternal(c: Option) {
        await changeValue(c, !isSelected(c))
    }

    async function changeValue(c: Option, state: boolean) {
        await new Promise(r => window.requestAnimationFrame(r));
        let newValues: Option[];
        if (state && !isSelected(c)) {
            newValues = [...values, c];
        } else if (!state && isSelected(c)) {
            newValues = values.filter(v => v.tag !== c.tag || v.color !== c.color);
        } else {
            return;
        }
        setValues(newValues);
        onChange({
            ...cell,
            data: {
                ...cell.data,
                values: newValues
            }
        })
    }

    async function clearAllValues() {
        await new Promise(r => window.requestAnimationFrame(r));
        setValues([]);
        onChange({
            ...cell,
            data: {
                ...cell.data,
                values: []
            }
        })
    }

    async function checkAllValues() {
        await new Promise(r => window.requestAnimationFrame(r));
        setValues(cell.data.allowedValues);
        onChange({
            ...cell,
            data: {
                ...cell.data,
                values: cell.data.allowedValues
            }
        })
    }

    function blankOptionOnly() {
        return cell.data.allowedValues.length === 0 ||
            (cell.data.allowedValues.length === 1 &&
                cell.data.allowedValues[0].tag === "" &&
                !cell.data.allowedValues[0].color);
    }

    function getBaseOptions() {
        return cell.data.shouldSort ? cell.data.allowedValues.sort((a, b) => a.tag.localeCompare(b.tag)): cell.data.allowedValues;
    }

    const searchFilter = ((c: { tag: string; color: string; }) => searchText ? c.tag.toLowerCase().includes(searchText.toLowerCase()) : true);

    return (
        <div className="flex flex-col min-w-[12em] overflow-y-hidden">
            <div className="p-2" >
                <input className="h-8 select-none text-[1.1em] outline-0 bg-transparent" placeholder="Search options" value={searchText} onChange={(e) => setSearchText(e.currentTarget.value)} />
            </div>
            <div className="max-h-60 overflow-y-auto mb-11">
                {!blankOptionOnly() && getBaseOptions()
                    .filter(searchFilter)
                    .map((c) =>
                        <div key={c.tag + c.color} className="px-2 py-1 flex hover:bg-zinc-100 cursor-pointer items-center" onClick={()=>changeValueExternal(c)}>
                            <Checkbox className="cursor-pointer" onChange={(checked) => changeValue(c, checked)} checked={isSelected(c)} />
                            <div className="px-2 rounded-xl select-none" style={{ backgroundColor: c.color }}>
                                {c.tag}
                            </div>
                        </div>
                    )}
                {cell.data.allowedValues.filter(searchFilter).length === 0 &&
                    <div className="px-2 py-2" >
                        <div className="py-[0.1em] px-2 rounded-xl select-none bg-transparent">
                            No results
                        </div>
                    </div>
                }

            </div>
            <div className="border-t w-full py-1 bottom-0 absolute bg-white">
                <div className="flex place-content-center gap-x-2 w-full">
                    <button className="secondary-button text-zinc-950" onClick={checkAllValues}>Select All</button>
                    <button className="secondary-button text-zinc-950" onClick={clearAllValues}>Clear All</button>
                </div>
            </div>
        </div>
    );
};

const renderer: CustomRenderer<PchipsCell> = {
    kind: GridCellKind.Custom,
    isMatch: (cell: CustomCell): cell is PchipsCell => (cell.data as PChipsCellProps).kind === "pchips-cell",
    needsHover: false,
    draw: (args, cell) => {
        const { values } = cell.data;
        if (values.length === 0 || (values.length === 1 && values[0].tag === "")) return true;

        const { ctx, theme, rect } = args;
        const drawX = rect.x + theme.cellHorizontalPadding;
        const textVerticalCenter = 2;
        const textVerticalPad = 4;
        const textSize = 8;
        const roundOffset = 6;

        const pillGap = 7;
        const pillHeight = textSize + textVerticalCenter * 2 + textVerticalPad * 2;

        let xOffset = 0;
        let yRow = 0;

        // Calculate total rows needed for pills
        let totalRows = 1;
        let rowWidth = 0;
        for (const value of values) {
            const pillWidth = ctx.measureText(value.tag).width + roundOffset * 2 + 1; //TODO store this, expensive
            if (rowWidth + pillWidth + pillGap > rect.width) {
                totalRows++;
                rowWidth = 0;
            }
            rowWidth += pillWidth + pillGap;
        }
        if (totalRows > values.length) totalRows = values.length

        // Center the pills vertically
        const totalHeight = totalRows * pillHeight + (totalRows - 1) * pillGap;
        const startY = rect.y + (rect.height - totalHeight) / 2;

        for (const value of values) {
            // Pill
            ctx.fillStyle = value.color;
            ctx.beginPath();
            const pillWidth = ctx.measureText(value.tag).width + roundOffset * 2 + 1;
            let pillX = drawX + xOffset - roundOffset;

            if (xOffset !== 0 && xOffset + pillGap + pillWidth > rect.width) {
                yRow++;
                pillX = drawX - roundOffset;
                xOffset = 0;
            }

            ctx.roundRect(pillX, startY + yRow * (pillHeight + pillGap), pillWidth, pillHeight, 16);
            ctx.fill();

            // Text
            ctx.fillStyle = "#222";
            ctx.fillText(value.tag, drawX + xOffset, startY + textVerticalCenter + textSize + yRow * (pillHeight + pillGap));
            xOffset += pillWidth + pillGap;
        }

        return true;
    },
    provideEditor: () => ({
        editor: Editor,
        disablePadding: true,
        deletedValue: v => ({
            ...v,
            copyData: "",
            data: {
                ...v.data,
                values: [],
            },
        }),
    }),
    onPaste: (val, d) => {
        const possibleColours = new Map(d.allowedValues.map(o => [o.tag, o.color]));
        const v = val.split("|")[0].split(",").filter(v=>possibleColours.has(v));
        if (v.length === 0 && !val.includes("|")) return d;
        const newValues = v.map(v=>({tag:v, color: possibleColours.get(v)??"#ffffff"}));
        return {
            ...d,
            values: newValues
        }
    },
};

export default renderer;
