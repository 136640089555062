import { useEffect, useRef, useState } from "react";
import 'App.css'
import { customerAbbreviation } from "./comment-cell.tsx";

interface MenuProps {
    open: boolean;
    setOpen: (o: boolean) => void;
    handleChange: () => void;
    abbreviation: string;
    iconColor: string;
    position?: number[];
}
// TODO: values besides pure strings
function PosterMenu(props: MenuProps) {
    const dialog = useRef<HTMLDialogElement>(null);
    const [showMenu, setShowMenu] = useState(false);
    const [position, setPosition] = useState([0, 0]);

    useEffect(() => {
        setShowMenu(props.open);
        if (props.position)
            setPosition(props.position);
    }, [props.open, props.position])

    function closeMenu() {
        props.setOpen(false);
        setShowMenu(false);
        dialog.current?.close()
    }

    function changeUser() {
        props.handleChange();
    }

    const menuStyle = {
        "top": position[1],
        "left": (position[0] + 6) + "px",
        "border": "1px white solid",
        "backgroundColor": "white",
        "filter": "drop-shadow(1px 1px 1px #ddd)",
        "borderRadius": "6px",
    };

    //Handle clicks outside menus
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: Event) => {
        if (dialog.current) { if ((e.target as HTMLElement).id === "rename") closeMenu() }
        else if (menuRef.current && !menuRef.current.contains(e.target as Node)) closeMenu();
    };

    if (!showMenu) return (<></>);
    return (
        <>
            <div ref={menuRef} className={"absolute dialog z-50 p-0 py-1"} style={menuStyle}>
                <div className="flex flex-col hover:bg-neutral-100 w-full px-2">
                    <button className="flex clear-button input-invisible text-red-500 items-center" onClick={changeUser}>
                        <div className="flex p-2 rounded-full w-8 h-8 items-center place-content-center text-white select-none" style={{ backgroundColor: props.iconColor !== "" ? props.iconColor : "#552AD2" }}>
                            {props.abbreviation === "" ? customerAbbreviation : props.abbreviation}
                        </div>
                    </button>
                </div>
            </div>
        </>
    )
}

export default PosterMenu;
