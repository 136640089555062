import { BoundingBox, TableResponse, Taggable, Value } from "./requests.ts";
import { BEComment } from "./comments.ts";

export type Message = {
    id: string;
    type: string;
    data: MessageData;
};

export type MessageData =
    AuthMessage |
    CombinedAuthMessage |
    InitialiseMessage |
    OpenSpecMessage |
    CurrentUsersMessage |
    UserCellSelect |
    CellDataUpdate |
    CellDataUpdateResponse |
    ColumnCreate |
    ColumnDelete |
    ColumnMove |
    ColumnDataUpdate |
    GenColumnCreate |
    DepartmentConfigUpdate |
    OptionsRewrite |
    RowCreate |
    RowDelete |
    UIActionSignal |
    MaintenanceMessage |
    CommentEvent |
    CommentCreate |
    CommentEdit |
    CommentResolve |
    CommentDelete;

export const AUTH_MESSAGE = "AuthMessage";
export type AuthMessage = {
    token: string;
}

export const COMBINED_AUTH_MESSAGE = "CombinedAuthMessage";
export type CombinedAuthMessage = {
    token: string;
    spec_id: string;
}

export const INITIALISE_MESSAGE = "InitialiseMessage";
export type InitialiseMessage = {
    readonly: boolean;
    comments: Record<string, BEComment[]>;
    department_config: string[];
    department_name_map: Record<string, string>;
    table: TableResponse;
}

export const OPEN_SPEC_MESSAGE = "OpenSpecMessage";
export type OpenSpecMessage = {
    spec_id: string;
}

export const CELL_DATA_UPDATE = "CellDataUpdate";
export type CellDataUpdate = {
    cell_id: string;
    cell_data: Value;
}

export const CELL_DATA_UPDATE_RESPONSE = "CellDataUpdateResponse";
export type CellDataUpdateResponse = {
    cells: CellDataUpdate[];
}

export const CURRENT_USERS_MESSAGE = "CurrentUsersMessage";
export type CurrentUsersMessage = {
    users: string[];
}

export const USER_CELL_SELECT_RELAY = "UserCellSelectRelay";
export const USER_CELL_SELECT = "UserCellSelect";
export type UserCellSelect = {
    cell_id: string;
    user_id?: string;
}

export const COLUMN_CREATE = "ColumnCreate";
export type ColumnCreate = {
    col_id: string;
    ref_col: string;
    before: boolean;
    type: string;
    name: string;
    options: string;
    cell_ids?: Record<string, string>;
    base_cell?: Value;
}

export const COLUMN_DELETE = "ColumnDelete";
export type ColumnDelete = {
    col_id: string;
}

export const COLUMN_MOVE = "ColumnMove";
export type ColumnMove = {
    col_id: string;
    ref_col: string;
    before: boolean;
}

export const COLUMN_DATA_UPDATE = "ColumnDataUpdate";
export type ColumnDataUpdate = {
    col_id: string;
    col_data: { name: string, width: number };
}

export const GEN_COLUMN_CREATE = "GenColumnCreate";
export type GenColumnCreate = {
    col_id: string;
    ref_col: string;
    before: boolean;
    type: string;
    name: string;
    request: string;
}

export const GEN_COLUMN_CREATE_RESPONSE = "GenColumnCreateResponse";
export type GenColumnCreateResponse = {
    col_id: string;
    type: string;
    base_data: string;
    response: Record<string, CollabCell>;
}

export const DEPARTMENT_CONFIG_UPDATE = "DepartmentConfigUpdate";
export type DepartmentConfigUpdate = {
    departments: string[];
    department_name_map: Record<string, string>;
}

export const OPTIONS_REWRITE = "OptionsRewrite";
export type OptionsRewrite = {
    col_id: string;
    options: OptionsRewriteOperation[];
}

export type OptionsRewriteOperationType = "CREATE" | "EDIT" | "DELETE"
export type OptionsRewriteOperation = {
    type: OptionsRewriteOperationType

    // used for edit and delete
    previousName: string
    newName?: string

    //used for only edit
    previousColour: string
    newColour?: string
}

export type CollabCell = {
    cell_id: string;
    row_id: string;
    col_id: string;
    rect: BoundingBox;
    isHeading: boolean;
    type: string;
    value: string;
    version: number;
}

export const ROW_CREATE = "RowCreate";
export type RowCreate = {
    row_id: string
    ref_row: string;
    before: boolean;
    cell_data?: Record<string, CollabCell>;
}

export const ROW_DELETE = "RowDelete";
export type RowDelete = {
    row_id: string
}

export const COMMENT_EVENT = "CommentEvent";
export type CommentEvent = {
    type: string;
    comment: BEComment;
}
export const COMMENT_CREATE = "CommentCreate";
export type CommentCreate = {
    comment_id: string;
    cell_id: string;
    is_customer: boolean;
    comment: string;
    tags: Taggable[];
}
export const COMMENT_EDIT = "CommentEdit";
export type CommentEdit = {
    comment_id: string;
    tags: Taggable[];
    comment: string;
    is_customer: boolean;
}
export const COMMENT_RESOLVE = "CommentResolve";
export type CommentResolve = {
    comment_id: string;
    is_resolved: boolean;
}
export const COMMENT_DELETE = "CommentDelete";
export type CommentDelete = {
    comment_id: string;
}

export const DOCUMENT_STRUCTURE_SIGNAL = "DocumentStructureSignal";
export type DocumentStructureSignal = {
    type: string
    data: string
}

export const ACTION_INACTIVITY = "INACTIVITY";
export const ACTION_DELETED = "DELETED";
export const ACTION_MAINTENANCE = "MAINTENANCE";
export const ACTION_ROLLBACK = "ROLLBACK";
export const ACTION_VERSIONING = "VERSIONING";
export const ACTION_RELOAD = "RELOAD";
export const ACTION_REJECTED = "REJECTED";
export const UI_ACTION_SIGNAL = "UIActionSignal";
export type UIActionSignal = {
    action: string
}

export const MAINTENANCE = "Maintenance";
export type MaintenanceMessage = {
    start: number;
}


export type MessageResponse = {
    message: string;
}

export const GEN_TYPE_DEPARTMENTS = "departments"
export const GEN_TYPE_TRANSLATION = "translation"

export type BECell = {
    isHeading: boolean;
    type: string;
    value: string;
}
