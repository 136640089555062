import '../../../table-extract.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "@glideapps/glide-data-grid/dist/index.css";
import { Plus } from 'lucide-react';
import React, { useState } from "react";
import { ColourMenu, ColourSelectItem } from "components/TableExtract/Table/menus/colour-menus/ColourItem.tsx";
import { colours, OptionItemData } from "components/TableExtract/Table/menus/colour-menus/common.ts";

function addItem(prevItems: OptionItemData[]) {
    const newItems = [...prevItems];
    newItems.push({
        id: "000",
        color: colours[newItems.length % colours.length],
        label: "Select department"
    });
    return newItems;
}

function removeItem(id: string, prevItems: OptionItemData[]) {
    return prevItems.filter(x => x.id != id);
}

function setColour(id: string, prevItems: OptionItemData[], index: number, colour: string) {
    const newItems = [...prevItems];
    newItems.splice(index, 1, {
        id: id,
        color: colour,
        label: newItems[index].label
    });
    return newItems;
}

function setLabel(id: string, prevItems: OptionItemData[], index: number, label: string) {
    const newItems = [...prevItems];
    newItems.splice(index, 1, {
        id: id,
        color: newItems[index].color,
        label: label
    });
    return newItems;
}

interface OptionItemsProps {
    items: OptionItemData[],
    colours?: string[],
    setItems: React.Dispatch<React.SetStateAction<OptionItemData[]>>,
    openItemLabel: string,
    options: OptionItemData[];
    disabledOptions: OptionItemData[];
}

function ColourDepartments(props: OptionItemsProps) {
    const [openColour, setOpenColour] = useState<ColourMenu>({ index: -1, openItemLabel: "" });

    return (
        <div className="my-4 flex flex-col gap-y-2">
            {props.items.map((o, i) =>
                <ColourSelectItem
                    option={o}
                    index={i}
                    setLabelHandler={(e) => {
                        props.setItems((prev) => setLabel(e, prev, i, props.options.find(i => i.id === e)?.label ?? ""))
                    }}
                    openItemLabel={props.openItemLabel}
                    open={openColour}
                    clickHandler={setOpenColour}
                    setColour={(i, colour) => props.setItems((prev) => setColour(o.id, prev, i, colour))}
                    removeItem={() => { props.setItems((prev) => removeItem(o.id!, prev)) }}
                    options={[{ id: "000", color: "#000000", label: "Select department" }, ...props.options]}
                    disabledOptions={["000", ...props.disabledOptions.map(dO => dO.id)]}
                />)}
            <button className="clear-button ghost-button disabled:hover:bg-white text-zinc-950 flex gap-x-2 font-semibold p-2 w-fit" onClick={() => props.setItems(addItem)} disabled={props.disabledOptions.length === props.options.length}>
                <Plus />
                <span>Add department</span>
            </button>
        </div>
    );
}

export default ColourDepartments;
