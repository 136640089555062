import React, { useEffect, useRef, useState } from "react";
import 'App.css'
import { APIResponse, request } from "services/api.ts";

interface CrudMenuProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    deletePath: string;
    handleDelete: () => void;
    itemType: string;
}

function DeleteWarning(props: CrudMenuProps) {
    const dialog = useRef<HTMLDialogElement>(null);
    const [deleteDisabled, setDeleteDisabled] = useState(false);
    const [deleteError, setDeleteError] = useState("");

    useEffect(() => {
        if(props.open) {
            dialog.current?.showModal();
        } else {
            dialog.current?.close();
        }
    }, [props.open]);

    function performDelete() {
        setDeleteDisabled(true);
        request<string>("DELETE", props.deletePath).subscribe({
            next: (r: APIResponse<string>) => {
                if (!r.ok || r.statusCode !== 200) {
                    setDeleteError(r.data ?? `Failed to delete ${props.itemType}`);
                    setDeleteDisabled(false);
                    return;
                }
                props.handleDelete();
                props.setOpen(false);
                cleanup();
            },
            error: (e) => {
                console.error(e);
                setDeleteError(`Failed to delete ${props.itemType}`);
                setDeleteDisabled(false);
            },
        });
    }

    function returnToMain(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        cleanup();
    }

    function cleanup() {
        dialog.current?.close()
        setDeleteDisabled(false);
        setDeleteError("");
        props.setOpen(false);
        setDeleteError("");
    }

    //Handle clicks outside menus
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: Event) => {
        if (dialog.current?.open) {
            if ((e.target as HTMLElement).id === "delete-warning") {
                e.preventDefault();
                e.stopPropagation();
                cleanup();
            }
        }
        else if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
            e.preventDefault();
            e.stopPropagation();
            cleanup();
        }
    };

    return (
        <dialog id="delete-warning" ref={dialog} className="bg-transparent">
            <div className="bg-white text-zinc-950 p-4 rounded-lg">
                <h3 className="select-none text-zinc-950 font-bold pb-4">Delete {props.itemType}</h3>
                <span>Are you sure you want to delete this {props.itemType}?</span>
                <div className="flex gap-x-4 justify-end pt-4 items-center">
                    <span className="pr-2 text-red-500 font-semibold">{deleteError}</span>
                    <button onClick={returnToMain} className="bg-zinc-100 text-zinc-950 hover:bg-zinc-200">Cancel</button>
                    <button className="bg-red-600" onClick={performDelete} disabled={deleteDisabled}>Delete</button>
                </div>
            </div>
        </dialog>
    )
}

export default DeleteWarning;
