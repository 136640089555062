import { useState, useCallback, useEffect } from 'react';
import LoaderStage from './Stages/LoaderStage';
import ResultStage from './Stages/ResultStage';
import { Specification, TableResponse } from "types/requests";
import './table-extract.css';
import { ArrowLeft } from "lucide-react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { APIResponse, request } from "services/api";
import ErrorStage from "./Stages/ErrorStage.tsx";
import { usePopup } from 'hooks/Popup.tsx';


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ViewTable() {
    const navigate = useNavigate();
    const { id } = useParams();
    const query = useQuery();
    const rowId = query.get('row_id') || undefined;
    const cellId = query.get('cell_id') || undefined;


    const handleListSpec = useCallback(() => {
        navigate("/specifications/list")
    }, [navigate]);

    const [stage, setStage] = useState(0);
    const [diffTableResp, setDiffTableResp] = useState<TableResponse | undefined>(undefined);
    const [delTableResp, setDelTableResp] = useState<TableResponse | undefined>(undefined);
    const [pdf, setPDF] = useState("");
    const [fileFormat, setFileFormat] = useState("");
    const [docName, setDocName] = useState("");
    const [readonly, setReadonly] = useState(false);
    const [parent, setParent] = useState("");
    const [created, setCreated] = useState(Date.now() / 1000);
    const [creator, setCreator] = useState<string>();
    const goBackPopup = usePopup("Go Back");

    useEffect(() => {
        request<Specification>("GET", `/specifications/${id}`).subscribe({
            next: (r: APIResponse<Specification>) => {
                if (!r.ok || r.statusCode !== 200 || !r.data) {
                    setStage(-1);
                    return; //TODO logs
                }
                if (r.data.diffs_table_data) setDiffTableResp(r.data.diffs_table_data);
                if (r.data.deletions_table_data) setDelTableResp(r.data.deletions_table_data);
                setDocName(r.data.name);
                setPDF(r.data.pdf_s3_path);
                setFileFormat(r.data.file_format ?? "application/pdf")
                setReadonly(r.data.readonly || r.data.status === "WORKING");
                setParent(r.data.parent_node);
                setCreated(r.data.created as unknown as number);
                setCreator(r.data.owner);
                setStage(1);
            },
            error: (e) => {
                console.error(e);
                setStage(-1);
                //TODO proper error
            },
        });
    }, [])

    return (
        <>
            {stage < 0 && <div className="absolute right-8 top-8">
                <button className="flex gap-x-2" onClick={handleListSpec} {...goBackPopup.props}><ArrowLeft />Specifications</button>
                {goBackPopup.component}
            </div>}

            <div className="flex place-content-center h-full">
                {stage === -1 && <ErrorStage />}
                {stage === 0 && <LoaderStage headline="Loading table" subtitle="This may take a moment..." hideSparkle={true} />}
                {stage === 1 && <ResultStage file={pdf} name={docName} specId={id} readonly={readonly} deletion_table={delTableResp} difference_table={diffTableResp} parent={parent} cellId={cellId} rowId={rowId} fileFormat={fileFormat} created={created} creator={creator} />}
            </div>
        </>
    )
}

export default ViewTable;
