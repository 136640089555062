import { type CustomCell, type CustomRenderer, GridCellKind, BaseDrawArgs, BaseGridCell } from "@glideapps/glide-data-grid";

interface PopoutImageCellProps {
    readonly kind: "popout-image-cell";
    readonly imageUrl: string;
    readonly thumbnailImageUrl?: string;
}
export type PopoutImageCell = CustomCell<PopoutImageCellProps>;

const renderer: CustomRenderer<PopoutImageCell> = {
    kind: GridCellKind.Custom,
    isMatch: (cell: CustomCell): cell is PopoutImageCell => (cell.data as PopoutImageCellProps).kind === "popout-image-cell",//TODO check the anyswap worked
    needsHover: false,
    draw: a => drawImage(
        a,
        a.cell.data.thumbnailImageUrl ?? a.cell.data.imageUrl,
        a.cell.contentAlign
    ),
    onClick: (args) => args.isDoubleClick && sessionStorage.setItem("displayImageDialog", args.cell.data.imageUrl) as any
};

export default renderer;

export function drawImage(
    args: BaseDrawArgs,
    data: string,
    contentAlign?: BaseGridCell["contentAlign"]
) {
    const { rect, col, row, theme, ctx, imageLoader } = args;
    const { x, y, height: h, width: w } = rect;

    const imgHeight = h - theme.cellVerticalPadding * 2;
    let img: HTMLImageElement;
    //if (imageCache.has(data)) {
    //    img = imageCache.get(data)
    //} else {
    img = imageLoader.loadOrGetImage(data, col, row) as any;
    //    imageCache.set(data, img)
    //}

    if (!img) return;

    function draw() {
        let imageWidth = img.width * (imgHeight / img.height);
        let drawX = x + theme.cellHorizontalPadding;
        if (contentAlign === "right") drawX = Math.floor(x + w - theme.cellHorizontalPadding - imageWidth);
        else if (contentAlign === "center") drawX = Math.floor(x + w / 2 - imageWidth / 2);

        if (!img) return;
        const imgWidth = img.width * (imgHeight / img.height);
        ctx.drawImage(img, drawX, y + theme.cellVerticalPadding, imgWidth, imgHeight);

        drawX += imgWidth;
    }

    //if ("complete" in img && img.complete) {
    draw()
    // } else {
    //     let count = 0;
    //     const id = setInterval(() => {
    //         if ("complete" in img && img.complete) {
    //             draw();
    //             clearInterval(id);
    //         } else if (count > 60) {
    //             clearInterval(id);
    //             console.error(`Gave up on image load ${img.src}`)
    //         }
    //     }, 1000)
    // }
}

export let imageCache = new Map();
