import React, { useState } from "react";
import 'App.css'
import { CheckCircle, X } from "lucide-react";
import {usePopup} from "hooks/Popup.tsx";

interface MenuProps {
    id: string;
    layout: string;
    closeMenu: () => void;
}
// TODO: values besides pure strings
function LinkDialogContent(props: MenuProps) {
    const [showCopied, setShowCopied] = useState(false);
    const closePopup = usePopup("Close", "left");

    function linkBoxClicked(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.select();
    }

    function copyLinkToClipboard(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        navigator.clipboard.writeText(window.location.origin + props.layout.replace("%id%", props.id)).then(() => setShowCopied(true));
    }

    function closeMenu() {
        setShowCopied(false);
        props.closeMenu();
    }

    return (
        <>
            <span className="flex gap-x-2 select-none text-zinc-950 font-bold pb-4 text-lg items-center">
                <span className="text-green-600"><CheckCircle /></span>
                Link Created
                <button className="clear-button ghost-button text-black ml-auto" {...closePopup.props} onClick={closeMenu}><X /></button>
                {closePopup.component}
            </span>
            <div className="flex h-10 mb-2">
                <input
                    type="text"
                    className="w-full input bg-white border rounded-lg text-[1.2em] px-2 mr-2 h-10 min-w-[20em]"
                    value={window.location.origin + props.layout.replace("%id%", props.id)}
                    onClick={linkBoxClicked}
                />
                <button onClick={copyLinkToClipboard}>Copy</button>
            </div>
            <div className="flex flex-col select-none">
                <span>{showCopied ? "Copied!" : ""}</span>
                <span>Users must be logged in to use the link.</span>
            </div>
        </>
    )
}

export default LinkDialogContent;
