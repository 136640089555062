import { BoundingBox, TableResponse, UserListResponse, Value } from "types/requests";
import React, {
    MouseEvent,
    Reducer,
    useCallback,
    useEffect,
    useReducer,
    useRef,
    useState,
    useMemo,
} from "react";
import ResultTable from "components/TableExtract/Table/ResultTable";
import {
    AlertCircle,
    ArrowLeft,
    Bell,
    CalendarCheck,
    Loader2,
    MoreHorizontal,
    RotateCw,
    Wifi,
    WifiOff
} from "lucide-react";
import SpecificationContextMenu from "components/Specifications/menus/SpecificationContextMenu";
import NotifyDepartmentsSidebar, {
    AssignmentStatus,
    getStatusAttributes
} from 'components/NotifyDepartmentsSidebar/NotifyDepartmentsSidebar';
import { useNavigate } from "react-router-dom";
import { APIResponse, request } from "services/api";
import HistoryModal from "components/Specifications/menus/HistoryModal";
import { Viewer } from '@react-pdf-viewer/core';
import type { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { highlightPlugin, RenderHighlightsProps, Trigger } from '@react-pdf-viewer/highlight';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import useWebSocket, { ReadyState } from "react-use-websocket";
import {
    ACTION_DELETED,
    ACTION_INACTIVITY,
    ACTION_MAINTENANCE,
    ACTION_REJECTED,
    ACTION_RELOAD,
    ACTION_ROLLBACK,
    ACTION_VERSIONING,
    CELL_DATA_UPDATE,
    CELL_DATA_UPDATE_RESPONSE,
    CellDataUpdate,
    CellDataUpdateResponse,
    COLUMN_CREATE,
    COLUMN_DATA_UPDATE,
    COLUMN_DELETE,
    COLUMN_MOVE,
    ColumnCreate,
    ColumnDataUpdate,
    ColumnDelete,
    ColumnMove,
    COMBINED_AUTH_MESSAGE,
    COMMENT_EVENT,
    CommentEvent,
    CURRENT_USERS_MESSAGE,
    CurrentUsersMessage,
    DEPARTMENT_CONFIG_UPDATE,
    DepartmentConfigUpdate,
    DOCUMENT_STRUCTURE_SIGNAL,
    DocumentStructureSignal,
    GEN_COLUMN_CREATE_RESPONSE,
    GEN_TYPE_DEPARTMENTS,
    GEN_TYPE_TRANSLATION,
    GenColumnCreateResponse,
    InitialiseMessage,
    MAINTENANCE,
    MaintenanceMessage,
    Message,
    MessageData,
    OPTIONS_REWRITE,
    OptionsRewrite,
    OptionsRewriteOperation,
    ROW_CREATE,
    ROW_DELETE,
    RowCreate,
    RowDelete,
    UI_ACTION_SIGNAL,
    UIActionSignal,
    USER_CELL_SELECT_RELAY,
    UserCellSelect
} from "types/collab";
import ExcelRenderer, { EXCEL_FORMATS } from './ExcelRenderer';
// CSS import
import 'components/TableExtract/table-extract.css'
import 'App.css'
import { splitChar } from "components/TableExtract/Table/menus/AddFilterMenu";
import { useComments } from "components/TableExtract/Table/cells/comments/CommentContext";
import { colours } from "components/TableExtract/Table/menus/colour-menus/common.ts";
import { imageCache } from "components/TableExtract/Table/cells/popout-image/image-cell";
import { usePopup } from "hooks/Popup";

interface ResultStageProps {
    difference_table?: TableResponse;
    deletion_table?: TableResponse;
    file?: string;
    name?: string;
    specId?: string;
    readonly: boolean;
    parent: string;
    rowId?: string;
    cellId?: string;
    fileFormat?: string;
    created: number;
    creator?: string;
}

const blankCell = { cell_id: "", type: "text", value: "", isHeading: false }
const emptyTable = {
    columns: [],
    table: [],
    name: "",
}

type TableReducer = Reducer<TableResponse, Action>;

interface Action {
    type: string,
    data: MessageData | TableResponse,
    cellUpdateSignals: undefined | ((k: number[], s: string, table?: TableResponse) => void);
    tableLayoutChange: undefined | ((table: TableResponse) => void);
}

function tableReducer(state: TableResponse, action: Action): TableResponse {
    const newTable = { ...state };
    switch (action.type) {
        case "init":
            return action.data as TableResponse;
        case CELL_DATA_UPDATE:
            return writeCellContent(
                getCellIndex((action.data as CellDataUpdate).cell_id, newTable),
                (action.data as CellDataUpdate).cell_data,
                action.cellUpdateSignals,
                state
            );
        case CELL_DATA_UPDATE_RESPONSE:
            const updates = (action.data as CellDataUpdateResponse).cells.map(c => ({ index: getCellIndex(c.cell_id, newTable), data: c.cell_data }))
            return updates.reduce((a, v) => writeCellContent(v.index, v.data, action.cellUpdateSignals, a), newTable)
        case COLUMN_CREATE:
            return overwriteLoaderColumn(action.data as ColumnCreate, newTable, action.tableLayoutChange);
        case COLUMN_DELETE:
            return deleteColumn(action.data as ColumnDelete, newTable, action.tableLayoutChange);
        case COLUMN_MOVE:
            return moveColumn(action.data as ColumnMove, newTable, action.tableLayoutChange);
        case COLUMN_DATA_UPDATE:
            return updateColumnData(action.data as ColumnDataUpdate, newTable);
        case GEN_COLUMN_CREATE_RESPONSE:
            return populateGenColumn(action.data as GenColumnCreateResponse, newTable, action.cellUpdateSignals);
        case OPTIONS_REWRITE:
            return rewriteOptions(action.data as OptionsRewrite, newTable);
        case ROW_CREATE:
            return createRow(action.data as RowCreate, newTable, action.tableLayoutChange);
        case ROW_DELETE:
            return deleteRow(action.data as RowDelete, newTable, action.tableLayoutChange);
        case UI_ACTION_SIGNAL:
            return applyMessage(action.data as UIActionSignal, newTable);
        case MAINTENANCE:
            return notifyMaintenance(action.data as MaintenanceMessage, newTable);

    }
    return state;
}

function createColumn(colCreate: ColumnCreate, table: TableResponse, cacheUpdate?: ((table: TableResponse) => void)): TableResponse {
    const refColIndex = getColIndex(colCreate.ref_col, table);
    if (refColIndex === -1) return table;
    const targetIndex = colCreate.before ? (refColIndex === 0 ? 0 : refColIndex - 1) : refColIndex + 1;

    table.columns.splice(targetIndex, 0, { col_id: colCreate.col_id, name: colCreate.name, width: 128 }); //TODO calculate width
    // TODO setColumns(prevColumns => { ...

    const rowCellIdMappings = new Map(Object.entries(colCreate.cell_ids ?? {}));
    const baseValue = colCreate.base_cell ?? blankCell;
    table.table.forEach((row, rowI) => {
        const row_id = table.table[rowI].row_id;
        const cell_id = rowCellIdMappings.get(row_id) ?? crypto.randomUUID(); //TODO this is a fatal situation req reload
        const cell = {
            cell_id: cell_id,
            type: baseValue.type,
            value: baseValue.value,
            isHeading: baseValue.isHeading,
        };
        if (baseValue.type === "comment") { //New column comment id's guaranteed to match cell id
            cell.value = JSON.stringify({ "id": cell_id });
        }
        row.values.splice(targetIndex, 0, [cell]);
    });

    if (cacheUpdate) cacheUpdate(table);

    return table;
}

function overwriteLoaderColumn(colCreate: ColumnCreate, table: TableResponse, cacheUpdate?: ((table: TableResponse) => void)): TableResponse {
    const index = getColIndex(colCreate.col_id, table);
    if (index < 0) return createColumn(colCreate, table, cacheUpdate)

    const rowCellIdMappings = new Map(Object.entries(colCreate.cell_ids ?? {}));
    const baseValue = colCreate.base_cell ?? blankCell;
    for (let rowI = 0; rowI < table.table.length; rowI++) {
        const row_id = table.table[rowI].row_id;
        if (!rowCellIdMappings.has(row_id)) {
            console.log("Fatal mismatch encountered, reloading")
            window.location.reload();
        }
        const cell_id = rowCellIdMappings.get(row_id) ?? crypto.randomUUID();
        const cell = {
            cell_id: cell_id,
            type: baseValue.type,
            value: baseValue.value,
            isHeading: baseValue.isHeading,
        }
        if (baseValue.type === "comment") { //New column comment id's guaranteed to match cell id
            cell.value = JSON.stringify({ "id": cell_id });
        }
        table.table[rowI].values[index][0] = cell;
        table.columns[index].type = baseValue.type;
    }

    if (cacheUpdate) cacheUpdate(table);

    return table;
}

function populateGenColumn(genColumnCreateResponse: GenColumnCreateResponse, table: TableResponse, callback: undefined | ((k: number[], s: string) => void)): TableResponse {
    // columnId: string, mapping: AIDepResponse, defaultDeps: string[], type: string
    const cellIdMappings = new Map(Object.entries(genColumnCreateResponse.response ?? {}));
    const index = getColIndex(genColumnCreateResponse.col_id, table);
    if (index === -1) return table;
    for (let r = 0; r < table.table.length; r++) {
        const tRow = table.table[r];
        if (tRow.values.length < index) break;
        const cell = tRow.values[index];

        if (cell[0].type === "loading") {
            //See if its value is in our data
            const mappingItem = cellIdMappings.get(cell[0].cell_id);

            if (!mappingItem) {
                table.table[r].values[index][0] = { cell_id: cell[0].cell_id, type: genColumnCreateResponse.type, value: genColumnCreateResponse.base_data }; //Clear loading state
                continue
            }

            //Splice in our new row
            if (genColumnCreateResponse.type === GEN_TYPE_TRANSLATION) {
                table.columns[index].type = "translation";
                table.table[r].values[index][0] = { cell_id: cell[0].cell_id, type: "translation", value: mappingItem.value, isHeading: mappingItem.isHeading }
            } else if (genColumnCreateResponse.type === GEN_TYPE_DEPARTMENTS) {
                table.columns[index].type = "departments";
                table.table[r].values[index][0] = { cell_id: cell[0].cell_id, type: "departments", value: mappingItem.value };
            }
        }
    }

    if (callback) callback([-1, index], ""); //Fix row height issue for remote clients

    return table;
}

function rewriteOptions(optionsRewrite: OptionsRewrite, table: TableResponse) {
    const colIndex = getColIndex(optionsRewrite.col_id, table);
    if (colIndex < 0) return table;

    table.table.forEach((row) => {
        const sides = row.values[colIndex][0].value.split(splitChar);
        const options = optionsRewrite.options;
        if (sides.length !== 2) return;
        const strOps = options.reduce((a: string[], v: OptionsRewriteOperation) => {
            if (v.type === "DELETE") return a;
            return [...a, `${v.newName}^${v.newColour}`]
        }, []).join(",");

        const value = sides[0].split(",").map((v: string) => {
            const op = options.find((x: OptionsRewriteOperation) => x.previousName === v);
            const opC = options.find((x: OptionsRewriteOperation) => `${x.previousName}^${x.previousColour}` === v);
            if (!op && !opC) return null;
            return op ? op.newName : `${opC?.newName}^${opC?.newColour}`;
        }).filter(x => x).join(",")

        row.values[colIndex][0].value = value + splitChar + strOps;
    });

    return table;
}

function createRow(rowCreate: RowCreate, table: TableResponse, cacheUpdate?: ((table: TableResponse) => void)) {
    const refIndex = getRowIndex(rowCreate.ref_row, table);
    if (refIndex < 0) return table;
    const targetIndex = rowCreate.before ? (refIndex === 0 ? 0 : refIndex - 1) : refIndex + 1;

    const rowCellIdMappings = new Map(Object.entries(rowCreate.cell_data ?? {}));
    const index = getRowIndex(rowCreate.row_id, table);
    const values = [];
    for (let i = 0; i < table.columns.length; i++) {
        let val = blankCell;
        const cVal = rowCellIdMappings.get(table.columns[i].col_id);
        if (cVal) val = { cell_id: cVal.cell_id, type: cVal.type, value: cVal.value, isHeading: cVal.isHeading }
        values.push([val])
    }

    table.table.splice(index === -1 ? targetIndex : index, index === -1 ? 0 : 1, {
        values: values,
        row_id: rowCreate.row_id,
        rect: { x0: 0, x1: 0, y0: 0, y1: 0, page_number: 1 },
    })

    if (cacheUpdate) cacheUpdate(table);

    return table;
}

function applyMessage(actionMessage: UIActionSignal, table: TableResponse) {
    switch (actionMessage.action) {
        case ACTION_RELOAD:
            setTimeout(() => { window.location.reload() }, 5000);
        case ACTION_VERSIONING:
        case ACTION_REJECTED:
        case ACTION_ROLLBACK:
        case ACTION_MAINTENANCE:
        case ACTION_DELETED:
        case ACTION_INACTIVITY:
            table.overlay = actionMessage.action;

        default:
            console.log("No such message" + actionMessage.action)
        //TODO more actions
    }
    return table
}

function notifyMaintenance(maintMessage: MaintenanceMessage, table: TableResponse) {
    table.maintenance = maintMessage.start

    return table
}

function deleteRow(rowDelete: RowDelete, table: TableResponse, cacheUpdate?: ((table: TableResponse) => void)) {
    const index = getRowIndex(rowDelete.row_id, table);
    if (index < 0) return table;

    table.table.splice(index, 1);

    if (cacheUpdate) cacheUpdate(table);

    return table;
}

function deleteColumn(colDelete: ColumnDelete, table: TableResponse, cacheUpdate?: ((table: TableResponse) => void)) {
    const index = getColIndex(colDelete.col_id, table);
    if (index < 0) return table;

    table.columns.splice(index, 1);
    for (let i = 0; i < table.table.length; i++) {
        table.table[i].values.splice(index, 1);
    }

    if (cacheUpdate) cacheUpdate(table);

    return table;
}

function updateColumnData(columnDataUpdate: ColumnDataUpdate, table: TableResponse) {
    const index = getColIndex(columnDataUpdate.col_id, table);
    if (index < 0) return table;

    table.columns.splice(index, 1, {
        col_id: table.columns[index].col_id,
        name: columnDataUpdate.col_data.name === "" ? table.columns[index].name : columnDataUpdate.col_data.name,
        width: columnDataUpdate.col_data.width < 0 ? table.columns[index].width : columnDataUpdate.col_data.width
    });

    return table;
}

function moveColumn(colMove: ColumnMove, table: TableResponse, cacheUpdate?: ((table: TableResponse) => void)) {
    const index = getColIndex(colMove.col_id, table);
    const refIndex = getColIndex(colMove.ref_col, table);
    if (index < 0 || refIndex < 0) return table;

    let refColTarget = colMove.before ? refIndex : refIndex;
    if (!colMove.before && index > refIndex) refColTarget += 1;

    //Move column labels
    const [deleted] = table.columns.splice(index, 1)
    table.columns.splice(refColTarget, 0, deleted)

    //Move data
    for (let i = 0; i < table.table.length; i++) {
        const row = table.table[i];
        const [deleted] = row.values.splice(index, 1);
        row.values.splice(refColTarget, 0, deleted);
    }

    if (cacheUpdate) cacheUpdate(table);

    return table;
}

function getRowIndex(row_id: string, table: TableResponse): number {
    //TODO use lookups
    for (let rowI = 0; rowI < table.table.length; rowI++) {
        if (table.table[rowI].row_id === row_id) return rowI;
    }
    return -1;
}

function getColIndex(col_id: string, table: TableResponse): number {
    //TODO use lookups
    for (let colI = 0; colI < table.columns.length; colI++) {
        if (table.columns[colI].col_id === col_id) return colI;
    }
    return -1;
}

function getCellIndex(cellId: string, table: TableResponse): [rowI: number, colI: number] {
    //TODO use lookups
    for (let rowI = 0; rowI < table.table.length; rowI++) {
        for (let colI = 0; colI < table.table[rowI].values.length; colI++) {
            if (table.table[rowI].values[colI][0].cell_id === cellId) return [rowI, colI]
        }
    }
    return [-1, -1];
}

function writeCellContent(index: [rowI: number, colI: number], data: Value, callback: undefined | ((k: number[], s: string) => void), table: TableResponse) {
    const [row, col] = index;
    if (row < 0 || col < 0) return table;
    table.table[row].values[col][0].value = data.value;
    table.table[row].values[col][0].isHeading = data.isHeading;
    if (callback) callback([row, col], data.cell_id);
    return table;
}

function ResultStage(props: ResultStageProps) {
    const navigate = useNavigate();
    const [isDragging, setIsDragging] = useState(false);
    const [sig, setSig] = useState(false); // Change signal
    const [tSig, setTSig] = useState(false); // Toggle signal
    const [lockPosSig, setLockPosSig] = useState(false);
    const [scrollCellCoords, setScrollCellCoords] = useState<[number, number]>([0, 0]);
    const [cellUpdateSignal, setCellUpdateSignal] = useState<number[]>();
    const [lastUpdateCell, setLastUpdateCell] = useState<string>("");
    const [lastColumnRegen, setLastColumnRegen] = useState<number>(-1);
    const [name, setName] = useState("");
    const resultGrid = useRef<HTMLDivElement>(null);
    const pdfCol = useRef<HTMLIFrameElement>(null); // also for excel renderer
    const [showOptions, setShowOptions] = useState(false);
    const [contextPosition, setContextPosition] = useState([0, 0]);
    const [showBackSpecHistory, setShowBackSpecHistory] = useState(false);
    const [showDiffs, setShowDiffs] = useState(false);
    const [mainRhCache] = useState<Map<number, number>>(new Map<number, number>());
    const [diffRhCache] = useState<Map<number, number>>(new Map<number, number>());
    const [highlightedArea, setHighlightArea] = useState([{
        pageIndex: 0,
        height: 0,
        width: 0,
        left: 0,
        top: 0,
    }])
    const [showToast, setShowToast] = useState(false);
    const [isNotifySidebarOpen, setIsNotifySidebarOpen] = useState(false);
    const [assignmentStatus, setAssignmentStatus] = useState<AssignmentStatus>("Loading");
    const [cellMap, setCellMap] = useState(new Map<string, [col: number, row: number]>);

    //Websockets
    const [users, setUsers] = useState<Map<string, string>>(new Map());
    const [userColours, setUserColours] = useState<Map<string, string>>(new Map());
    const [self, setSelf] = useState<string>("");
    const [table, dispatchTableChange] = useReducer<TableReducer>(tableReducer, emptyTable);
    const [cReadonly, setCReadonly] = useState(false);
    const [departmentConfig, setDepartmentConfig] = useState<string[]>([]);
    const [departmentNameMapping, setDepartmentNameMapping] = useState<Map<string, string>>(new Map());
    const { setComments, setPublish } = useComments();
    const [viewers, setViewers] = useState<string[]>([]);
    const [userSelections, setUserSelections] = useState<Map<string, string>>(new Map());
    const socketUrl = import.meta.env.VITE_COLLAB_WS_URI;
    const [wsConnAsked, setWSConnAsked] = useState(false);
    const [wsConnected, setWSConnected] = useState(false);
    const [seenMessages] = useState(new Map<string, boolean>);
    const { sendJsonMessage, lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl);
    const backPopup = usePopup("Go Back");
    const moreOptionsPopup = usePopup("More Options", "left");


    function toggleNotifySidebar(event: MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
        setIsNotifySidebarOpen(!isNotifySidebarOpen);
    }

    //Handler useEffect, sends messages off for processing
    useEffect(() => {
        if (!lastMessage) return;
        if (!wsConnected && wsConnAsked) {
            const data = JSON.parse(lastMessage.data)
            if (
                Object.prototype.hasOwnProperty.call(data, "table") &&
                Object.prototype.hasOwnProperty.call(data, "readonly")
            ) {
                const msg = (data as InitialiseMessage)
                setCReadonly(msg.readonly);
                setComments(new Map(Object.entries(msg.comments)));
                setDepartmentConfig(msg.department_config);
                setDepartmentNameMapping(new Map(Object.entries(msg.department_name_map)));
                setPublish(() => onTableUpdate); // todo: clean up
                dispatchTableChange({ type: "init", data: msg.table, cellUpdateSignals: undefined, tableLayoutChange: undefined })
                generateCellIdMap(msg.table);
                setWSConnected(true);
            } else if (Object.prototype.hasOwnProperty.call(data, "message")) {
                console.log("killing websocket due to message field presence in preconn")
                getWebSocket()?.close(); // Should not keep open, no further messages are coming
            } else if (Object.prototype.hasOwnProperty.call(data, "type") &&
                Object.prototype.hasOwnProperty.call(data, "data")
            ) {
                if (data.type === CURRENT_USERS_MESSAGE) {
                    const users = Array.from(new Set((data.data as CurrentUsersMessage).users)); // Unique only
                    setViewers(users)
                    //Remove selections of users who left
                    const missingUsers: string[] = [];
                    for (const key of userSelections.keys()) {
                        if (!users.includes(key)) missingUsers.push(key);
                    }
                    setUserSelections(prevList => {
                        const newList = new Map(prevList);
                        for (const user of missingUsers) newList.delete(user);
                        return newList;
                    });
                } else if (data.type === USER_CELL_SELECT_RELAY) {
                    const msg = (data.data as UserCellSelect);
                    if (!msg.user_id || msg.user_id === self) return; // Prevent shadow cell border
                    setUserSelections(prevList => {
                        const newList = new Map(prevList);
                        if (msg.user_id) newList.set(msg.user_id, msg.cell_id);
                        return newList;
                    });
                }
            }
        } else {
            const data = JSON.parse(lastMessage.data);
            if (Object.prototype.hasOwnProperty.call(data, "message") && data.message === "rejected") {
                console.error("killing websocket due to rejected message") // Avoid desync
                dispatchTableChange({ type: UI_ACTION_SIGNAL, data: { "action": ACTION_REJECTED }, cellUpdateSignals: undefined, tableLayoutChange: undefined });
            } else if (Object.prototype.hasOwnProperty.call(data, "type") &&
                Object.prototype.hasOwnProperty.call(data, "data")
            ) {
                if (data.type === CURRENT_USERS_MESSAGE) {
                    const users = Array.from(new Set((data.data as CurrentUsersMessage).users)); // Unique only
                    setViewers(users)
                } else if (data.type === USER_CELL_SELECT_RELAY) {
                    const msg = (data.data as UserCellSelect);
                    if (!msg.user_id || msg.user_id === self) return; // Prevent shadow cell border
                    setUserSelections(prevList => {
                        const newList = new Map(prevList);
                        if (msg.user_id) newList.set(msg.user_id, msg.cell_id);
                        return newList;
                    });
                } else if (data.type === DOCUMENT_STRUCTURE_SIGNAL) {
                    const msg = (data.data as DocumentStructureSignal);
                    if (msg.type === "rename") {
                        setName(msg.data);
                    }
                } else if (data.type === COMMENT_EVENT) {
                    if (seenMessages.has(data.id)) {
                        console.log("skipping seen comment message")
                    } else {
                        seenMessages.set(data.id, true);
                        const msg = (data.data as CommentEvent);
                        switch (msg.type) {
                            case "CREATE":
                                setComments((prevComments => {
                                    const newComments = new Map(prevComments);
                                    const cell_comments = newComments.get(msg.comment.comment_cell_id) ?? [];
                                    cell_comments.push(msg.comment);
                                    newComments.set(msg.comment.comment_cell_id, cell_comments);
                                    return newComments;
                                }));
                                break;
                            case "EDIT":
                                setComments((prevComments => {
                                    const newComments = new Map(prevComments);
                                    const cell_comments = newComments.get(msg.comment.comment_cell_id) ?? [];
                                    const index = cell_comments.findIndex(c => c.comment_id === msg.comment.comment_id);
                                    cell_comments[index] = {
                                        ...cell_comments[index],
                                        comment: msg.comment.comment,
                                        customer_display: msg.comment.customer_display,
                                        tags: msg.comment.tags,
                                    }
                                    newComments.set(msg.comment.comment_cell_id, cell_comments);
                                    return newComments;
                                }));
                                break;
                            case "RESOLVE":
                                setComments((prevComments => {
                                    const newComments = new Map(prevComments);
                                    const cell_comments = newComments.get(msg.comment.comment_cell_id) ?? [];
                                    const index = cell_comments.findIndex(c => c.comment_id === msg.comment.comment_id);
                                    cell_comments[index].resolved = msg.comment.resolved;
                                    cell_comments[index].resolved_date = msg.comment.resolved_date;
                                    cell_comments[index].resolved_user = msg.comment.resolved_user;
                                    newComments.set(msg.comment.comment_cell_id, cell_comments);
                                    return newComments;
                                }));
                                break;
                            case "DELETE":
                                setComments((prevComments => {
                                    const newComments = new Map(prevComments);
                                    let cell_comments = newComments.get(msg.comment.comment_cell_id) ?? [];
                                    cell_comments = cell_comments.filter(c => c.comment_id !== msg.comment.comment_id)
                                    newComments.set(msg.comment.comment_cell_id, cell_comments);
                                    return newComments;
                                }));
                                break;
                        }
                    }
                } else if (data.type === DEPARTMENT_CONFIG_UPDATE) {
                    const msg = (data.data as DepartmentConfigUpdate);
                    setDepartmentConfig(msg.departments);
                    setDepartmentNameMapping(new Map(Object.entries(msg.department_name_map)));
                } else {
                    if (!seenMessages.has(data.id)) {
                        seenMessages.set(data.id, true);
                        dispatchTableChange({
                            type: data.type, data: data.data, cellUpdateSignals: (k, s) => {
                                setCellUpdateSignal(k);
                                setLastUpdateCell(s);
                                if (k[0] === -1) { //Special case for when a whole column has been modified. Triggers rh update
                                    setLastColumnRegen(k[1]);
                                }
                            },
                            tableLayoutChange: generateCellIdMap
                        });
                    } else {
                        console.log("skipping seen message")
                    }
                }
            }
        }
    }, [lastMessage, wsConnAsked]);

    //Cell id map generation
    function prepareCellMap(table: TableResponse) {
        const newMap = new Map<string, [col: number, row: number]>();

        for (let rowI = 0; rowI < table.table.length; rowI++) {
            for (let colI = 0; colI < table.table[rowI].values.length; colI++) {
                newMap.set(table.table[rowI].values[colI][0].cell_id, [colI, rowI]);
            }
        }

        return newMap;
    }

    function generateCellIdMap(table: TableResponse) {
        setCellMap(prepareCellMap(table));
    }

    //Handle special overlay conditions
    useEffect(() => {
        if ([ACTION_INACTIVITY, ACTION_REJECTED].includes(table.overlay ?? "")) {
            getWebSocket()?.close();
        }
    }, [table.overlay]);

    //ReadyState management, auths with collab and resets state when disconnected
    useEffect(() => {
        if (readyState === ReadyState.OPEN && props.specId && !wsConnected && !wsConnAsked) {
            sendJsonMessage({ "id": crypto.randomUUID(), "type": COMBINED_AUTH_MESSAGE, data: { "token": sessionStorage.getItem("jwt"), "spec_id": props.specId } });
            setWSConnAsked(true);

        }
        if (readyState === ReadyState.CLOSING || readyState === ReadyState.CLOSED) {
            setWSConnected(false);
            setWSConnAsked(false);
        }
    }, [readyState, props.specId]);

    //PDF Viewer
    const renderHighlights = (props: RenderHighlightsProps) => (
        <div>
            {highlightedArea
                .filter((area) => area.pageIndex === props.pageIndex)
                .map((area, idx) => (
                    <div
                        key={idx}
                        id={"highlight-region-" + idx}
                        className="highlight-area"
                        style={Object.assign(
                            {},
                            {
                                background: '#a482f7',
                                opacity: 0.2,
                            },
                            props.getCssProperties(area, props.rotation)
                        )}
                    />
                ))}
        </div>
    );

    const highlightPluginInstance = highlightPlugin({
        renderHighlights,
        trigger: Trigger.None,
    });
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const fullScreenPluginInstance = fullScreenPlugin();
    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => {
        const { NumberOfPages } = slot;
        return Object.assign({}, slot, {
            NumberOfPages: () => (
                <>
                    of <NumberOfPages />
                </>
            ),
            SwitchTheme: () => <></>,
            Open: () => <></>,
            SwitchScrollModeMenuItem: () => <></>,
            SwitchViewModeMenuItem: () => <></>,
            RotateBackwardMenuItem: () => <></>,
            RotateForwardMenuItem: () => <></>,
        });
    };
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar: () => { return <Toolbar>{renderDefaultToolbar(transform)}</Toolbar> },
        sidebarTabs: defaultTabs => {
            return [defaultTabs[0]]
        },
    });

    //PDF-table link display on click
    function updateSelectedItem(box: BoundingBox, doScroll: boolean = true) {
        setHighlightArea([{
            pageIndex: box.page_number - 1,
            height: (Math.max(box.y0, box.y1) - Math.min(box.y0, box.y1)) * 100,
            width: (Math.max(box.x0, box.x1) - Math.min(box.x0, box.x1)) * 100,
            left: box.x0 * 100,
            top: 100 - (box.y1 * 100), //Y is inverted, don't ask me
        }]);

        //Better centering of elements
        let pageHeight = 0;
        const pageElements = document.querySelectorAll(`[aria-label="Page ${box.page_number}"]`);
        if (pageElements.length > 0) pageHeight = pageElements[0].clientHeight;
        if (pageHeight === 0) {
            const classPageElements = document.getElementsByClassName("rpv-core__inner-page");
            if (classPageElements.length > 0) pageHeight = classPageElements[0].clientHeight;
            else console.log("failed to grab page height")
        }

        if (doScroll) setTimeout(() => {//bodge
            const item = document.getElementById("highlight-region-0");
            const elements = document.querySelectorAll('[data-testid=core__inner-pages]');

            if (item && elements.length > 0) {
                const w = elements[0];
                item.scrollIntoView();
                w.scrollBy(0, -10)
            } else {
                pageNavigationPluginInstance.jumpToPage(box.page_number - 1);
            }
        }, 50);
    }

    //Row jumping based on props.rowId changing
    useEffect(() => {
        if (props.rowId && props.rowId != "" && table && table.table.length > 0) {
            setTimeout(() => {
                if (!props.rowId || props.rowId === "" || !table) return;
                let targetRowIndex;
                let targetRow;
                for (let y = 0; y < table.table.length; y++) {
                    const row = table.table[y];
                    if (row.row_id === props.rowId) {
                        targetRowIndex = y;
                        targetRow = row;
                        break;
                    }
                }
                if (targetRowIndex && targetRow) {
                    updateSelectedItem(targetRow.rect, true);
                    setScrollCellCoords([0, targetRowIndex]);
                    props.rowId = "";
                }
            }, 2500);
        }
    }, [table, props.rowId]); // lock helped to jump to the right cell, might not be necessary though

    //cell jumping based on props.cellId changing
    useEffect(() => {
        if (props.cellId && props.cellId != "" && table && table.table.length > 0) {
            setTimeout(() => {
                if (!props.cellId || props.cellId === "" || !table) return;
                let targetRowIndex;
                let targetRow;
                let targetCellIndex;
                for (let y = 0; y < table.table.length; y++) {
                    const row = table.table[y];
                    if (targetRow) break;
                    for (let x = 0; x < row.values.length; x++) {
                        const cell = row.values[x][0]
                        if (cell.cell_id === props.cellId) {
                            targetRowIndex = y;
                            targetRow = row;
                            targetCellIndex = x;
                            break;
                        }
                    }
                }
                if (targetCellIndex && targetRowIndex && targetRow) {
                    setScrollCellCoords([targetCellIndex, targetRowIndex]);
                    props.cellId = "";
                }
            }, 2500);
        }
    }, [table, props.cellId]); // lock helped to jump to the right cell, might not be necessary though


    //Prepares handlers for pdf -> table click links
    useEffect(() => {
        setName(props.name ?? "");
        const getClickedBound = (evt: any) => {
            // todo: this is a hack, make it better
            const e = evt as MouseEvent;
            if (!table) return;
            const target = e.target;
            if (!target || !(target instanceof HTMLElement)) return;
            const pageEl: HTMLElement | null = target.closest(".rpv-core__page-layer");
            if (!pageEl || !("virtualIndex" in pageEl.dataset)) return;
            const pageStr = pageEl.dataset.virtualIndex;
            if (!pageStr) return;
            const page = Number(pageStr);


            const box = pageEl.getBoundingClientRect();
            const body = document.body;
            const docEl = document.documentElement;
            const scrollLeft = window.scrollX || docEl.scrollLeft || body.scrollLeft;
            const clientLeft = docEl.clientLeft || body.clientLeft || 0;
            const left = box.left + scrollLeft - clientLeft;
            const pctLeft = (e.pageX - left) / (box.width - 8);
            const pctTop = target.offsetTop / box.height;

            let closestI = 0;
            let closestRect = null;
            let closestDist = -1;
            for (let i = 0; i < table.table.length; i++) {
                const rowRect = table.table[i].rect;
                if (rowRect.page_number !== page + 1) continue;


                let distX = 0;
                let distY = 0;
                if (pctLeft < rowRect.x0 || pctLeft > rowRect.x1) {
                    distX = Math.max(rowRect.x0 - pctLeft, pctLeft - rowRect.x1);
                }
                const invPctTop = 1 - pctTop;
                if (invPctTop < rowRect.y0 || invPctTop > rowRect.y1) {
                    distY = Math.max(rowRect.y0 - invPctTop, invPctTop - rowRect.y1);
                }
                const dist = distX + distY;
                if (closestDist === -1 || dist < closestDist) {
                    closestDist = dist;
                    closestRect = rowRect;
                    closestI = i;
                }
            }
            if (closestRect !== null) {
                updateSelectedItem(closestRect, false);
                setScrollCellCoords([0, closestI]);
            }

        }
        document.addEventListener('click', getClickedBound, false);
        return () => {
            document.removeEventListener("click", getClickedBound);
        }
    }, [props.name, table]);

    //Store a list of users once
    useEffect(() => {
        request<UserListResponse>("GET", `/users?hide_system=false`).subscribe({
            next: (r: APIResponse<UserListResponse>) => {
                if (!r.ok || r.statusCode !== 200 || !r.data) {
                    return; //TODO logs
                }
                const m = new Map<string, string>();
                const colourM = new Map<string, string>();
                const userResp = r.data;
                let i = 0;
                for (const u of userResp.users) {
                    m.set(u.user_id, u.abbreviation);
                    colourM.set(u.user_id, colours[(i % (colours.length - 1)) + 1]);
                    i++;
                }
                setUsers(m);
                setUserColours(colourM);
                setSelf(r.data.requesting_user.user_id);
            },
            error: (e) => {
                console.error(e);
            },
        });
    }, []);

    const backToList = useCallback(() => {
        navigate("/specifications/list" + (props.parent !== "" && props.parent !== "00000000-0000-0000-0000-000000000000" ? `/${props.parent}` : ""))
    }, [navigate]);

    function minimise(event: React.MouseEvent<HTMLDivElement>) {
        if (event.detail < 2) return
        resultGrid.current?.style.setProperty("--pdf-width", `0px`);
        resultGrid.current?.style.setProperty("--table-width", `auto`);
    }

    //Divider resize bar
    function startDrag() {
        setIsDragging(true);
        pdfCol.current?.style.setProperty("pointer-events", "none");
    }

    function endDrag() {
        setIsDragging(false);
        pdfCol.current?.style.setProperty("pointer-events", "auto");
    }

    const dragWidth = 8;
    const offset = 40;
    function onDrag(event: React.MouseEvent<HTMLDivElement>) {//TODO window resizing
        if (!isDragging) return;
        event.preventDefault();

        const widthNone = 0;
        const widthStick = 0;
        const widthStick2 = 450;

        const cRect = pdfCol.current?.getBoundingClientRect();
        let width = Math.abs((cRect?.x ?? 0) + (resultGrid.current?.offsetLeft ?? 0) - event.clientX) - offset;

        if (width < widthNone) width = -4;
        else if (width < widthStick) width = widthStick;

        if (width > (resultGrid.current?.clientWidth ?? 0) - widthStick - offset - 133) {
            resultGrid.current?.style.setProperty("--pdf-width", `auto`);
            resultGrid.current?.style.setProperty("--table-width", `${widthStick2}px`);
        } else {
            resultGrid.current?.style.setProperty("--pdf-width", `${width + dragWidth / 2}px`);
            resultGrid.current?.style.setProperty("--table-width", `auto`);
        }
    }

    function toggleContext(e: MouseEvent<HTMLButtonElement>) {
        setContextPosition([e.currentTarget.offsetLeft, e.currentTarget.offsetTop + e.currentTarget.clientHeight]);
        setShowOptions(!showOptions);
    }

    //Collaboration
    function onTableUpdate(type: string, messageData: MessageData) {
        if (props.readonly || cReadonly) return;
        const message: Message = {
            id: crypto.randomUUID(),
            type: type,
            data: messageData
        };
        //RowCreate/ColumnCreate have special case where loader col is shown first
        if (message.type !== "ColumnCreate" && message.type !== "RowCreate") {
            seenMessages.set(message.id, true);
        }
        sendJsonMessage(message);
        setSig(!sig)

        if (["ColumnCreate", "ColumnDelete", "ColumnMove", "RowCreate", "RowDelete"].includes(message.type)) generateCellIdMap(table);
    }

    function onRowDelete(rowId: string) {
        if (props.readonly || !props.difference_table) return;
        const diffIndex = props.difference_table.table.map(r => r.row_id).indexOf(rowId);
        if (diffIndex < 0) return;

        request("DELETE", `/specifications/${props.specId}/table/row/${rowId}`).subscribe({
            error: (e) => {
                console.error(e);
                //TODO proper error
            },
        });
        props.difference_table.table.splice(diffIndex, 1);
    }

    //View control
    function toggleView() {
        setScrollCellCoords([0, 0]);
        setLockPosSig(true);
        setShowDiffs(!showDiffs);
        setTimeout(() => {//bodge
            setTSig(!tSig);
            setLockPosSig(false);
        }, 50);
    }

    useEffect(() => {
        imageCache.clear()
    }, []);
    // will initally load all the images into the cache
    useEffect(() => {
        table.table.forEach(row => {
            const cell = row.values[0][0];
            if (cell.type !== "image") return;
            const value = JSON.parse(cell.value)
            if (imageCache.has(value.signed_url)) return;

            const img = new Image();
            img.src = value.signed_url;
            imageCache.set(value.signed_url, img);
        })

        props.difference_table?.table.forEach(row => {
            let cell = row.values[0][0];
            if (cell.type === "image") {
                const value = JSON.parse(cell.value)
                if (!imageCache.has(value.signed_url)) {

                    const img = new Image();
                    img.src = value.signed_url;
                    imageCache.set(value.signed_url, img);
                }
            }
            cell = row.values[1][0];
            if (cell.type === "image") {
                const value = JSON.parse(cell.value)
                if (!imageCache.has(value.signed_url)) {

                    const img = new Image();
                    img.src = value.signed_url;
                    imageCache.set(value.signed_url, img);
                }
            }

        })


    }, [table.table, props.difference_table?.table])

    const creatorString = useMemo(() => {
        if (!props.creator || !users || !users.has(props.creator)) return "you";
        return users.get(props.creator);
    }, [props.creator, users]);

    const dateString = useMemo(() => {
        const gap = Date.now() / 1000 - props.created;

        // Less than 1 minute
        if (gap < 60) {
            return "just now";
        }

        // Less than 1 hour
        if (gap < 3600) {
            const minutes = Math.floor(gap / 60);
            return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
        }

        // Less than 1 day
        if (gap < 86400) {
            const hours = Math.floor(gap / 3600);
            return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
        }

        // Less than 30 days
        if (gap < 2592000) {
            const days = Math.floor(gap / 86400);
            return `${days} ${days === 1 ? "day" : "days"} ago`;
        }

        // Less than 1 year
        if (gap < 31536000) {
            const months = Math.floor(gap / 2592000);
            return `${months} ${months === 1 ? "month" : "months"} ago`;
        }

        // More than 1 year
        const years = Math.floor(gap / 31536000);
        return `${years} ${years === 1 ? "year" : "years"} ago`;
    }, [props.created]);

    return (
        <div
            ref={resultGrid}
            id="result-grid"
            className="grid w-full px-4 pt-4 text-left result-grid h-[var(--body-height)] gap-x-4 pb-8"
            style={{
                "--pdf-width": "auto",
                "--table-width": "50%",
                "gridTemplateColumns": `var(--pdf-width) 6px var(--table-width)`
            } as React.CSSProperties}
            onMouseMove={onDrag}
            onMouseUp={endDrag}
        >
            <NotifyDepartmentsSidebar specId={props.specId} department_config={departmentConfig} isOpen={isNotifySidebarOpen} setIsOpen={setIsNotifySidebarOpen} assignmentStatus={assignmentStatus} setAssignmentStatus={setAssignmentStatus} />
            <div className="flex col-span-full px-4">
                <button className="clear-button ghost-button text-zinc-950 px-2 mr-2 flex items-center" onClick={backToList} {...backPopup.props}><ArrowLeft /></button>
                {backPopup.component}
                <div className="flex flex-col">
                    <span className="font-semibold text-2xl">{name}</span>
                    <span className="text-neutral-400">Created by {creatorString} {dateString}</span>
                </div>

                <div className="flex ml-auto items-center gap-x-2">
                    {showToast && (
                        <div className="flex items-center bg-yellow-50 text-yellow-700 border-yellow-600 border rounded-lg px-4 py-1.5">
                            Preparing document for export. This might take a few minutes…
                        </div>
                    )}
                    {(props.readonly || cReadonly) && <div className="flex gap-x-2 items-center bg-yellow-50 text-yellow-700 border-yellow-600 border rounded-lg px-4 py-1.5">
                        <AlertCircle />
                        This is an old version. You cannot make changes.
                    </div>}

                    {table.maintenance !== undefined && <div className="flex gap-x-2 items-center bg-yellow-50 text-yellow-700 border-yellow-600 border rounded-lg px-4 py-1.5">
                        <AlertCircle />
                        {table.maintenance <= 0 &&
                            <span>Maintenance is underway, we'll be back soon.</span>
                        }
                        {table.maintenance > 0 &&
                            <span>Maintenance will begin at approximately {new Date(table.maintenance).toLocaleString()}</span>
                        }
                    </div>}

                    {viewers.map(v =>
                        <div className="flex mx-auto gap-2">
                            <div className="flex p-2 rounded-full w-6 h-6 text-xs bg-primary-500 items-center place-content-center text-white select-none" style={{ backgroundColor: userColours.get(v) ?? "#ff0000" }}>
                                {users.get(v) ?? v.slice(0, 3)}
                            </div>
                        </div>
                    )}
                    {(readyState === ReadyState.CONNECTING || readyState === ReadyState.UNINSTANTIATED) && <Loader2 className="spin" />}
                    {readyState === ReadyState.OPEN && <Wifi />}
                    {(readyState === ReadyState.CLOSING || readyState === ReadyState.CLOSED) && <WifiOff />}

                    {["Loading", "In progress", "Overdue", "Done"].includes(assignmentStatus) && (
                        <button className={`flex primary-button gap-x-2 ${getStatusAttributes(assignmentStatus).bgColorStrong} ${getStatusAttributes(assignmentStatus).bgColorHover} text-color-white`} onClick={toggleNotifySidebar}>
                            <CalendarCheck />
                            {getStatusAttributes(assignmentStatus).label}
                        </button>
                    )}
                    {assignmentStatus === "Open" && (
                        <button className={`flex primary-button gap-x-2 bg-color-black`} onClick={toggleNotifySidebar} disabled={!departmentConfig || departmentConfig.length === 0}>
                            <Bell />
                            Notify Departments
                        </button>
                    )}
                    <button className="flex secondary-button px-2 w-fit h-fit" onClick={toggleContext} {...moreOptionsPopup.props}>
                        <MoreHorizontal color="black" />
                    </button>
                    {moreOptionsPopup.component}
                </div>

                <HistoryModal open={showBackSpecHistory} setOpen={setShowBackSpecHistory} specId={props.specId} viewingSpec={true} />
                <SpecificationContextMenu open={showOptions} id={props.specId ?? ""} setOpen={setShowOptions} name={name} folder={props.parent} handleDelete={backToList} handleRename={(nn) => setName(nn)} position={contextPosition} readonly={props.readonly || cReadonly} viewingSpec={true} />
            </div>

            {/* PDF View */}
            <div id="pdf-render-window" className="overflow-y-auto pt-4 pl-4">
                {props.file && props.fileFormat == "application/pdf" && <Viewer fileUrl={props.file} plugins={[defaultLayoutPluginInstance, toolbarPluginInstance, fullScreenPluginInstance, pageNavigationPluginInstance, highlightPluginInstance]} httpHeaders={{
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "POST, GET, PATCH, OPTIONS, DELETE",
                    "Access-Control-Allow-Headers": "Origin, Content-Type, Authorization",
                    "Expose-Headers": "Origin, Content-Type, X-Auth-Token"
                }} />}
                {props.file && props.fileFormat && EXCEL_FORMATS.includes(props.fileFormat) && (
                    <ExcelRenderer file={props.file} fileFormat={props.fileFormat} />
                )}
            </div>
            {/* Drag Bar */}
            <div id="leftdragbar" className={"cursor-col-resize opacity-20 hover:opacity-100 " + (isDragging ? "opacity-100" : "")} onMouseDown={startDrag} onClick={minimise} >
                <div className="h-full bg-purple-300 w-[8px] mx-auto"></div>
            </div>
            {/* Messages */}
            {readyState === ReadyState.CLOSED &&
                <div className="flex flex-col gap-y-2 justify-center items-center bg-zinc-100">
                    {table.overlay === ACTION_INACTIVITY &&
                        <span className="text-xl">You have been disconnected for inactivity. Please reload to continue</span>
                    }
                    {table.overlay === ACTION_REJECTED &&
                        <span className="text-xl">An unexpected error occurred. Please reload to continue</span>
                    }
                    {![ACTION_INACTIVITY, ACTION_REJECTED].includes(table.overlay ?? "") &&
                        <span className="text-xl">Connection lost, please reload.</span>
                    }
                    <button className="flex gap-x-2" onClick={() => window.location.reload()}><RotateCw />Reload</button>
                </div>
            }
            {(readyState === ReadyState.CONNECTING || (wsConnAsked && !wsConnected) || !table.columns) &&
                <div className="flex flex-col gap-y-2 justify-center items-center bg-zinc-100">
                    <span className="text-xl">Loading...</span>
                    <div className="loader">
                        <div className="loaderBar"></div>
                    </div>
                </div>
            }
            {readyState !== ReadyState.CLOSED && table.overlay &&
                <div className="flex flex-col gap-y-2 justify-center items-center bg-zinc-100">
                    {table.overlay === ACTION_RELOAD && <span className="text-xl">An error occurred, we are gathering data and will reload shortly</span>}
                    {table.overlay === ACTION_MAINTENANCE && <span className="text-xl">System is under maintenance, please check back later</span>}
                    {table.overlay === ACTION_DELETED && <>
                        <span className="text-xl">Document was deleted by a user, please return to the folder view.</span>
                        <button className="flex gap-x-2" onClick={backToList}><ArrowLeft />Exit</button>
                    </>}
                    {table.overlay === ACTION_VERSIONING && <>
                        <span className="text-xl">A new version has been uploaded, this document is now read-only.</span>
                        <button className="flex gap-x-2" onClick={() => window.location.reload()}><RotateCw />Reload</button>
                    </>}
                    {table.overlay === ACTION_ROLLBACK && <>
                        <span className="text-xl">Document has been restored, please reload to enable editing.</span>
                        <button className="flex gap-x-2" onClick={() => window.location.reload()}><RotateCw />Reload</button>
                    </>}
                </div>
            }
            {/* Table View */}
            {!table.overlay && readyState !== ReadyState.CLOSED && table.columns.length > 0 && props.difference_table &&
                <>
                    {showDiffs && <div className={"overflow-auto flex flex-col "}><ResultTable table={table} changeSignal={sig} toggleSignal={tSig} cellUpdateSignal={cellUpdateSignal} lastRemoteChangedCell={lastUpdateCell} userSelections={userSelections} userColours={userColours} scrollCellCoords={scrollCellCoords} positionStoreLock={lockPosSig} changesTable={props.difference_table} deletedTable={props.deletion_table} onChange={onTableUpdate} readonly={props.readonly || cReadonly} department_config={departmentConfig} department_name_mapping={departmentNameMapping} onPageJump={updateSelectedItem} toggleView={toggleView} isDifference={true} showToast={showToast} setShowToast={setShowToast} lastColumnRegen={lastColumnRegen} rhCache={diffRhCache} cellMap={cellMap} regenCellMap={prepareCellMap} /></div>}
                    {!showDiffs && <div className={"overflow-auto flex flex-col "}><ResultTable table={table} changeSignal={sig} toggleSignal={tSig} lastRemoteChangedCell={lastUpdateCell} userSelections={userSelections} userColours={userColours} scrollCellCoords={scrollCellCoords} positionStoreLock={lockPosSig} onChange={onTableUpdate} onRowDelete={onRowDelete} readonly={props.readonly || cReadonly} department_config={departmentConfig} department_name_mapping={departmentNameMapping} onPageJump={updateSelectedItem} toggleView={toggleView} isDifference={false} showToast={showToast} setShowToast={setShowToast} lastColumnRegen={lastColumnRegen} rhCache={mainRhCache} cellMap={cellMap} regenCellMap={prepareCellMap} /></div>}
                </>
            }
            {!table.overlay && readyState !== ReadyState.CLOSED && table.columns.length > 0 && !props.difference_table &&
                <ResultTable table={table} onChange={onTableUpdate} readonly={props.readonly || cReadonly} department_config={departmentConfig} department_name_mapping={departmentNameMapping} onPageJump={updateSelectedItem} cellUpdateSignal={cellUpdateSignal} lastRemoteChangedCell={lastUpdateCell} userSelections={userSelections} userColours={userColours} scrollCellCoords={scrollCellCoords} showToast={showToast} setShowToast={setShowToast} lastColumnRegen={lastColumnRegen} rhCache={mainRhCache} cellMap={cellMap} regenCellMap={prepareCellMap} />
            }
        </div>
    )
}

export default ResultStage;
