import { Plus } from "lucide-react";
import { useEffect, useRef } from "react";
import { ColorPicker, IColor, useColor } from "react-color-palette";
import "react-color-palette/css";


interface ColourMenuProps {
    colours: string[];
    onPick: (colour: string) => void;
    className?: string;
    id: string;
    position?: 'right' | 'left'
}

const X_POSITION = {
    right: { left: 'anchor(right)' },
    left: { right: 'anchor(left)' }
} as const;

function ColourMenu(props: ColourMenuProps) {
    const [colour, setColour] = useColor(localStorage.getItem("customColour") ?? "#562AD3");
    const dialog = useRef<HTMLDialogElement>(null);
    const menu = useRef<HTMLDivElement>(null)

    function onChangeComplete(color: IColor) {
        localStorage.setItem("customColour", color.hex)
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        menu.current?.showPopover()
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    function handleClickOutside(e: Event) {
        if (dialog.current && dialog.current.open && dialog.current === e.target) {
            dialog.current.close();
        }
    }


    return (
        <div ref={menu} popover="" style={{
            // @ts-expect-error tsc is slow
            positionAnchor: `--${props.id}`,
            ...X_POSITION[props.position ?? 'left'],
            top:"anchor(bottom)"
        }}
             className={props.className ? props.className : "absolute inset-auto m-0 right-[75%] border z-30 bg-white rounded-2xl p-4"}
        >
            {<dialog className="colourPickerDialog" ref={dialog}>
                <ColorPicker color={colour} onChange={setColour} onChangeComplete={onChangeComplete} hideAlpha />
            </dialog>}
            <div className="flex gap-x-4">
                <div className="w-8 h-8 bg-red-300 rounded-full cursor-pointer grid place-items-center"
                    style={{ backgroundColor: 'lightgray', color: 'black' }}
                    onClick={(e) => {
                        e.stopPropagation();
                        dialog.current?.showModal();
                    }}
                >
                    <Plus />
                </div>
                <div className="w-8 h-8 bg-red-300 rounded-full cursor-pointer"
                    style={{ backgroundColor: colour.hex }}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onPick(colour.hex);
                    }}
                />
                {props.colours.map((o, i) =>
                    <div key={i} className="w-8 h-8 bg-red-300 rounded-full cursor-pointer"
                        style={{ backgroundColor: o }}
                        onClick={(e) => {
                            e.stopPropagation();
                            props.onPick(o);
                        }}
                    />
                )}
            </div>
        </div>
    );
}

export default ColourMenu;
