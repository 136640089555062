import '../../table-extract.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "@glideapps/glide-data-grid/dist/index.css";
import { useEffect, useState, useRef } from "react";
import { Rectangle } from "@glideapps/glide-data-grid";
import { ArrowDown, ArrowUp, Trash2 } from 'lucide-react';

interface RowMenuProps {
    onAddRow: (index: number) => void;
    onDeleteRow: (index: number) => void;
    isOpen: boolean;
    hideInserts: boolean;
    menu: {
        row: number;
        bounds: Rectangle;
    } | undefined;
}
//TODO this is a carbon copy of ColMenu, the two can likely merge with a slot and a little finesse
function RowMenu(props: RowMenuProps) {
    const [rowMenuOpen, setRowMenuOpen] = useState(false);
    const [menu, setMenu] = useState<{
        row: number;
        bounds: Rectangle;
    }>();

    useEffect(() => { //Pass through prop updates to open menu
        setMenu(props.menu);
        setRowMenuOpen(props.isOpen);
        if (!props.isOpen) closeMenus();
    }, [props.menu, props.isOpen]);
    function closeMenus() {
        setRowMenuOpen(false);
        setMenu(undefined);
    }
    const menuStyle = {
        "left": menu?.bounds.x ?? 0,
        "top": (menu?.bounds.y ?? 0) + (menu?.bounds.height ?? 0) + 1,
        "border": "1px white solid",
        "backgroundColor": "white",
        "filter": "drop-shadow(1px 1px 1px #ddd)",
        "borderRadius": "6px",
    };
    //Handle clicks outside menus
    const rowMenuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: Event) => {
        if (rowMenuRef.current && !rowMenuRef.current.contains(e.target as Node)) closeMenus();
    };

    if (!rowMenuOpen) return <></>
    else return (
        <div ref={rowMenuRef} className="absolute p-4 rounded-xl" style={menuStyle}>
            <>
            {!props.hideInserts && <>
                <button
                    onClick={() => props.onAddRow(menu?.row ?? -1)}
                    className="flex clear-button pr-4 w-full py-2 hover:bg-neutral-100 text-zinc-950 font-semibold"
                >
                    <div className="px-4"><ArrowUp /></div>Insert Above
                </button>
                <button
                    onClick={() => props.onAddRow((menu?.row ?? -2) + 1)}
                    className="flex clear-button pr-4 w-full py-2 border-b-slate-300 hover:bg-neutral-100 text-zinc-950 font-semibold"
                >
                    <div className="px-4"><ArrowDown /></div>Insert Below
                </button>
            </>}
                <button
                    onClick={() => props.onDeleteRow(menu?.row ?? -1)}
                    className="flex clear-button pr-4 w-full py-2 hover:bg-neutral-100 text-zinc-950 font-semibold"
                >
                    <div className="px-4"><Trash2 className="" /></div>Delete Row
                </button>
            </>
        </div>
    );
}

export default RowMenu;