import { ReactNode, useMemo, useRef, useState } from "react";
import { nanoid } from 'nanoid';
import { createPortal } from "react-dom";


export function usePopup(content: string | ReactNode, xposition: 'left' | 'right' = "right", yposition: 'bottom' | 'top' = "bottom", delay: number = 1000) {
    const id = useMemo(() => nanoid(), [])
    const popoverRef = useRef<HTMLDivElement>(null);
    const component = useMemo(() => createPortal(<div
        ref={popoverRef}
        popover="auto"
        className='bg-primary-500 text-white py-2 px-3 rounded-md m-0 inset-auto absolute'
        style={{
            // @ts-expect-error tsc doesn't know about this style yet
            positionAnchor: `--${id}`,
            ...Y_POSITION[yposition],
            ...X_POSITION[xposition],
        }}
    >
        {content}
    </div>, document.getElementById("root")!), [xposition, yposition, content])
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
    const props = useMemo(() => ({
        onMouseEnter: () => {
            setTimeoutId(setTimeout(() => {
                popoverRef.current?.showPopover();
            }, delay));
        },
        onMouseLeave: () => {
            popoverRef.current?.hidePopover();
            if (timeoutId) {
                clearTimeout(timeoutId);
                setTimeoutId(undefined);
            }
        },
        style: {
            anchorName: `--${id}`
        }
    }), [timeoutId])
    return { props, component }
}

const X_POSITION = {
    right: { left: 'anchor(right)' },
    left: { right: 'anchor(left)' }
} as const;

const Y_POSITION = {
    bottom: { top: 'anchor(bottom)' },
    top: { bottom: 'anchor(top)' }
} as const;

