import classes from './styles.module.css'
import classNames from 'classnames';


type ButtonTabsProps = {
    tab: string,
    tabs: string[],
    onTabSelected?: (text: string) => void
    className?: string
}
export function ButtonTabs(props: ButtonTabsProps) {
    function setTab(index: number) {
        if (props.onTabSelected) props.onTabSelected(props.tabs[index]);
    }

    return (
        <div className={classNames(classes.tabs, props.className)}>
            {props.tabs.map((tab, i) => <div
                key={tab}
                className={classNames(classes.tabbutton, { [classes.selected]: tab === props.tab })}
                onClick={() => setTab(i)}
            >{tab}</div>)
            }
        </div >

    );
}
