import Uploader from '../../Uploader';

interface UploadStageProps {
    preUploadHandler: () => void;
    fileReadHandler: (file: string | ArrayBuffer | null, name: string) => void;
    postUploadHandler: (success: boolean, spec_id: string, group_id: string) => void;
    baseSpec: string;
    showHint?: boolean;
    parentFolder: string;
}

function UploadStage(props: UploadStageProps) {
    return (
        <div className="flex flex-col gap-y-2">
            <div className="border rounded-xl p-8">
                <Uploader
                    preUploadHandler={props.preUploadHandler}
                    fileReadHandler={props.fileReadHandler}
                    postUploadHandler={props.postUploadHandler}
                    baseSpec={props.baseSpec}
                    parentFolder={props.parentFolder}
                />
            </div>
            {props.showHint &&
                <div className="flex flex-col select-none">
                    <span className="text-3xl text-slate-500">Drop specifications here</span>
                    <span className="text-2xl text-slate-500"> or use the "New" button.</span>
                </div>
            }
        </div>
    )
}

export default UploadStage;
