import { useState, useCallback, ReactElement } from 'react';
import UploadStage from './Stages/UploadStage';
import LoaderStage from './Stages/LoaderStage';
import ResultStage from './Stages/ResultStage';
import { SpecificationStatus, TableResponse } from "types/requests";
import './table-extract.css';
import { useNavigate } from 'react-router-dom';
import ErrorStage from "./Stages/ErrorStage";
import { APIResponse, request } from "services/api";
import { ArrowDownRight } from "lucide-react";

interface ExtractProps {
    baseSpec?: string;
    showHint?: boolean;
    parentFolder?: string;
    onJobAccept?: () => void;
}

function NewTableExtract(props: ExtractProps) {
    const navigate = useNavigate();

    const redirect = useCallback((r: string) => {
        navigate(`/specifications/view/${r}`)
        if (window.location.pathname.includes("/specifications/view")) window.location.reload();
    }, [navigate]);

    const [stage, setStage] = useState(0);
    const [diffTableResp, setDiffTableResp] = useState<TableResponse | undefined>(undefined);
    const [delTableResp, setDelTableResp] = useState<TableResponse | undefined>(undefined);
    const [pdf, setPDF] = useState("");
    const [docName, setDocName] = useState("");
    const [id, setId] = useState("");
    const [parent, setParent] = useState("");

    function fileReadHandler(file: string | ArrayBuffer | null, name: string) {
        if (typeof file === "string") {
            setPDF(file);
        }
        setDocName(name);
    }

    function preUploadHandler() {
        setStage(1);
    }

    function postUploadHandler(success: boolean, spec_id: string, group_id: string) {
        if (!success) {
            console.error("Upload failure");
            setStage(-1);
            return;
        }

        if (props.onJobAccept) {
            setTimeout(() => {
                if (props.onJobAccept) props.onJobAccept()
                setStage(0)
                setDocName("")
                setPDF("")
                setId("")
                setParent("")
                setDiffTableResp(undefined)
                setDelTableResp(undefined)

            }, 5000);
        } else {
            setTimeout(() => poll(group_id, spec_id), 5000);
        }
    }

    function poll(group_id: string, spec_id: string, failure_count = 0) {
        request<SpecificationStatus>("GET", `/groups/${group_id}/status/${spec_id}`).subscribe({
            next: (r: APIResponse<SpecificationStatus>) => {
                if (!r.ok || r.statusCode !== 200 || !r.data || r.data.status === "ERROR") {
                    console.error(`Upload failure: ${r.ok}|${r.statusCode}|${!r.data}`);
                    if (r.data && r.data.status === "ERROR") {
                        setStage(-1);
                        return;
                    }
                    if (failure_count >= 3) {
                        setStage(-1);
                        return;
                    }
                    setTimeout(() => poll(group_id, spec_id, failure_count + 1), 6000);
                    return;
                }
                if (r.data.status === "WORKING" || !r.data.specification) {
                    setTimeout(() => poll(group_id, spec_id), 5000);
                    return;
                }

                //TODO validation
                setId(r.data.specification.project_id);
                setParent(r.data.specification.parent_node);
                if (r.data.specification.diffs_table_data) setDiffTableResp(r.data.specification.diffs_table_data);
                if (r.data.specification.deletions_table_data) setDelTableResp(r.data.specification.deletions_table_data);
                redirect(r.data.specification.project_id);
            },
            error: (e) => {
                console.error(e);
                if (failure_count >= 3) {
                    setStage(-1);
                    return;
                }
                setTimeout(() => poll(group_id, spec_id, failure_count + 1), 6000);
            },
        });
    }

    return (
        <>
            <div className="flex place-content-center h-full">
                {stage === -1 && center(<ErrorStage />)}
                {stage === 0 && center(<UploadStage
                    preUploadHandler={preUploadHandler}
                    fileReadHandler={fileReadHandler}
                    postUploadHandler={postUploadHandler}
                    baseSpec={props.baseSpec ?? ""}
                    showHint={props.showHint}
                    parentFolder={props.parentFolder ?? ""} />)}
                {stage === 1 && center(<LoaderStage headline="Processing your document with AI" subtitle="This process may take a while..." />)}
                {stage === 2 && <ResultStage difference_table={diffTableResp} deletion_table={delTableResp} file={pdf} name={docName} specId={id} readonly={false} parent={parent} created={Date.now() / 1000}/>}
            </div>
            {!props.showHint && stage === 1 &&
                <div className="absolute bottom-4 right-4">
                    <div className="flex flex-col items-end">
                        <span className="mr-6 -mb-1 select-none">Your job progress will be reported in the tray below!</span>
                        <ArrowDownRight className="wiggle" />
                    </div>
                </div>
            }
        </>
    )
}

function center(child: ReactElement) {
    return (<div className="flex flex-col gap-y-8 items-center h-full place-content-center">{child}</div>)
}

export default NewTableExtract;
