import {Plus} from "lucide-react";
import React from "react";

interface ChipPickCellProps {
    id: number;
    showEdit: boolean;
    itemSource: string[];
    editItemSource: string[];
    onOpenPicker: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, i: number) => void;
    itemMapper?: (input: string) => string | undefined;
    defaultItemName: string;
    pickerText: string;
}

function ChipPickCell(props: ChipPickCellProps) {
    function mapItemName(input: string): string | undefined {
        if(!props.itemMapper) return input;
        return props.itemMapper(input);
    }

    function getSource() {
        return props.showEdit ? props.editItemSource : props.itemSource;
    }

    return (
        <div className="flex items-center gap-x-2">
            {getSource().length > 0 &&
                <span className="border border-zinc-200 px-2 py-1 rounded-lg select-none">
                    {mapItemName(getSource()[0]) ?? props.defaultItemName}
                    {getSource().length > 1 &&
                        <span className="bg-zinc-100 select-none ml-1">+{getSource().length-1}</span>
                    }
                    </span>
            }
            {props.showEdit &&
                <button className="flex gap-x-2 secondary-button border-0 text-black input-invisible" onClick={(e) => props.onOpenPicker(e, props.id)}>
                    <Plus width={18} /> {props.pickerText}
                </button>
            }
        </div>
    );
}

export default ChipPickCell;