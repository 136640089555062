import React, { useEffect, useRef, useState } from "react";
import 'App.css'
import { FolderInput, Link, Trash2 } from "lucide-react";
import Pencil from "assets/Pencil.svg"
import { APIResponse, request } from "services/api";
import { DetailMessage } from "types/requests";
import LinkDialogContent from "./LinkDialogContent";
import MoveDialogContent from "./MoveDialogContent";

interface MenuProps {
    open: boolean;
    setOpen: (o: boolean) => void;
    handleDelete: () => void;
    handleRename: (id: string, newName: string) => void;
    id: string;
    name: string;
    folder: string;
    position?: number[];
}
// TODO: values besides pure strings
function NodeMenu(props: MenuProps) {
    const dialog = useRef<HTMLDialogElement>(null);
    const [showOptions, setShowOptions] = useState(false);
    const [currentName, setCurrentName] = useState("");
    const [position, setPosition] = useState([0, 0]);
    const [modalType, setModalType] = useState("");
    const [renameDisabled, setRenameDisabled] = useState(false);
    const [renameError, setRenameError] = useState("");
    const [deleteDisabled, setDeleteDisabled] = useState(false);
    const [deleteError, setDeleteError] = useState("");

    useEffect(() => {
        setShowOptions(props.open);
        setCurrentName(props.name)
        if (props.position)
            setPosition(props.position);
    }, [props.open, props.id, props.name, props.position])

    function closeMenu() {
        props.setOpen(false);
        setShowOptions(false);

        dialog.current?.close()
        setModalType("");
        setRenameError("");
        setRenameDisabled(false);
        setDeleteError("");
        setDeleteDisabled(false);
    }

    function delClicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setModalType("delete");
        dialog.current?.showModal();
    }

    function performDelete() {
        setDeleteDisabled(true);
        request<string>("DELETE", `/folders/${props.id}`).subscribe({
            next: (r: APIResponse<string>) => {
                if (!r.ok || r.statusCode !== 200) {
                    setDeleteError(r.data ?? "Failed to delete folder");
                    delClicked(undefined);
                    setDeleteDisabled(false);
                    return;
                }
                props.handleDelete();
                closeMenu();
            },
            error: (e) => {
                console.error(e);
                setDeleteError("Failed to delete folder");
                setDeleteDisabled(false);
            },
        });
    }

    function moveFolderClicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        setModalType("move");
        dialog.current?.showModal();
    }

    function getLinkClicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        setModalType("link");
        dialog.current?.showModal();
    }

    function renameClicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        setModalType("rename");
        dialog.current?.showModal();
    }

    function performRename() {
        setRenameDisabled(true);
        request<DetailMessage>("PUT", `/folders/${props.id}/name/${currentName}`).subscribe({
            next: (r: APIResponse<DetailMessage>) => {
                if (!r.ok || r.statusCode !== 200) {
                    setRenameError(r.data?.detail ?? "Failed to rename folder");
                    setRenameDisabled(false);
                    return;
                }
                props.handleRename(props.id, currentName);
                closeMenu();
            },
            error: (e) => {
                console.error(e);
                setRenameError("Failed to rename folder");
                setRenameDisabled(false);
            },
        });
    }

    function returnToMain(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        dialog.current?.close()
        setDeleteError("");
        setRenameError("");
    }

    //Handle clicks outside menus
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: Event) => {
        if ((e.target as HTMLElement).id === "uploader") return;
        if (dialog.current?.open) {
            if ((e.target as HTMLElement).id === "rename" || showOptions) {
                closeMenu();
            }
        }
        else if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
            closeMenu();
        }
    };

    const menuStyle = {
        "top": position[1],
        "right": window.innerWidth - position[0],
        "border": "1px white solid",
        "backgroundColor": "white",
        "filter": "drop-shadow(1px 1px 1px #ddd)",
        "borderRadius": "6px",
    };

    if (!showOptions) return (<></>);
    return (
        <>
            <dialog id="rename" ref={dialog} className="bg-transparent">
                <div className="bg-white text-zinc-950 p-4 rounded-lg">
                    {modalType === "rename" && <>
                        <h3 className="select-none text-zinc-950 font-bold pb-4">Rename folder</h3>
                        <div className="flex gap-x-4 items-center min-w-[20rem]">
                            <input className="bg-white w-full input border rounded-lg h-9 p-2 font-semibold" value={currentName} onChange={(e) => setCurrentName(e.target.value)}></input>
                        </div>
                        <div className="flex gap-x-4 items-center justify-end pt-4">
                            <span className="pr-2 text-red-500 font-semibold">{renameError}</span>
                            <button onClick={returnToMain} className="bg-zinc-100 text-zinc-950 hover:bg-zinc-200">Cancel</button>
                            <button onClick={performRename} disabled={renameDisabled}>Save</button>
                        </div>
                    </>}
                    {modalType === "delete" && <>
                        <h3 className="select-none text-zinc-950 font-bold pb-4">Delete folder</h3>
                        <span>Are you sure you want to delete {currentName}?<br /> This will delete this folder and all specifications/sub-folders within it.</span>
                        <div className="flex gap-x-4 justify-end pt-4 items-center">
                            <span className="pr-2 text-red-500 font-semibold">{deleteError}</span>
                            <button onClick={returnToMain} className="bg-zinc-100 text-zinc-950 hover:bg-zinc-200">Cancel</button>
                            <button className="bg-red-600" onClick={performDelete} disabled={deleteDisabled}>Delete</button>
                        </div>
                    </>}
                    {modalType === "move" && <MoveDialogContent file_id={props.id} name={props.name} start_dir={props.folder} moveFolder={true} closeMenu={() => window.location.reload()} />}
                    {modalType === "link" && <LinkDialogContent id={props.id} layout={"/specifications/list/%id%"} closeMenu={closeMenu} />}
                </div>
            </dialog>
            <div ref={menuRef} className={"absolute dialog z-10 "} style={menuStyle}>
                <div className="flex flex-col">
                    <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={renameClicked}>
                        <div className="px-2"><img src={Pencil} /></div>Rename Folder
                    </button>
                    <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={delClicked}>
                        <div className="px-2"><Trash2 absoluteStrokeWidth={true} /></div>Delete Folder
                    </button>
                    <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={moveFolderClicked}>
                        <div className="px-2"><FolderInput absoluteStrokeWidth={true} /></div>Move Folder
                    </button>
                    <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={getLinkClicked}>
                        <div className="px-2"><Link absoluteStrokeWidth={true} /></div>Copy Link
                    </button>
                </div>
            </div>
        </>
    )
}

export default NodeMenu;
