import { type CustomCell, type ProvideEditorCallback, type CustomRenderer, GridCellKind } from "@glideapps/glide-data-grid";
import { useState } from "react";
import { Option } from "components/TableExtract/Table/types/types";

interface PDropDownCellProps {
    readonly kind: "pdropdown-cell";
    allowedValues: Option[];
    value: Option;
    cell_id: string; //Workaround for column moves underneath us, all custom cells must use this
}
export type PDropDownCell = CustomCell<PDropDownCellProps>;

const Editor: ReturnType<ProvideEditorCallback<PDropDownCell>> = p => {
    const { value: cell, onFinishedEditing } = p;
    const [searchText, setSearchText] = useState<string>("");

    async function changeValue(c: { tag: string, color: string }) {
        await new Promise(r => window.requestAnimationFrame(r));
        onFinishedEditing({
            ...cell,
            data: {
                ...cell.data,//TODO
                value: c
            },
        });
    }

    function blankOptionOnly() {
        return cell.data.allowedValues.length === 0 ||
            (cell.data.allowedValues.length === 1 &&
                cell.data.allowedValues[0].tag === "" &&
                !cell.data.allowedValues[0].color);
    }

    const searchFilter = ((c: { tag: string; color: string; }) => searchText ? c.tag.toLowerCase().includes(searchText.toLowerCase()) : true);

    return (
        <div className="flex flex-col min-w-[12em] overflow-y-hidden">
            <div className="p-2" >
                <input className="h-8 select-none text-[1.1em] outline-0 bg-transparent" placeholder="Search options" value={searchText} onChange={(e) => setSearchText(e.currentTarget.value)} />
            </div>
            <div className="max-h-60 overflow-scroll">
                <div className="px-2 py-2 hover:bg-neutral-200" onClick={() => changeValue({ tag: "", color: "" })} >
                    <div className="py-[0.1em] px-2 rounded-xl select-none bg-transparent">
                        ‎
                    </div>
                </div>
                {!blankOptionOnly() && cell.data.allowedValues
                    .filter(searchFilter)
                    .map((c) =>
                        <div key={c.tag + c.color} className="px-2 py-2 hover:bg-neutral-200 flex" onClick={() => changeValue(c)} >
                            <div className="px-2 rounded-xl select-none" style={{ backgroundColor: c.color }}>
                                {c.tag}
                            </div>
                        </div>
                    )}
                {cell.data.allowedValues.filter(searchFilter).length === 0 &&
                    <div className="px-2 py-2" >
                        <div className="py-[0.1em] px-2 rounded-xl select-none bg-transparent">
                            No results
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

const renderer: CustomRenderer<PDropDownCell> = {
    kind: GridCellKind.Custom,
    isMatch: (cell: CustomCell): cell is PDropDownCell => (cell.data as PDropDownCellProps).kind === "pdropdown-cell",//TODO check the anyswap worked
    needsHover: false,
    draw: (args, cell) => {
        const { ctx, theme, rect } = args;
        const { value } = cell.data;
        const drawX = rect.x + theme.cellHorizontalPadding;
        const textVerticalCenter = 2;
        const textVerticalPad = 4;
        const textSize = 8;
        const roundOffset = 6;

        //Pill
        ctx.fillStyle = value.color;
        ctx.beginPath();
        ctx.roundRect(drawX - roundOffset, rect.y + rect.height / 2 - textSize + textVerticalCenter - textVerticalPad, ctx.measureText(value.tag).width + roundOffset * 2 + 1, textSize + textVerticalCenter * 2 + textVerticalPad * 2, 16)
        ctx.fill();

        //Text
        ctx.fillStyle = "#222";
        ctx.fillText(value.tag, drawX, rect.y + rect.height / 2 + textVerticalCenter);
        return true;
    },
    provideEditor: () => ({
        editor: Editor,
        disablePadding: true,
        deletedValue: v => ({
            ...v,
            copyData: "",
            data: {
                ...v.data,
                value: { tag: "", color: "#fff" },
            },
        }),
    }),
    onPaste: (val, d) => {
        const vals = val.split("^");
        if (vals.length !== 2 || !d.allowedValues.find(x => x.tag === vals[0] && x.color === vals[1])) return d;
        return {
            ...d, value: {
                tag: vals[0],
                color: vals[1],
            }
        }
    },
};

export default renderer;
