import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { Auth0Provider } from '@auth0/auth0-react';
import { CommentsProvider } from "./components/TableExtract/Table/cells/comments/CommentContext.tsx";


ReactDOM.createRoot(document.getElementById('root')!).render(
    <Auth0Provider
        domain={import.meta.env.VITE_AUTH0_DOMAIN}
        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
        authorizationParams={{
            audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            redirect_uri: window.location.origin
        }}
    >
        <CommentsProvider>
            <App />
        </CommentsProvider>
    </Auth0Provider>
)

