import { ArrowLeft, ArrowRight } from "lucide-react";
import { PropsWithChildren } from "react";

type PaginationProps = {
    totalItemCount: number,
    pageSize: number,
    currentPage: number,
    onPageChange?: (page: number) => void
}

function Pagination({ totalItemCount = 0, pageSize = 1, currentPage = 1, onPageChange }: PaginationProps) {
    const totalPages = Math.ceil(totalItemCount / pageSize);
    if (totalPages === 1) return;

    let pages = [...Array(totalPages).keys()].map(x => x + 1);


    if (pages.length > 7) {
        if (currentPage < 5) {
            pages = [1, 2, 3, 4, 5, -1, ...pages.slice(-1)] // 1,2,3,4,5,...,-1
        } else if (currentPage > 4 && pages.length - currentPage < 3) {
            pages = [1, -1, ...pages.slice(-5)]  // 1,...,-5,-4,-3,-2,-1
        } else {
            pages = [1, -1, ...pages.slice(currentPage - 2, currentPage + 1), -2, ...pages.slice(-1)] // 1,2,3,...,c-1,c,c+1,...,-2,-1
        }
    }

    return (
        <div className="flex gap-1">
            <PaginationButton onClick={() => onPageChange && onPageChange(currentPage - 1)} disabled={currentPage === 1}><ArrowLeft size={14} />Previous</PaginationButton>
            {pages.map(x => (
                x < 0 ? <Spacer key={x} /> :
                    x === currentPage ?
                        <SelectedPaginationButton key={x}>{x}</SelectedPaginationButton> :
                        <PaginationButton key={x} onClick={() => onPageChange && onPageChange(x)} >{x}</PaginationButton>
            ))}
            <PaginationButton onClick={() => onPageChange && onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next<ArrowRight size={14} /></PaginationButton>
        </div>
    );
}

export default Pagination

const PaginationButton = (props: PropsWithChildren<{
    disabled?: boolean,
    onClick: () => void,
}>) => <button onClick={props.onClick} className="flex gap-1 text-zinc-950 clear-button" disabled={props.disabled} style={{ padding: "6.4px 16px" }}> {props.children}</button>

const SelectedPaginationButton = (props: PropsWithChildren<{}>) => <button className="flex gap-1 text-white"> {props.children}</button>

const Spacer = () => <span className="flex gap-1 text-zinc-950 clear-button" style={{ padding: "6.4px 16px" }}>...</span>

