interface TextInputCellProps {
    id: number;
    showEdit: boolean;
    displayValue: string;
    editValue: string;
    error?: string;
    maxLength?: number;
    onChange: (id: number, newValue: string) => void;
}

function TextInputCell(props: TextInputCellProps) {
    return (
        <div className="content-center pr-0 2xl:pr-16">
        {!props.showEdit && <span>{props.displayValue}</span>}
        {props.showEdit &&
            <input
                type="text"
                className={"input bg-white border rounded-lg px-2 h-10 w-full " + (props.error ? " border-red-400 text-xs lg:text-sm xl:text-base" : "")}
                value={props.editValue}
                maxLength={props.maxLength}
                placeholder={props.error}
                onChange={(e) => props.onChange(props.id, e.target.value)}
            />
        }
        </div>
    );
}

export default TextInputCell;