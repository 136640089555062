import 'App.css';
import './Sidebar.css';
import { useEffect, useRef, useState } from "react";
import { BookOpenCheck, LogOut, Settings } from "lucide-react";
import { useNavigate } from 'react-router-dom';

interface SideMenuProps {
    open: boolean;
    setOpen: (o: boolean) => void;
    getInitials: () => string;
    name: string;
    email: string;
    logout: () => void;
}

function SideMenu(props: SideMenuProps) {
    const [showUserDialog, setShowUserDialog] = useState(false);
    const [showSettings] = useState(false);
    const showSettingsRef = useRef(showSettings);
    const navigate = useNavigate();

    useEffect(() => { //Pass through prop updates to open menu
        setShowUserDialog(props.open);
        if (!props.open) closeMenus();
    }, [props.open]);

    // Update the ref whenever the state changes
    useEffect(() => {
        showSettingsRef.current = showSettings;
    }, [showSettings]);

    function closeMenus() {
        setShowUserDialog(false);
        props.setOpen(false);
    }

    function openSettings() {
        navigate("/settings")
    }

    function openKnowledge() {
        navigate("/knowledge")
    }

    const menuStyle = {
        border: "1px white solid",
        backgroundColor: "white",
        filter: "drop-shadow(1px 1px 1px #ddd)",
        borderRadius: "6px",
        zIndex: 100,
    };

    //Handle clicks outside menus
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);

    function handleClickOutside(e: Event) {
        if (showSettingsRef.current) {
            return;
        }
        if (menuRef.current && !menuRef.current.contains(e.target as Node)) closeMenus();
    }

    if (!showUserDialog) return <></>;
    return (
        <>
            <div ref={menuRef} className="absolute dialog bottom-4 left-16" style={menuStyle}>
                <div className="flex gap-x-4 items-center pb-2 mb-2 border-b">
                    <div className="flex mx-auto gap-2">
                        <div className="flex p-2 rounded-full w-8 h-8 bg-primary-500 items-center place-content-center text-white select-none">
                            {props.getInitials()}
                        </div>
                    </div>
                    <div className="flex flex-col self-center">
                        {/* Reversed to account for naming in auth0 */}
                        <span className="text-black font-medium">{props.name.split(" ").reverse().join(" ")}</span>
                        <span className="text-zink-500 text-sm">{props.email}</span>
                    </div>
                </div>

                <button className="clear-button input-invisible hover:bg-zinc-100 right-0 relative p-2 flex w-full" onClick={openSettings}>
                    <div className="items-center flex gap-4">
                        <Settings color="#000" className="h-6 mx-auto" />
                        <span className="text-primary-500 max-h-7 font-semibold">Settings</span>
                    </div>
                </button>
                <button className="clear-button input-invisible hover:bg-zinc-100 right-0 relative p-2 flex w-full" onClick={openKnowledge}>
                    <div className="items-center flex gap-4">
                        <BookOpenCheck color="#000" className="h-6 mx-auto" />
                        <span className="text-primary-500 max-h-7 font-semibold">Knowledge</span>
                    </div>
                </button>
                <button className="clear-button input-invisible hover:bg-zinc-100 right-0 relative -mb-2 p-2 flex w-full" onClick={props.logout}>
                    <div className="items-center flex gap-4">
                        <LogOut color="#000" className="h-6 mx-auto" />
                        <span className="text-primary-500 max-h-7 font-semibold">Sign Out</span>
                    </div>
                </button>
            </div>
        </>
    );
}

export default SideMenu;
