import React, { useEffect, useRef, useState } from "react";
import 'App.css'
import {Edit, Trash2} from "lucide-react";
import DeleteWarning from "components/CrudMenu/DeleteWarning.tsx";

interface ReviewTaskActionsMenuProps {
    open: boolean;
    setOpen: (o: boolean) => void;
    specId: string;
    position?: number[];
    editClicked: (e: React.MouseEvent<HTMLButtonElement>) => void;
    editDisabled: boolean;
    onDelete: () => void;
}

function ReviewTaskActionsMenu(props: ReviewTaskActionsMenuProps) {
    const [showOptions, setShowOptions] = useState(false);
    const [position, setPosition] = useState([0, 0]);
    const [deleteWarnOpen, setDeleteWarnOpen] = useState<boolean>(false);

    useEffect(() => {
        setShowOptions(props.open);
        if (props.position)
            setPosition(props.position);
    }, [props.open, props.specId, props.position])

    function closeMenu() {
        props.setOpen(false);
        setShowOptions(false);
        setDeleteWarnOpen(false);
        props.onDelete();
    }

    function deleteClicked(e: React.MouseEvent<HTMLButtonElement>) {
        e.stopPropagation();
        setDeleteWarnOpen(true);
    }

    //Handle clicks outside menus
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: Event) => {
        if (menuRef.current && menuRef.current !== e.target && !menuRef.current.contains(e.target as Node)) {
            closeMenu();
        }
    };

    const menuStyle = {
        "top": position[1],
        "right": "70px",
        "border": "1px white solid",
        "backgroundColor": "white",
        "filter": "drop-shadow(1px 1px 1px #ddd)",
        "borderRadius": "6px",
    };

    if (!showOptions) return (<></>);
    return (
        <>
            <div ref={menuRef} className={"absolute dialog z-10 "} style={menuStyle}>
                <div className="flex flex-col">
                    <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={props.editClicked} disabled={props.editDisabled}>
                        <div className="px-2"><Edit absoluteStrokeWidth={true} /></div>Edit Review Task
                    </button>
                    <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={deleteClicked}>
                        <div className="px-2"><Trash2 absoluteStrokeWidth={true} /></div>Delete Review Task
                    </button>
                </div>
            </div>
            <DeleteWarning open={deleteWarnOpen} setOpen={setDeleteWarnOpen} deletePath={`/specifications/${props.specId}/work-assignment`} handleDelete={closeMenu} itemType={"review task"}/>
        </>
    )
}

export default ReviewTaskActionsMenu;
