import { useEffect, useRef, useState } from "react";
import 'App.css'
import { Taggable } from "types/requests.ts";
import { X } from "lucide-react";
import Picker from "components/Sidebar/Settings/menus/PickerMenu/PickerMenuContent.tsx";
import { usePopup } from "hooks/Popup";

interface PickerMenuProps {
    open: boolean;
    setOpen: (o: boolean) => void;
    selected: Taggable[];
    taggables: Taggable[];
    handleSelection: (tag: Taggable) => void;
    handleDeselection: (tag: Taggable) => void;
    type: string;
    style: "chip" | "profile";
    position?: number[];
}

function PickerMenu(props: PickerMenuProps) {
    const dialog = useRef<HTMLDialogElement>(null);
    const [showMenu, setShowMenu] = useState(false);
    const [position, setPosition] = useState([0, 0]);
    const cancelPopup = usePopup("Cancel")

    useEffect(() => {
        setShowMenu(props.open);
        if (props.position)
            setPosition(props.position);
    }, [props.open, props.position]);

    function closeMenu() {
        props.setOpen(false);
        setShowMenu(false);
        dialog.current?.close()
    }

    const menuStyle = {
        "top": position[1],
        "left": (position[0] + 6) + "px",
        "backgroundColor": "white",
        "filter": "drop-shadow(3px 3px 3px #ddd)",
        "borderRadius": "6px",
    };

    //Handle clicks outside menus
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: Event) => {
        if (dialog.current) { if ((e.target as HTMLElement).id === "rename") closeMenu() }
        else if (menuRef.current && !menuRef.current.contains(e.target as Node)) closeMenu();
    };

    if (!showMenu) return (<></>);
    return (
        <div ref={menuRef} className={"absolute dialog z-50 p-6 w-[26rem] h-96 select-none"} style={menuStyle}>
            <div className="flex flex-col h-full">
                <div className="flex items-center justify-between py-2">
                    <span className="text-gray-400 text-lg">Link {props.type}s</span>
                    <button className="ghost-button secondary-button border-none text-black p-2" {...cancelPopup.props} onClick={() => props.setOpen(false)}><X width={22} /></button>
                    {cancelPopup.component}
                </div>
                <Picker
                    selected={props.selected}
                    taggables={props.taggables}
                    handleSelection={props.handleSelection}
                    handleDeselection={props.handleDeselection}
                    style={props.style}
                    unselectedTitle={"Link a " + props.type}
                    unselectedPopup={"Link " + props.type}
                    selectedTitle={props.selected.length + " linked " + props.type + (props.selected.length > 1 ? "s" : "")}
                    selectedPopup={"Unlink " + props.type}
                />
            </div>
        </div>
    )
}

export default PickerMenu;
