import '../../../table-extract.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "@glideapps/glide-data-grid/dist/index.css";
import { Plus } from 'lucide-react';
import React, { useRef, useState } from "react";
import { nanoid } from 'nanoid';
import ColourTextItem, { ColourMenu } from "components/TableExtract/Table/menus/colour-menus/ColourItem.tsx";
import { colours, OptionItemData } from "components/TableExtract/Table/menus/colour-menus/common.ts";

function addItem(prevItems: OptionItemData[]) {
    const newItems = [...prevItems];
    newItems.push({
        id: nanoid(),
        color: colours[newItems.length % colours.length],
        label: ""
    });
    return newItems;
}

function removeItem(id: string, prevItems: OptionItemData[]) {
    return prevItems.filter(x => x.id != id);
}

function setColour(id: string, prevItems: OptionItemData[], index: number, colour: string) {
    const newItems = [...prevItems];
    newItems.splice(index, 1, {
        id: id,
        color: colour,
        label: newItems[index].label
    });
    return newItems;
}

function setLabel(id: string, prevItems: OptionItemData[], index: number, label: string) {
    const newItems = [...prevItems];
    newItems.splice(index, 1, {
        id: id,
        color: newItems[index].color,
        label: label
    });
    return newItems;
}

interface OptionItemsProps {
    items: OptionItemData[],
    colours?: string[],
    setItems: React.Dispatch<React.SetStateAction<OptionItemData[]>>,
    openItemLabel: string,
}

function ColourTextOptions(props: OptionItemsProps) {
    const [openColour, setOpenColour] = useState<ColourMenu>({ index: -1, openItemLabel: "" });
    const [selectedOption, setSelectedOption] = useState<string>()
    const modal = useRef<HTMLDialogElement>(null);
    return (
        <div className="my-4 flex flex-col gap-y-2">
            <dialog ref={modal}>
                <div className="bg-white text-zinc-950 p-4 rounded-lg w-96 text-left">
                    <h3 className="select-none text-zinc-950 font-bold pb-4"></h3>
                    <span>Are you sure you want to delete this item? this will remove any use of this item throughout the column</span>
                    <div className="flex gap-x-4 justify-end pt-4 items-center">
                        <button onClick={() => { props.setItems((prev) => removeItem(selectedOption!, prev)); modal.current?.close() }} className="bg-zinc-100 text-zinc-950 hover:bg-zinc-200">Delete</button>
                        <button className="bg-black" onClick={() => modal.current?.close()}>Cancel</button>
                    </div>
                </div>
            </dialog>
            {props.items.map((o, i) =>
                <ColourTextItem
                    option={o}
                    index={i}
                    setLabelHandler={(e) => props.setItems((prev) => setLabel(o.id, prev, i, e))}
                    openItemLabel={props.openItemLabel}
                    open={openColour}
                    clickHandler={setOpenColour}
                    setColour={(i, colour) => props.setItems((prev) => setColour(o.id, prev, i, colour))}
                    removeItem={() => { setSelectedOption(o.id); modal.current?.showModal() }}
                />)}
            <button className="clear-button ghost-button text-zinc-950 flex gap-x-2 font-semibold p-2 w-fit" onClick={() => props.setItems(addItem)}>
                <Plus />
                <span>Add option</span>
            </button>
        </div>
    );
}

export default ColourTextOptions;
