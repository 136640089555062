import { useEffect, useRef } from "react";
import { X } from "lucide-react";
import NewTableExtract from "components/TableExtract/NewTableExtract";
import { usePopup } from "hooks/Popup";

interface ModalProps {
    open: boolean;
    specId?: string;
    parentFolder?: string;
    onModalClose?: () => void;
}

function Modal(props: ModalProps) {
    const dialog = useRef<HTMLDialogElement>(null);
    const cancelPopup = usePopup("Cancel");

    useEffect(() => {
        if (props.open) {
            newSpec();
        }
    }, [props.open]);

    function newSpec() {
        dialog.current?.showModal();
    }

    function closeMenu() {
        dialog.current?.close()
        if (props.onModalClose) props.onModalClose()
    }

    //Handle clicks outside menus
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: MouseEvent) => {
        if (dialog.current === e.target) {
            closeMenu()
        }
    };

    return (
        <dialog id="new" ref={dialog} className="bg-transparent">
            <button className="clear-button ghost-button input-invisible absolute w-8 h-8 right-0 mr-2 mt-2 text-zinc-950" {...cancelPopup.props} onClick={closeMenu}> <X /></button>
            {cancelPopup.component}
            <div className="bg-white text-zinc-950 p-32 rounded-lg">
                <NewTableExtract baseSpec={props.specId} parentFolder={props.parentFolder} onJobAccept={closeMenu} />
            </div>
        </dialog>
    );
}

export default Modal;
