import {
    BaseDrawArgs,
    BaseGridCell,
    type CustomCell,
    type CustomRenderer,
    GridCellKind, measureTextCached,
} from "@glideapps/glide-data-grid";
import { split as splitText } from "canvas-hypertxt";
// @ts-expect-error thanks glide
import { Theme } from "@glideapps/glide-data-grid/dist/ts/common/styles";
import TextareaAutosize from 'react-textarea-autosize';

interface DiffsCellProps {
    readonly kind: "diffs-cell";
    isBold: boolean
    value: string;
}
export type DiffsCell = CustomCell<DiffsCellProps>;

//Glide seems to randomly pick what they want to expose, a good portion of this is rebuilt from their git with some text styling modifications built on top
const renderer: CustomRenderer<DiffsCell> = {
    kind: GridCellKind.Custom,
    isMatch: (cell: CustomCell): cell is DiffsCell => (cell.data as DiffsCellProps).kind === "diffs-cell",
    needsHover: false,
    draw: a => {
        a.ctx.fillStyle = a.theme.textDark
        drawDiffsCell(a, a.cell.data.value, a.cell.data.isBold, a.cell.contentAlign)
    },
    provideEditor: () => ({
        editor: p => {
            const { onChange, value } = p;
            return (
                <TextareaAutosize
                    id="diffs-editor"
                    className="bg-white px-[3px] py-[8.5px]"
                    style={{ fontWeight: value.data.isBold ? "bold" : "" }}
                    autoFocus
                    onFocus={(e) => { const temp_value = e.currentTarget.value; e.currentTarget.value = ""; e.currentTarget.value = temp_value }}
                    value={value.data.value}
                    onChange={e =>
                        onChange({
                            ...value,
                            data: { ...value.data, value: e.target.value },
                        })
                    }
                />
            );
        },
        disablePadding: true,
        deletedValue: v => ({
            ...v,
            copyData: "",
            data: {
                ...v.data,
                value: "",
            },
        }),
    }),
    onPaste: (_val, d) => { return d },
};

function drawDiffsCell(
    args: Pick<BaseDrawArgs, "rect" | "ctx" | "theme">,
    data: string,
    bold: boolean,
    contentAlign?: BaseGridCell["contentAlign"]
): void {
    const { ctx, rect, theme } = args;
    const { x, y, width: w, height: h } = rect;
    const font = bold ? "bold " : "" + theme.baseFontStyle + " " + theme.fontFamily;
    ctx.font = font; // Apply font here to measure text correctly
    const bias = getMiddleCenterBias(ctx, font);

    if (data.length > 0) {
        let changed = false;
        if (contentAlign === "right") {
            // Use right alignment as default for RTL text
            ctx.textAlign = "right";
            changed = true;
        } else if (contentAlign !== undefined && contentAlign !== "left") {
            // Since default is start (=left), only apply if alignment is center or right
            ctx.textAlign = contentAlign;
            changed = true;
        }

        drawMultiLineDiffs(ctx, data, x, y, w, h, bias, theme, bold, contentAlign);

        if (changed) {
            // Reset alignment to default
            ctx.textAlign = "start";
        }
    }
}


// Helper function to parse the value string into segments
export function parseValueSegments(value: string): { text: string; changePrefix: string }[] {
    // if the value == '' return empty array
    if (value === '') {
        return [{ text: '', changePrefix: ' ' }]; // You might adjust the default return value as needed
    }
    try {
        const elements: string[] = JSON.parse(value);
        return elements.map(element => {
            const changePrefix = element.substring(0, 2); // '+ ' for added, '- ' for deleted, '  ' for unchanged
            const text = element.substring(2); // The rest is the text content
            return { text, changePrefix };
        });
    } catch (error) {
        console.error("Error parsing value segments:", error);
        return [];
    }
}

export function splitSegments(ctx: CanvasRenderingContext2D,
    segments: {
        text: string;
        changePrefix: string
    }[],
    fontStyle: string,
    w: number,
    theme: Theme
): string[] {
    let initialSplit: string[] = []
    let split: string[] = []
    for (const segment of segments) {
        initialSplit = [...splitText(ctx, segment.text, fontStyle, w - theme.cellHorizontalPadding * 2, false)];

        // prepend the changePrefix ("+ ", "- ", or "  ") of the initial segment to every sub-segment
        // Iterate over the split segments starting from the second element
        for (let i = 0; i < initialSplit.length; i++) {
            // Prepend the changePrefix of the initial segment to the current segment
            initialSplit[i] = segment.changePrefix + initialSplit[i];

        }

        // Concatenate the processed splits to the main split list
        split = split.concat(initialSplit);
    }
    return split;
}


function drawMultiLineDiffs(
    ctx: CanvasRenderingContext2D,
    data: string,
    x: number,
    y: number,
    w: number,
    h: number,
    bias: number,
    theme: Theme,
    bold: boolean,
    contentAlign?: BaseGridCell["contentAlign"]
) {

    const fontStyle = (bold ? "bold " : "") + theme.baseFontStyle + " " + theme.fontFamily;
    theme.baseFullFont = fontStyle;

    // Parse the value string and split it
    const segments = parseValueSegments(data);
    const split = splitSegments(ctx, segments, fontStyle, w, theme)

    const emHeight = getEmHeight(ctx, fontStyle);
    const lineHeight = theme.lineHeight * emHeight;

    const actualHeight = emHeight + lineHeight * (split.length - 1);
    const mustClip = actualHeight + theme.cellVerticalPadding > h; //TODO how to increase row height dynamically?

    if (mustClip) {
        // well now we have to clip because we might render outside the cell vertically
        ctx.save();
        ctx.rect(x, y, w, h);
        ctx.clip();
    }

    const optimalY = y + h / 2 - actualHeight / 2;
    let drawY = Math.max(y + theme.cellVerticalPadding, optimalY);
    for (const line of split) {
        drawSingleDiffsLine(ctx, line, x, drawY, w, emHeight, bias, theme, contentAlign);
        drawY += lineHeight;
        if (drawY > y + h) break;
    }
    if (mustClip) {
        ctx.restore();
    }

    return { newX: x, newY: drawY };
}

function drawSingleDiffsLine(
    ctx: CanvasRenderingContext2D,
    data: string,
    x: number,
    y: number,
    w: number,
    h: number,
    bias: number,
    theme: Theme,
    contentAlign?: BaseGridCell["contentAlign"]
) {
    ctx.font = theme.baseFullFont;

    // Measure the text width for background
    const textMetrics = ctx.measureText(data.substring(2));
    const textWidth = textMetrics.width;
    let backgroundX = x + theme.cellHorizontalPadding + 0.5; // Default for left alignment
    const backgroundY = y + (h / 2 - textMetrics.actualBoundingBoxAscent / 2) + bias - 3;
    const backgroundHeight = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;
    const padding = 2; // Add some padding to the background

    // Adjust backgroundX for right and center alignment
    if (contentAlign === "right") {
        backgroundX = x + w - (textWidth + theme.cellHorizontalPadding + 0.5);
    } else if (contentAlign === "center") {
        backgroundX = x + (w / 2 - textWidth / 2);
    }

    // Set fillStyle based on the segment prefix for background color
    ctx.fillStyle = data.substring(0, 2) === '+ ' ? 'rgba(0, 255, 0, 0.3)' : // Light green for added
        data.substring(0, 2) === '- ' ? 'rgba(255, 0, 0, 0.3)' : // Light red for deleted
            'rgba(0, 0, 0, 0)'; // Transparent for unchanged

    // Draw the background rectangle with padding
    ctx.fillRect(backgroundX - padding, backgroundY - padding, textWidth + (padding * 2), backgroundHeight + (padding * 2));

    // Reset fillStyle to draw the text
    ctx.fillStyle = theme.textDark; // Use the theme's text color

    // Draw the text over the background
    if (contentAlign === "right") {
        ctx.fillText(data.substring(2), x + w - (theme.cellHorizontalPadding + 0.5), y + h / 2 + bias);
    } else if (contentAlign === "center") {
        ctx.fillText(data.substring(2), x + w / 2, y + h / 2 + bias);
    } else {
        ctx.fillText(data.substring(2), x + theme.cellHorizontalPadding + 0.5, y + h / 2 + bias);
    }
}

export function getEmHeight(ctx: CanvasRenderingContext2D, fontStyle: string): number {
    const textMetrics = measureTextCached("ABCi09jgqpy", ctx, fontStyle); // do not question the magic string
    return textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;
}

const biasCache: { key: string; val: number }[] = [];
export function getMiddleCenterBias(ctx: CanvasRenderingContext2D, font: string): number {
    for (const x of biasCache) {
        if (x.key === font) return x.val;
    }

    const alphabeticMetrics = loadMetric(ctx, "alphabetic");
    const middleMetrics = loadMetric(ctx, "middle");

    const bias =
        -(middleMetrics.actualBoundingBoxDescent - alphabeticMetrics.actualBoundingBoxDescent) +
        alphabeticMetrics.actualBoundingBoxAscent / 2;

    biasCache.push({
        key: font,
        val: bias,
    });

    return bias;
}

function loadMetric(ctx: CanvasRenderingContext2D, baseline: "alphabetic" | "middle") {
    const sample = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    ctx.save();
    ctx.textBaseline = baseline;
    const result = ctx.measureText(sample);

    ctx.restore();

    return result;
}

export default renderer;
