import './App.css'
import logo from '/bowatt.svg'
import {
    RouterProvider,
    createBrowserRouter, useLocation, useNavigationType, matchRoutes, createRoutesFromChildren,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Specifications from "./components/Specifications/Specifications";
import Knowledge from "./components/Knowledge/Knowledge";
import ViewTable from "./components/TableExtract/ViewTable";
import { SidebarLayout } from "./components/Sidebar/SidebarLayout.tsx"
import { Worker } from '@react-pdf-viewer/core';
import {useEffect, useState} from "react";
import Tray from "./components/Tray/Tray.tsx";
import SettingsMenu from "components/Sidebar/Settings/SettingsMenu.tsx";

import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENV,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    tracesSampleRate: 1.0,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
    createBrowserRouter,
);

const routes = sentryCreateBrowserRouter([
    {
        path: "/",
        element: <SidebarLayout />,
        children: [
            {
                path: "",
                element: <Specifications />
            },
            {
                path: "knowledge",
                element: <Knowledge />
            },
            {
                path: "settings",
                element: <SettingsMenu />
            },
            {
                path: "specifications",
                children: [
                    {
                        path: "view/:id",
                        element: <ViewTable />
                    },
                    {
                        path: "list",
                        element: <Specifications />
                    },
                    {
                        path: "list/:id",
                        element: <Specifications />
                    },

                ]
            }

        ]
    },
])

function App() {
    const { isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const [jwtStored, setJwtStored] = useState<boolean>(false);
    const [redirHandled, setRedirHandled] = useState<boolean>(false);

    if (!isLoading && !isAuthenticated) {
        loginWithRedirect({
            appState: {
                returnTo: window.location.pathname + window.location.search
            }
        })
    }


    if (!isLoading && isAuthenticated) {
        const token = getAccessTokenSilently();
        token.then(t => {
            sessionStorage.setItem("jwt", t)
            setJwtStored(true);
        })
    }

    if (isLoading || !isAuthenticated || !jwtStored) return (
        <div className="flex flex-col h-full w-full items-center place-content-center gap-y-8">
            <img src={logo} className="w-64" />
            <div className="loader self">
                <div className="loaderBar"></div>
            </div>
        </div>
    )

    if (!redirHandled) {
        setRedirHandled(true);
        window.location.replace(window.location.toString() + "#");
        window.location.replace(window.location.toString().replace("##", "#"));
    }
    return (
        <>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <Tray />
                <RouterProvider router={routes} />
            </Worker>
        </>
    )
}

export default App;
