import { Languages } from "lucide-react";
import { useEffect, useState } from "react";
import { AITranslateCell, AITranslateRequest, TableResponse } from "types/requests";
import { GEN_TYPE_TRANSLATION } from "types/collab";

interface TranslateMenuContentProps {
    table: TableResponse;
    onNameChanged: (name: string) => void;
    onLanguageChanged: (lang: string) => void;
    onTargetColChanged: (col: string) => void;
    columnDefaultName?: string;
    disableColSelector?: boolean;
    selectedColumn?: string;
}

function replacer(_key: string, value: Map<string, AITranslateCell>) {
    if (value instanceof Map) {
        return Object.fromEntries(value.entries());
    } else {
        return value;
    }
}

export function createTranslateCol(table: TableResponse, colName: string, translateCol: string, language: string, onAddGenCol: (index: number, name: string, type: string, request: string) => void) {
    const sourceColIndex = table.columns.map(c => c.name).indexOf(translateCol);
    const payload: AITranslateRequest = {
        target_language: language
    };
    onAddGenCol(sourceColIndex + 1, colName, GEN_TYPE_TRANSLATION, JSON.stringify(payload, replacer));
}

function TranslateMenuContent(props: TranslateMenuContentProps) {
    const [menuCurrentName, setMenuCurrentName] = useState("");

    const [translateCol, setTranslateCol] = useState<string>(props.table.columns[0].name);
    const [language, setLanguage] = useState<string>("German");

    useEffect(() => {
        if (props.columnDefaultName) {
            setMenuCurrentName(props.columnDefaultName);
        }
    }, [props.columnDefaultName]);

    useEffect(() => {
        if(props.selectedColumn) setTranslateCol(props.selectedColumn);
    }, [props.selectedColumn]);

    function nameSet(name: string) {
        setMenuCurrentName(name);
        props.onNameChanged(name);
    }

    function targetSet(col: string) {
        setTranslateCol(col);
        props.onTargetColChanged(col);
    }

    function languageSet(newLang: string) {
        setLanguage(newLang);
        props.onLanguageChanged(newLang);
    }

    function isStringCol(i: number) {
        if (
            i < 0 ||
            props.table.table.length === 0 ||
            props.table.table[0].values.length <= i ||
            props.table.table[0].values[i].length === 0
        ) return false;
        switch (props.table.table[0].values[i][0].type) {
            case "string":
            case "text":
            case "image":
            case "translation":
            case "deviation":
                return true;
        }

        return false;
    }

    function getAllowedCols() {
        if (props.table.table.length === 0) return [];
        return props.table.columns.filter((_c, i) => isStringCol(i));
    }

    return <>
        <div className="flex gap-x-4 items-center py-4">
            <Languages className="text-accent" strokeWidth={1} absoluteStrokeWidth={true} />
            <input className="bg-white input border rounded-lg h-9 p-2 font-semibold w-full"
                autoFocus
                value={menuCurrentName}
                onChange={(e) => nameSet(e.target.value)} />
        </div>
        <div className="pt-4 flex flex-col">
            <span className="pb-1 text-zinc-400">Column to translate:</span>
            <select className="bg-white input p-2 rounded-lg border" disabled={props.disableColSelector}
                value={translateCol}
                onChange={e => targetSet(e.target.value)}>
                {getAllowedCols().map(col => <option>{col.name}</option>)}
            </select>
        </div>
        <div className="pt-4 pb-2 flex flex-col">
            <span className="pb-1 text-zinc-400">Translate to:</span>
            <select className="bg-white input p-2 rounded-lg border"
                value={language}
                onChange={e => languageSet(e.target.value)}>
                <option>German</option>
                <option>English</option>
            </select>
        </div>
    </>
}

export default TranslateMenuContent;
