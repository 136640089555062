import { AlertTriangle, FileClock, FileType, Folder, MoreVertical, Plus, PlusSquare } from "lucide-react";
import { APIResponse, request, bodyRequest } from "services/api";
import { Specification, Folder as folderType, FolderListResponse, Breadcrumb } from "types/requests";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoaderStage from "components/TableExtract/Stages/LoaderStage";
import SpecificationContextMenu from "./menus/SpecificationContextMenu";
import NewTableExtract from "components/TableExtract/NewTableExtract";
import { AssignmentStatus, getStatusAttributes } from 'components/NotifyDepartmentsSidebar/NotifyDepartmentsSidebar';
import NewNodeMenu from "./menus/NewNodeMenu";
import NodeMenu from "./menus/NodeMenu";
import BreadcrumbMenu from "./BreadcrumbViewer";
import ErrorStage from "components/TableExtract/Stages/ErrorStage";
import SpecUploadModal from "./menus/SpecUploadModal";
import {usePopup} from "hooks/Popup.tsx";


function TableExtract() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [noSuchPage, setNoSuchPage] = useState<boolean>(false);
    const [loadingFailed, setLoadingFailed] = useState<boolean>(false);
    const [folders, setFolders] = useState<folderType[]>([]);
    const [specs, setSpecs] = useState<Specification[]>([]);
    const [showOptions, setShowOptions] = useState(false);
    const [showNodeMenu, setShowNodeMenu] = useState(false);
    const [showNewNodeMenu, setShowNewNodeMenu] = useState(false);
    const [optionsItem, setOptionsItem] = useState<Specification>();
    const [nodeItem, setNodeItem] = useState<folderType>();
    const [contextPosition, setContextPosition] = useState([0, 0]);
    const [nodePosition, setNodePosition] = useState([0, 0]);
    const [newNodePosition, setNewNodePosition] = useState([0, 0]);
    const [breadcrumb, setBreadcrumb] = useState<Breadcrumb[]>([{ name: "Home", id: "" }]);
    const [workAssignmentStatuses, setWorkAssignmentStatuses] = useState<{ status: AssignmentStatus, spec_id: string }[]>([]);
    const [showSpecUpload, setShowSpecUpload] = useState(false);

    const handleViewSpec = useCallback((id: string, status: string) => {
        if (status === "WORKING") return;
        navigate(`/specifications/view/${id}`)
    }, [navigate]);
    const handleViewFolder = useCallback((id: string) => {
        navigate(`/specifications/list/${id}`)
    }, [navigate]);

    useEffect(() => {
        setLoading(true);
        request<FolderListResponse>("GET", `/folders${!id ? "" : `/${id}`}`).subscribe({
            next: (r: APIResponse<FolderListResponse>) => {
                if (!r.ok || r.statusCode !== 200 || !r.data) {
                    if (r.statusCode === 404) {
                        setNoSuchPage(true);
                    }
                    setLoadingFailed(true);
                    setLoading(false);
                    return;
                } //TODO logs
                for (let i = 0; i < r.data.specs.length; i++) {
                    const createdDate = new Date(0);
                    //@ts-expect-error hacky workaround
                    createdDate.setUTCSeconds(r.data.specs[i].created);
                    r.data.specs[i].created = createdDate;
                }
                setSpecs(r.data.specs);
                for (let i = 0; i < r.data.folders.length; i++) {
                    const createdDate = new Date(0);
                    //@ts-expect-error hacky workaround
                    createdDate.setUTCSeconds(r.data.folders[i].created);
                    r.data.folders[i].created = createdDate;
                }
                setFolders(r.data.folders);
                setBreadcrumb(r.data.crumb);
                setLoading(false);
            },
            error: (e) => {
                console.error(e);
                setLoadingFailed(true);
                setLoading(false);
                //TODO proper error
            },
        });
    }, [id]);

    useEffect(() => {
        if (specs.length === 0) return;
        let specIds = specs.map(s => s.project_id);
        bodyRequest<string[], { status: AssignmentStatus, spec_id: string }[]>("POST", "/specifications/work-assignments/statuses", specIds).subscribe({
            next: (r: APIResponse<{ status: AssignmentStatus, spec_id: string }[]>) => {
                if (!r.ok || r.statusCode !== 200 || !r.data) {
                    console.error("Failed to fetch data");
                    return;
                }

                const isOrderCorrect = r.data.every((item, index) => item.spec_id === specIds[index]);

                if (!isOrderCorrect) {
                    console.error("Order of spec_ids and statuses does not match");
                    return;
                }

                setWorkAssignmentStatuses(r.data);
            },
            error: (e) => {
                console.error(e);
            },
        });
    }
        , [specs]);


    function openOptions(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, specification: Specification) {
        if (specification.status === "WORKING") return;
        setContextPosition([e.pageX, e.pageY]);
        setOptionsItem(specification);
        setShowOptions(true);
    }

    function openNewNodeMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setNewNodePosition([e.currentTarget.offsetLeft, e.currentTarget.offsetTop + e.currentTarget.clientHeight]);
        setShowNewNodeMenu(true);
    }

    function openNodeMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, nodeId: folderType) {
        setNodePosition([e.pageX, e.pageY]);
        setNodeItem(nodeId);
        setShowNodeMenu(true);
    }

    if (loading) return (
        <div className="flex flex-col h-full p-4">
            <PageHead openNew={openNewNodeMenu} />
            <LoaderStage headline={"Loading specifications"} subtitle={"This may take a moment..."} hideSparkle={true} />
        </div>
    )
    if (loadingFailed) return (
        <div className="flex flex-col h-full p-4">
            <PageHead openNew={openNewNodeMenu} />
            <ErrorStage error={noSuchPage?"Folder not found":undefined} hideSubtitle={noSuchPage} />
        </div>
    )

    return (
        <div className="flex flex-col p-4 h-full">
            <PageHead openNew={openNewNodeMenu} />
            <div className="px-4"><BreadcrumbMenu breadcrumb={breadcrumb} onFolderClicked={handleViewFolder} /></div>
            <div className="flex flex-col gap-y-4 p-4 h-full overflow-auto">
                {(folders.length > 0 || specs.length > 0) &&
                    <>
                        <table className="w-full bg-white border-zinc-200">
                            <thead>
                                <tr>
                                    <th className="text-zinc-500">Name</th>
                                    <th>Status</th>
                                    <th>Owner</th>
                                    <th>Date</th>
                                    <th className="w-8">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {folders.map((f) =>
                                    <FolderRow key={f.node_id} folder={f} optionsClick={openNodeMenu} handleViewFolder={handleViewFolder}/>
                                )}
                                {specs.map((s, i) =>
                                    <SpecRow key={s.project_id} spec={s} optionsClick={openOptions}  handleViewSpec={handleViewSpec} index={i} specCount={specs.length} workAssignmentStatuses={workAssignmentStatuses}/>
                                )}
                            </tbody>
                        </table>
                    </>
                }
                {specs.length === 0 && folders.length === 0 && !id &&
                    <div className="bg-white text-zinc-950 h-full px-32 pb-32 rounded-lg">
                        <div className="flex place-content-center h-full flex-col items-center text-slate-500 mx-auto max-w-[32rem]">
                            <PlusSquare size={100} strokeWidth={0.4} />
                            <span className="text-2xl font-normal select-none">Use the "New" button to add folders or upload specifications.</span>
                        </div>
                    </div>
                }
                {specs.length === 0 && folders.length === 0 && id &&
                    <div className="bg-white text-zinc-950 h-full px-32 pb-32 rounded-lg">
                        <NewTableExtract showHint={true} parentFolder={id} />
                    </div>
                }
            </div>
            <SpecUploadModal open={showSpecUpload} parentFolder={id ?? ""} onModalClose={() => setShowSpecUpload(false)} />
            <NodeMenu open={showNodeMenu} id={nodeItem?.node_id ?? ""} setOpen={setShowNodeMenu} name={nodeItem?.name ?? ""} folder={nodeItem?.parent_id ?? ""} handleDelete={() => window.location.reload()} handleRename={() => window.location.reload()} position={nodePosition} />
            <SpecificationContextMenu open={showOptions} id={optionsItem?.project_id ?? ""} folder={optionsItem?.parent_node ?? ""} jobStatus={optionsItem?.status ?? "READY"} setOpen={setShowOptions} name={optionsItem?.name ?? ""} handleDelete={() => window.location.reload()} handleRename={() => window.location.reload()} position={contextPosition} readonly={false} folderView={true} />
            <NewNodeMenu open={showNewNodeMenu} id={id ?? ""} setOpen={setShowNewNodeMenu} position={newNodePosition} openSpecUploadModal={() => setShowSpecUpload(true)} />
        </div>
    )
}

interface FolderRowProps {
    key: string;
    folder: folderType;
    optionsClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, nodeId: folderType) => void;
    handleViewFolder: (folderId: string) => void;
}

function FolderRow(props: FolderRowProps) {
    const moreOptionsPopup = usePopup("More Options", "left");

    return (
        <tr key={props.key} className="hover:bg-zinc-100">
            <td className="cursor-pointer flex gap-x-2" onClick={() => props.handleViewFolder(props.folder.node_id)}><Folder />{props.folder.name}</td>
            <td className="cursor-pointer" onClick={() => props.handleViewFolder(props.folder.node_id)}></td>
            <td className="cursor-pointer" onClick={() => props.handleViewFolder(props.folder.node_id)}>{props.folder.owner}</td>
            <td className="cursor-pointer" onClick={() => props.handleViewFolder(props.folder.node_id)}>{props.folder.created.toLocaleDateString()}</td>
            <td className="flex">
                <button className="clear-button mx-auto" {...moreOptionsPopup.props} onClick={(e) => props.optionsClick(e, props.folder)}>
                    <MoreVertical color="black" />
                </button>
            </td>
            {moreOptionsPopup.component}
        </tr>
    );
}

interface SpecRowProps {
    key: string;
    spec: Specification;
    specCount: number;
    optionsClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, nodeId: Specification) => void;
    handleViewSpec: (specId: string, specStatus: string) => void;
    workAssignmentStatuses: { status: AssignmentStatus, spec_id: string }[];
    index: number;
}

function SpecRow(props: SpecRowProps) {
    const moreOptionsPopup = usePopup("More Options", "left");

    return (
        <tr key={props.key} className="hover:bg-zinc-100">
            <td className={`${(props.spec.status !== "WORKING") ? "cursor-pointer" : "cursor-not-allowed text-zinc-400"} flex gap-x-2`} onClick={() => props.handleViewSpec(props.spec.project_id, props.spec.status)}>
                {props.spec.status === "ERROR" && <AlertTriangle />}
                {props.spec.status == "WORKING" && <FileClock />}
                {(props.spec.status === "" || props.spec.status === "READY") && <FileType />}
                {props.spec.name}
            </td>
            <td className={`${(props.spec.status !== "WORKING") ? "cursor-pointer" : "cursor-not-allowed text-zinc-400"}`} onClick={() => props.handleViewSpec(props.spec.project_id, props.spec.status)}>
                {props.workAssignmentStatuses.length === props.specCount ?
                    <span className={`px-2 py-1 rounded-lg border ${getStatusAttributes(props.workAssignmentStatuses[props.index].status).borderColor} ${getStatusAttributes(props.workAssignmentStatuses[props.index].status).bgColorLight} ${getStatusAttributes(props.workAssignmentStatuses[props.index].status).textColor}`}>
                        {props.workAssignmentStatuses[props.index].status}
                    </span>
                :
                    <span className="text-gray-500">Loading</span>
                }
            </td>
            <td className={(props.spec.status !== "WORKING") ? "cursor-pointer" : "cursor-not-allowed text-zinc-400"} onClick={() => props.handleViewSpec(props.spec.project_id, props.spec.status)}>{props.spec.owner}</td>
            <td className={(props.spec.status !== "WORKING") ? "cursor-pointer" : "cursor-not-allowed text-zinc-400"} onClick={() => props.handleViewSpec(props.spec.project_id, props.spec.status)}>{props.spec.created.toLocaleDateString()}</td>
            <td className="flex">
                <button className="clear-button mx-auto disabled:bg-transparent input-invisible text-black disabled:text-zinc-400 disabled:cursor-not-allowed"
                        {...moreOptionsPopup.props}
                        disabled={(props.spec.status === "WORKING")}
                        onClick={(e) =>props.optionsClick(e, props.spec)}>
                    <MoreVertical />
                </button>
            </td>
            {moreOptionsPopup.component}
        </tr>
    );
}

interface PageHeadProps {
    openNew: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function PageHead(props: PageHeadProps) {
    return (
        <div className="flex justify-between p-4">
            <span className="text-xl font-medium">All Specifications</span>
            <button className="flex" onClick={props.openNew}>
                <div className="pr-2"><Plus /></div>New
            </button>
        </div>
    );
}

export default TableExtract;
