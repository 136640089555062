import { useEffect, useRef, useState } from "react";
import 'App.css'
import { Taggable } from "types/requests";

interface MenuProps {
    open: boolean;
    setOpen: (o: boolean) => void;
    taggables: Taggable[];
    handleSelection: (tag: Taggable) => void;
    position?: number[];
}
// TODO: values besides pure strings
function TagMenu(props: MenuProps) {
    const dialog = useRef<HTMLDialogElement>(null);
    const [showMenu, setShowMenu] = useState(false);
    const [position, setPosition] = useState([0, 0]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        setShowMenu(props.open);
        if (props.position)
            setPosition(props.position);
    }, [props.open, props.position])

    function closeMenu() {
        props.setOpen(false);
        setShowMenu(false);
        dialog.current?.close()
    }

    const menuStyle = {
        "top": position[1],
        "left": (position[0] + 6) + "px",
        "border": "1px #9333EA solid",
        "backgroundColor": "white",
        "filter": "drop-shadow(1px 1px 1px #ddd)",
        "borderRadius": "6px",
    };

    //Handle clicks outside menus
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: Event) => {
        if (dialog.current) { if ((e.target as HTMLElement).id === "rename") closeMenu() }
        else if (menuRef.current && !menuRef.current.contains(e.target as Node)) closeMenu();
    };

    if (!showMenu) return (<></>);
    const searchResults = props.taggables.filter(u => u.name.toLowerCase().includes(search.toLowerCase()) || u.abbreviation?.toLowerCase().includes(search.toLowerCase()));
    return (
        <>
            <div ref={menuRef} className={"absolute dialog z-50 p-2 w-[17.25rem] "} style={menuStyle}>
                <input
                    className="w-full bg-white border rounded-lg p-2 mb-2"
                    placeholder="Search"
                    autoFocus
                    value={search}
                    onChange={(e) => setSearch(e.target.value.toLowerCase())}
                />
                <div className="flex flex-col w-full max-h-32 overflow-y-auto">
                    {(search ? searchResults : props.taggables).map(u =>
                        <button key={u.name} className="flex gap-x-2 py-2 clear-button hover:bg-neutral-100 input-invisible text-black items-center" onClick={() => props.handleSelection(u)}>
                            <div className="flex p-2 rounded-full w-8 h-8 items-center place-content-center text-white select-none" style={{ backgroundColor: u.colour }}>
                                {u.abbreviation ? u.abbreviation.toUpperCase() : u.name.charAt(0).toUpperCase()}
                            </div>
                            {u.name}
                        </button>
                    )}
                    {!searchResults &&
                        <div className="py-2">No results</div>
                    }
                </div>
            </div >
        </>
    )
}

export default TagMenu;
