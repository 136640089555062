import React, { useEffect, useRef, useState } from "react";
import 'App.css'
import { Column, TableResponse } from "types/requests";
import { getLucideIconByType } from "../types/types";

interface MenuProps {
    open: boolean;
    setOpen: (o: boolean) => void;
    position?: number[];
    cols: Column[];
    onHideChange: React.Dispatch<React.SetStateAction<string[]>>
    hiddenCols: string[];
    table: TableResponse;
}

function HideMenu(props: MenuProps) {
    const dialog = useRef<HTMLDialogElement>(null);
    const [showOptions, setShowOptions] = useState(false);
    const [position, setPosition] = useState([0, 0]);

    useEffect(() => {
        setShowOptions(props.open);
        if (props.position)
            setPosition(props.position);
    }, [props.open, props.position])

    function closeMenu() {
        props.setOpen(false);
        setShowOptions(false);

        dialog.current?.close()
    }

    function handleCheck(name: string) {
        if (props.hiddenCols.includes(name)) {
            props.onHideChange((prev) => {
                const newItems = [...prev];
                newItems.splice(props.hiddenCols.indexOf(name), 1);
                return newItems;
            })
        } else {
            props.onHideChange([...props.hiddenCols, name])
        }
    }

    function handleShowAll() {
        props.onHideChange([]);
    }
    function handleHideAll() {
        props.onHideChange(props.cols.map(o => o.name));
    }

    //Handle clicks outside menus
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: Event) => {
        if (menuRef.current && !menuRef.current.contains(e.target as Node)) closeMenu();
    };

    if (!showOptions) return (<></>);
    else return (
        <>
            <div ref={menuRef} className={"absolute dialog z-10 "} style={{ top: position[1], right: window.innerWidth - position[0] }}>
                <div className="flex flex-col">
                    {props.cols.map((o, i) =>
                        <div key={o.name} className="flex gap-x-2 p-2">
                            <input type="checkbox" id={o.name+"_"} className="checkbox" checked={!props.hiddenCols.includes(o.name)} onChange={() => handleCheck(o.name)} />
                            <label htmlFor={o.name+"_"} className="switch"></label>
                            <div className="ml-2">
                                {getLucideIconByType(props.table.table[0].values[i][0].type)}
                            </div>
                            {o.name}
                        </div>
                    )}
                    <div className="flex gap-x-4 border-t border-zinc-200 pt-2">
                        <button className="secondary-button text-zinc-950 font-medium" onClick={handleHideAll}>Hide all</button>
                        <button className="secondary-button text-zinc-950 font-medium" onClick={handleShowAll}>Show all</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HideMenu;
