import React, { useEffect, useRef, useState } from "react";
import 'App.css'
import { Trash2 } from "lucide-react";
import Pencil from "assets/Pencil.svg"
import DeleteWarning from "components/CrudMenu/DeleteWarning.tsx";

interface CrudMenuProps {
    open: boolean;
    setOpen: (o: boolean) => void;
    deletePath: string;
    handleDelete: () => void;
    handleEdit: (id: string) => void;
    id: string;
    itemType: string;
    position?: number[];
}

function CrudMenu(props: CrudMenuProps) {
    const [deleteWarnOpen, setDeleteWarnOpen] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [position, setPosition] = useState([0, 0]);

    useEffect(() => {
        setShowOptions(props.open);
        if (props.position)
            setPosition(props.position);
    }, [props.open, props.id, props.position])

    function closeMenu() {
        props.setOpen(false);
        setShowOptions(false);
    }

    function delClicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setDeleteWarnOpen(true);
    }

    function editClicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        props.handleEdit(props.id);
        closeMenu();
    }

    //Handle clicks outside menus
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: Event) => {
        if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
            closeMenu();
        }
    };

    const menuStyle = {
        "top": position[1],
        "right": window.innerWidth - position[0],
        "border": "1px white solid",
        "backgroundColor": "white",
        "filter": "drop-shadow(1px 1px 1px #ddd)",
        "borderRadius": "6px",
    };

    if (!showOptions) return (<></>);
    return (
        <>
            <DeleteWarning open={deleteWarnOpen} setOpen={setDeleteWarnOpen} deletePath={props.deletePath} handleDelete={props.handleDelete} itemType={props.itemType}/>
            <div ref={menuRef} className={"absolute dialog z-10 "} style={menuStyle}>
                <div className="flex flex-col">
                    <button className="flex clear-button input-invisible text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={editClicked}>
                        <div className="px-2"><img src={Pencil} /></div>Edit {props.itemType}
                    </button>
                    <button className="flex clear-button input-invisible text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={delClicked}>
                        <div className="px-2"><Trash2 absoluteStrokeWidth={true} /></div>Delete {props.itemType}
                    </button>
                </div>
            </div>
        </>
    )
}

export default CrudMenu;
