import {
    BaseDrawArgs,
    BaseGridCell,
    type CustomCell,
    type CustomRenderer,
    GridCellKind, measureTextCached, ProvideEditorCallback,
} from "@glideapps/glide-data-grid";
import { split as splitText } from "canvas-hypertxt";
// @ts-expect-error thanks glide
import { Theme } from "@glideapps/glide-data-grid/dist/ts/common/styles";
import { useEffect } from "react";
import TextareaAutosize from 'react-textarea-autosize';

interface FormatTextCellProps {
    readonly kind: "format-text-cell";
    isBold: boolean;
    textColour?: string;
    value: string;
    cell_id: string; //Workaround for column moves underneath us, all custom cells must use this
}
export type FormatTextCell = CustomCell<FormatTextCellProps>;

const Editor: ReturnType<ProvideEditorCallback<FormatTextCell>> = p => {
    const { onChange, value, initialValue } = p;
    const taValue = initialValue ? initialValue : value.data.value;

    useEffect(() => {
        if (initialValue !== undefined) {
            onChange({
                ...value,
                data: { ...value.data, value: initialValue },
            });
        }
    }, []);

    return (
        <TextareaAutosize
            id="format-text-editor"
            className="bg-white px-[3px] py-[8.5px]"
            style={{ fontWeight: value.data.isBold ? "bold" : "", color: value.data.textColour ?? "#000" }}
            autoFocus
            onFocus={(e) => { const temp_value = e.currentTarget.value; e.currentTarget.value = ""; e.currentTarget.value = temp_value }}
            value={initialValue ?? value.data.value}
            onInput={e => {
                if (taValue === (e.target as HTMLTextAreaElement).value) return
                onChange({
                    ...value,
                    data: { ...value.data, value: (e.target as HTMLTextAreaElement).value },
                });
            }}
        />
    );
}

//Glide seems to randomly pick what they want to expose, a good portion of this is rebuilt from their git with some text styling modifications built on top
const renderer: CustomRenderer<FormatTextCell> = {
    kind: GridCellKind.Custom,
    isMatch: (cell: CustomCell): cell is FormatTextCell => (cell.data as FormatTextCellProps).kind === "format-text-cell",
    needsHover: false,
    draw: a => {
        a.ctx.fillStyle = a.theme.textDark
        drawTextCell(a, a.cell.data.value, a.cell.data.isBold, a.cell.data.textColour, a.cell.contentAlign, true)
    },
    provideEditor: () => ({
        editor: Editor,
        disablePadding: true,
        deletedValue: v => ({
            ...v,
            copyData: "",
            data: {
                ...v.data,
                value: "",
            },
        }),
    }),
    onPaste: (v, target) => {
        return {
            ...target,
            value: v,
        };
    },
};

function drawTextCell(
    args: Pick<BaseDrawArgs, "rect" | "ctx" | "theme">,
    data: string,
    bold: boolean,
    textColour: string | undefined,
    contentAlign?: BaseGridCell["contentAlign"],
    hyperWrapping?: boolean
): void {
    const { ctx, rect, theme } = args;

    const { x, y, width: w, height: h } = rect;

    const font = bold ? "bold " : "" + args.theme.baseFontStyle + " " + args.theme.fontFamily;

    const bias = getMiddleCenterBias(ctx, font);

    if (data.length > 0) {
        let changed = false;
        if (contentAlign === "right") {
            // Use right alignment as default for RTL text
            ctx.textAlign = "right";
            changed = true;
        } else if (contentAlign !== undefined && contentAlign !== "left") {
            // Since default is start (=left), only apply if alignment is center or right
            ctx.textAlign = contentAlign;
            changed = true;
        }

        drawMultiLineText(ctx, data, x, y, w, h, bias, theme, bold, textColour, contentAlign, hyperWrapping);

        if (changed) {
            // Reset alignment to default
            ctx.textAlign = "start";
        }
    }
}

function drawMultiLineText(
    ctx: CanvasRenderingContext2D,
    data: string,
    x: number,
    y: number,
    w: number,
    h: number,
    bias: number,
    theme: Theme,
    bold: boolean,
    textColour: string | undefined,
    contentAlign?: BaseGridCell["contentAlign"],
    hyperWrapping?: boolean
) {
    const fontStyle = (bold ? "bold " : "") + theme.baseFontStyle + " " + theme.fontFamily;
    theme.baseFullFont = fontStyle;
    const baseColour = ctx.fillStyle;
    if (textColour) ctx.fillStyle = textColour
    const split = splitText(ctx, data, fontStyle, w - theme.cellHorizontalPadding * 2, hyperWrapping ?? false);

    const emHeight = getEmHeight(ctx, fontStyle);
    const lineHeight = theme.lineHeight * emHeight;

    const actualHeight = emHeight + lineHeight * (split.length - 1);
    const mustClip = actualHeight + theme.cellVerticalPadding > h;

    if (mustClip) {
        // well now we have to clip because we might render outside the cell vertically
        ctx.save();
        ctx.rect(x, y, w, h);
        ctx.clip();
    }

    const optimalY = y + h / 2 - actualHeight / 2;
    let drawY = Math.max(y + theme.cellVerticalPadding, optimalY);
    for (const line of split) {
        drawSingleTextLine(ctx, line, x, drawY, w, emHeight, bias, theme, contentAlign);
        drawY += lineHeight;
        if (drawY > y + h) break;
    }

    ctx.fillStyle = baseColour

    if (mustClip) {
        ctx.restore();
    }
}

function drawSingleTextLine(
    ctx: CanvasRenderingContext2D,
    data: string,
    x: number,
    y: number,
    w: number,
    h: number,
    bias: number,
    theme: Theme,
    contentAlign?: BaseGridCell["contentAlign"]
) {
    ctx.font = theme.baseFullFont;
    if (contentAlign === "right") {
        ctx.fillText(data, x + w - (theme.cellHorizontalPadding + 0.5), y + h / 2 + bias);
    } else if (contentAlign === "center") {
        ctx.fillText(data, x + w / 2, y + h / 2 + bias);
    } else {
        ctx.fillText(data, x + theme.cellHorizontalPadding + 0.5, y + h / 2 + bias);
    }
}

export function getEmHeight(ctx: CanvasRenderingContext2D, fontStyle: string): number {
    const textMetrics = measureTextCached("ABCi09jgqpy", ctx, fontStyle); // do not question the magic string
    return textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;
}

const biasCache: { key: string; val: number }[] = [];
export function getMiddleCenterBias(ctx: CanvasRenderingContext2D, font: string): number {
    for (const x of biasCache) {
        if (x.key === font) return x.val;
    }

    const alphabeticMetrics = loadMetric(ctx, "alphabetic");
    const middleMetrics = loadMetric(ctx, "middle");

    const bias =
        -(middleMetrics.actualBoundingBoxDescent - alphabeticMetrics.actualBoundingBoxDescent) +
        alphabeticMetrics.actualBoundingBoxAscent / 2;

    biasCache.push({
        key: font,
        val: bias,
    });

    return bias;
}

function loadMetric(ctx: CanvasRenderingContext2D, baseline: "alphabetic" | "middle") {
    const sample = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    ctx.save();
    ctx.textBaseline = baseline;
    const result = ctx.measureText(sample);

    ctx.restore();

    return result;
}

export default renderer;
