import React, {
    useEffect,
    useState
} from "react";
import ExcelJS from 'exceljs';

interface CellValue {
    value: string | number | boolean | Date | ExcelJS.CellRichTextValue | ExcelJS.CellFormulaValue | ExcelJS.CellHyperlinkValue | ExcelJS.CellErrorValue | ExcelJS.CellSharedFormulaValue | null | undefined;
    style: Partial<ExcelJS.Style>;
}
type RowData = Array<CellValue>


interface CSSStyle {
    fontWeight?: string;
    fontStyle?: string;
    fontSize?: string;
    color?: string;
    fontFamily?: string;
    textAlign?: string;
    verticalAlign?: string;
    whiteSpace?: string;
    backgroundColor?: string;
    borderTop?: string;
    borderBottom?: string;
    borderLeft?: string;
    borderRight?: string;
}

interface ExcelRendererProps {
    file: string;
    fileFormat: string;
}

export const EXCEL_FORMATS = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel"
];

function ExcelRenderer( props: ExcelRendererProps ) {
    const [excelData, setExcelData] = useState<Array<RowData>>();

    useEffect(() => {
        if (props.file && props.fileFormat && EXCEL_FORMATS.includes(props.fileFormat)) {
            fetch(props.file)
                .then(res => res.arrayBuffer())
                .then(data => {
                    const workbook = new ExcelJS.Workbook();
                    return workbook.xlsx.load(data);
                })
                .then(workbook => {
                    const worksheet = workbook.getWorksheet(1);
                    if (!worksheet) {
                        throw new Error('Worksheet not found');
                    }
                    const rows: RowData[] = [];
                    worksheet.eachRow({ includeEmpty: false }, (row) => {
                        const rowData: RowData = [];
                        row.eachCell({ includeEmpty: true }, (cell) => {
                            let cellValue = cell.value;
                            if (cellValue && typeof cellValue === 'object' && 'richText' in cellValue) {
                                // Handle rich text by concatenating the text parts
                                cellValue = cellValue.richText.map(textPart => textPart.text).join('');
                            }
                            rowData.push({ value: cellValue, style: cell.style });
                        });
                        rows.push(rowData);
                    });
                    setExcelData(rows);
                })
                .catch(error => console.error('Error loading Excel file:', error));
        }
    }, [props.file, props.fileFormat]);

    function mapExcelStyleToCSS(excelStyle: Partial<ExcelJS.Style>): CSSStyle {
        const cssStyle: CSSStyle = {};

        if (excelStyle) {
            if (excelStyle.font) {
                if (excelStyle.font.bold) cssStyle.fontWeight = 'bold';
                if (excelStyle.font.italic) cssStyle.fontStyle = 'italic';
                if (excelStyle.font.size) cssStyle.fontSize = `${excelStyle.font.size}px`;
                if (excelStyle.font.name) cssStyle.fontFamily = excelStyle.font.name;
            }

            if (excelStyle.alignment) {
                if (excelStyle.alignment.horizontal) cssStyle.textAlign = excelStyle.alignment.horizontal;
                if (excelStyle.alignment.vertical) cssStyle.verticalAlign = excelStyle.alignment.vertical;
                if (excelStyle.alignment.wrapText) cssStyle.whiteSpace = 'pre-wrap';
            }

            if (excelStyle.border) {
                if (excelStyle.border.top) cssStyle.borderTop = `1px solid #${excelStyle.border.top.color ? excelStyle.border.top.color.argb : '000'}`;
                if (excelStyle.border.bottom) cssStyle.borderBottom = `1px solid #${excelStyle.border.bottom.color ? excelStyle.border.bottom.color.argb : '000'}`;
                if (excelStyle.border.left) cssStyle.borderLeft = `1px solid #${excelStyle.border.left.color ? excelStyle.border.left.color.argb : '000'}`;
                if (excelStyle.border.right) cssStyle.borderRight = `1px solid #${excelStyle.border.right.color ? excelStyle.border.right.color.argb : '000'}`;
            }
        }

        return cssStyle;
    };

    const renderExcel = () => {
        if (!excelData) return null;
        return (
            <table>
                <tbody>
                    {excelData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex} style={mapExcelStyleToCSS(cell.style) as React.CSSProperties}>
                                    {cell.value ? cell.value.toString() : ""}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return <>{EXCEL_FORMATS.includes(props.fileFormat) && renderExcel()}</>;
};


export default ExcelRenderer;
