import cloudUpload from 'assets/Cloud-Download.svg'
import { createRef } from "react";
import { APIResponse, uploadFile } from "services/api";
import { PreSpecification } from "types/requests";

const FEATURE_FLAG_EXCEL_IMPORTER: boolean = import.meta.env.VITE_FEATURE_FLAG_EXCEL_IMPORTER === 'true';

interface UploaderProps {
    preUploadHandler: () => void;
    fileReadHandler: (file: string | ArrayBuffer | null, name: string) => void;
    postUploadHandler: (success: boolean, spec_id: string, group_id: string) => void;
    baseSpec: string;
    parentFolder: string;
    extraFormData?: Record<string, any>;
}

function Uploader(props: UploaderProps) {
    const input = createRef<HTMLInputElement>();
    let allowedTypes = [
        "application/pdf",
        // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    if (FEATURE_FLAG_EXCEL_IMPORTER) {
        allowedTypes.push(
            "application/vnd.ms-excel", // Enable Excel (legacy format)
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" // Enable Excel (modern xlsx format)
        );
    }

    function upload(file: File) {
        //Validate type, error/cleanup
        if (!file) return;
        if (!allowedTypes.includes(file.type)) {
            alert("Invalid file type provided");
            //TODO tidier error
            return;
        }

        props.preUploadHandler();

        const fileReader = new FileReader();
        fileReader.onload = function() {
            const formData = new FormData();
            if (props.extraFormData) for (const prop in props.extraFormData) {
                formData.append(prop, props.extraFormData[prop]);
            }
            formData.append("file", file);

            uploadFile<PreSpecification>("/extracttable" + (
                props.baseSpec !== "" ? `/spec/${props.baseSpec}` :
                    props.parentFolder !== "" ? `/folder/${props.parentFolder}` : ""
            ), formData).subscribe({
                next: (r: APIResponse<PreSpecification>) => {
                    if (!r.data || r.data.status === "ERROR") {
                        props.postUploadHandler(false, r.data?.project_id ?? "", r.data?.group_id ?? "");
                        return;
                    }
                    props.postUploadHandler(r.ok && r.statusCode === 200, r.data.project_id, r.data.group_id);
                },
                error: (e) => {
                    console.error(e);
                    props.postUploadHandler(false, "", "");
                    //TODO proper error
                },
            });

            props.fileReadHandler(URL.createObjectURL(file), file.name);
        }
        fileReader.readAsDataURL(file);
    }

    return (
        <>
            <div
                onDragOver={(e) => {
                    e.preventDefault()
                }}
                onDrop={(e) => {
                    e.preventDefault()
                    upload(e.dataTransfer.files[0]);
                }}
                id="uploader-wrapper"
                className="flex flex-col relative w-fit select-none items-center px-44 py-16 gap-y-1 border-dashed border-2 border-gray-200 rounded-lg"
            >
                <input ref={input} onChange={(e) => e.currentTarget.files ? upload(e.currentTarget.files[0]) : {}} id="uploader" type="file" className="w-full h-full absolute top-0 opacity-0 cursor-pointer" />
                <img src={cloudUpload} alt="Home icon" className="w-12" />
                <span className="text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</span>
                <span className="text-sm font-semibold text-gray-500">Max File Size 30MB</span>
            </div>
        </>
    )
}

export default Uploader;
