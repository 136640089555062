import React, {SetStateAction, useEffect, useState} from "react";
import {Plus} from "lucide-react";
import {useBlocker} from "react-router-dom";

import {ASSIGN_MANUAL, DepartmentListResponse, DepartmentRequest} from "types/departments.ts";
import {APIResponse, bodyRequest, request} from "services/api.ts";
import Pagination from "components/Pagination/Pagination.tsx";
import {Taggable, User, UserListResponse} from "types/requests.ts";
import CrudMenu from "components/CrudMenu/CrudMenu.tsx";
import PickerMenu from "components/Sidebar/Settings/menus/PickerMenu/PickerMenu.tsx";
import TextInputCell from "components/Sidebar/Settings/cells/TextInputCell.tsx";
import ActionCell from "components/Sidebar/Settings/cells/ActionCell.tsx";
import ProfilePickCell from "components/Sidebar/Settings/cells/ProfilePickCell.tsx";
import DropdownCell from "components/Sidebar/Settings/cells/DropdownCell.tsx";
import ColourCell from "components/Sidebar/Settings/cells/ColourCell.tsx";

interface DepartmentTabProps {
    setDirty: (dirty: boolean) => void;
}

const AssignOptions = new Map([
    ["0", "Assign manually"],
    ["1", "Assign per default"],
    ["2", "Assign with AI"]
]);

const gridTemplateColumns = "50px 1fr 1fr 1fr 1fr 100px";

function DepartmentTab(props: DepartmentTabProps) {
    const [loadingDepartments, setLoadingDepartments] = useState(true);
    const [errorGetDepartments, setErrorGetDepartments] = useState(true);
    const [errorSetDepartments, setErrorSetDepartments] = useState(false);
    const [departments, setDepartments] = useState<DepartmentRequest[]>([]);
    const [abbreviations, setAbbreviations] = useState<string[]>([]);
    const [createdDepartments, setCreatedDepartments] = useState<DepartmentRequest[]>([]);
    const [loadingCount, setLoadingCount] = useState<number>(0);
    const [reloadingDepartments, setReloadingDepartments] = useState<Map<string, boolean>>(new Map());
    const [memberships, setMemberships] = useState<Map<string, string[]>>(new Map());
    const [users, setUsers] = useState<Map<string, User>>(new Map());

    const [currentPage] = useState<number>(1); //TODO pagination set

    useEffect(() => { //Pass through prop updates to open menu
        setLoadingDepartments(true);
        getDepartments();
    }, []);

    function getDepartments() {
        setLoadingDepartments(true);
        setErrorGetDepartments(false);
        request<DepartmentListResponse>("GET", "/config/departments").subscribe({
            next: (r: APIResponse<DepartmentListResponse>) => {
                if (!r.ok || r.statusCode !== 200 || !r.data) {
                    console.error("Failed to get departments");
                    setLoadingDepartments(false);
                    setErrorGetDepartments(true);
                    return;
                }
                const memberships = new Map(Object.entries(r.data.memberships));
                setMemberships(memberships);
                setDepartments(r.data.departments.map(d=>
                    ({
                    department_id: d.department_id,
                    department: d.department,
                    email: d.email,
                    colour: d.colour,
                    default_assignment_type: d.default_assignment_type,
                    abbreviation: d.abbreviation,
                    members: memberships.get(d.department_id)??[]
                    }) //Filter out newly created departments to prevent duplication as we are persisting them ourselves
                ).filter(d => !createdDepartments.map(c=>c.department_id).includes(d.department_id)).sort((a,b)=>a.department.localeCompare(b.department)));
                setAbbreviations(r.data.departments.map(d=>d.abbreviation?.toUpperCase()??"").filter(a=>a.trim()!==""))

                request<UserListResponse>("GET", "/users?hide_system=true").subscribe({
                    next: (r: APIResponse<UserListResponse>) => {
                        if (!r.ok || r.statusCode !== 200 || !r.data) {
                            setLoadingDepartments(false);
                            setErrorGetDepartments(true);
                            console.error("Failed to get users for config")
                            return;
                        }

                        const userMap = new Map();
                        r.data.users.forEach(user => userMap.set(user.user_id, user));
                        setUsers(userMap);

                        setLoadingDepartments(false);
                    },
                    error: (e) => {
                        console.error(e);
                        setLoadingDepartments(false);
                        setErrorGetDepartments(true);
                    },
                });
            },
            error: (e) => {
                console.error(e);
                setLoadingDepartments(false);
                setErrorGetDepartments(true);
            },
        });
    }

    function saveDepartment(department: DepartmentRequest, departmentId?: string, skipUpdate?: boolean) {
        setLoadingDepartments(true);
        setErrorGetDepartments(false);
        setReloadingDepartments(prevState => {
            const newState = new Map(prevState);
            newState.set(departmentId??"", true);
            return newState;
        })
        bodyRequest<DepartmentRequest, string>("PUT", `/config/departments/${department.department_id}`, department).subscribe({
            next: (r: APIResponse<string>) => {
                if (!r.ok || r.statusCode !== 200) {
                    console.error("Failed to save department");
                    setLoadingDepartments(false);
                    setErrorSetDepartments(true);
                    return;
                }

                //Update existing data without reload
                if(!skipUpdate) getDepartments();
                setReloadingDepartments(prevState => {
                    const newState = new Map(prevState);
                    newState.delete(departmentId??"");
                    return newState;
                });
                setLoadingDepartments(false);
                setErrorSetDepartments(false);
            },
            error: (e) => {
                console.error(e);
                setLoadingDepartments(false);
                setErrorSetDepartments(true);
            },
        });
    }

    function findDepartmentUpdateMode(dep: DepartmentRequest) {
        if (dep.department_id !== "") {
            saveDepartment(dep, dep.department_id, false)
            return;
        }
        createDepartment(dep);
    }

    function createDepartment(dep: DepartmentRequest) {
        setErrorGetDepartments(false);
        setLoadingCount(loadingCount+1);
        bodyRequest<DepartmentRequest, string>("POST", `/config/departments`, dep).subscribe({
            next: (r: APIResponse<string>) => {
                if (!r.ok || r.statusCode !== 200) {
                    console.error("Failed to create department");
                    setErrorSetDepartments(true);
                    setLoadingCount(loadingCount-1);
                    return;
                }

                setCreatedDepartments(prevState => {
                    return [...prevState, {
                        department_id: r.data??"",
                        department: dep.department,
                        email: dep.email,
                        colour: dep.colour,
                        default_assignment_type: dep.default_assignment_type,
                        abbreviation: dep.abbreviation,
                        members: dep.members
                    }]
                })

                setLoadingCount(loadingCount-1);
                setErrorSetDepartments(false);
            },
            error: (e) => {
                console.error(e);
                setLoadingCount(loadingCount-1);
                setErrorSetDepartments(true);
            },
        });
    }

    function departmentDeleted() {
        setLoadingDepartments(true);
        setErrorSetDepartments(false);
        setErrorGetDepartments(false);
        getDepartments();
        setLoadingDepartments(false);
    }

    function virtualDepartmentDeleted(id?: string) {
        if (!id) return;
        setCreatedDepartments(prevState => {
            return [...prevState].filter(c => c.department_id !== id)
        })
    }

    if (errorGetDepartments) return (
        <div className="flex flex-col">
            <div className="text-red-500 font-semibold">
                There was an error retrieving the departments. Please try again later.
            </div>
        </div>
    );
    if (loadingDepartments) return (
        <div className="flex flex-col">
            <span>Loading departments...</span>
        </div>
    );

    return (
        <div className="flex flex-col">
            {errorSetDepartments && <span className="text-red-500 text-xl">Failed to save department</span>}
            <div className="overflow-y-auto max-h-[60vh] w-full">
                <div className="border rounded-xl">
                    <div className="grid grid-cols-5 text-gray-400 text-left py-3 px-8 border-b"
                         style={{gridTemplateColumns: gridTemplateColumns}}>
                        <span></span>
                        <span>Department*</span>
                        <span>Abbreviation</span>
                        <span>Users</span>
                        <span>Department configuration</span>
                        <span className="text-center">Actions</span>
                    </div>

                    <DepartmentRows departments={createdDepartments} reloadingDepartments={reloadingDepartments} abbreviations={abbreviations} memberships={memberships} users={users} onSaveDepartment={findDepartmentUpdateMode} onDeleteDepartment={virtualDepartmentDeleted} loadingCount={loadingCount} setDirty={props.setDirty} ghostMode={true} />
                    <DepartmentRows departments={departments} reloadingDepartments={reloadingDepartments} abbreviations={abbreviations} memberships={memberships} users={users} onSaveDepartment={saveDepartment} onDeleteDepartment={departmentDeleted} setDirty={props.setDirty} ghostMode={false} />
                </div>
                <div className="mt-4 float-right">
                    <Pagination totalItemCount={departments.length} pageSize={10000} currentPage={currentPage}/>
                </div>
            </div>
        </div>
    );
}

interface DepartmentRowsProps {
    departments: DepartmentRequest[];
    reloadingDepartments: Map<string, boolean>;
    abbreviations: string[];
    memberships: Map<string,string[]>;
    users: Map<string, User>;
    onSaveDepartment: (department: DepartmentRequest, userId?: string) => void;
    onDeleteDepartment: (userId?: string) => void;
    loadingCount?: number;
    setDirty: (dirty: boolean) => void;
    ghostMode: boolean;
}

function DepartmentRows(props: DepartmentRowsProps) {
    const [rowActionsOpen, setRowActionsOpen] = useState<Map<number, boolean>>(new Map());
    const [nameErrors, setNameErrors] = useState<Map<number, string>>(new Map());
    const [abbreviationErrors, setAbbreviationErrors] = useState<Map<number, string>>(new Map());

    const [originalAbbreviations, setOriginalAbbreviations] = useState<Map<number, string>>(new Map());
    const [departmentEditors, setDepartmentEditors] = useState<Map<number, DepartmentRequest>>(new Map());
    const [pendingDepartments, setPendingDepartments] = useState<DepartmentRequest[]>([]);

    const [taggableDeps, setTaggableDeps] = useState<Taggable[]>([]);
    const [pickerSelected, setPickerSelected] = useState<Taggable[]>([]);
    const [pickerId, setPickerId] = useState(-1);
    const [showPicker, setShowPicker] = useState(false);
    const [pickerPosition, setPickerPosition] = useState([0, 0]);

    const [showCrudMenu, setShowCrudMenu] = useState(false);
    const [crudId, setCrudId] = useState("");
    const [delId, setDelId] = useState("");
    const [crudPosition, setCrudPosition] = useState([0, 0]);

    const blocker = useBlocker(() =>
        pendingDepartments.length > 0 || departmentEditors.size > 0
    )

    useEffect(() => {
        props.setDirty(departmentEditors.size>0 || (pendingDepartments.length > 0))
    }, [departmentEditors, pendingDepartments]);

    useEffect(() => {
        setTaggableDeps(Array.from(props.users.entries()).map(([key, value]) => ({
            "name": value.name,
            "type": "user",
            "id": key,
            "colour": "#ddd"
        })));
    }, [props.users]);

    //Department fields
    function getBlankDepartment(): DepartmentRequest {
        return {department_id: "", department:  "", email: "", colour: "", default_assignment_type: ASSIGN_MANUAL, abbreviation: "", members: []};
    }

    function setDepAbbreviation(i: number, newVal: string) {
        setDepartmentEditors(prevDE => {
            const newDE = new Map(prevDE);
            const dep = newDE.get(i)??getBlankDepartment();
            dep.abbreviation = newVal;
            newDE.set(i, dep)
            return newDE;
        });
        validateDepAbbreviation(i, newVal);
    }
    function validateDepAbbreviation(i: number, newVal: string) {
        newVal = newVal.trim().toUpperCase();
        const exists = newVal !== "" && props.abbreviations.includes(newVal);
        const valid = newVal === "" || newVal === (originalAbbreviations.get(i)??"") || !exists;
        setAbbreviationErrors(prevAbbrErrs => {
            const newAbbrErrs = new Map(prevAbbrErrs);
            if(!valid) newAbbrErrs.set(i, "Abbreviations must be unique");
            else newAbbrErrs.delete(i);
            return newAbbrErrs;
        });
        return valid;
    }

    function setDepConfiguration(i: number, config: number) {
        setDepartmentEditors(prevDE => {
            const newDE = new Map(prevDE);
            const dep = newDE.get(i)??getBlankDepartment();
            dep.default_assignment_type = config;
            newDE.set(i, dep)
            return newDE;
        });
    }

    function setDepName(i: number, newVal: string) {
        setDepartmentEditors(prevDE => {
            const newDE = new Map(prevDE);
            const dep = newDE.get(i)??getBlankDepartment();
            dep.department = newVal;
            newDE.set(i, dep)
            return newDE;
        });
        validateDepName(i, newVal);
    }
    function validateDepName(i: number, newVal: string) {
        const valid = newVal.trim() !== "";
        setNameErrors(prevNameErrs => {
            const newNameErrs = new Map(prevNameErrs);
            if(!valid) newNameErrs.set(i, "Department name cannot be blank");
            else newNameErrs.delete(i);
            return newNameErrs;
        });
        return valid
    }

    function setDepColour(i: number, newVal: string) {
        setDepartmentEditors(prevDE => {
            const newDE = new Map(prevDE);
            const dep = newDE.get(i)??getBlankDepartment();
            dep.colour = newVal;
            newDE.set(i, dep)
            return newDE;
        });
    }

    //API related
    function saveDepartment(i: number, department: DepartmentRequest) { //TODO department field redundant
        const deDep = departmentEditors.get(i);
        if(!deDep) return;
        department = deDep;

        if (!validateDepName(i, department.department) || !validateDepAbbreviation(i, department.abbreviation??"")) return;

        const req: DepartmentRequest = {
            department_id: department.department_id,
            department:  department.department,
            email: department.email,
            colour: department.colour,
            default_assignment_type: department.default_assignment_type,
            abbreviation: department.abbreviation,
            members: department.members
        }
        if(props.departments[i]) props.departments[i].default_assignment_type = department.default_assignment_type
        props.onSaveDepartment(req);
        setRowActions(i, false);
    }

    //UI
    function clearError(i: number, setter: React.Dispatch<SetStateAction<Map<number, string>>>) {
        setter(prevErr => {
            const newErr = new Map(prevErr);
            newErr.delete(i);
            return newErr;
        })
    }

    function newDepartment() {
        setPendingDepartments([getBlankDepartment()]);
        // setPendingDepartments(prevPDeps => {
        //     const newPDeps = [...prevPDeps];
        //     newPDeps.push(getBlankDepartment()); //TODO restore later
        //     return newPDeps;
        // });
    }

    function getAbbreviation(id: string) {
        const user = props.users.get(id);
        if (!user) return "?";
        if (!user.abbreviation || user.abbreviation.length === 0) return "?";
        return user.abbreviation;
    }

    function getDepartmentSource() {
        return props.ghostMode ? [...pendingDepartments, ...props.departments] : props.departments;
    }

    function displayEdit(i: number) {
        return props.ghostMode && i < pendingDepartments.length ? true : rowActionsOpen.get(i);
    }

    function openPicker(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, i: number) {
        setShowPicker(true);
        setPickerId(i);
        const pickerHeight = 384;
        const offset = Math.max(0, pickerHeight-(window.innerHeight - (e.currentTarget.offsetTop+e.currentTarget.clientHeight)));
        setPickerPosition([e.currentTarget.offsetLeft+e.currentTarget.clientWidth-416, e.currentTarget.offsetTop+e.currentTarget.clientHeight-offset])
        setPickerSelected((departmentEditors.get(i)?.members??[]).map(d => ({
            "name": props.users.get(d)?.name??"Unknown",
            "type": "user",
            "id": d,
            "colour": "#ddd"
        })))
    }

    function selectUser(tag: Taggable) {
        if (!pickerSelected.map(t=>t.id).includes(tag.id)) {
            setPickerSelected(prevSel => {
                return [...prevSel, tag];
            })
        }
        const current = departmentEditors.get(pickerId);
        if (current && !current.members.includes(tag.id)) {
            if(current.members.includes(tag.id)) return;
            setDepartmentEditors(prevDE => {
                const newDE = new Map(prevDE);
                const department = newDE.get(pickerId) ?? getBlankDepartment();
                department.members = [...department.members, tag.id];
                newDE.set(pickerId, department)
                return newDE;
            });
        }
    }

    function deselectUser(tag: Taggable) {
        setPickerSelected(prevSel => {
            return [...prevSel].filter(s => s.id != tag.id);
        })
        setDepartmentEditors(prevDE => {
            const newDE = new Map(prevDE);
            const department = newDE.get(pickerId)??getBlankDepartment();
            department.members = department.members.filter(u => u !== tag.id);
            newDE.set(pickerId, department)
            return newDE;
        });
    }

    function setRowActions(i: number, open: boolean) {
        if (props.ghostMode && !open) {
            setPendingDepartments(prevPDeps => {
                const newPDeps = [...prevPDeps];
                newPDeps.splice(i, 1);
                return newPDeps;
            });
        }

        setDepartmentEditors(prevDE => {
            const newDE = new Map(prevDE);
            if (open){
                newDE.set(i, {...getDepartmentSource()[i]});
                setOriginalAbbreviations(prevState => {
                    const newState = new Map(prevState);
                    newState.set(i, newDE.get(i)?.abbreviation ?? "");
                    return newState;
                })
            } else {
                newDE.delete(i);
                setOriginalAbbreviations(prevState => {
                    const newState = new Map(prevState);
                    newState.delete(i);
                    return newState;
                })
            }
            return newDE;
        });
        clearError(i, setNameErrors);
        setRowActionsOpen(prevState => {
            const newState = new Map(prevState);
            newState.clear(); //TODO this is temp to avoid edit reload issue
            newState.set(i, open);
            return newState;
        });
    }

    function openCrudMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, i: number, id: string) {
        setCrudId(i.toString());
        setDelId(id);
        setCrudPosition([e.pageX, e.pageY])
        setShowCrudMenu(true)
    }

    return (<>
    {blocker.state !== "blocked" && blocker && (<dialog>
        <button onClick={blocker.proceed}>
            Proceed
        </button>
        <button onClick={blocker.reset}>
            Cancel
        </button>
    </dialog>)}
    {props.ghostMode &&
        <div className="flex gap-x-2 select-none cursor-pointer text-left py-3 px-12 border-b hover:bg-zinc-50" onClick={newDepartment}>
            <Plus/>New Department
        </div>
    }
    {(props.loadingCount??0)>0 && <span className="flex items-center justify-center border-b py-5">Refreshing...</span>}
    {getDepartmentSource().map((d, i) => <>
        {props.reloadingDepartments.get(d.department_id) &&
        <div className="grid grid-cols-1 py-3 px-8 border-b hover:bg-zinc-50">
            Refreshing...
        </div>
        }
        {!props.reloadingDepartments.get(d.department_id) &&
        <div className="grid grid-cols-5 text-left py-3 px-8 border-b" style={{gridTemplateColumns: gridTemplateColumns}}>
            <ColourCell id={i} canEdit={displayEdit(i)??false} showEdit={displayEdit(i)??false} displayValue={d.colour} editValue={departmentEditors.get(i)?.colour} setColour={setDepColour}/>
            <TextInputCell id={i} showEdit={displayEdit(i)??false} displayValue={d.department} editValue={departmentEditors.get(i)?.department??""} onChange={setDepName} error={nameErrors.get(i)} />
            <div className="content-center">
                <TextInputCell id={i} showEdit={displayEdit(i)??false} displayValue={d.abbreviation??""} editValue={departmentEditors.get(i)?.abbreviation??""} onChange={setDepAbbreviation} error={abbreviationErrors.get(i)} />
                {abbreviationErrors.get(i) && <span className="text-red-500 text-sm">{abbreviationErrors.get(i)}</span>}
            </div>
            <ProfilePickCell id={i} showEdit={displayEdit(i)??false} itemSource={getDepartmentSource()[i].members??[]} editItemSource={departmentEditors.get(i)?.members??[]} itemMapper={(i)=>getAbbreviation(i)} onOpenPicker={openPicker} defaultItemName={"?"} limit={displayEdit(i)? 5 : 3} pickerText={"Link Users"} />
            <DropdownCell id={i} disable={!displayEdit(i)} options={AssignOptions} selected={displayEdit(i) ? departmentEditors.get(i)?.default_assignment_type+"" : d.default_assignment_type+""} onChange={(id: string)=>{setDepConfiguration(i, parseInt(id))}} />
            <ActionCell id={i} showEdit={displayEdit(i)??false} onEdit={(e) => openCrudMenu(e, i, d.department_id)} onSave={() => saveDepartment(i, d)} onCancel={() => setRowActions(i, false)} />
        </div>
        }
    </>)}
    <CrudMenu open={showCrudMenu} id={crudId} setOpen={setShowCrudMenu} handleDelete={()=>props.onDeleteDepartment(delId)} handleEdit={() => setRowActions(parseInt(crudId), true)} deletePath={`/config/departments/${delId}`} itemType="department" position={crudPosition} />
    <PickerMenu open={showPicker} setOpen={setShowPicker} taggables={taggableDeps} selected={pickerSelected} handleSelection={selectUser} handleDeselection={deselectUser} type="user" style="profile" position={pickerPosition} />
    </>);
}

export default DepartmentTab;