import { Observable, Subscriber } from "rxjs";

export const BASEURL: string = import.meta.env.VITE_API_BASE_URL + "/v1";

export class APIResponse<T> {
    ok: boolean = false;
    statusCode: number = 0;
    data: T | null = null;
    error: string = "";

    setSuccess(data: T) {
        this.data = data;
        this.ok = true;
    }
    setError(error: string) {
        this.error = error;
        this.ok = false;
    }
}

export function request<TR>(method: string, path: string): Observable<APIResponse<TR>> {
    return new Observable<APIResponse<TR>>(
        function subscribe(subscriber: Subscriber<APIResponse<TR>>) {
            const apiResponse = new APIResponse<TR>();

            fetch(BASEURL + path, {
                method: method,
                headers: {
                    "Authorization": "Bearer " + getToken(),
                    "Content-Type": "application/json"
                },
            }).then((r) => {
                apiResponse.statusCode = r.status;
                if (r.ok) return r.json();

                apiResponse.setError(r.statusText);
                subscriber.next(apiResponse);
                subscriber.complete();
            }).then((r) => {
                apiResponse.setSuccess(r);
                subscriber.next(apiResponse);
                subscriber.complete();
            }).catch((e) => {
                apiResponse.setError(e);
                subscriber.error(apiResponse);
                subscriber.complete();
            });
        }
    );
}

export function bodyRequest<T, TR>(method: string, path: string, body: T): Observable<APIResponse<TR>> {
    return new Observable<APIResponse<TR>>(
        function subscribe(subscriber: Subscriber<APIResponse<TR>>) {
            const apiResponse = new APIResponse<TR>();

            fetch(BASEURL + path, {
                method: method,
                headers: {
                    "Authorization": "Bearer " + getToken(),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body),
            }).then((r) => {
                apiResponse.statusCode = r.status;
                if (r.ok) return r.json();

                apiResponse.setError(r.statusText);
                subscriber.next(apiResponse);
                subscriber.complete();
            }).then((r) => {
                apiResponse.setSuccess(r);
                subscriber.next(apiResponse);
                subscriber.complete();
            }).catch((e) => {
                apiResponse.setError(e);
                subscriber.error(apiResponse);
                subscriber.complete();
            });
        }
    );
}

export function downloadFile<T>(method: string, path: string, body: T): Observable<Blob> {
    return new Observable<Blob>(
        function subscribe(subscriber: Subscriber<Blob>) {
            fetch(BASEURL + path, {
                method: method,
                headers: {
                    "Authorization": "Bearer " + getToken(),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body),
            }).then((r) => { //Slightly different approach
                if (r.ok) return r.blob();
                subscriber.error(new Error(`Failed to download file. Status: ${r.status}, ${r.statusText}`));
                subscriber.complete();
            }).then((blob) => {
                subscriber.next(blob);
                subscriber.complete();
            }).catch((e) => {
                subscriber.error(new Error(`Error during file download: ${e}`));
                subscriber.complete();
            });
        }
    );
}


export function uploadFile<TR>(path: string, formData: FormData): Observable<APIResponse<TR>> {
    return new Observable<APIResponse<TR>>(
        function subscribe(subscriber: Subscriber<APIResponse<TR>>) {
            const apiResponse = new APIResponse<TR>();

            fetch(BASEURL + path, {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + getToken(),
                },
                body: formData,
            }).then((r) => {
                apiResponse.statusCode = r.status;
                if (r.ok) return r.json();

                apiResponse.setError(r.statusText);
                subscriber.next(apiResponse);
                subscriber.complete();
            }).then((r) => {
                apiResponse.setSuccess(r);
                subscriber.next(apiResponse);
                subscriber.complete();
            }).catch((e) => {
                apiResponse.setError(e);
                subscriber.error(apiResponse);
                subscriber.complete();
            });
        }
    );
}

function getToken(): string {
    return sessionStorage.getItem("jwt") ?? "";
}

export function getFetchHeaders() {
    return {
        "Authorization": "Bearer " + getToken(),
    }
}
