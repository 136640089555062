import { ChevronDown, GripVertical, X } from "lucide-react";
import ColourMenu from "components/TableExtract/Table/menus/colour-menus/ColourMenu.tsx";
import { colours, OptionItemData } from "components/TableExtract/Table/menus/colour-menus/common.ts";
import { usePopup } from "hooks/Popup";
import {useMemo} from "react";
import {nanoid} from "nanoid";

export interface ColourMenu {
    index: number;
    openItemLabel: string;
}

interface OptionItemProps {
    option: OptionItemData;
    index: number;
    openItemLabel: string;
    open: ColourMenu;
    setLabelHandler: (e: string) => void;
    clickHandler: (c: ColourMenu) => void;
    setColour: (i: number, colour: string) => void;
    removeItem: (i: number) => void;
    colours?: string[];
}

interface OptionItemProps2 {
    options: OptionItemData[];
    disabledOptions: string[];
}

export function ColourSelectItem(props: OptionItemProps & OptionItemProps2) {
    const removeDepartmentPopup = usePopup("Remove Department", "left")
    const id = useMemo(() => nanoid(),[])
    return (
        <div className="flex gap-x-2 h-9 items-center">
            <GripVertical strokeWidth={1} />
            <button style={{
                backgroundColor: props.option.color,
                // @ts-expect-error tsc is slow
                anchorName: `--${id}`
            }}
                className="p-0 text-zinc-950 rounded-full w-[24px] h-[24px] flex place-content-center items-center"
                onClick={(e) => {
                    e.stopPropagation();
                    //Determine whether to close or open
                    if (props.index === props.open.index && props.openItemLabel === props.open.openItemLabel) props.clickHandler({ index: -1, openItemLabel: "" });
                    else props.clickHandler({ index: props.index, openItemLabel: props.openItemLabel });
                }}
            ><ChevronDown height={20} width={20} /></button>

            <select className={"bg-white p-2 outline-none rounded-lg w-[20rem]" + (props.option.id === "000" ? " text-gray-500 outline-1 outline-red-500 " : "")} value={props.option.id} onChange={(e) => props.setLabelHandler(e.currentTarget.value)}>
                {props.options.map(o => <option value={o.id} disabled={o.id === "000" || props.disabledOptions.includes(o.id)}>{o.label}</option>)}
            </select>
            <button className="clear-button ghost-button text-zinc-950" {...removeDepartmentPopup.props} onClick={(e) => {
                e.stopPropagation();
                props.removeItem(props.index);

            }}><X /></button>
            {removeDepartmentPopup.component}
            {props.index === props.open.index && props.openItemLabel === props.open.openItemLabel &&
                <ColourMenu id={id} colours={props.colours ?? colours} onPick={(c) => {
                    props.setColour(props.index, c);
                    props.clickHandler({ index: -1, openItemLabel: "" }); //Close
                }} />
            }
        </div>
    );
}

function ColourTextItem(props: OptionItemProps) {
    const removeOptionPopup = usePopup("Remove Option", "left");
    const id = useMemo(() => nanoid(),[])
    return (
        <div className="flex gap-x-2 h-9 items-center">
            <GripVertical strokeWidth={1} />
            <button
                // @ts-expect-error tsc is slow
                style={{ backgroundColor: props.option.color, anchorName: `--${id}` }}
                className="p-0 text-zinc-950 rounded-full w-[24px] h-[24px] flex place-content-center items-center"
                onClick={(e) => {
                    e.stopPropagation();
                    //Determine whether to close or open
                    if (props.index === props.open.index && props.openItemLabel === props.open.openItemLabel) props.clickHandler({ index: -1, openItemLabel: "" });
                    else props.clickHandler({ index: props.index, openItemLabel: props.openItemLabel });
                }}
            ><ChevronDown height={20} width={20} /></button>
            <input autoFocus className="bg-white input border rounded-lg h-full p-2 font-semibold" value={props.option.label} onChange={(e) => props.setLabelHandler(e.currentTarget.value)}></input>
            {removeOptionPopup.component}
            <button className="clear-button ghost-button text-zinc-950" {...removeOptionPopup.props} onClick={(e) => {
                e.stopPropagation();
                props.removeItem(props.index);

            }}><X /></button>
            {props.index === props.open.index && props.openItemLabel === props.open.openItemLabel &&
                <ColourMenu id={id} colours={props.colours ?? colours} onPick={(c) => {
                    props.setColour(props.index, c);
                    props.clickHandler({ index: -1, openItemLabel: "" }); //Close
                }} />
            }
        </div>
    );
}

export default ColourTextItem;
