import React, { useEffect, useRef, useState } from "react";
import 'App.css'
import { AlertTriangle, ArchiveRestore, Download, FileInput, FilePlus2, History, Link, Trash2 } from "lucide-react";
import Pencil from "assets/Pencil.svg"
import { APIResponse, downloadFile, request } from "services/api";
import SpecUploadModal from "./SpecUploadModal";
import HistoryModal from "./HistoryModal";
import { DetailMessage } from "types/requests";
import LinkDialogContent from "./LinkDialogContent";
import MoveDialogContent from "./MoveDialogContent";

interface MenuProps {
    open: boolean;
    setOpen: (o: boolean) => void;
    handleDelete: () => void;
    handleRename: (newName: string) => void;
    folder: string;
    id: string;
    name: string;
    position?: number[];
    readonly: boolean;
    folderView?: boolean;
    viewingSpec?: boolean;
    jobStatus?: string;
}
// TODO: values besides pure strings
function SpecificationContextMenu(props: MenuProps) {
    const dialog = useRef<HTMLDialogElement>(null);
    const [showOptions, setShowOptions] = useState(false);
    const [currentName, setCurrentName] = useState("");
    const [position, setPosition] = useState([0, 0]);
    const [modalType, setModalType] = useState("");
    const [showVersionUpload, setShowVersionUpload] = useState(false);
    const [showHistory, setShowHistory] = useState(false);

    useEffect(() => {
        setShowOptions(props.open);
        setCurrentName(props.name)
        if (props.position)
            setPosition(props.position);
    }, [props.open, props.id, props.name, props.position])

    function closeMenu() {
        props.setOpen(false);
        setShowOptions(false);
        setShowVersionUpload(false);
        setShowHistory(false);

        dialog.current?.close()
        setModalType("");
    }

    function delClicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        setModalType("delete");
        dialog.current?.showModal();
    }

    function performDelete() {
        request<DetailMessage>("DELETE", `/specifications/${props.id}`).subscribe({
            next: (r: APIResponse<DetailMessage>) => {
                if (!r.ok || r.statusCode !== 200) return; //TODO logs
                props.handleDelete();
                closeMenu();
            },
            error: (e) => {
                console.error(e);
                //TODO proper error
            },
        });
    }

    function renameClicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        setModalType("rename");
        dialog.current?.showModal();
    }

    function performRename() {
        request<DetailMessage>("PUT", `/specifications/${props.id}/name/${currentName}`).subscribe({
            next: (r: APIResponse<DetailMessage>) => {
                if (!r.ok || r.statusCode !== 200) return; //TODO logs
                props.handleRename(currentName);
                closeMenu();
            },
            error: (e) => {
                console.error(e);
                //TODO proper error
            },
        });
    }

    function restoreClicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        setModalType("rollback");
        dialog.current?.showModal();
    }

    function performRestore() {
        request<DetailMessage>("POST", `/specifications/${props.id}/rollback`).subscribe({
            next: (r: APIResponse<DetailMessage>) => {
                if (!r.ok || r.statusCode !== 200) return;
                closeMenu();
                window.location.reload();
            },
            error: (e) => {
                console.error(e);
                alert("Rollback unsuccessful");
            },
        });
    }

    function moveSpecClicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        setModalType("move");
        dialog.current?.showModal();
    }

    function getLinkClicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        setModalType("link");
        dialog.current?.showModal();
    }

    function clearError() {
        request<DetailMessage>("POST", `/specifications/${props.id}/jobs/status/ERROR/clear`).subscribe({
            next: (r: APIResponse<DetailMessage>) => {
                if (!r.ok || r.statusCode !== 200) return;
                closeMenu();
                window.location.reload();
            },
            error: (e) => {
                console.error(e);
            },
        });
    }

    function downloadClicked() {
        downloadFile<string>("POST", `/specifications/${props.id}/download`, "{}").subscribe(
            (blob) => {
                // Handle the downloaded file
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `${props.name}.pdf`;
                //Fake a download link click
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                closeMenu();
            },
            (error) => {
                // Handle download error
                console.error(error);
            }
        );
    }

    function returnToMain(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        dialog.current?.close()
    }

    //Handle clicks outside menus
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: Event) => {
        if (showVersionUpload || (e.target as HTMLElement).id === "uploader") return;
        if (dialog.current?.open) {
            if ((e.target as HTMLElement).id === "rename" || showOptions) {
                closeMenu();
            }
        }
        else if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
            closeMenu();
        }
    };

    const menuStyle = {
        "top": position[1],
        "right": window.innerWidth - position[0],
        "border": "1px white solid",
        "backgroundColor": "white",
        "filter": "drop-shadow(1px 1px 1px #ddd)",
        "borderRadius": "6px",
    };

    if (!showOptions) return (<></>);
    return (
        <>
            <HistoryModal open={showHistory} setOpen={setShowHistory} specId={props.id} viewingSpec={props.viewingSpec} />
            <SpecUploadModal open={showVersionUpload} specId={props.id} />
            <dialog id="rename" ref={dialog} className="bg-transparent">
                <div className="bg-white text-zinc-950 p-4 rounded-lg">
                    {modalType === "rename" && <>
                        <h3 className="select-none text-zinc-950 font-bold pb-4">Rename specification</h3>
                        <div className="flex gap-x-4 items-center min-w-[20rem]">
                            <input className="bg-white w-full input border rounded-lg h-9 p-2 font-semibold" value={currentName} onChange={(e) => setCurrentName(e.target.value)}></input>
                        </div>
                        <div className="flex gap-x-4 items-center justify-end pt-4">
                            <button onClick={returnToMain} className="bg-zinc-100 text-zinc-950 hover:bg-zinc-200">Cancel</button>
                            <button onClick={performRename}>Save</button>
                        </div>
                    </>}
                    {modalType === "delete" && <>
                        <h3 className="select-none text-zinc-950 font-bold pb-4">Delete specification</h3>
                        <span>Are you sure you want to delete {currentName}?<br /> This will delete all versions of the document.</span>
                        <div className="flex gap-x-4 justify-end pt-4">
                            <button onClick={returnToMain} className="bg-zinc-100 text-zinc-950 hover:bg-zinc-200">Cancel</button>
                            <button className="bg-red-600" onClick={performDelete}>Delete</button>
                        </div>
                    </>}
                    {modalType === "rollback" && <>
                        <h3 className="select-none text-zinc-950 font-bold pb-4">Restore version</h3>
                        <span>Are you sure you want to rollback to {props.name}?<br />This will delete all versions created after this version.</span>
                        <div className="flex gap-x-4 justify-end pt-4">
                            <button onClick={returnToMain} className="bg-zinc-100 text-zinc-950 hover:bg-zinc-200">Cancel</button>
                            <button className="bg-red-600" onClick={performRestore}>Rollback</button>
                        </div>
                    </>}
                    {modalType === "move" && <MoveDialogContent file_id={props.id} name={props.name} start_dir={props.folder} moveFolder={false} closeMenu={() => window.location.reload()} />}
                    {modalType === "link" && <LinkDialogContent id={props.id} layout={"/specifications/view/%id%"} closeMenu={closeMenu} />}
                </div>
            </dialog>
            <div ref={menuRef} className={"absolute dialog z-10 "} style={menuStyle}>
                <div className="flex flex-col">
                    {!props.readonly && <>
                        <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={renameClicked}>
                            <div className="px-2"><img src={Pencil} alt={"Pencil Icon"} /></div>Rename Specification
                        </button>
                        <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={delClicked}>
                            <div className="px-2"><Trash2 absoluteStrokeWidth={true} /></div>Delete Specification
                        </button>
                    </>}
                    <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={downloadClicked}>
                        <div className="px-2"><Download absoluteStrokeWidth={true} /></div>Download Specification
                    </button>
                    {props.readonly &&
                        <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={restoreClicked}>
                            <div className="px-2"><ArchiveRestore absoluteStrokeWidth={true} /></div>Restore this version
                        </button>
                    }
                    {!props.readonly && <>
                        <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={() => setShowVersionUpload(true)}>
                            <div className="px-2"><FilePlus2 absoluteStrokeWidth={true} /></div>Upload New Version
                        </button>
                    </>}
                    <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={() => setShowHistory(true)}>
                        <div className="px-2"><History absoluteStrokeWidth={true} /></div>View Version History
                    </button>
                    {props.folderView &&
                        <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={moveSpecClicked}>
                            <div className="px-2"><FileInput absoluteStrokeWidth={true} /></div>Move Specification
                        </button>
                    }
                    <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={getLinkClicked}>
                        <div className="px-2"><Link absoluteStrokeWidth={true} /></div>Copy Link
                    </button>
                    {props.jobStatus === "ERROR" &&
                        <button className="flex clear-button text-zinc-950 items-center p-2 hover:bg-zinc-100" onClick={clearError}>
                            <div className="px-2"><AlertTriangle absoluteStrokeWidth={true} /></div>Clear Error
                        </button>
                    }
                </div>
            </div>
        </>
    )
}

export default SpecificationContextMenu;
