import '../../table-extract.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "@glideapps/glide-data-grid/dist/index.css";
import React, {useEffect, useRef, useState} from "react";

interface FilterTypePickerProps {
    open: boolean;
    setOpen: (o: boolean)=>void;
    items: string[];
    setItem: (s: string) => void;
    openItemLabel: string;
}

function FilterTypePicker(props: FilterTypePickerProps) {
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => { //Pass through prop updates to open menu
        setMenuOpen(props.open);
    }, [props.open]);
    function pickType(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, s: string) {
        e.stopPropagation();
        props.setItem(s);
        props.setOpen(false);
    }

    function toSentenceLabel(label: string) {
        return label.slice(0, 1).toUpperCase()+label.slice(1);
    }

    function closeMenu() {
        props.setOpen(false);
        setMenuOpen(false);
    }

    //Handle clicks outside menus
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    const handleClickOutside = (e: Event) => {
        if (props.open && menuRef.current && !menuRef.current.contains(e.target as Node)) closeMenu();
    };

    if (!menuOpen) return <></>;
    return (
        <div ref={menuRef} className="my-4 absolute bg-white p-4 rounded-xl gap-y-2 top-6 w-52" onClick={(e)=>e.stopPropagation()}>
            <div className="flex flex-col">
            {props.items.map((o) =>
                <button className="clear-button input-invisible text-zinc-950 px-2 py-1 hover:bg-neutral-100 text-left" onClick={(e)=>pickType(e, o)}>
                    {toSentenceLabel(o)}
                </button>
            )}
            </div>
        </div>
    );
}


export default FilterTypePicker;