import { PropsWithChildren } from 'react'

type TagProps = {
    onClick?: () => void,
    className?: string
}

function Tag(props: PropsWithChildren<TagProps>) {
    return (<span onClick={() => props.onClick && props.onClick()} className={"px-2 py-0.5 border border-gray-200 rounded-lg font-semibold " + (props.className ?? "")}>{props.children}</span>)
}

export default Tag

export function ActiveTag(props: TagProps) {
    return (<Tag onClick={props.onClick} className={"bg-emerald-50 text-green-800 " + (props.className ?? "")}>active</Tag>)
}

export function InactiveTag(props: TagProps) {
    return (<Tag onClick={props.onClick} className={"bg-red-50 text-red-600 " + (props.className ?? "")}>inactive</Tag>)
}
