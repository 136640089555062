import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import { BEComment } from "types/comments";
import { MessageData } from "types/collab";


type CommentsContextType = {
    comments: Map<string, BEComment[]>;
    setComments: React.Dispatch<React.SetStateAction<Map<string, BEComment[]>>>;
    publish: (type: string, data: MessageData) => void;
    setPublish: React.Dispatch<React.SetStateAction<((type: string, data: MessageData) => void)>>;
};

const defaultCommentsContext: CommentsContextType = {
    comments: new Map<string, BEComment[]>(),
    setComments: () => { },
    publish: () => { },
    setPublish: () => { }
};

// Create the context
const CommentsContext = createContext<CommentsContextType>(defaultCommentsContext);

// Create a provider component
export const CommentsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [comments, setComments] = useState<Map<string, BEComment[]>>(new Map());
    const [publish, setPublish] = useState<((type: string, data: MessageData) => void)>(() => { });

    return (
        <CommentsContext.Provider value={{ comments, setComments, publish, setPublish }}>
            {children}
        </CommentsContext.Provider>
    );
};

// Custom hook to use the comments context
export const useComments = () => useContext(CommentsContext);
