/* eslint-disable no-case-declarations */
import '../table-extract.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "@glideapps/glide-data-grid/dist/index.css";
import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import {
    BoundingBox, Column, ExportExcelRequest,
    ImageData,
    TableResponse,
    Value
} from "../../../types/requests";
import type {
    DataEditorRef,
    FillPatternEventArgs,
    GridKeyEventArgs,
    GridSelection,
    Highlight,
    SpriteMap
} from "@glideapps/glide-data-grid"
import {
    CellClickedEventArgs,
    CompactSelection,
    DataEditor,
    EditableGridCell,
    GridCell,
    GridCellKind,
    GridColumn,
    Item,
    Rectangle,
} from "@glideapps/glide-data-grid";
import {
    ChevronDown,
    EyeOff,
    ListFilter,
    Plus,
    Search,
    Table,
    Upload
} from 'lucide-react';
import { downloadFile } from "services/api";
import ColMenu from "./menus/ColMenu";
import RowMenu from "./menus/RowMenu";
import AddColMenu from "./menus/AddColMenu";
import { allCells } from "@glideapps/glide-data-grid-cells";

import HideMenu from "./menus/HideMenu.tsx";
import CommentCellRenderer from "./cells/comments/comment-cell.tsx";
import PDropDownCellRenderer from "./cells/pdrodown/pdropdown-cell.tsx";
import PChipsCellRenderer from "./cells/pchips/pchips-cell.tsx";
import DiffsCellRenderer, { splitSegments, parseValueSegments } from './cells/diffs/diffs-cell.tsx';
import FormatCellRenderer from "./cells/format-text/format-text-cell.tsx";
import AISuggestionCellRenderer from "./cells/ai-suggestion/ai-suggestion-cell.tsx";
import PopoutImageCell from "./cells/popout-image/image-cell.tsx";
import AddFilterMenu, {
    FilterData,
    getFilterSchema,
    noPreviewOperations,
    splitChar,
    valueToMatchParams
} from "./menus/AddFilterMenu.tsx";
import { AISuggestionReference, Filter, getLucideIconByType, HistoryChangeEvent } from "./types/types.tsx";
import { Comment } from "../../../types/comments.ts";
import { split } from "canvas-hypertxt";
import {
    CELL_DATA_UPDATE,
    CellDataUpdate,
    COLUMN_CREATE,
    COLUMN_DATA_UPDATE,
    COLUMN_DELETE,
    COLUMN_MOVE,
    ColumnCreate,
    ColumnDataUpdate,
    ColumnDelete,
    ColumnMove, GEN_COLUMN_CREATE, GenColumnCreate,
    MessageData,
    OPTIONS_REWRITE,
    OptionsRewrite,
    ROW_CREATE,
    ROW_DELETE,
    RowCreate,
    RowDelete,
    USER_CELL_SELECT,
    UserCellSelect
} from "types/collab";
import { useComments } from "./cells/comments/CommentContext";
import { OptionsRewriteOperation } from 'types/collab';
import { colours } from "components/TableExtract/Table/menus/colour-menus/common.ts";
import { usePopup } from 'hooks/Popup.tsx';

interface ResultTableProps {
    table?: TableResponse;
    changesTable?: TableResponse;
    deletedTable?: TableResponse;
    onChange?: (type: string, message: MessageData) => void;
    onRowDelete?: (id: string) => void;
    changeSignal?: boolean;
    toggleSignal?: boolean;
    cellUpdateSignal?: number[];
    lastRemoteChangedCell: string;
    userSelections: Map<string, string>;
    userColours: Map<string, string>;
    scrollCellCoords?: [number, number];
    positionStoreLock?: boolean;
    onPageJump?: (pageIndex: BoundingBox) => void;
    readonly: boolean;
    department_config: string[];
    department_name_mapping: Map<string, string>;
    toggleView?: () => void;
    isDifference?: boolean;
    showToast: boolean;
    setShowToast: (v: boolean) => void;
    lastColumnRegen: number;
    rhCache: Map<number, number>;
    cellMap: Map<string, [col: number, row: number]>;
    regenCellMap: (table: TableResponse) => Map<string, [col: number, row: number]>;
}

const BlankCell: GridCell = {
    kind: GridCellKind.Text,
    data: "",
    allowOverlay: true,
    displayData: "",
};

const BlankRect: Rectangle = { x: 0, y: 0, width: 0, height: 0 }

const theme = {
    accentColor: "#9333EA",
    bgHeader: "#fff",
    borderColor: "#F4F4F5",
    cellHorizontalPadding: 8,
}

const changeColPrefix = "_ch";

const textWidthCache = new Map<string, number>()

function ResultTable(props: ResultTableProps) {
    const customRenderers = allCells;
    const ourRenderers = [...customRenderers, CommentCellRenderer(), PDropDownCellRenderer, PChipsCellRenderer, FormatCellRenderer, DiffsCellRenderer, AISuggestionCellRenderer, PopoutImageCell];
    const [columns, setColumns] = useState<GridColumn[]>([]);
    const [rows, setRows] = useState<number>(0);
    const [widthsLoaded, setWidthsLoaded] = useState<boolean>(false);
    const [reqsDetected, setReqsDetected] = useState<boolean>(false);
    const [currentSelection, setCurrentSelection] = useState<[col: number, row: number]>([-1, -1]);
    const tableRef = useRef<DataEditorRef>(null);
    const [lastPos, setLastPos] = useState<number[]>([0, 0]);
    const [freezeCols, setFreezeCols] = useState<number>(0);
    const [rowSelectionRect, setRowSelectionRect] = useState<Rectangle>(BlankRect);
    const [userSelectionRects, setUserSelectionRects] = useState<Highlight[]>([]);
    const { comments } = useComments();

    //Filtering
    const [lastFilter, setLastFilter] = useState<string>("INIT");
    const [filterRows, setFilterRows] = useState<number[]>([]);
    const [reverseFilterRows, setReverseFilterRows] = useState<Map<number, number>>(new Map());
    const [hiddenCols, setHiddenCols] = useState<string[]>([]); //TODO unique cols
    const [showFilter, setShowFilter] = useState(false);
    const [filters, setFilters] = useState<Filter[]>([]);
    const [currentFilter, setCurrentFilter] = useState<Filter | undefined>(undefined);
    const searchPopup = usePopup("Search Content");
    const hideColumnsPopup = usePopup("Hide Columns");
    const applyFilterPopup = usePopup("Apply Filters");
    const addColumnPopup = usePopup("Add Column", "left")

    useEffect(() => {
        props.rhCache.clear();
    }, [hiddenCols, props.lastColumnRegen]);

    useEffect(() => {
        if (lastFilter !== JSON.stringify(filters)) {
            props.rhCache.clear();
        }
        setLastFilter(JSON.stringify(filters));
        setRowSelectionRect(BlankRect);
        computeFilter();
    }, [filters, hiddenCols, props.table]);

    useEffect(() => {
        computeFilter();
        if (props.cellUpdateSignal) {
            props.rhCache.delete(props.cellUpdateSignal[0]);
        }
    }, [props.rhCache, props.cellUpdateSignal]);

    function computeFilter() {
        if (!props.table) return;

        //Hidden columns
        const reducedCols = props.table.columns.filter((col) => !hiddenCols.includes(col.name));
        if (props.changesTable) {
            setColumns([...props.changesTable.columns, ...reducedCols].map((k, i) => ({
                id: k.name,
                icon: getColIcon(i),
                title: k.name,
                width: k.width,
                hasMenu: true,
            })));
        } else {
            setColumns(reducedCols.map((k, i) => ({
                id: k.name,
                icon: getColIcon(i),
                title: k.name,
                width: k.width,
                hasMenu: true,
            })));
        }

        //Filtered rows
        if (filters.filter(f => f.active).length === 0) {
            setRows(getRowBaseCount());
            return;
        }

        const fRow: number[] = [];
        const revFRow: Map<number, number> = new Map();

        //Hidden rows
        let results = 0;
        // Build map of what filter functions apply to which columns
        const funcMap = new Map<string, ((c: string, i: boolean) => boolean)>();
        [...(props.changesTable?.columns ?? []), ...props.table.columns].forEach(c => {
            if (filters.filter(f => f.active).map(f => f.column_name).includes(c.name)) {
                const filter = filters[filters.map(f2 => f2.column_name).indexOf(c.name)];
                const fD: FilterData = JSON.parse(filter.filter);
                const schema = getFilterSchema(filter.column_type, fD.operation);
                if (!schema) return;
                funcMap.set(c.name, (c: string, i: boolean) => schema.isMatch(c, i, fD.filterContent))
            }
        })
        // Execute filtering
        for (let rowIndex = 0; rowIndex < (props.changesTable ? props.changesTable?.table.length : props.table.table.length); rowIndex++) {
            let rowMatch = true;
            for (let colIndex = 0; colIndex < columns.length; colIndex++) {
                const [trueCol, trueRow, mTable] = getCellFromData([colIndex, rowIndex], true);
                if (!mTable || trueCol < 0 || trueRow < 0) continue;

                const cell = mTable?.table[trueRow].values[trueCol] ?? [];
                //Retrieve appropriate filter func
                if (fRow.includes(rowIndex) || cell.length === 0) continue;
                const filterFunc = funcMap.get(mTable.columns[trueCol].name);
                if (!filterFunc) continue;

                //Filter content
                const [success, filterReadyCellData, isSeparated] = valueToMatchParams(cell[0], comments);
                if (!success) continue;
                const hasMatchingItem = filterFunc(filterReadyCellData, isSeparated);
                rowMatch = rowMatch && hasMatchingItem;
            }

            if (rowMatch) {
                const fRowVal = rowIndex;
                if (fRowVal !== -1) {
                    fRow.push(fRowVal);
                    revFRow.set(fRowVal, fRow.length)
                }
                results++;
            }
        }

        setRows(results);
        setFilterRows(fRow);
        setReverseFilterRows(revFRow);
    }

    function skipHiddenCols(col: number): number {
        if (!props.table) return -1;
        const initlen = columns.length;
        if (col >= initlen) return initlen - 1;

        for (let i = 0; i < props.table.columns.length; i++) {
            try {
                if (props.table.columns[i].name === columns[col].title) return i;
            } catch (e) {
                return -1;
            }
        }

        return -1;
    }

    function getSpecId(): string {
        return window.location.pathname.split("/")[3];
    }

    function toggleFilterView() {
        setShowFilter(!showFilter);
        const id = getSpecId();
        if (!showFilter) localStorage.setItem(id + "_filters_shown", "true");
        else localStorage.removeItem(id + "_filters_shown");
    }

    function loadShowFilter() {
        const item = localStorage.getItem(getSpecId() + "_filters_shown");
        if (item === "true") setShowFilter(true);
    }

    function filterUpdate(f: Filter) {
        if (!currentFilter) return;
        const index = filters.map(f => f.column_name).indexOf(currentFilter.column_name);
        if (index < 0) return;//TODO error?

        setFilters(prevFilters => {
            const newFilters = [...prevFilters];
            newFilters.splice(index, 1, f);
            storeFilters(newFilters);
            return newFilters;
        });
    }

    function getStoredFilterKey() {
        return `${getSpecId()}_filter_data${props.changesTable ? changeColPrefix : ""}`
    }

    function storeFilters(f: Filter[]) {
        localStorage.setItem(getStoredFilterKey(), JSON.stringify(f));
    }

    function loadFilters(): Filter[] {
        const result = localStorage.getItem(getStoredFilterKey());
        if (!result || result === "") {
            return [];
        }
        return JSON.parse(result);
    }

    function addFilter(f: Filter) {
        setFilters(prevFilters => {
            const newFilters = [...prevFilters];
            newFilters.splice(newFilters.length, 0, f);
            storeFilters(newFilters);
            return newFilters;
        })
    }

    function deleteFilter(columnName: string) {
        const index = filters.map(f => f.column_name).indexOf(columnName)
        setFilters(prevFilters => {
            const newFilters = [...prevFilters];
            newFilters.splice(index, 1);
            storeFilters(newFilters);
            return newFilters;
        })
    }

    function filtersActive() {
        return filters.filter(f => f.active).length > 0;
    }

    function getFilterLabel(f: Filter): string {
        let fS = f.filter;
        if (f.filter !== "") {
            try {
                const fD: FilterData = JSON.parse(f.filter);
                const schema = getFilterSchema(f.column_type, fD.operation);
                if (schema && !noPreviewOperations.includes(schema.id) && schema.showOptions) {
                    fS = fD.filterContent
                    if (["chips", "departments"].includes(f.column_type)) fS = fS.replace(splitChar, ", ");
                } else {
                    fS = "";
                }
            } catch (e) {
                fS = "";
            }
        }
        const base = f.column_name + (fS !== "" ? ": " : "") + fS;
        return base.length > 25 ? base.slice(0, 25) + "..." : base;
    }

    //Export
    const [disableDL, setDisableDL] = useState(false);

    function exportToXlsx() {
        if (disableDL || !props.table) return;
        setDisableDL(true);
        props.setShowToast(true);

        const columns: Column[] = props.table.columns.map((c, i) => {
            c.type = c.type ? c.type : props.table?.table[0].values[i][0].type;
            return c;
        });
        let changeColumns: Column[] = [];
        if (props.changesTable) {
            changeColumns = props.changesTable.columns.map((c, i) => {
                c.type = c.type ? c.type : props.changesTable?.table[0].values[i][0].type;
                return c;
            });
        }

        const viewTable: TableResponse = {
            name: props.table.name,
            columns: [...changeColumns, ...columns].filter(f => !hiddenCols.includes(f.name)),
            table: []
        }

        for (let i = 0; i < rows; i++) {
            const displayVals: Value[][] = [];
            let rowId = "";
            let rowRect: BoundingBox = { page_number: 0, x0: 0, y0: 0, x1: 0, y1: 0 };
            const classificationColIndex = columns.map(c => c.type).indexOf("classification")
            for (let j = 0; j < columns.filter(c => !hiddenCols.includes(c.name)).length; j++) {
                const [trueCol, trueRow, mTable] = getCellFromData([j, i]);
                if (!mTable || trueCol < 0 || trueRow < 0) continue;
                const row = mTable.table[trueRow];
                rowId = row.row_id;
                rowRect = row.rect;
                //Fix for legacy issue with translation cols not being updated by collab side effect
                if (classificationColIndex > -1 && ["string", "translation"].includes(row.values[trueCol][0].type)) {
                    const leftSide = row.values[classificationColIndex][0].value.split("|")[0].split("^")[0];
                    if (leftSide.toLowerCase() === "heading") row.values[trueCol][0].isHeading = true;
                }
                displayVals.push(row.values[trueCol]);
            }
            viewTable.table.push({ values: displayVals, rect: rowRect, row_id: rowId })
        }

        const req: ExportExcelRequest = {
            comments: Object.fromEntries(comments),
            table: viewTable
        }

        for (const key in req.comments) {
            for (const comment of req.comments[key]) {
                //@ts-expect-error deleting a prop for the back to parse correctly
                if (!comment.resolved_user) delete comment.resolved_user
            }
        }

        downloadFile<ExportExcelRequest>("POST", `/specifications/${getSpecId()}/export/xlsx`, req).subscribe(
            (blob) => {
                const name = props.table?.name ?? "ExportedTable";
                const d = new Date();
                // Handle the downloaded file
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `TableExtraction_${name}_${d.getDate()}-${d.getMonth()}-${d.getFullYear()}.xlsx`;//TODO not ideal
                //Fake a download link click
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                props.setShowToast(false);
            },
            (error) => {
                // Handle download error
                console.error(error);
                props.setShowToast(false);
            }
        );


        setDisableDL(false);
    }

    //Searching & Undo
    const [showSearch, setShowSearch] = React.useState(false);
    const [selection, setSelection] = React.useState<GridSelection>({
        rows: CompactSelection.empty(),
        columns: CompactSelection.empty(),
    });

    const [changeKeysIndex, setChangeKeysIndex] = React.useState<number>(0);
    const [changeKeys, setChangeKeys] = React.useState<string[]>([]); //list of history
    const [changeIndexes, setChangeIndexes] = React.useState<Map<string, number>>(new Map());
    const [changes, setChanges] = React.useState<Map<string, HistoryChangeEvent[]>>(new Map());
    const [cellHistoryCutoffs, setCellHistoryCutoffs] = React.useState<Map<string, number>>(new Map());

    //Updates cutoffs for cell history based on signal from above
    useEffect(() => {
        setCellHistoryCutoffs(prevCutoff => {
            const newCutoff = new Map(prevCutoff);
            newCutoff.set(props.lastRemoteChangedCell, new Date().getTime());
            return newCutoff;
        })
    }, [props.lastRemoteChangedCell])

    const getNextHistoryItem = React.useCallback((isUndo: boolean): [string, HistoryChangeEvent | undefined] => {
        const offset = isUndo ? -1 : 0;
        const step = isUndo ? -1 : 1;

        if (changeKeysIndex + offset < 0 || changeKeysIndex + offset >= changeKeys.length) return ["", undefined];

        const targetCellId = changeKeys[changeKeysIndex + offset];
        const cellHistory = changes.get(targetCellId);
        const cellHistoryIndex = changeIndexes.get(targetCellId);
        if (!cellHistory || cellHistoryIndex === undefined) return ["", undefined];
        const cutoff = cellHistoryCutoffs.has(targetCellId) ? cellHistoryCutoffs.get(targetCellId) : 0;
        const historyItem = cellHistory[cellHistoryIndex + offset];
        if (!historyItem) return ["", undefined];
        if (cutoff !== undefined && cutoff > 0 && historyItem.changed <= cutoff) {
            changeIndexes.set(targetCellId, cellHistoryIndex + step);
            setChangeKeysIndex(changeKeysIndex + step);
            return getNextHistoryItem(isUndo);
        }

        changeIndexes.set(targetCellId, cellHistoryIndex + step);
        setChangeKeysIndex(changeKeysIndex + step);

        return [targetCellId, historyItem];
    }, [cellHistoryCutoffs, changeIndexes, changeKeys, changeKeysIndex, changes]);

    const gridKeyHandler = React.useCallback((event: GridKeyEventArgs) => {
        // if (props.table && props.onChange && (event.ctrlKey || event.metaKey) && (event.key === "z" || event.key === "y")) {
        //     const isUndo = event.key == "z";
        //     const [targetCellId, historyItem] = getNextHistoryItem(isUndo);
        //     if (!targetCellId || !historyItem) return;
        //
        //     const targetHistoryValue = isUndo ? historyItem.original_value : historyItem.new_value;
        //     const [col, row] = getCellCoordsFromId(targetCellId);
        //     props.table.table[row].values[col][0].isHeading = targetHistoryValue.isHeading;
        //     props.table.table[row].values[col][0].value = targetHistoryValue.value;
        //     if (props.onChange) {
        //         props.rhCache.delete(row);
        //         const message: CellDataUpdate = {
        //             cell_id: targetHistoryValue.cell_id,
        //             cell_data: targetHistoryValue
        //         }
        //         props.onChange(CELL_DATA_UPDATE, message);
        //     }
        //
        // }
        if ((event.ctrlKey || event.metaKey) && event.key === "f") {
            setShowSearch(cv => !cv);
            event.stopPropagation();
            event.preventDefault();
        }
    }, [getCellCoordsFromId, getNextHistoryItem, props.onChange, props.table]);


    //Content rendering
    function getCellFromData([col, row]: Item, ignoreFilter: boolean = false): [col: number, row: number, table?: TableResponse] {
        const dataTableColOffset = props.changesTable ? props.changesTable.columns.length : 0;
        let trueRow = !ignoreFilter && filtersActive() ? filterRows[row] : row;
        if (!trueRow) trueRow = row;
        if (col < dataTableColOffset) {//TODO should this be trueCol?
            return [col, trueRow, props.changesTable]
        }

        const trueCol = skipHiddenCols(col);
        if (!props.changesTable) {
            return [trueCol, trueRow, props.table]
        } else {//Getting props.table data for changes table
            const trueRowData = props.changesTable.table[trueRow];
            if (trueRowData === undefined) return [trueCol, -1, undefined] //TODO safety
            const matchRowId = trueRowData.row_id;
            //Determine whether to pull data from extract or deletion
            const changesColIndex = trueRowData.values.map(v => v[0].type).findIndex(t => t === "change");
            if (changesColIndex === -1) return [trueCol, -1, undefined];
            const isDeletionSource = props.changesTable.table[trueRow].values[changesColIndex][0].value.split("|")[0] === "Deleted";
            const tableSource = isDeletionSource ? props.deletedTable : props.table;//TODO validate returned text
            //Get the data coords
            const tableRow = tableSource?.table.map(t => t.row_id).findIndex(v => v === matchRowId) ?? 0;
            let tableCol = trueCol;
            if (isDeletionSource) { // Handle column order changes
                const tableColData = props.table?.columns[trueCol];
                tableCol = !tableColData ? -1 : tableSource?.columns.map(c => c.col_id).findIndex(c => (tableColData.col_id ?? "") === c) ?? -1;
            }
            return [tableCol, tableRow, tableSource]
        }
    }

    function getRowBaseCount() {
        if (!props.table) return 0;
        return props.changesTable ? props.changesTable.table.length : props.table.table.length;
    }

    function getCellContent([col, row]: Item): GridCell {
        const [trueCol, trueRow, mTable] = getCellFromData([col, row]);
        if (!props.table || !mTable || trueCol < 0 || row < 0) return BlankCell;

        //Data
        if (mTable.table.length <= trueRow) return BlankCell;
        const tRow = mTable.table[trueRow];
        if (!tRow) return BlankCell;//TODO fix
        if (tRow.values.length <= trueCol) return BlankCell;
        const cellData = tRow.values[trueCol];
        if (cellData.length === 0) return BlankCell;
        const classificationColumnIndex = mTable.columns.findIndex(x => x.type === "classification");
        const isHeading = classificationColumnIndex === -1 ? false : mTable.table[trueRow].values[classificationColumnIndex][0].value.split('|')[0].includes("Heading");
        const isInfo = classificationColumnIndex === -1 ? false : mTable.table[trueRow].values[classificationColumnIndex][0].value.split('|')[0].includes("Info");

        const canOverlay = mTable !== props.changesTable && !props.readonly;
        switch (cellData[0].type) {
            case "image":
                const imageData: ImageData = JSON.parse(cellData[0].value);
                return {
                    kind: GridCellKind.Custom,
                    allowOverlay: false,
                    copyData: imageData.signed_url,
                    data: {
                        kind: "popout-image-cell",
                        imageUrl: imageData.signed_url,
                    },
                    cursor: "pointer"
                }
            case "loading":
                return {
                    kind: GridCellKind.Custom,
                    allowOverlay: false,
                    copyData: "4",
                    data: {
                        kind: "spinner-cell",
                        id: cellData[0].value
                    },
                }
            case "categorisation":
            case "classification":
            case "dropdown":
                if (cellData[0].value === "") return BlankCell;
                const pdparts = cellData[0].value.split("|");
                if (pdparts.length !== 2) return BlankCell;
                const pdpossible = pdparts[1].split(",").map(tag => ({ tag: tag.split("^")[0], color: tag.split("^")[1] }));
                const pdvalue = { tag: pdparts[0].split("^")[0], color: pdparts[0].split("^")[1] }

                return {
                    kind: GridCellKind.Custom,
                    allowOverlay: canOverlay,
                    copyData: cellData[0].value.split("|")[0],
                    themeOverride: {
                        bgCell: "#fff"
                    },
                    data: {
                        kind: "pdropdown-cell",
                        allowedValues: pdpossible,
                        value: pdvalue,
                        cellId: cellData[0].cell_id,
                    },
                }
            case "ai":
            case "change":
            case "departments":
            case "chips":
                if (cellData[0].value === "") return BlankCell;
                const parts = cellData[0].value.split("|");
                const possible = parts.length > 1 ? parts[1].split(",").filter(p => p !== "").map(tag => ({ tag: tag.split("^")[0], color: tag.split("^")[1] })) : [];
                const possibleColours = new Map(possible.map(o => [o.tag, o.color]));
                let selected = parts.length > 1 ? parts[0].split(",").filter(p => p !== "").map(tag => ({ tag: tag, color: possibleColours.get(tag) ?? "#ffffff" })) : [];
                if (cellData[0].type === "departments") selected = selected.sort((a, b) => a.tag.localeCompare(b.tag));
                const copyData = parts[0] + ",|" + (parts.length > 1 ? parts[1] : "");

                return {
                    kind: GridCellKind.Custom,
                    allowOverlay: canOverlay && possible.length > 0,
                    copyData: copyData,
                    themeOverride: {
                        bgCell: "#fff"
                    },
                    data: {
                        kind: "pchips-cell",
                        readonly: props.readonly,
                        allowedValues: possible,
                        values: selected,
                        shouldSort: cellData[0].type === "departments",
                        cellId: cellData[0].cell_id,
                    },
                }
            case "comment":
                return {
                    kind: GridCellKind.Custom,
                    allowOverlay: true,
                    copyData: "",
                    data: {
                        kind: "comment-cell",
                        readonly: props.readonly || (props.deletedTable && mTable === props.deletedTable),
                        comments: cellData[0].value
                    },
                }
            case "suggestion": //TODO confirm type
                return {
                    kind: GridCellKind.Custom,
                    allowOverlay: true,
                    copyData: cellData[0].value,
                    data: {
                        kind: "ai-suggestion-cell",
                        readonly: props.readonly,
                        suggestions: cellData[0].value
                    },
                }
            case "diffs":
                return {
                    kind: GridCellKind.Custom,
                    allowOverlay: false,
                    copyData: cellData[0].value,
                    data: {
                        kind: "diffs-cell",
                        // convert 
                        value: cellData[0].value,
                    },
                }
            case "string":
            case "text":
            case "deviation":
            default:
                return {
                    kind: GridCellKind.Custom,
                    allowOverlay: canOverlay,
                    copyData: cellData[0].value,
                    data: {
                        kind: "format-text-cell",
                        value: cellData[0].value,
                        isBold: isHeading,
                        textColour: isInfo ? "#a1a1aa" : "#000",
                        cellId: cellData[0].cell_id,
                    }
                }
        }
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const extraSpace = 6 + 26;//padding+icons
    const colMaximum = 500;
    const twCache = new Map<string, number>();
    function getTextWidth(text: string, font?: string): number {
        if (!context) return 0;
        const cacheValue = twCache.get(text);
        if (cacheValue) return cacheValue;
        context.font = font ?? getComputedStyle(document.body).font;
        const calcValue = context.measureText(text).width + extraSpace;
        twCache.set(text, calcValue);
        return calcValue;
    }

    function getColumnSavedSize(column: Column, override: string = "", suffix: string = ""): number {
        const storedWidth = localStorage.getItem("COLWIDTH_" + (override ? override : column.col_id) + suffix);
        if (!storedWidth) return getTextWidth(column.name)
        try {
            const parsed = parseFloat(storedWidth);
            return isNaN(parsed) ? getTextWidth(column.name) : parsed;
        } catch (e) {
            return getTextWidth(column.name);
        }
    }

    function hasColumnSavedSize(column: Column, override: string = "", suffix: string = ""): boolean {
        const storedWidth = localStorage.getItem("COLWIDTH_" + (override ? override : column.col_id) + suffix);
        if (!storedWidth) return false;
        try {
            const parsed = parseFloat(storedWidth);
            return !isNaN(parsed)
        } catch (e) {
            return false
        }
    }

    function getMaxTextWidths(table: TableResponse, altTable: boolean = false, suffix: string = ""): number[] {
        const specId = getSpecId();
        const sizes: number[] = table.columns.map((h, i) => getColumnSavedSize(h, altTable ? specId + i.toString() : "", suffix)); //List longest text seen by column
        const presizedCols: boolean[] = table.columns.map((h, i) => hasColumnSavedSize(h, altTable ? specId + i.toString() : "", suffix));

        table.table.forEach(row => {
            row.values.forEach((cell, j) => { //Iterate row cells to check for widest values
                while (sizes.length < row.values.length) {
                    sizes.push(0);
                    presizedCols.push(false);
                }
                if (presizedCols[j]) return;

                //Iterate over values inside cell, see if any are biggest in col
                cell.forEach(cellItem => {
                    if (!cellItem || (presizedCols.length > j && presizedCols[j])) return;
                    let cellWidth: number = 64;
                    switch (cellItem.type) {
                        case "translation":
                        case "text":
                        case "string":
                        case "diffs":
                        case "deviation":
                            cellWidth = getTextWidth(cellItem.value); break;
                        case "image":
                            const img = new Image();
                            img.src = cellItem.value;
                            cellWidth = img.width;
                            break;
                        case "comment":
                            const comments: Comment[] = JSON.parse(cellItem.value);
                            cellWidth = getTextWidth(comments.length + " Comments");
                            break;
                        case "suggestion":
                            const suggestions: AISuggestionReference[] = JSON.parse(cellItem.value);
                            cellWidth = getTextWidth(suggestions.length > 1 ? `${suggestions.length} similar deviations found` : suggestions.length === 1 ? `${suggestions.length} similar deviation found` : ""); //TODO adjust for sparkle
                            break;
                        case "departments":
                        case "chips":
                            const selected = cellItem.value.split("|")[0]
                            if (selected.length === 0) break;
                            const count = selected.split(",").length;
                            cellWidth = getTextWidth(selected) * .7 + (count - 1) * 21 + 14;
                            break;
                        case "categorisation":
                        case "classification":
                        case "dropdown":
                            const pickedOption = cellItem.value.split("|")[0].split("^")[0];
                            if (pickedOption.length === 0) break;
                            cellWidth = getTextWidth(pickedOption) * .75 + 16;
                            break;
                        case "loading":
                            cellWidth = 64;
                    }
                    sizes[j] = presizedCols[j] ? sizes[j] : Math.min(colMaximum, Math.max(sizes[j], cellWidth));
                });
            });
        });

        return sizes;
    }

    const headerIcons = useMemo<SpriteMap>(() => {
        return {
            text: p => `<svg width="24" height="24" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.3333 1.06665H1M13 5.06665H1M9.06667 9H1" stroke="${p.bgColor}" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
            ai: (p) => `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 3V7M19 17V21M3 5H7M17 19H21M12 3L10.088 8.813C9.99015 9.11051 9.82379 9.38088 9.60234 9.60234C9.38088 9.82379 9.11051 9.99015 8.813 10.088L3 12L8.813 13.912C9.11051 14.0099 9.38088 14.1762 9.60234 14.3977C9.82379 14.6191 9.99015 14.8895 10.088 15.187L12 21L13.912 15.187C14.0099 14.8895 14.1762 14.6191 14.3977 14.3977C14.6191 14.1762 14.8895 14.0099 15.187 13.912L21 12L15.187 10.088C14.8895 9.99015 14.6191 9.82379 14.3977 9.60234C14.1762 9.38088 14.0099 9.11051 13.912 8.813L12 3Z" stroke="${p.bgColor === "white" ? p.bgColor : theme.accentColor}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
            chips: p => `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="${p.bgColor}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-list "><line x1="8" x2="21" y1="6" y2="6"></line><line x1="8" x2="21" y1="12" y2="12"></line><line x1="8" x2="21" y1="18" y2="18"></line><line x1="3" x2="3.01" y1="6" y2="6"></line><line x1="3" x2="3.01" y1="12" y2="12"></line><line x1="3" x2="3.01" y1="18" y2="18"></line></svg>`,
            dropdown: p => `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="${p.bgColor}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down-circle "><circle cx="12" cy="12" r="10"></circle><path d="m16 10-4 4-4-4"></path></svg>`,
            comment: p => `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="${p.bgColor}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-messages-square "><path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z"></path><path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1"></path></svg>`,
            snowflake: p => `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="${p.bgColor}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-snowflake"><line x1="2" x2="22" y1="12" y2="12"/><line x1="12" x2="12" y1="2" y2="22"/><path d="m20 16-4-4 4-4"/><path d="m4 8 4 4-4 4"/><path d="m16 4-4 4-4-4"/><path d="m8 20 4-4 4 4"/></svg>`,
            classification: p => `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="${p.bgColor}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-heading"><path d="M6 12h12"/><path d="M6 20V4"/><path d="M18 20V4"/></svg>`,
            deviation: p => `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="${p.bgColor}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-message-square-x"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"/><path d="m14.5 7.5-5 5"/><path d="m9.5 7.5 5 5"/></svg>`,
            categorisation: p => `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="${p.bgColor}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-x"><circle cx="12" cy="12" r="10"/><path d="m15 9-6 6"/><path d="m9 9 6 6"/></svg>`,
            translation: p => `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="${p.bgColor === "white" ? p.bgColor : theme.accentColor}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-languages"><path d="m5 8 6 6"/><path d="m4 14 6-6 2-3"/><path d="M2 5h12"/><path d="M7 2h1"/><path d="m22 22-5-10-5 10"/><path d="M14 18h6"/></svg>`,
            departments: p => `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="${p.bgColor === "white" ? p.bgColor : theme.accentColor}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-group"><path d="M3 7V5c0-1.1.9-2 2-2h2"/><path d="M17 3h2c1.1 0 2 .9 2 2v2"/><path d="M21 17v2c0 1.1-.9 2-2 2h-2"/><path d="M7 21H5c-1.1 0-2-.9-2-2v-2"/><rect width="7" height="5" x="7" y="7" rx="1"/><rect width="7" height="5" x="10" y="12" rx="1"/></svg>`
        };
    }, []);

    function getColIcon(col: number, autoFrozen: boolean = true): string {
        if (autoFrozen && col === 0 && freezeCols === 1) return "snowflake";
        const [trueCol, , mTable] = getCellFromData([col, 0]);
        if (!mTable || trueCol === -1) return "text"
        if (mTable.table.length === 0 || mTable.table[0].values.length <= trueCol || !mTable.table[0].values[trueCol] || mTable.table[0].values[trueCol].length === 0) {
            return "text";
        }
        const type = mTable.table[0].values[trueCol][0].type;
        switch (type) {
            case "ai":
            case "dropdown":
            case "text":
            case "deviation":
            case "comment":
            case "translation":
            case "departments":
            case "classification":
            case "categorisation":
            case "chips":
                return type;
            case "suggestion":
                return "ai";
            case "change":
                return "dropdown";
            default: return "text";
        }
    }

    function getExtractCols(): string[] {
        if (!props.table) return [];

        const result = [];
        for (let i = 0; i < props.table.columns.length; i++) {
            if (props.table.table[0].values[i][0].type === "string" || props.table.table[0].values[i][0].type === "image") { //Hack, need obvious separate type for reqs
                result.push(props.table.columns[i].name)
            }
        }

        return result;
    }

    useEffect(() => {
        if (lastPos[0] === 0 && lastPos[1] === 0) return;
        tableRef.current?.scrollTo(0, { amount: lastPos[0] - 1, unit: "cell" }, "both", 0, 0, { hAlign: "start" });
    }, [props.toggleSignal]);

    useEffect(() => {
        if (!props.scrollCellCoords || props.scrollCellCoords[0] < 0 || props.scrollCellCoords[1] < 0) return;
        const trueRow = !filtersActive() ? props.scrollCellCoords[1] : (reverseFilterRows.get(props.scrollCellCoords[1]) ?? 0) - 1;
        if (trueRow < 0) return;
        tableRef.current?.scrollTo({ amount: props.scrollCellCoords[0], unit: "cell" }, { amount: trueRow, unit: "cell" }, "both", 0, 0, { vAlign: "center", hAlign: "center" });
        setSelection({
            current: {
                cell: [props.scrollCellCoords[0], trueRow],
                range: {
                    x: props.scrollCellCoords[0],
                    y: trueRow,
                    width: 1,
                    height: 1
                },
                rangeStack: []
            },
            rows: CompactSelection.empty(),
            columns: CompactSelection.empty(),
        })
    }, [props.scrollCellCoords]);

    useEffect(() => {
        setFilters(loadFilters());
        loadShowFilter();
        if (!props.table) return;
        const sizes = getMaxTextWidths(props.table, false, props.changesTable ? changeColPrefix : "");
        if (props.changesTable) {
            const altSizes = getMaxTextWidths(props.changesTable, true);
            if (!widthsLoaded) {
                setColumns([...props.changesTable.columns, ...props.table.columns].map((k, i) => ({
                    id: k.name,
                    icon: getColIcon(i),
                    title: k.name,
                    width: props.changesTable && i < props.changesTable.columns.length ? altSizes[i] : sizes[i],
                    hasMenu: true,
                })));
                setWidthsLoaded(true);
            }
            if (!reqsDetected) {
                const reqCols = getExtractCols();
                if (reqCols.length > 0) {
                    setHiddenCols([...hiddenCols, ...reqCols]);
                    setReqsDetected(true);
                }
            }
            props.changesTable.columns.forEach((column, i) => column.width = altSizes[i]);//Populate rendered widths
        } else {
            setColumns(props.table.columns.map((k, i) => ({
                id: k.name,
                icon: getColIcon(i),
                title: k.name,
                width: sizes[i],
                hasMenu: true,
            })));
        }
        props.table.columns.forEach((column, i) => column.width = sizes[i]);//Populate rendered widths

        setRows(getRowBaseCount());
    }, [props.changeSignal, hiddenCols, props.table]);

    function getCellCoordsFromId(cell_id: string): [col: number, row: number] {
        if (!props.table) return [-1, -1];

        for (let j = 0; j < props.table.columns.length; j++) {
            if (hiddenCols.includes(props.table.columns[j].name)) continue //TODO swap to id
            for (let i = 0; i < props.table.table.length; i++) {
                if (props.table.table[i].values[j][0].cell_id == cell_id) return [j, i]
            }
        }
        return [-1, -1]
    }

    useEffect(() => {
        if (!tableRef.current || !props.cellUpdateSignal) return;
        tableRef.current?.updateCells([{ cell: ([props.cellUpdateSignal[0], props.cellUpdateSignal[1]]) }])
    }, [props.cellUpdateSignal]);
    useEffect(() => {
        if (!tableRef.current || !props.userSelections) return;
        //Build cell-unique list first
        const seenCells: string[] = [];
        const items: Highlight[] = []
        Array.from(props.userSelections).forEach(([user, cell]) => {
            if (seenCells.includes(cell)) return;
            seenCells.push(cell);
            const [col, row] = getCellCoordsFromId(cell);
            items.push({
                color: (props.userColours.get(user) ?? "#000000") + "00",
                range: {
                    x: col,
                    y: row,
                    width: 1,
                    height: 1
                },
                style: "solid"
            });
        })
        setUserSelectionRects(items)
    }, [props.userSelections]);

    function getConstrainedTextHeight(text: string, type: string, columnWidth: number) {
        if (!context) return 0;

        //Coerced out of base glide theme
        context.font = "13px Inter, Roboto, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, noto, arial, sans-serif";
        context.textBaseline = "top"
        switch (type) {
            default: {
                const lines = split(context, text, context.font, columnWidth - 16, false);
                return lines.length * 21;
            }
            case "diffs": {
                const segments = parseValueSegments(text);
                const lines = splitSegments(context, segments, context.font, columnWidth - 16, theme); //TODO: replace with actual width of cell
                return lines.length * 21;
            }
        }
    }

    const defaultRowHeight = 33;
    function getRowHeight(rowIndex: number): number {
        if (!props.table) return defaultRowHeight;
        if (rowIndex < 0 || rowIndex >= rows) return defaultRowHeight;
        if (filtersActive() && rowIndex >= filterRows.length) return defaultRowHeight;
        if (rowIndex >= rows) return defaultRowHeight; //New Row row counts as a table row but does not apply to our data
        if (props.rhCache.has(rowIndex)) {
            return props.rhCache.get(rowIndex) ?? defaultRowHeight;
        }

        let maxFound = defaultRowHeight;
        for (let i = 0; i < columns.length; i++) {
            const [trueCol, trueRow, mTable] = getCellFromData([i, rowIndex]);
            if (!mTable || trueCol < 0 || trueRow < 0) {
                // if (props.changesTable) console.log("bailed-col:" + i + "-row:" + rowIndex)
                // if (props.changesTable) console.log("bailreso-col:" + trueCol + "-row:" + trueRow + "-mtable:" + mTable?.name)
                continue;
            }

            const row = mTable.table[trueRow];
            if (row === undefined || trueCol >= row.values.length) continue;
            const item = row.values[trueCol][0];//TODO safety bail
            if (!item) continue;
            const width = getTextWidth(item.value);
            const columnWidth: number = mTable.columns[trueCol].width ?? colMaximum;
            switch (item.type) {
                case "text":
                case "string":
                case "deviation":
                case "translation":
                    if (width < colMaximum) break;
                    maxFound = Math.max(maxFound, getConstrainedTextHeight(item.value, item.type, columnWidth));
                    break;
                case "image":
                    const imageData: ImageData = JSON.parse(item.value);
                    const trueImageWidth = imageData.width;
                    const trueImageHeight = imageData.height;
                    const imgWidth = Math.min(trueImageWidth, columnWidth);
                    const imgHeight = Math.max(trueImageHeight * (imgWidth / trueImageWidth), defaultRowHeight)

                    maxFound = Math.max(maxFound, imgHeight);
                    break;
                case "diffs":
                    if (width < colMaximum) break;

                    maxFound = Math.max(maxFound, getConstrainedTextHeight(item.value, item.type, columnWidth));
                    break;
                case "departments":
                case "chips":
                    const parts = item.value.split('|')
                    if (parts.length !== 2) break;
                    const items = parts[0].split(",")
                    let runningWidth = 0;
                    const chipHeight = 20;
                    const vSpacing = 7;
                    const hSpacing = 0;
                    let runningHeight = vSpacing + chipHeight
                    for (const it of items) {
                        let w = 0;
                        if (textWidthCache.has(it)) w = textWidthCache.get(it)!
                        else {
                            // w = (getTextWidth(it, "13px Inter, Roboto, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, noto, arial, sans-serif") / 2) + 14
                            w = getTextWidth(it, "13px Inter, Roboto, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, noto, arial, sans-serif")
                            textWidthCache.set(it, w)
                        }
                        if (runningWidth + w <= columnWidth) {
                            runningWidth += hSpacing + w;
                        } else {
                            runningWidth = w;
                            runningHeight += vSpacing + chipHeight
                        }
                    }
                    runningHeight += vSpacing;
                    maxFound = Math.max(maxFound, runningHeight);
                    break;
                default:
                    break;
            }
        }
        props.rhCache.set(rowIndex, maxFound);
        return maxFound;//TODO
    }

    function indexBefore(targetIndex: number) {
        return targetIndex === 0;
    }

    function updateHistory(cellId: string, originalVal: Value, newVal: Value) {
        //Cell specific history
        setChanges((prevHistory => {
            const newHistory = new Map(prevHistory);
            let currentList = newHistory.get(cellId) ?? [];
            if (currentList.length > 0 && changeIndexes.get(cellId) !== currentList.length) {
                currentList = currentList.slice(0, changeIndexes.get(cellId))
            }
            newHistory.set(cellId, [...currentList, {
                event_id: "", // TODO having access to this will allow error rollback
                type: CELL_DATA_UPDATE,
                original_value: originalVal,
                new_value: newVal,
                changed: new Date().getTime(),
            }])
            return newHistory;
        }));
        //Cell specific history index
        setChangeIndexes((prevIdxs => {
            const newIdxs = new Map(prevIdxs);
            newIdxs.set(cellId, (newIdxs.get(cellId) ?? 0) + 1)
            return newIdxs;
        }));

        //Global history chain
        setChangeKeys((prevKeys => {
            if (changeKeysIndex < prevKeys.length - 1) console.log("wipe forward")
            // Wipe forward history if a change is made mid-history
            const newKeys = [...(changeKeysIndex < prevKeys.length - 1 ? prevKeys.slice(0, changeKeysIndex + 1) : prevKeys)];
            newKeys.push(cellId);
            return newKeys;
        }));
        //GHC index
        setChangeKeysIndex(changeKeysIndex + 1);
    }

    function addColumn(targetIndex: number, name: string, type: string, baseData: string, skip_message: boolean = false): string {
        if (!props.table || targetIndex < -1 || (targetIndex === 0 && freezeCols === 1)) return "";
        if (targetIndex === -1) targetIndex = props.table.columns.length;

        const ref_col_id = indexBefore(targetIndex) ? props.table.columns[0].col_id : props.table.columns[targetIndex - 1].col_id;
        const col_id = crypto.randomUUID();
        props.table.columns.splice(targetIndex, 0, { col_id: col_id, name: name, width: getTextWidth(name) }); //TODO mismatch with 64
        setColumns(prevColumns => {
            const newCols = [...prevColumns];
            newCols.splice(targetIndex, 0, {
                id: col_id,
                icon: type,
                title: name,
                width: getTextWidth(name),
                hasMenu: true,
            });
            return newCols;
        });
        props.table.table.forEach((row, i) => row.values.splice(targetIndex, 0, [{ cell_id: "", type: "loading", value: "" + i }]));

        //Reveal new col
        if (targetIndex === props.table.columns.length - 1) {
            setTimeout(() => {//bodge
                document.getElementsByClassName("dvn-scroller")[0].scrollLeft = 100000;//double-bodge
            }, 50);
        }
        closeMenus();

        if (props.onChange && !skip_message) {
            props.rhCache.clear();

            const message: ColumnCreate = {
                col_id: col_id,
                ref_col: ref_col_id,
                before: indexBefore(targetIndex),
                type: type,
                name: name,
                options: baseData
            };
            props.onChange(COLUMN_CREATE, message);
        }
        return col_id;
    }

    function addGenColumn(targetIndex: number, name: string, type: string, request: string) {
        if (!props.table || targetIndex < -1 || (targetIndex === 0 && freezeCols === 1)) return;
        const col_id = addColumn(targetIndex, name, "loading", "", true);
        if (props.onChange) {
            props.rhCache.clear();
            const ref_col_id = indexBefore(targetIndex) ? props.table.columns[0].col_id : props.table.columns[targetIndex - 1].col_id;
            const message: GenColumnCreate = {
                col_id: col_id,
                ref_col: ref_col_id,
                before: indexBefore(targetIndex),
                type: type,
                name: name,
                request: request
            };
            props.onChange(GEN_COLUMN_CREATE, message);
        }
    }

    function freezeFirstColumn() {
        setFreezeCols(freezeCols === 0 ? 1 : 0);
        setColumns(prevColumns => {
            const newCols = [...prevColumns];
            newCols.splice(0, 1, {
                ...prevColumns[0],
                icon: freezeCols === 1 ? getColIcon(0, false) : "snowflake",
            });
            return newCols;
        });
        if (freezeCols === 1) {
            setCurrentSelection([0, currentSelection[1]])
            setSelection({
                current: {
                    cell: [0, currentSelection[1]],
                    range: {
                        x: 0,
                        y: currentSelection[1],
                        width: 1,
                        height: 1
                    },
                    rangeStack: []
                },
                rows: CompactSelection.empty(),
                columns: CompactSelection.empty(),
            })
        }
    }

    //deleteColumn expects a props.table index, not a true table index
    function deleteColumn(index: number) {
        if (!props.table || index < 0) return;
        const col_id = props.table.columns[index].col_id;
        props.table.columns.splice(index, 1);
        setColumns(prevColumns => {
            const newCols = [...prevColumns];
            newCols.splice(index, 1);
            return newCols;
        })

        for (let i = 0; i < props.table.table.length; i++) {
            props.table.table[i].values.splice(index, 1);
        }

        closeMenus();
        if (props.onChange) {
            props.rhCache.clear();
            const message: ColumnDelete = {
                col_id: col_id
            }
            props.onChange(COLUMN_DELETE, message);
        }
    }

    //renameColumn expects a props.table index, not a true table index
    function renameColumn(index: number, newName: string) {
        if (!props.table || index < 0) return;
        const col_id = props.table.columns[index].col_id;
        while (props.table.columns.map(c => c.name.toLowerCase()).includes(newName.toLowerCase())) newName += "_";
        props.table.columns[index].name = newName;
        setColumns(prevColumns => {
            const newCols = [...prevColumns];
            newCols.splice(index, 1, {
                ...newCols[index],
                title: newName,
            });
            return newCols;
        });

        closeMenus();
        if (props.onChange) {
            const message: ColumnDataUpdate = {
                col_id: col_id,
                col_data: { name: newName, width: -1 } //-1 width = ignore
            };
            props.onChange(COLUMN_DATA_UPDATE, message);
        }
    }

    function onColumnResize(_column: GridColumn, newSize: number, colIndex: number) {
        if (newSize <= 0) newSize = 1;
        const [trueCol, , mTable] = getCellFromData([colIndex, 0]);
        if (!mTable) return;

        setColumns(prevColumns => {
            const newColumns = [...prevColumns];
            newColumns.splice(colIndex, 1, {
                ...newColumns[colIndex],
                width: newSize,
            });
            return newColumns;
        });

        mTable.columns[colIndex].width = newSize;

        if (props.onChange) {
            const col_id = mTable === props.table ? mTable.columns[trueCol].col_id + (props.changesTable ? changeColPrefix : "") : getSpecId() + trueCol;
            localStorage.setItem("COLWIDTH_" + col_id, String(newSize));
            // const message: ColumnDataUpdate = {
            //     col_id: col_id, //blank name = ignore
            //     col_data: {name: "", width: newSize}
            // };
            // props.onChange(COLUMN_DATA_UPDATE, message);
        }
        props.rhCache.clear();
    }

    function onColumnMoved(startIndex: number, endIndex: number) {
        if (!props.table || startIndex < 0 || endIndex < 0 || props.readonly) {
            return;
        }
        if (freezeCols === 1 && (startIndex === 0 || endIndex === 0)) {
            return;
        }
        const [trueStartCol, , mTable] = getCellFromData([startIndex, 0]);
        const [trueEndCol, , endMTable] = getCellFromData([endIndex, 0]);
        if (!mTable || endMTable !== mTable) {
            return;
        }

        const col_id = mTable.columns[trueStartCol].col_id;
        if (trueStartCol === trueEndCol && trueEndCol !== 0) return;
        const directionOffset = trueStartCol > trueEndCol ? 1 : 0; // Handle moving items to the right
        const ref_col_id = trueEndCol === 0 ? mTable.columns[0].col_id : mTable.columns[trueEndCol - directionOffset].col_id; //TODO validate these offsets

        //Move column labels
        const [deleted] = mTable.columns.splice(trueStartCol, 1)
        mTable.columns.splice(trueEndCol, 0, deleted)

        setColumns(prevColumns => {
            const newCols = [...prevColumns];
            const [deleted] = newCols.splice(trueStartCol, 1);
            newCols.splice(trueEndCol, 0, deleted);
            return newCols;
        });

        //Move data
        for (let i = 0; i < mTable.table.length; i++) {
            const row = mTable.table[i];
            const [deleted] = row.values.splice(trueStartCol, 1);
            row.values.splice(trueEndCol, 0, deleted);
        }
        if (props.onChange && mTable === props.table) { //TODO do we persist diff cols order
            const message: ColumnMove = {
                col_id: col_id,
                ref_col: ref_col_id,
                before: trueEndCol === 0
            };
            props.onChange(COLUMN_MOVE, message);
        }
    }

    function onFillPattern(event: FillPatternEventArgs) {
        event.preventDefault(); // We control this now

        //Validate that base cell is one of our editable custom cells
        const baseCell = getCellContent([event.patternSource.x, event.patternSource.y]);
        if (!("data" in baseCell)) return;

        const baseData = baseCell.data ?? "";
        if (!("kind" in baseData) || !("cellId" in baseData)) return;

        //Apply our base cell data with appropriate cellIds
        for (let row = 1; row < event.fillDestination.height; row++) {
            const targetCell = getCellContent([event.patternSource.x, event.patternSource.y + row]);

            if ("data" in targetCell) { // Did we get extra data?
                const targetData = targetCell.data ?? "";
                if ("kind" in targetData && "cellId" in targetData) { //Is it one of our editable custom cells?
                    baseData["cellId"] = targetData["cellId"];
                    // @ts-expect-error We're safe here at this point, it's definitely an EditableGridCell
                    onCellEdited([event.patternSource.x, event.patternSource.y + row], baseCell);
                }
            }
        }
    }

    function onCellEdited([col, row]: Item, newValue: EditableGridCell) {
        if (!props.table || row < 0 || props.readonly || newValue.kind === "image") return;
        let data = newValue.data ?? "";
        let type = "string";
        let cell_id = "";

        if (Object.prototype.hasOwnProperty.call(data, "kind")) {
            // @ts-expect-error Data can be an object or a string, therefore accessing fields on it can cause type errors
            switch (data["kind"]) {
                case "tags-cell":
                    // @ts-expect-error Data can be an object or a string, therefore accessing fields on it can cause type errors
                    data = data["tags"].join(",") + "|" + data["possibleTags"].map(t => t["tag"] + "^" + t["color"]).join(",");
                    type = "chips";
                    break;
                case "pdropdown-cell":
                    // @ts-expect-error Data can be an object or a string, therefore accessing fields on it can cause type errors
                    cell_id = data["cellId"];
                    // @ts-expect-error Data can be an object or a string, therefore accessing fields on it can cause type errors
                    data = (data["value"]["tag"] !== "" ? data["value"]["tag"] + "^" + data["value"]["color"] : "") + "|" + data["allowedValues"].map(t => t["tag"] + "^" + t["color"]).join(",");
                    type = "dropdown";
                    break;
                case "pchips-cell":
                    // @ts-expect-error Data can be an object or a string, therefore accessing fields on it can cause type errors
                    cell_id = data["cellId"];
                    // @ts-expect-error Data can be an object or a string, therefore accessing fields on it can cause type errors
                    data = data["values"].map(v => v["tag"]).join(",") + "|" + data["allowedValues"].map(t => t["tag"] + "^" + t["color"]).join(",");
                    type = "chips";
                    break
                case "comment-cell":
                    return; // Basic safety for comment updates, they can never change here
                case "ai-suggestion-cell":
                    type = "suggestion" //TODO confirm type
                    // @ts-expect-error Data can be an object or a string, therefore accessing fields on it can cause type errors
                    data = data["suggestions"]
                    break;
                case "format-text-cell":
                    // @ts-expect-error Data can be an object or a string, therefore accessing fields on it can cause type errors
                    cell_id = data["cellId"];
                    type = "string"
                    // @ts-expect-error Data can be an object or a string, therefore accessing fields on it can cause type errors
                    data = data["value"]
                    break;
                case "diffs-cell":
                    type = "diffs"
                    // @ts-expect-error Data can be an object or a string, therefore accessing fields on it can cause type errors
                    data = data["value"]
                    break;
            }
        }

        //Safety checks //TODO failing
        let [trueCol, trueRow, mTable] = getCellFromData([col, row]);
        if (!mTable || mTable !== props.table) return;
        if (cell_id !== "" && props.cellMap.has(cell_id)) {
            [trueCol, trueRow] = props.cellMap.get(cell_id) ?? [-1, -1];
        }
        if (trueCol < 0) return;

        let item = props.table.table[trueRow].values[trueCol][0];
        if (cell_id !== item.cell_id) {
            //Recovery attempt
            const tempMap = props.regenCellMap(props.table);
            [trueCol, trueRow] = tempMap.get(cell_id) ?? [-1, -1];
            //Second check
            item = props.table.table[trueRow].values[trueCol][0];
            if (cell_id !== item.cell_id) {
                console.error("failed to perform cell id lookup");
                return;
            }
        }
        if (!item) item = { cell_id: cell_id, type: type, value: "" }

        //Store new value
        const originalValue = item.value;
        item.value = data.toString();
        props.table.table[trueRow].values[trueCol][0] = item;
        if (props.onChange) {
            props.rhCache.delete(row);
            const message: CellDataUpdate = {
                cell_id: cell_id,
                cell_data: item
            }
            props.onChange(CELL_DATA_UPDATE, message);
            updateHistory(
                cell_id,
                { cell_id: cell_id, type: item.type, value: originalValue, isHeading: item.isHeading },
                { cell_id: cell_id, type: item.type, value: item.value, isHeading: item.isHeading }
            );
        }

        if (item.type === "classification") {
            const isHeader = item.value.split('|')[0].toLowerCase().includes("heading");
            props.table.table[trueRow].values.forEach((c, i) => {
                if (c[0].type !== "string") return;
                props.table!.table[trueRow].values[i][0].isHeading = isHeader;
            })
        }
    }

    //rewriteOptions expects a props.table index, not a true table index
    function rewriteOptions(index: number, options: OptionsRewriteOperation[], name_mapping?: Map<string, string>) { //TODO revert name_mapping when id cell is ready
        if (!props.table || index < 0) return;
        const col_id = props.table.columns[index].col_id;
        const renderOptions = !name_mapping ? options : options.map(o => ({
            ...o,
            newName: !o.newName ? o.newName : (name_mapping.get(o.newName) ?? o.newName),
            previousName: !o.previousName ? o.previousName : (name_mapping.get(o.previousName) ?? o.previousName),
        }));

        for (let i = 0; i < props.table.table.length; i++) {
            const current2 = props.table.table[i].values[index][0];
            const parts = current2.value.split("|");
            const strOps = renderOptions.reduce((a: string[], v: OptionsRewriteOperation) => {
                if (v.type === "DELETE") return a;
                return [...a, `${v.newName}^${v.newColour}`]
            }, []).join(",");

            const value = parts[0].split(",").map((v: string) => {
                const op = renderOptions.find((x: OptionsRewriteOperation) => x.previousName === v && v !== "");
                const opC = renderOptions.find((x: OptionsRewriteOperation) => `${x.previousName}^${x.previousColour}` === v);
                if (!op && !opC) return null;
                return op ? op.newName : `${opC?.newName}^${opC?.newColour}`;
            }).filter(x => x).join(",")

            current2.value = value + "|" + strOps;
            props.table.table[i].values[index][0] = current2;
        }

        if (props.onChange) {
            const message: OptionsRewrite = {
                col_id,
                options
            }
            props.onChange(OPTIONS_REWRITE, message);
        } //TODO might need cache clear
    }

    function appendRow(index: number) {
        if (!props.table || index < 0 || filtersActive() || props.readonly || props.changesTable) return; //TODO hide with filter instead

        const rowId = crypto.randomUUID();
        const refRowId = index === 0 ? props.table.table[0].row_id : props.table.table[index - 1].row_id;
        //TODO transfer this logic over to collab service

        const captureRow: Value[][] = props.table.table[0].values;
        props.table.table.splice(index, 0, {
            values: captureRow.map(() => [{ cell_id: crypto.randomUUID(), type: "loading", value: "" }]),
            rect: { x0: 0, x1: 0, y0: 0, y1: 0, page_number: 1 },
            row_id: rowId
        });

        setRows(cv => cv + 1);
        closeMenus();
        if (props.onChange) {
            props.rhCache.clear();
            const message: RowCreate = {
                row_id: rowId,
                ref_row: refRowId,
                before: index === 0,
            };
            props.onChange(ROW_CREATE, message);
        }
    }

    function deleteRow(index: number) {
        if (!props.table || index < 0 || props.changesTable) return;
        const [, trueRow,] = getCellFromData([0, index]);
        const rowId = props.table.table[trueRow].row_id;

        props.table.table.splice(trueRow, 1);

        setRows(cv => cv - 1);
        closeMenus();
        if (props.onRowDelete) props.onRowDelete(rowId);
        if (props.onChange) {
            if (!filtersActive()) props.rhCache.clear();
            const message: RowDelete = {
                row_id: rowId,
            };
            props.onChange(ROW_DELETE, message);
        }
    }

    //Menus
    const [addColMenuOpen, setAddColMenuOpen] = useState(false);
    const [colMenuOpen, setColMenuOpen] = useState(false);
    const [rowMenuOpen, setRowMenuOpen] = useState(false);
    const [addFilterOpen, setAddFilterOpen] = useState(false);
    const [showHideColMenu, setShowHideColMenu] = useState(false);
    const [addColMenu, setAddColMenu] = useState<{
        col: number;
        bounds: Rectangle;
    }>();
    const [colMenu, setColMenu] = useState<{
        col: number;
        bounds: Rectangle;
    }>();
    const [rowMenu, setRowMenu] = useState<{
        row: number;
        bounds: Rectangle;
    }>();
    const [addFilterPos, setAddFilterPos] = useState([0, 0]);
    const [hideColMenuPos, setHideColMenuPos] = useState([0, 0]);
    function onAddCollClickPositional(e: React.MouseEvent<HTMLButtonElement>, i: number) {
        e.stopPropagation();

        const bounds = {
            x: e.clientX - e.currentTarget.clientWidth / 2,
            y: e.clientY,
            width: e.currentTarget.clientWidth,
            height: e.currentTarget.clientHeight
        };
        setAddColMenu({ col: i, bounds: bounds });
        setAddColMenuOpen(!addColMenuOpen);
    }
    function onAddCollClick(e: React.MouseEvent<HTMLButtonElement>) {
        onAddCollClickPositional(e, -1);
    }
    function onHeaderMenuClick(col: number, bounds: Rectangle) {
        const [trueCol, trueRow, mTable] = getCellFromData([col, 0]);
        if (!mTable || props.readonly || mTable !== props.table || trueCol < 0) return;
        if (mTable.table[trueRow].values[trueCol][0].type === "loading") return;
        setColMenu({ col: trueCol, bounds: bounds });
        setColMenuOpen(!colMenuOpen);
    }
    function handleCellClick([col, row]: Item, event: CellClickedEventArgs) {
        //Update views
        if (col >= 0 && row >= 0) {
            if (!props.table) return;
            // Colour in row for indication
            setRowSelectionRect({
                x: 0,
                y: row,
                width: columns.length,
                height: 1
            })

            //Work out if column is a heading dor bold item
            const [trueCol, trueRow, mTable] = getCellFromData([col, row]);
            setCurrentSelection([col, row]);
            //Jump to rect position in pdf
            if (props.onPageJump) {
                if (mTable !== props.table) {//Rewrite row to main table row first
                    const id = mTable!.table[trueRow].row_id;
                    const dataTableIndex = props.table.table.map(t => t.row_id).indexOf(id);
                    if (dataTableIndex !== -1) props.onPageJump(props.table.table[dataTableIndex].rect);
                } else props.onPageJump(props.table.table[trueRow].rect);
            }
            if (props.onChange) {
                if (mTable !== props.table) return;
                const message: UserCellSelect = {
                    cell_id: mTable.table[trueRow].values[trueCol][0].cell_id,
                };
                props.onChange(USER_CELL_SELECT, message);
            }

            return;
        }
        setCurrentSelection([-1, -1]);
        //Detect row number click
        if (row >= 0) {
            if (props.readonly || props.changesTable) return;
            setRowMenuOpen(!rowMenuOpen);
            const bounds = event.bounds;
            setRowMenu({ row, bounds });
        }
    }
    function openAddFilterMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        openEditFilterMenu(e, undefined, -2)
    }
    function openEditFilterMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, f: Filter | undefined, offset: number = -8) {
        setCurrentFilter(f);
        setAddFilterPos([window.innerWidth - e.currentTarget.offsetLeft - e.currentTarget.clientWidth, e.currentTarget.offsetTop + e.currentTarget.clientHeight - offset]);
        setAddFilterOpen(true);
    }
    function toggleHideColMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setHideColMenuPos([e.currentTarget.offsetLeft, e.currentTarget.offsetTop + e.currentTarget.clientHeight]);
        setShowHideColMenu(!showHideColMenu);
    }
    function closeMenus() {
        setAddColMenuOpen(false);
        setAddColMenu(undefined);
        setColMenuOpen(false);
        setColMenu(undefined);
        setRowMenuOpen(false);
        setRowMenu(undefined);
        setShowHideColMenu(false);
        setHideColMenuPos([0, 0]);
    }

    // manages the image modal, both the click outside and zoom dectection
    const dialogRef = createRef<HTMLDialogElement>();
    useEffect(() => {
        if (!sessionStorage.getItem("displayImageDialog")) return
        dialogRef.current?.showModal();
        dialogRef.current?.style.setProperty('--scale', '1');
        function clickOutside(ref: any, e: any) {
            if (!ref.current?.open) return;
            if (e.target === ref.current) {
                ref.current?.close();
                sessionStorage.removeItem("displayImageDialog")
                dialogRef.current?.style.setProperty('--scale', '1');
            }
        }
        function zoom(ref: any, e: any) {
            let scale = Math.min(Math.max(parseInt(ref.current?.style.getPropertyValue('--scale') ?? '1'), 1), 10);
            scale += e.deltaY * 0.01;
            ref.current?.style.setProperty('--scale', scale.toString());
        }
        dialogRef.current?.addEventListener("click", e => clickOutside(dialogRef, e))
        dialogRef.current?.addEventListener("wheel", e => zoom(dialogRef, e))
        return () => {
            dialogRef.current?.removeEventListener("click", e => clickOutside(dialogRef, e))
            dialogRef.current?.removeEventListener("wheel", e => zoom(dialogRef, e))
        }
    }, [dialogRef]);

    return (
        <div id={"tableidtest" + (props.changesTable ? "change" : "norm")} className="overflow-auto min-w-fit flex flex-col gap-y-2 pt-4 pr-4">
            <dialog ref={dialogRef} style={{ "--scale": 1, scale: "clamp(1,var(--scale),10)", transition: "scale .1s" }}>
                <img className='select-none' src={sessionStorage.getItem("displayImageDialog")!} />
            </dialog>
            <div className="flex no-border justify-between">
                <div className="flex gap-x-1">
                    {props.toggleView && <>
                        <button className={"flex gap-x-1 secondary-button border-0 border-b-2 rounded-none px-2 input-invisible text-black " + (props.isDifference ? "border-black" : "border-transparent")} onClick={props.toggleView}>
                            <Table size={22} strokeWidth={1.8} color={props.isDifference ? theme.accentColor : "#000"} />Comparison
                        </button>
                        <button className={"flex gap-x-1 secondary-button border-0 border-b-2 rounded-none px-2 input-invisible text-black " + (!props.isDifference ? "border-black" : "border-transparent")} onClick={props.toggleView}>
                            <Table size={22} strokeWidth={1.8} color={!props.isDifference ? theme.accentColor : "#000"} />New Version
                        </button>
                    </>}
                </div>
                <div className="flex gap-x-1 place-content-end">
                    <button className="flex secondary-button px-2 input-invisible text-black" {...searchPopup.props} onClick={() => setShowSearch(!showSearch)}>
                        <Search />
                    </button>
                    {searchPopup.component}
                    <button className="flex secondary-button px-2 text-black" {...hideColumnsPopup.props} onClick={toggleHideColMenu}>
                        <EyeOff color="black" height="20" />
                    </button>
                    {hideColumnsPopup.component}
                    <button className="flex secondary-button px-2 text-black" {...applyFilterPopup.props} onClick={() => { toggleFilterView() }}>
                        <ListFilter color="black" height="20" />
                    </button>
                    {applyFilterPopup.component}
                    <button className="flex secondary-secondary-button gap-x-2 text-black" onClick={exportToXlsx} disabled={disableDL}>
                        <Upload color="black" height="20" />
                        Export
                    </button>
                </div>
            </div>
            <div className={`flex flex-wrap gap-2 no-border justify-end ${!showFilter && "hidden"}`}>
                {filters.map((f, i) =>
                    <button className="flex clear-button hover:bg-neutral-100 items-center input-invisible gap-x-2 px-2 border rounded-2xl" style={{ borderColor: colours[i + 1] }} onClick={(e) => openEditFilterMenu(e, f)}>
                        {getLucideIconByType(f.column_type, colours[i + 1], 16)}
                        <span className="text-sm font-medium text-purple-600" style={{ color: colours[i + 1] }}>{getFilterLabel(f)}</span>
                        <ChevronDown color={colours[i + 1]} strokeWidth={3} width={16} />
                        <div className="w-2 h-2 bg-purple-500 rounded-full relative top-0" style={{ backgroundColor: f.active ? "#4ade80" : "#f87171" }}></div>
                    </button>
                )}
                <button className="flex gap-x-2 input-invisible text-sm" onClick={openAddFilterMenu}>
                    <Plus height="18" />
                    Add filter
                </button>
                <AddFilterMenu
                    isOpen={addFilterOpen}
                    setOpen={setAddFilterOpen}
                    position={addFilterPos}
                    filters={filters}
                    currentFilter={currentFilter}
                    onFilterChanged={filterUpdate}
                    onDeleteFilter={deleteFilter}
                    setCurrentFilter={(f) => setCurrentFilter(f)}
                    onAddFilter={addFilter}
                    table={props.table ?? { name: "", columns: [], table: [] }}
                    diffTable={props.changesTable ?? { name: "", columns: [], table: [] }}
                />
            </div>
            <DataEditor ref={tableRef}
                onKeyDown={gridKeyHandler}
                width={"100%"}
                theme={theme}
                className="rounded-lg"
                customRenderers={ourRenderers}
                getCellContent={getCellContent}
                columns={columns}
                rightElement={<>
                    {!props.readonly &&
                        <div className="add-col-button items-center flex py-0">
                            <button
                                className="secondary-button p-[0.25em]"
                                onClick={onAddCollClick}
                                {...addColumnPopup.props}
                            >
                                <Plus absoluteStrokeWidth={true} width={20} height={20} color="black" />
                            </button>
                            {addColumnPopup.component}
                        </div>
                    }</>
                }
                rightElementProps={{
                    fill: false,
                    sticky: true,
                }}
                rows={rows}
                rowMarkers="clickable-number"
                onCellClicked={handleCellClick}
                scaleToRem={true}
                maxColumnAutoWidth={500}
                maxColumnWidth={2000}
                onColumnResize={onColumnResize}
                headerIcons={headerIcons}
                onColumnMoved={onColumnMoved}
                onCellEdited={onCellEdited}
                onPaste={true}
                // onRowAppended={() => appendRow(props.table?.table.length ?? -1)}
                trailingRowOptions={{
                    hint: props.readonly || props.changesTable ? "Read-only version" : (filtersActive() ? "Unavailable in filtered view" : " "),
                    tint: true,
                    sticky: true,
                }}
                rowSelect="none"
                rowSelectionMode="auto"
                onHeaderMenuClick={onHeaderMenuClick}
                smoothScrollX={true}
                smoothScrollY={true}
                overscrollY={200}
                overscrollX={60}
                showSearch={showSearch}
                onSearchClose={() => setShowSearch(false)}
                fillHandle={true}
                allowedFillDirections={"vertical"}
                onFillPattern={onFillPattern}
                gridSelection={selection}
                onGridSelectionChange={setSelection}
                getCellsForSelection={true}
                freezeColumns={freezeCols}
                rowHeight={r => (getRowHeight(r))}
                onVisibleRegionChanged={(r) => {
                    if (!props.positionStoreLock) {
                        if (r.y + r.height - 1 === -1) return;
                        setLastPos([r.y + r.height - 1, r.x + r.width]);
                    }
                }}
                highlightRegions={[{
                    color: "#6C2BD918",
                    range: rowSelectionRect,
                    style: "no-outline"
                }, ...userSelectionRects]}
            />
            <ColMenu
                setAddColVisible={onAddCollClickPositional}
                onDeleteCol={deleteColumn}
                onRenameCol={renameColumn}
                onRewriteOptions={rewriteOptions}
                onAddCol={addColumn}
                onAddGenCol={addGenColumn}
                firstColFrozen={freezeCols > 0}
                onFreeze={freezeFirstColumn}
                getCol={(i) => { return props.table?.columns[i] ?? { col_id: "", name: "", width: 0 } }}
                menu={colMenu}
                isOpen={colMenuOpen}
                setOpen={setColMenuOpen}
                table={props.table ?? { name: "", columns: [], table: [] }}
                department_config={props.department_config}
                department_name_mapping={props.department_name_mapping}
            />
            <AddColMenu
                onAddCol={addColumn}
                onAddGenCol={addGenColumn}
                getCol={(i) => { return props.table?.columns[i] ?? { col_id: "", name: "", width: 0 } }}
                menu={addColMenu}
                isOpen={addColMenuOpen}
                setOpen={setAddColMenuOpen}
                table={props.table ?? { name: "", columns: [], table: [] }}
            />
            <RowMenu
                onAddRow={appendRow}
                onDeleteRow={deleteRow}
                isOpen={rowMenuOpen}
                hideInserts={filtersActive()}
                menu={rowMenu}
            />
            <HideMenu open={showHideColMenu} setOpen={setShowHideColMenu} position={hideColMenuPos} cols={props.table?.columns ?? []} onHideChange={setHiddenCols} hiddenCols={hiddenCols} table={props.table ?? { name: "", columns: [], table: [] }} />
        </div>
    )
}

export default ResultTable;
