import { type CustomCell, type ProvideEditorCallback, type CustomRenderer, GridCellKind } from "@glideapps/glide-data-grid";
import { AISuggestionReference } from "../../types/types";
import { ExternalLink, Copy, ChevronDown, ChevronUp, Check, EllipsisVertical, PowerOff, Info } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { APIResponse, BASEURL, request, getFetchHeaders } from "services/api";
import { Specification } from "types/requests";
import { usePopup } from "hooks/Popup";

interface AISuggestionCellProps {
    readonly kind: "ai-suggestion-cell";
    suggestions: string
}
export type AISuggestionCell = CustomCell<AISuggestionCellProps>;

const Editor: ReturnType<ProvideEditorCallback<AISuggestionCell>> = p => {
    const { value: cell, onFinishedEditing } = p;
    const [suggestions, setSuggestions] = useState<AISuggestionReference[]>([]);
    const [specInfos, setSpecInfos] = useState<Specification[]>([])
    const [expandedSuggestion, setExpandedSuggestion] = useState<number>();
    const [copied, setCopied] = useState<number[]>([]);
    const disableDialog = useRef<HTMLDialogElement>(null);
    const [selectedSuggestion, setSelectedSuggestion] = useState<number>();
    const moreOptionsPopup = usePopup("More Options", "left")

    useEffect(() => {
        let d = async () => {
            const data = JSON.parse(cell.data.suggestions).map((x: { id: string }) => x.id);
            let sug_data: AISuggestionReference[] = await fetch(`${BASEURL}/deviations/${encodeURIComponent(data.join(","))}`, {
                headers: {
                    ...getFetchHeaders(),
                    "Content-Type": "application/json",
                    "Cache-Control": "private"
                }
            }).then((res) => {
                if (res.ok) return res.json()
                throw Error("request failed")
            })

            sug_data.forEach((x) => x.url = `/specifications/view/${x.spec_id}?row_id=${x.row_id}`)
            setSuggestions(sug_data)

            let specIds = sug_data.map(x => x.spec_id)
            let spec_data = await Promise.allSettled(specIds.map(x => fetch(`${BASEURL}/specifications/${x}/info`, {
                headers: {
                    ...getFetchHeaders(),
                    "Content-Type": "application/json",
                    "Cache-Control": "private"
                }
            }))).then(x => x.filter(y => y.status === "fulfilled").map((y: any) => y.value))

            let spec_infos = await Promise.allSettled(spec_data.map(x => x.json()))
                .then(x => x.filter(y => y.status === "fulfilled").map((y: any) => y.value))

            setSpecInfos(spec_infos)
        }
        d()
    }, [cell.data.suggestions]);
    useEffect(() => {
        if (localStorage.getItem("lastpage") !== location.origin + location.pathname) {
            Object.keys(localStorage).forEach(function(key) {
                if (key.startsWith("suggrating")) localStorage.removeItem(key);
            });
            localStorage.setItem("lastpage", location.origin + location.pathname);
        }
    }, [cell]);

    async function writeChange(new_suggestions: AISuggestionReference[]) {
        await new Promise(r => window.requestAnimationFrame(r));
        const data = { ...cell }
        data.data.suggestions = JSON.stringify(new_suggestions.map(x => ({ id: x.id })))
        onFinishedEditing(data);
    }


    function copy(index: number, suggestion: AISuggestionReference) {
        navigator.clipboard.writeText(suggestion.suggestion)
        setCopied([...copied, index]);
        setTimeout(() => {
            setCopied(copied.filter(x => x !== index));
        }, 1000);
    }

    function openDisableDialog() {
        disableDialog.current?.showModal();
    }

    function openMenu(index: number) {
        setSelectedSuggestion(selectedSuggestion === index ? undefined : index)
    }

    function openSource(index: number) {
        const x = suggestions[index]
        window.open(window.location.origin + `/specifications/view/${x.spec_id}?row_id=${x.row_id}`, "_blank")
    }

    function disableSuggestion() {
        if (selectedSuggestion === undefined) return;
        const suggestion: AISuggestionReference = suggestions[selectedSuggestion];
        request("PUT", `/deviations/${suggestion.id}/disable`).subscribe({
            next: (r: APIResponse<unknown>) => {
                if (!r.ok || r.statusCode !== 200) {
                    alert("Failed to deactivate suggestion")
                    return; //TODO logs

                }

                setSuggestions(suggestions.filter(x => x.id !== suggestion.id))
                setSelectedSuggestion(undefined)
                disableDialog.current?.close()
                writeChange(suggestions.filter(x => x.id !== suggestion.id))
            },
            error: (e) => {
                console.error(e);
            },
            complete: () => {
            }
        })

    }

    return (
        <div style={{ display: "flex flex-col", alignItems: "center", padding: "", color: "#000", width: "650px" }} >
            <dialog
                className="dialog bg-white max-w-sm"
                ref={disableDialog}
                onClick={(e) => {
                    const dialogDimensions = disableDialog.current?.getBoundingClientRect();
                    if (
                        dialogDimensions && (
                            e.clientX < dialogDimensions.left ||
                            e.clientX > dialogDimensions.right ||
                            e.clientY < dialogDimensions.top ||
                            e.clientY > dialogDimensions.bottom)
                    ) {
                        disableDialog.current?.close();
                    }
                }}
            >
                <div className="font-bold">Deactive Suggestion</div>
                <div>Are you sure you want deactivate this deviation across the whole knowledge base? Deactivated deviations will not be shown as suggestions.</div>
                <div className="flex justify-end gap-3">
                    <button className="clear-button text-zinc-950" onClick={() => disableDialog.current?.close()}>Cancel</button>
                    <button className="bg-red-600 hover:bg-red-700" onClick={() => disableSuggestion()}>Deactivate</button>
                </div>

            </dialog>

            <div className="max-h-72 overflow-y-auto p-4 gap-y-2 flex flex-col">
                <div className="flex justify-between">
                    <div className="tabs">
                        <div className="tab-button selected">Deviations</div>
                        {/*<div className="tab-button">Standards</div>*/}
                    </div>
                    <div className="relative">
                        <Info className="infoPopupTrigger" />
                        <div className="infoPopup hidden absolute z-50 bg-white w-xs" style={{ right: "2rem", width: "30rem", top: 0 }}>You can manage your knowledge base in the knowledge section. Found by clicking on your profile in the bottom left corner of the screen”</div>
                    </div>
                </div>
                {moreOptionsPopup.component}
                {suggestions.map((s, i) => {
                    return (
                        <div key={i} className="">
                            <div className="relative grid place-items-center gap-1" style={{ gridTemplateColumns: "35px 1fr min-content min-content" }}>
                                <button onClick={() => setExpandedSuggestion(expandedSuggestion === i ? undefined : i)} className="clear-button text-zinc-950">
                                    {expandedSuggestion === i ? <ChevronUp /> : <ChevronDown />}
                                </button>
                                <div className="w-full">
                                    {s.suggestion}
                                </div>
                                <button className="secondary-button text-zinc-950 h-9 px-2 py-0 flex place-content-center font-semibold gap-1"
                                    onClick={() => copy(i, s)}
                                >
                                    {copied.includes(i) ? (<><Check width={19} /> Copied!</>) : (<><Copy width={19} /> Copy</>)}
                                </button>
                                <button onClick={() => openMenu(i)} {...moreOptionsPopup.props} className="clear-button text-zinc-950">
                                    <EllipsisVertical width={19} />
                                </button>
                                <div className={`absolute menu ${selectedSuggestion !== i ? "hidden" : ""}`} style={{ right: "25px" }}>
                                    <button onClick={() => openDisableDialog()} className="menu-button text-zinc-950 flex gap-1" >
                                        <PowerOff width={19} /> Deactivate in knowledge base
                                    </button>
                                    <button className="menu-button text-zinc-950 flex gap-1" onClick={() => openSource(i)} >
                                        <ExternalLink width={19} /> View Source
                                    </button>

                                </div>
                            </div>
                            {
                                expandedSuggestion === i &&
                                <div className="flex flex-col gap-x-1">
                                    <div className="text-gray-400">Requirement</div>
                                    <div>{s.requirement}</div>
                                    <div className="text-gray-400">Source</div>
                                    <div className="flex gap-1 items-center underline cursor-pointer" onClick={() => openSource(i)}><ExternalLink width={14} />
                                        {specInfos.find(x => x.project_id === s.spec_id)?.table_data.name}
                                    </div>
                                </div>
                            }

                        </div>
                    )
                })}
            </div >
        </div >
    );
};

const renderer: CustomRenderer<AISuggestionCell> = {
    kind: GridCellKind.Custom,
    isMatch: (cell: CustomCell): cell is AISuggestionCell => (cell.data as AISuggestionCellProps).kind === "ai-suggestion-cell",
    needsHover: false,
    draw: (args, cell) => {
        const { ctx, theme, rect } = args;
        const { suggestions } = cell.data;
        const drawX = rect.x + theme.cellHorizontalPadding;
        const suggestionArr = JSON.parse(suggestions);
        const count = !suggestions || suggestions === "" || suggestionArr.length === 0 ? 0 : suggestionArr.length; //TODO extract properly
        ctx.font = "8px"
        ctx.fillStyle = "#222"; //TODO move right and draw sparkles~
        ctx.fillText(count > 0 ? `${count} AI Suggestion${count > 1 ? "s" : ""}` : "", drawX, rect.y + rect.height / 2 + 2);
        return true;
    },
    provideEditor: () => ({
        editor: Editor,
        disablePadding: true,
        deletedValue: v => ({
            ...v,
            copyData: "",
            data: {
                ...v.data,
                value: "",
            },
        }),
    }),
    onPaste: (_val, d) => { return d },
};

export default renderer;
