import Checkbox from "components/FormElements/Checkbox.tsx";
import { ChevronDown, ChevronRight, CircleCheck, SquareX } from "lucide-react";
import React from "react";

export enum GroupState {
    ALL_SELECTED = "all",
    SOME_SELECTED = "some",
    NONE_SELECTED = "none",
}

interface CheckTreeProps {
    className?: string;
    emptyMessage: string;
    treeData: Map<string, string[]>;
    expandedGroup: string;

    getGroupState: (group: string) => GroupState;
    getGroupLabel?: (group: string) => string;
    onGroupToggleExpand: (group: string) => void;
    onToggleGroup?: (group: string) => void;

    getItemState: (group: string, item: string) => boolean;
    getItemIconText?: (group: string, item: string) => string;
    getItemLabels?: (group: string, item: string) => string[];
    onToggleItem: (group: string, item: string, checked: boolean) => void;
    noItemsInGroupMessage: string;

    showFlagIcon?: boolean;

    buttonMode?: boolean;
    buttonTextWhenChecked?: string;
    buttonTextWhenUnchecked?: string;
}

function CheckTree(props: CheckTreeProps) {
    function toggleGroupExpand(e: React.MouseEvent<HTMLButtonElement>, group: string) {
        e.stopPropagation();
        props.onGroupToggleExpand(group);
    }

    function getGroupLabel(group: string) {
        if (!props.getGroupLabel) {
            return group;
        }
        return props.getGroupLabel(group);
    }

    return (
        <div className={props.className}>
            {props.treeData.size === 0 &&
                <div className="text-sm text-red-600">{props.emptyMessage}</div>
            }
            {Array.from(props.treeData.keys()).map((group) => (
                <div key={group} className="flex flex-col items-start">
                    <div className="flex items-center h-5">
                        {props.onToggleGroup && !props.buttonMode &&
                            <Checkbox
                                checked={props.getGroupState(group) === GroupState.ALL_SELECTED}
                                indeterminate={props.getGroupState(group) === GroupState.SOME_SELECTED}
                                onChange={() => { if (props.onToggleGroup) props.onToggleGroup(group) }}
                                disabled={props.treeData.get(group)?.length === 0}
                            />
                        }

                        <button className="clear-button text-black input-invisible" onClick={(e) => toggleGroupExpand(e, group)}>
                            {props.expandedGroup === group ? <ChevronDown /> : <ChevronRight />}
                        </button>
                        <span className="ml-2 text-sm">{getGroupLabel(group)}</span>
                        {props.onToggleGroup && props.buttonMode &&
                            <button
                                className={`py-1 px-2 ${props.getGroupState(group) && "secondary-button text-black"}`}
                                onClick={() => props.onToggleGroup!(group)}
                                disabled={props.treeData.get(group)?.length === 0}
                            >
                                {props.getGroupState(group) ? props.buttonTextWhenChecked : props.buttonTextWhenUnchecked}
                            </button>}

                    </div>

                    {props.expandedGroup === group && props.treeData.get(group)?.length === 0 && <span className="mt-2 pl-14">{props.noItemsInGroupMessage}</span>}

                    {props.expandedGroup === group && props.treeData.get(group)?.map((item) => (
                        <div key={item} className={"flex gap-x-2 items-center w-full mt-2 " + (!props.showFlagIcon ? "pl-14" : "pl-4")}>
                            {!props.buttonMode &&
                                <div className="flex">
                                    <Checkbox
                                        checked={props.getItemState(group, item)}
                                        onChange={() => props.onToggleItem(group, item, !props.getItemState(group, item))}
                                    />
                                </div>
                            }

                            {props.buttonMode &&
                                (props.getItemState(group, item) ? <CircleCheck style={{ minWidth: '26px' }} width={40} className="text-emerald-500" /> : <SquareX style={{ minWidth: '26px' }} width={38} className="text-red-500" />)
                            }

                            {props.getItemIconText &&
                                <div className="flex p-2 rounded-full w-8 h-8 items-center place-content-center text-white text-sm select-none bg-primary-100">
                                    {props.getItemIconText(group, item)}
                                </div>
                            }
                            <div className="flex gap-x-8 w-full overflow-hidden">
                                {props.getItemLabels && props.getItemLabels(group, item).map((l, i, a) => <span className={i === a.length - 1 ? "truncate" : ""}>{l}</span>)}
                                {!props.getItemLabels && <span>{item}</span>}
                            </div>
                            {
                                props.buttonMode &&
                                <div className="flex pr-2">
                                    <button
                                        className={`py-1 px-2 ${props.getItemState(group, item) && "secondary-button text-black"}`}
                                        onClick={() => props.onToggleItem(group, item, !props.getItemState(group, item))}
                                    >
                                        {props.getItemState(group, item) ? props.buttonTextWhenChecked : props.buttonTextWhenUnchecked}
                                    </button>
                                </div>
                            }
                        </div>
                    ))}
                </div>
            ))
            }
        </div >
    );
}

export default CheckTree;
