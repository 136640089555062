import React, { useEffect, useState } from "react";
import 'App.css'
import { Folder } from "lucide-react";
import BreadcrumbMenu from "../BreadcrumbViewer";
import { APIResponse, request } from "services/api";
import { Breadcrumb, Folder as folderType, FolderListResponse } from "types/requests";

interface MenuProps {
    file_id: string;
    start_dir: string;
    name: string
    moveFolder: boolean;
    closeMenu: () => void;
}
// TODO: values besides pure strings
function MoveDialogContent(props: MenuProps) {
    const [loading, setLoading] = useState<boolean>(true);
    const [folders, setFolders] = useState<folderType[]>([]);
    const [breadcrumb, setBreadcrumb] = useState<Breadcrumb[]>([{ name: "Home", id: "" }]);
    const [moving, setMoving] = useState<boolean>(false);
    const [startFolderName, setStartFolderName] = useState<string>("");
    const zeroUUID = "00000000-0000-0000-0000-000000000000";

    useEffect(() => {
        console.log(props.start_dir)
        changeFolder(props.start_dir, true);
    }, [props.start_dir]);

    function changeFolder(id?: string, start?: boolean) {
        setLoading(true);
        request<FolderListResponse>("GET", `/folders${!id || id === zeroUUID ? "" : `/${id}`}`).subscribe({
            next: (r: APIResponse<FolderListResponse>) => {
                if (!r.ok || r.statusCode !== 200 || !r.data) return; //TODO logs
                for (let i = 0; i < r.data.folders.length; i++) {
                    const createdDate = new Date(0);
                    //@ts-expect-error hacky workaround
                    createdDate.setUTCSeconds(r.data.folders[i].created);
                    r.data.folders[i].created = createdDate;
                }
                setFolders(r.data.folders);
                setBreadcrumb(r.data.crumb);
                if (start) setStartFolderName(r.data.crumb[r.data.crumb.length - 1].name);
                setLoading(false);
            },
            error: (e) => {
                console.error(e);
                setLoading(false);
                closeMenu();
                //TODO proper error
            },
        });
    }

    function folderClicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) {
        e.preventDefault();
        e.stopPropagation();
        changeFolder(id);
    }

    function cancelEvent(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
    }

    function closeMenu() {
        setLoading(true);
        setFolders([]);
        setMoving(false);
        setStartFolderName("");
        props.closeMenu();
    }

    function moveFolder() {
        setMoving(true);
        const newId = breadcrumb.length <= 1 ? zeroUUID : breadcrumb[breadcrumb.length - 1].id;
        request<FolderListResponse>("PUT", `/${props.moveFolder ? "folders" : "specifications"}/${props.file_id}/parent/${newId}`).subscribe({
            next: (r: APIResponse<FolderListResponse>) => {
                if (!r.ok || r.statusCode !== 200 || !r.data) return; //TODO logs
                closeMenu();
            },
            error: (e) => {
                console.error(e);
                setMoving(false);
                closeMenu();
                //TODO proper error
            },
        });
    }

    return (
        <div className="min-w-[26rem]">
            <h3 className="select-none text-zinc-950 font-bold pb-4">Move {`"${props.name}"`}</h3>
            <div className="flex gap-x-2 items-center">
                Current location:
                <div className="flex gap-x-2 p-2 border rounded-lg">
                    <Folder />
                    {startFolderName}
                </div>
            </div>

            {loading && <><div className="flex h-10 text-left items-center">Loading...</div> <div className="flex flex-col border h-48 place-content-center">Loading</div></>}
            {!loading && <>
                <BreadcrumbMenu breadcrumb={breadcrumb} onFolderClicked={changeFolder} />
                <div className="flex flex-col border h-48 max-h-48 overflow-y-auto" onClick={cancelEvent}>
                    {breadcrumb.length > 1 &&
                        <button className="flex p-2 bg-white hover:bg-zinc-100 select-none text-black rounded-none text-left" onClick={(e) => folderClicked(e, breadcrumb[breadcrumb.length - 2].id)}>
                            ...
                        </button>
                    }
                    {folders.map(f =>
                        <button
                            className={`flex justify-between p-2 bg-white border-t select-none text-black rounded-none disabled:bg-zinc-300 text-left ${props.moveFolder && props.file_id === f.node_id ? "cursor-not-allowed" : "hover:bg-zinc-100"}`}
                            disabled={props.moveFolder && props.file_id === f.node_id}
                            onClick={(e) => folderClicked(e, f.node_id)}
                        >
                            <span>{f.name}</span>
                            <span className="text-red-400">{props.moveFolder && props.file_id === f.node_id ? "Current folder" : ""}</span>
                        </button>
                    )}
                </div>
            </>}
            <div className="flex gap-x-4 justify-end pt-4">
                <button onClick={closeMenu} className="bg-zinc-100 text-zinc-950 hover:bg-zinc-200">Cancel</button>
                <button disabled={loading || moving || (props.start_dir === zeroUUID && breadcrumb.length <= 1) || props.start_dir === breadcrumb[breadcrumb.length - 1].id || (!props.moveFolder && breadcrumb.length <= 1)} onClick={moveFolder}>Move</button>
                {}
            </div>
        </div>
    )
}

export default MoveDialogContent;
