import React, { useEffect, useRef, useState } from "react";
import 'App.css'
import { ChevronRight } from "lucide-react";
import { Breadcrumb } from "types/requests";
import BreadcrumbMenu from "./menus/BreadcrumbMenu";

interface MenuProps {
    breadcrumb: Breadcrumb[];
    onFolderClicked: (id: string) => void
}
// TODO: values besides pure strings
function BreadcrumbViewer(props: MenuProps) {
    const [breadcrumb, setBreadcrumb] = useState<Breadcrumb[]>([{ name: "Home", id: "" }]);
    const [breadcrumbCutoff, setBreadcrumbCutoff] = useState(3);
    const [showBreadcrumbMenu, setShowBreadcrumbMenu] = useState(false);
    const [breadcrumbMenuPosition, setBreadcrumbMenuPosition] = useState([0, 0]);
    const bce = useRef<HTMLDivElement>(null);

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    function getTextWidth(text: string): number {
        if (!context) return 0;
        context.font = "normal 600 " + getComputedStyle(document.body).font;
        return context.measureText(text).width + 32;
    }

    useEffect(() => {
        setBreadcrumb(props.breadcrumb);
        const updateCutoff = () => {
            if (props.breadcrumb.length > 1) {
                setBreadcrumbCutoff(props.breadcrumb.length)
                if (!bce.current) return;
                let maxWidth = bce.current.clientWidth * 0.75;
                for (let i = props.breadcrumb.length; i > 0; i--) {
                    maxWidth -= getTextWidth(props.breadcrumb[i - 1].name);
                    if (maxWidth < 0) {
                        let cutoff = props.breadcrumb.length - i;
                        if (cutoff < 1) cutoff = 1;
                        setBreadcrumbCutoff(cutoff);
                        break;
                    }
                }
            }
        }
        updateCutoff();

        document.addEventListener("resize", updateCutoff, false);
        return () => {
            document.removeEventListener("resize", updateCutoff, false);
        };
    }, [props.breadcrumb]);

    function openBreadcrumbMenu(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        setBreadcrumbMenuPosition([e.currentTarget.offsetLeft, e.currentTarget.offsetTop + e.currentTarget.clientHeight]);
        setShowBreadcrumbMenu(true);
    }

    return (
        <div ref={bce} className="flex items-center overflow-x-auto">
            {breadcrumb.map((b, i) => <>
                {i !== 0 && i >= breadcrumb.length - breadcrumbCutoff && <div className="text-gray-500"><ChevronRight /></div>}
                {breadcrumb.length > breadcrumbCutoff && i < breadcrumb.length - breadcrumbCutoff ?
                    i == 0 ? <div className="select-none" onClick={openBreadcrumbMenu}>...</div> : <></> :
                    <div
                        className={`py-2 px-1 select-none font-semibold rounded-md text-${i + 1 != breadcrumb.length && breadcrumb.length > 0 ? "gray-500 hover:bg-gray-300" : "black-500"}`}
                        style={
                            // @ts-expect-error No idea why ts doesn't see this
                            { "text-wrap": "nowrap" }
                        }
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            props.onFolderClicked(b.id);
                        }}
                    >
                        {b.name}
                    </div>
                }
            </>)}
            <BreadcrumbMenu open={showBreadcrumbMenu} setOpen={setShowBreadcrumbMenu} position={breadcrumbMenuPosition} onNav={props.onFolderClicked} items={breadcrumb.slice(0, breadcrumb.length - breadcrumbCutoff)} />
        </div>
    )
}

export default BreadcrumbViewer;
