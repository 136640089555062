interface DropdownCellProps {
    id: number;
    disable: boolean;
    options: Map<string, string>;
    selected: string;
    onChange: (selected: string) => void;
}

function DropdownCell(props: DropdownCellProps) {
    return (
        <div>
            <div className="flex items-center h-10">
                <select className="bg-white input p-2 rounded-lg border" disabled={props.disable} value={props.selected} onChange={e => props.onChange(e.target.value)}>
                    {Array.from(props.options.entries()).map(([k, v]) =>
                        <option value={k}>{v}</option>
                    )}
                </select>
            </div>
        </div>
    );
}

export default DropdownCell;